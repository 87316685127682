#campaignMediaCompleted {
  .download-container {
    display: flex;
    justify-content: flex-end;

    #downloadAllBtn {
      .type-sfpro-bold() !important;
      border-radius: 5px;
      height: 30px;
      font-size: 10px !important;
      letter-spacing: 0px;
      line-height: 12px !important;
      text-transform: capitalize !important;
      padding: 0;
      width: 96px;

      &:focus,
      &:hover {
        svg path {
          transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
          stroke: #ffcc40;
        }
      }
    }
  }

  .content-gallery {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 8px;
    grid-row-gap: 8px;
    margin: auto;
    max-width: 742px;
    padding: 0 8px;

    @media (min-width: 700px) {
      grid-template-columns: repeat(3, 1fr);
    }

    &--filters {
      display: flex;
      justify-content: space-between;
      padding: 24px 16px;
      margin: 0 auto;
      max-width: 742px;
      width: 100%;
    }
  }
}
