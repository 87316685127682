#admin-layout {
  overflow-x: hidden;
  .search-filters {
    position: relative;

    padding-top: 15px;
    padding-bottom: 16px;

    .trend-select {
      width: 100%;
      .ant-select-selection__clear {
        right: 25px;
      }
    }

    .search {
      .ant-select-selection {
        background: transparent;
        position: relative;
        .ant-select-selection__clear {
          top: 13px;
        }
      }
      .anticon {
        font-size: 18px;
        color: #777e9b;
      }

      .ant-select-selection__rendered {
        font-size: 17px;
      }
      input {
        background: transparent;
        border: none;
        font-family: 'TruenoLight';
        color: #777e9b;
        font-size: 17px;
        padding-left: 14;
        width: calc(100% - 20px);
      }
    }
  }

  .filters-combo {
    > label {
      padding-left: 1rem;
    }
  }
}

#admin-pending-influencers-approval {
  .search {
    padding: 20px;
    display: flex;
    justify-content: center;

    .ant-select-selection {
      background: transparent;
      position: relative;
      .ant-select-selection__clear {
        top: 13px;
      }
    }
    .anticon {
      font-size: 18px;
      color: #777e9b;
    }

    .ant-select-selection__rendered {
      font-size: 17px;
    }

    input {
      background: transparent;
      border: none;
      border-bottom: 2px solid #777e9b;
      border-radius: 0;
      font-family: 'TruenoLight';
      color: #777e9b;
      font-size: 17px;
      padding-left: 14;

      &:hover {
        border-bottom: 2px solid #ffb917;
      }
    }

  }

  .bulk-actions {
    display: flex;
    justify-content: left;
    .ant-btn {
      margin-right: 10px;
    }
  }
}

#admin-influencer-page {
  background: #fff;

  .influencers-stats {
    margin-top: 28px;
    margin-bottom: 24px;

    .stats {
      color: #000;
      background: #fff;
      border-radius: 2px;
      border: solid 1px #a9b0b9;
      background-color: #ffffff;
      padding: 8px 15px;
      font-size: 12px;
      line-height: 1.48;
      height: 100%;

      .stat-title {
        .type-bold();
        text-transform: uppercase;
      }

      .stat-count {
        .type-bold();
      }
      .stat-label {
        font-size: 11px;
      }
    }
  }
  .search-filters {
    background: #fff;

    .search {
      .ant-select-selection {
        background: transparent;
        position: relative;
        .ant-select-selection__clear {
          top: 13px;
        }
      }
      .anticon {
        font-size: 18px;
        color: #777e9b;
      }

      .ant-select-selection__rendered {
        font-size: 17px;
      }
      input {
        background: transparent;
        border: none;
        font-family: 'TruenoLight';
        color: #777e9b;
        font-size: 17px;
        padding-left: 14;
        width: calc(100% - 20px);
      }
    }

    &:before,
    &:after {
      content: '';
      position: absolute;
      width: 100%;
      height: 63px;
      top: 0px;
      background: #fff;
      z-index: 1;
      overflow: hidden;
    }
    &:before {
      left: -100%;
    }
    &:after {
      right: -100%;
    }

    button.download-csv {
      width: 122px;
      padding-top: 6px;
      padding-bottom: 9px;
      color: #5b6572;
      text-align: left;
      border-radius: 2px;
      border: solid 1px #dbdfe8;
      background-color: #f8faff;
      .anticon {
        font-size: 16px;
      }

      span {
        display: inline-block;
        text-align: left;
        text-transform: uppercase;
        font-family: 'FirmeBlackItalic';
        font-size: 9px;
        font-style: italic;
        line-height: 1.11;
        letter-spacing: 1px;
      }
    }
  }

  .ant-table {
    td {
      text-align: right;
      &.username {
        padding: 22px 0 25px;
      }

      &.last-login-date {
        width: 100px;
        padding-right: 25px;
        color: #5b6572;
      }

      &.joined {
        width: calc(34px + 43px);
        color: #000000;
        padding-right: calc(43px / 2);
      }

      &.rating {
        width: calc(34px + (43px + 34px) / 2);
        padding-right: calc((43px + 34px) / 4);
      }

      &.followers {
        width: calc(56px + (34px + 33px) / 2);
        padding-right: calc((34px + 33px) / 4);
      }
      &.apps {
        width: calc(27px + (33px + 30px) / 2);
        padding-right: calc((33px + 30px) / 4);
      }
      &.last-applied-date {
        width: calc(70px + (30px + 29px) / 2);
        padding-right: calc((30px + 29px) / 4);
      }
      &.active {
        width: calc(36px + (29px + 34px) / 2);
        padding-right: calc((29px + 34px) / 4);
      }
      &.complete {
        width: calc(49px + (34px + 38px) / 2);
        padding-right: calc((34px + 38px) / 4);
      }
      &.level {
        text-align: center;
        img {
          width: 34px;
          height: 47px;
        }
      }
      .active-partnerships,
      .completed-partnerships {
        height: 27px;
        width: 27px;
        border-radius: 100%;
        display: inline-block;
        text-align: center;
        line-height: 2;
        font-family: 'TruenoRegular';
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        color: #000000;
      }
      .active-partnerships {
        border: solid 1px #a9b0b9;
        cursor: pointer;
        background-color: #ffffff;
      }
      .completed-partnerships {
        border: solid 1px #a9b0b9;
        cursor: pointer;
        background-color: #ffffff;
      }
    }
  }
}

.admin-influencer-active-campaigns {
  width: 197px;
  font-family: 'TruenoRegular';
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: right;
  background: #000;
  padding: 10px 18px 17px 14px;
  border-radius: 2px;
  color: #fff;

  p {
    margin-bottom: 10px;
    cursor: pointer;
    &:hover {
      color: #ffb917;
    }
  }
}
.ant-popover-placement-bottomRight,
.ant-popover-placement-topRight {
  & > .ant-popover-content > .ant-popover-arrow {
    color: #000 !important;
    border-top-color: #000 !important;
    border-right-color: transparent;
    border-bottom-color: transparent;
    border-left-color: #000 !important;
    -webkit-box-shadow: -2px -2px 5px rgba(0, 0, 0, 0.06);
    box-shadow: -2px -2px 5px rgba(0, 0, 0, 0.06);
    border-right-color: #000 !important;
    border-bottom-color: #000 !important;
  }
}

#expired-credits-table {
  .ant-table-thead {
    th {
      text-align: center !important;
    }
    th:first-child {
      text-align: left !important;
    }
  }
  .ant-table-tbody {
    td {
      text-align: right;
    }
    td:first-child {
      text-align: left;
    }
  }
}
