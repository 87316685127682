.pending-review-modal {
  .ant-modal-content {
    box-shadow: 0 2px 19px 0 rgba(0, 0, 0, 0.16);
    background-color: #ffffff;
    border-radius: 0;

    .close {
      position: absolute;
      right: 25px;
      top: 25px;
      .ant-btn {
        width: 45px;
        height: 45px;
        border: 1px solid #000;
      }
    }
    .ant-modal-body {
      min-height: 686px;
      margin: 0px;
      padding: 0px;
      padding-bottom: 32px;
    }

    .review-photo {
      text-align: center;
      position: relative;
      width: 200px;
      height: 200px;
      margin-top: 50px;

      img {
        display: inline-block;
        width: 200px;
        height: 200px;
        box-shadow: 0 2px 19px 0 rgba(0, 0, 0, 0.44);
        background-color: #d8d8d8;
        object-fit: cover;
      }

      .instagram-logo {
        position: absolute;
        bottom: 173px;
        left: 170px;
        background-image: url('../../../images/instagram-logo@3x.png');
        background-size: cover;
        height: 43px;
        width: 43px;
      }

      .emoji-heart {
        position: absolute;
        top: 106px;
        right: 185px;
        background-size: cover;
        background-image: url('../../../images/heart-eyes-emoji@3x.png');
        height: 44px;
        width: 44px;
      }
      .link {
        position: absolute;
        top: 177px;
        left: 45px;
        width: 110px;
        height: 45px;

        span {
          .type-sfpro-bold();
          margin-top: 15px;
          font-size: 14px;
          line-height: 16px;
          text-align: center;
          color: #000000;
        }
      }
    }
    .review-message {
      .type-sfpro-bold();
      font-style: normal;
      font-weight: 800;
      font-size: 18px;
      line-height: 26px;
      margin-top: 45px;
      text-align: center;
      color: #000000;
      margin-bottom: 19px;

      p {
        display: inline-block;
        width: 279px;
        margin-bottom: 0px;
      }
    }

    .review-form {
      margin-bottom: 24.8px;

      .review-input {
        .ant-form-item-control {
          display: flex;
          justify-content: center;
          .ant-form-explain {
            text-align: center;
            position: absolute;
            top: -10px;
            font-size: 10px;
          }
        }

        &.ant-form-item {
          margin-bottom: 17.8px;
        }

        &:last-child {
          margin-bottom: 0;
        }

        &-label {
          display: block;
          .type-sfpro-bold();
          font-style: normal;
          font-size: 12px;
          line-height: 18px;

          text-align: center;
          color: #000000;
          margin-bottom: 9px;
        }

        &-control {
          text-align: center;
          .ant-rate {
            .ant-rate-star {
              .anticon {
                width: 38.2px;
                height: 38.2px;
                font-size: 38.2px;
              }

              &.ant-rate-star-full {
                .anticon {
                  svg {
                    path {
                      fill: #ffb917;
                      stroke: #ffb917;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .review-additional-comments {
      margin-bottom: 31px;
      display: flex;
      justify-content: center;
      .ant-form-item {
        margin-bottom: 0;
      }

      .ant-form-item-control {
        display: flex;
        justify-content: center;
        .ant-form-explain {
          text-align: center;
          position: absolute;
          top: -17px;
          font-size: 10px;
        }
      }

      textarea {
        .type-sfpro-regular();
        font-size: 14px;
        line-height: 20px;
        padding: 13px 18px;
        resize: none;
        width: 448px;
        height: 99px;
        object-fit: contain;
        border-radius: 8px;
        border: none;
        background-color: #f1f5fd;
        &::placeholder {
          color: #5b6572;
        }
      }
    }

    .review-footer {
      text-align: center;
      .skip-button,
      .submit-button {
        .type-sfpro-bold();
        width: 177px;
        height: 55px;
        color: #000;
        border: none;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 16px;
        text-align: center;
        text-shadow: none;
      }

      .skip-button {
        background: #f1f5fd;
        margin-right: 14px;
      }
    }
  }
}
