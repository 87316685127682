.text-black {
  color: #000000;
}
.text-black-bis {
  color: #151515;
}
.text-black-ter {
  color: #3A3A3A;
}
.text-gray-darkest {
  color: #747474;
}
.text-gray-darker {
  color: #969696;
}
.text-gray-dark {
  color: #999999;
}
.text-gray {
  color: #A6A6A6;
}
.text-gray-light {
  color: #D8D8D8;
}
.text-gray-lighter {
  color: #D6D6D6;
}
.text-gray-lightest {
  color: #E8E8E8;
}
.text-white {
  color: #FFFFFF;
}
.text-orange {
  color: #FFB917;
}
.text-pigeon-dark {
  color: #5B6572;
}
.text-pigeon-light {
  color: #A9B0B9;
}
.text-pigeon {
  color: #DBDFE8;
}
.bg-black {
  background-color: #000000;
}
.bg-black-bis {
  background-color: #151515;
}
.bg-black-ter {
  background-color: #3A3A3A;
}
.bg-gray-darkest {
  background-color: #747474 !important;
}
.bg-gray-darker {
  background-color: #969696;
}
.bg-gray-dark {
  background-color: #999999;
}
.bg-gray {
  background-color: #A6A6A6;
}
.bg-gray-light {
  background-color: #D8D8D8;
}
.bg-gray-lighter {
  background-color: #D6D6D6;
}
.bg-gray-lightest {
  background-color: #E8E8E8;
}
.bg-white {
  background-color: #FFFFFF;
}
.bg-orange {
  background-color: #FFB917;
}
.approval-table {
  display: flex;
  flex-direction: column;
  min-width: 100%;
  font-family: 'SFProDisplay';
}
.header-width-container {
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 742px;
  height: 26px;
  text-transform: uppercase;
}
.approval-table-header {
  background: #f1f5fd;
  min-width: 100%;
  padding: 0 16px;
}
.approval-table-header p {
  font-family: 'SFProDisplay';
  font-style: normal;
  font-weight: 600;
  font-size: 9px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: #5b6572;
  margin-bottom: 0;
}
.creator-name-column,
.required-content-column,
.action-column {
  display: flex;
  align-items: center;
  height: max-content;
}
.creator-name-column.hidden-xs,
.required-content-column.hidden-xs,
.action-column.hidden-xs {
  display: none;
}
@media (min-width: 700px) {
  .creator-name-column.hidden-xs,
  .required-content-column.hidden-xs,
  .action-column.hidden-xs {
    display: flex;
  }
}
.creator-name-column {
  min-width: 40%;
  max-width: 40%;
  cursor: pointer;
}
.creator-name-column .influencer-avatar-image {
  margin-right: 0 !important;
}
.required-content-column {
  min-width: 42%;
  max-width: 42%;
}
.required-content-column .required-content-style {
  display: flex;
  flex-direction: column;
  max-width: 100%;
}
.required-content-column .required-content-style p {
  font-family: 'SFProDisplay';
  margin-bottom: 8px;
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
}
.required-content-column .required-content-style .content-preview-row {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 5px;
}
.required-content-column .required-content-style .required-content-preview {
  background: #FFFFFF;
  border: 0.0625rem solid #000000;
  border-radius: 0.5rem;
  height: 2.5rem;
  overflow: hidden;
  width: 2.5rem;
}
.required-content-column .required-content-style .required-content-preview img,
.required-content-column .required-content-style .required-content-preview video {
  object-fit: contain;
}
.required-content-column .required-content-style .required-content-preview svg {
  display: none;
}
.required-content-column .required-content-style .additional-previews {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.required-content-column .required-content-style .additional-previews p {
  margin: 0;
  font-weight: 600;
  font-size: 10px;
  line-height: 13px;
  color: #000000;
}
.content-table-action-column .action-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 62px;
  background: #000000;
  border: 1px solid #000000;
  border-radius: 6px;
  position: relative;
  cursor: pointer;
}
.content-table-action-column .action-card p {
  font-family: 'SFProDisplay';
  margin: 0;
}
.content-table-action-column .action-card .info-container {
  background-color: #ebd0ff;
  position: absolute;
  top: -16px;
  right: -5px;
  border-radius: 28px;
  text-align: center;
  border: 1px solid #000000;
  padding: 0.125rem 0.25rem;
}
@media (min-width: 700px) {
  .content-table-action-column .action-card .info-container {
    width: 45px;
    height: 18px;
    top: -9px;
    right: 12px;
    padding: 0;
  }
}
.content-table-action-column .action-card .info-container .info-title {
  font-family: 'SFProDisplay';
  font-size: 9px;
  font-weight: 700;
  font-style: normal;
  color: #000000;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.content-table-action-column .action-card .review-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #bde5cc;
  width: 100%;
  height: 40px;
  border-radius: 6px;
  padding: 0 2px;
}
.content-table-action-column .action-card .review-content p {
  font-family: 'SFProDisplay';
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  color: #000000;
}
.content-table-action-column .action-card .review-content img {
  height: 12px;
}
@media (min-width: 700px) {
  .content-table-action-column .action-card .review-content {
    padding: 8px 10px;
  }
}
.content-table-action-column .action-card .content-approved-count {
  padding: 8px 10px;
}
.content-table-action-column .action-card .content-approved-count p {
  font-family: 'SFProDisplay';
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  color: #ffffff;
}
.approval-table-body {
  display: flex;
  flex-direction: column;
  min-width: 100%;
  background: #ffffff;
}
.approval-table-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  min-width: 100%;
}
.row-width-container {
  margin-left: auto;
  margin-right: auto;
  padding: 0 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 742px;
  height: 104px;
}
