.trend-label {
  color: #747474;
  font-style: italic;
}
.hashtag-input.ant-input-affix-wrapper {
  .ant-input {
    padding-left: 27px !important;
  }
  &.dark.focused {
    .ant-input-prefix {
      color: @color-orange;
    }
  }
}
.brandtag-input.ant-input-affix-wrapper {
  &.dark.focused {
    .ant-input-prefix {
      color: @color-orange;
    }
  }
}
.ant-input-affix-wrapper.trend-input {
  .ant-input {
    border-top: 0 !important;
    border-left: 0 !important;
    border-right: 0 !important;
    border-color: #2a2a2a !important;
    border-radius: 0;
    background-color: transparent;
    box-shadow: none !important;
    color: @color-orange;
    padding-bottom: 12px;

    &:focus {
      border-color: @color-white !important;
    }
  }

  .ant-input-prefix {
    font-size: 16px;
    padding-bottom: 9px;
  }
  &.dark {
    .ant-input-prefix {
      color: @color-black-ter;
    }
    .ant-input {
      background: none;

      &::placeholder {
        color: @color-black-ter;
      }
    }
  }
}

input.ant-form-item-control.has-error {
  .trend-input {
    &.dark {
      background: none;
    }
  }
}

.ant-input,
.ant-input-password,
.ant-input-number {
  &.trend-input {
    border-top: 0 !important;
    border-left: 0 !important;
    border-right: 0 !important;
    border-color: #2a2a2a !important;
    border-radius: 0;
    background-color: transparent;
    box-shadow: none !important;
    color: @color-orange;
    padding-bottom: 12px;

    &:focus:not(.inverse) {
      border-color: @color-black !important;
    }

    &.large {
      height: 50px;
      padding-bottom: 15px;
      font-size: 24px;
    }

    &.dark {
      &::placeholder {
        color: #989898;
      }
    }
  }

  &.trend-textarea {
    border: 1px solid #2a2a2a !important;
    background: @color-black;
    border-radius: 0;
    padding: 17px;
    color: @color-orange;
    font-size: 15px;

    &::placeholder {
      color: @color-black-ter;
    }

    &:focus {
      border-color: @color-white !important;
    }
  }
}

.ant-form-item-label label,
.trend-form-label {
  // font-size: 13px !important;
  color: @color-black;
  .type-ebold();
}

// Select

.trend-select {
  .ant-select-selection {
    border-radius: 50px !important;
    border-color: #a9b0b9 !important;

    &--single {
      height: 30px;
    }

    &:focus {
      box-shadow: none;
    }
  }

  .ant-select-selection__placeholder {
    font-size: 10px !important;
    color: @color-pigeon-dark !important;
  }

  .ant-select-selection__rendered {
    margin-left: 15px !important;
    margin-right: 15px !important;
    .size-10px();
    .type-sfpro-regular();
    font-weight: 400 !important;
  }

  &-dark {
    .ant-select-selection--multiple .ant-select-selection__rendered > ul > li {
      height: 34px;
      line-height: 34px;
      width: 155px;
      .anticon {
        padding-top: 2px;
        line-height: 34px;
        font-size: 20px;
        color: #000;
      }
    }
    &:not(.ant-select-open) {
      .ant-select-selection {
        &::before {
          content: '';
          position: absolute;
          top: calc(50% - 5px);
          right: 15px;
          width: 0;
          height: 0;
          border-left: 6px solid transparent;
          border-right: 6px solid transparent;
          border-top: 6px solid #fff;
          border-bottom: 0px;
        }
      }
    }
    .ant-select-selection {
      min-height: 51px;
      border-radius: 2px;
      background-color: #151515;
      border: 1px solid #3c3c3c;
      color: #747474;
      padding: 10px 10px 12px;
      &::before {
        content: '';
        position: absolute;
        top: calc(50% - 5px);
        right: 15px;
        width: 0;
        height: 0;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-bottom: 6px solid #fff;
      }
    }

    .ant-select-arrow-icon {
      color: #fff;
    }
    .ant-select-selection-selected-value {
      color: @primary-color;
      font-size: 15px;
    }
    .ant-select-selection--multiple .ant-select-selection__rendered {
      line-height: 37px;
      margin: 0;
      .ant-select-selection__placeholder {
        margin-left: 0;
      }
    }
    &.ant-select-no-arrow {
      .ant-select-selection__rendered {
        margin: 0 !important;
      }
    }
    ul {
      li.ant-select-selection__choice {
        background: @primary-color;
        border: 0 !important;
        color: @color-black;
      }
    }
  }

  &-dark-v2 {
    background: #272727;
    border-radius: 5px;

    .ant-select-selection--multiple .ant-select-selection__rendered > ul > li {
      height: 34px;
      line-height: 34px;
      width: 155px;
      .anticon {
        padding-top: 2px;
        line-height: 34px;
        font-size: 20px;
        color: #000;
      }
    }
    &:not(.ant-select-open) {
      .ant-select-selection {
        &::before {
          content: '';
          position: absolute;
          top: calc(50% - 5px);
          right: 15px;
          width: 0;
          height: 0;
          border-left: 6px solid transparent;
          border-right: 6px solid transparent;
          border-top: 6px solid white;
          border-bottom: 0px;
        }
      }
    }

    .remove-glow-effect {
      border-color: transparent !important;
      box-shadow: none !important;
      -webkit-box-shadow: none !important;
    }

    .ant-select-selection {
      .remove-glow-effect();
      min-height: 55px;
      border-radius: 5px;
      background-color: #272727;
      border: none;
      color: black;
      padding: 15px;

      &:active,
      &:focus {
        .remove-glow-effect();
      }

      .ant-select-selection__placeholder {
        .type-sfpro-medium();
        font-weight: bold !important;
        font-size: 14px;
        line-height: 16px;
        opacity: 0.75;
      }

      &::before {
        content: '';
        position: absolute;
        top: calc(50% - 5px);
        right: 15px;
        width: 0;
        height: 0;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-bottom: 6px solid white;
      }
    }

    .ant-select-arrow-icon {
      color: white !important;
    }

    .ant-select-selection-selected-value {
      .type-sfpro-bold();
      color: white;
      font-size: 15px;
    }

    .ant-select-selection--multiple .ant-select-selection__rendered {
      line-height: 37px;
      margin: 0;
      .ant-select-selection__placeholder {
        margin-left: 0;
      }
    }
    &.ant-select-no-arrow {
      .ant-select-selection__rendered {
        margin: 0 !important;
      }
    }
    ul {
      li.ant-select-selection__choice {
        background: @primary-color;
        border: 0 !important;
        color: @color-black;
      }
    }
  }

  &.ant-select-open {
    .ant-select-selection {
      border-radius: 17px 17px 0px 0px !important;
      border-color: #a9b0b9 !important;
    }

    &:after {
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
      border-bottom: 4px solid #5b6572;
      border-top: 0;
    }
  }

  &:after {
    width: 0;
    height: 0;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 4px solid #5b6572;
    content: '';
    display: inline-block;
    position: absolute;
    z-index: 1;
    top: 14px;
    right: 13px;
  }
}

.ant-select-dropdown {
  border: 1px solid #a9b0b9;
  border-radius: 0px 0px 17px 17px !important;

  .ant-select-dropdown-menu {
    margin-top: 12px;
    margin-bottom: 12px;

    .ant-select-dropdown-menu-item {
      .type-sfpro-bold();
      font-size: 10px !important;
      color: #5b6572 !important;
    }
  }

  .ant-select-dropdown-menu-item-selected {
    border-radius: 0px !important;
  }
  .ant-select-dropdown-menu-item-active,
  .ant-select-dropdown-menu-item:hover {
    background-color: #e8e8e8 !important;
    border-radius: 0px !important;
  }
}

// Radio
.item-campaign {
  .ant-card {
    border: 0;

    &-cover {
      div {
        transition: all 0.18s ease-in;
        border-radius: 4px;
      }
    }

    &-body {
      padding-left: 0;
      padding-right: 0;
    }

    &:hover {
      box-shadow: none;

      .ant-card-cover {
        div {
          box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.26);
        }
      }
    }
  }

  .ant-card-cover {
    div {
      border-radius: 4px;
    }
  }

  .campaign {
    &-user {
      .mb-1();
      .text-pigeon-dark();
      .spacing-05px();
      .size-10px();
      .type-sbold() !important;
      .uppercase();
    }

    &-name {
      .size-16px;
      .text-black();
      line-height: 1.38;
    }

    &-value {
      display: inline-block;
      position: relative;
      z-index: 2;
      .size-14px();
      .type-bold();
      .text-black();
      margin-top: 6.8px;

      &:after {
        content: '';
        position: absolute;
        z-index: -1;
        bottom: 0;
        left: 0;
        width: 70%;
        height: 11px;
        background: #ffe9b7;
      }
    }
  }
}

.trend-message-input {
  input::placeholder {
    font-size: 16px;
    color: @color-black;
  }

  display: flex;
  flex-direction: row;
}

.product-form {
  @media screen and (max-width: 600px) {
    .ant-tabs-left-bar {
      display: none;
    }
    .ant-tabs-left-content {
      border: none;
    }
  }

  .mobile-content-container {
    padding: 20px;
  }

  .ant-form-item-label {
    label {
      font-size: 14px;
      .type-regular-sfpro-display();
      color: #a6a6a6;
    }
  }

  .ant-input-number {
    color: @color-orange;
    width: 100%;
    height: 40px;
    border-top: 0 !important;
    border-left: 0 !important;
    border-right: 0 !important;
    border-color: #2a2a2a !important;
    border-radius: 0;
    font-size: 16px;
    background-color: transparent;
    box-shadow: none !important;
    padding-left: 0;
    &:focus-within {
      border-bottom: 1px solid @color-white !important;
    }
  }

  .ant-input-number:focus-within,
  .orange {
    color: #ffb917;
  }

  .ant-input-number-handler-wrap {
    background: transparent;
    border-left: 0;
    .ant-input-number-handler-down {
      border-top: 0;
    }
    .ant-input-number-handler-active {
      background-color: #151515;
    }
  }

  .trend-tag-plain {
    &:extend(.ant-input.trend-input);
    color: @color-black-ter;
    height: 40px;
    font-size: 15px;
    line-height: 40px;
    width: 100%;
    cursor: text;
  }
  input.trend-tag-plain {
    color: @color-orange;
  }

  .trend-tag {
    .type-sfpro-regular();
    font-size: 14px;
    display: inline-block;
    background: @color-orange;
    border-radius: 2px;
    border: 0 !important;
    height: 34px;
    line-height: 34px;
    color: @color-black;

    &.ant-tag {
      .anticon.anticon-close {
        font-size: 20px;
        line-height: 21px;
        color: #000;
        vertical-align: middle;
      }
    }
  }

  .ant-switch {
    &[aria-checked='false'] {
      background: @color-black-ter;
    }
  }
}

// Radio
.ant-radio {
  &-checked {
    .ant-radio-inner {
      &:after {
        transform: scale(1) !important;
      }
    }
  }

  &-inner {
    width: 20px !important;
    height: 20px !important;
    background: #f4f4f4 !important;
    border-color: #dadada !important;

    &:after {
      width: 20px !important;
      height: 20px !important;
      left: -1px !important;
      top: -1px !important;
      border-radius: 30px !important;
    }
  }
}

.plain-radio-group {
  .ant-radio-button-wrapper {
    color: @color-pigeon-dark;
    .size-10px();
    .spacing-05px();
    border-color: #a9b0b9;
  }

  .ant-radio-button-wrapper-checked {
    &:not(.ant-radio-button-wrapper-disabled) {
      background: #000 !important;
      border-color: #000 !important;

      &:before {
        opacity: 1;
        background-color: #fff !important;
      }
    }
  }
}

.status-tag {
  .type-firme-iblack();
  background-color: @color-orange;
  border-radius: 0.25rem;
  color: @color-black;
  font-size: 0.5rem;
  left: 0;
  letter-spacing: 0.0625rem;
  margin: 0;
  padding: 0.25rem 0.5rem;
  position: absolute;
  text-transform: uppercase;
  top: 3.125rem;

  &::after {
    content: '';
    position: absolute;
    top: -0.3125rem;
    left: 1.125rem;
    width: 0.8125rem;
    height: 0.8125rem;
    background-color: @color-orange;
    transform: rotate(45deg);
  }
}

.trend-selected-tags {
  .mt-3();

  &-dropdown {
    border: 1px solid #666;
  }

  .ant-select-selection__rendered {
    width: 600px;
    overflow-x: auto;
    height: 55px !important;
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0), #000000 100%, #000000);

    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      display: inline-flex;

      li {
        width: 150px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}

// mark changes
.trend-chat-input {
  &:focus-within {
    border: none !important;
    box-shadow: none !important;
  }
}
