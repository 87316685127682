#socialCreativeBrief {
  color: @color-white;
  max-width: 452px;

  .trend-radio-buttons-v2 {
    flex-wrap: wrap;
    justify-content: space-between;
    @media screen and (max-width: 600px) {
      justify-content: center;
    }
  }

  .page-title {
    .type-sfpro-heavy();
    font-size: 24px;
    line-height: 30px;
  }

  .page-description {
    .type-sfpro-regular();
    color: @color-white;
    font-size: 16px;
    line-height: 24px;
    margin-top: 3px;
    margin-bottom: 24px;
  }

  .extra-title {
    .type-sfpro-bold();
    font-weight: 800;
    font-size: 16px;
    line-height: 20px;
  }

  .extra-sub-label {
    .type-sfpro-regular();
    font-size: 14px;
    line-height: 22px;
    margin-top: 5px;
    margin-bottom: 32px;
  }

  .post-types {
    .ant-radio-button-wrapper {
      width: 212px;
      height: 120px;
      border-radius: 5px;

      &.image-post {
        margin-right: 8px;
        @media screen and (max-width: 600px) {
          margin-right: 0px;
          margin-bottom: 8px;
        }
      }

      .post-type-label {
        .type-sfpro-bold();
        font-size: 14px !important;
        font-style: normal;
        line-height: 16px;
        margin-top: 13px;
      }

      span:last-child {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;
      }
    }
  }

  .creator-instruction {
    .ant-radio-button-wrapper-checked {
      .more-examples {
        color: black !important;
      }

      .card-checkbox-circle {
        background: @color-black !important;
      }

      .select-label {
        color: @color-black !important;
      }
    }

    .ant-radio-button-wrapper:hover:not(.ant-radio-button-wrapper-checked) {
      .card-checkbox-circle {
        align-items: center;
        background: #fff0cd;
        border: 1px solid #fff0cd;
      }

      .more-examples {
        color: black !important;
      }

      .select-label {
        color: #fff0cd;
      }
    }

    .ant-radio-button-wrapper {
      border-radius: 5px;
      height: 363px;
      padding: 0;
      width: 142px;
      margin-right: 8px;
      &:last-child {
        margin-right: 0;
      }

      @media screen and (max-width: 600px) {
        width: 100%;
        max-width: 220px;
        margin-right: 0px;
        margin-bottom: 16px;
        height: fit-content;
      }

      .card {
        align-items: center;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        padding: 16px;
      }

      .card-image {
        position: relative;
        text-align: center;

        @media screen and (max-width: 600px) {
          width: 100%;
          height: auto;
        }
        img.thumbnail {
          height: 124px;
          border-radius: 4px;
          width: 124px;
          @media screen and (max-width: 600px) {
            width: 100%;
            height: auto;
          }
        }

        img.play-button {
          position: absolute;
          height: 38px;
          width: 38px;
          top: calc(50% - 19px); // 50% - (height / 2)
          left: calc(50% - 19px);
        }
      }

      .card-title {
        .type-sfpro-bold();
        font-size: 14px;
        line-height: 18px;
        margin-top: 8px;
        margin-bottom: 1px;
        text-align: center;
      }

      .more-examples {
        .type-sfpro-regular();
        color: white;
        font-size: 10px;
        line-height: 20px;
        margin-bottom: 38px;
        text-align: center;
        text-decoration-line: underline;
      }

      .selector-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: absolute;
        bottom: 1.5rem;
      }

      .card-checkbox-circle {
        align-items: center;
        background: #545454;
        border: 1px solid #545454;
        box-sizing: border-box;
        border-radius: 41px;
        display: flex;
        justify-content: center;
        height: 53px;
        width: 53px;
      }

      .select-label {
        .type-sfpro-bold();
        color: #595959;
        font-size: 12px;
        line-height: 12px;
        margin-top: 8px;
      }

      &.unboxing-option {
        margin-left: 10px;
        margin-right: 10px;
      }
    }
  }

  .is-story-required {
    .ant-radio-button-wrapper {
      .type-sfpro-bold();
      align-items: center;
      display: flex;
      font-size: 14px;
      height: 55px;
      line-height: 16px;
      justify-content: center;
      width: 224px;
      margin-bottom: 8px;
      @media screen and (max-width: 600px) {
        border-radius: 4px !important;
      }
    }
  }
}
