html,
body {
  height: auto;
  width: 100%;
}
.progress-flat .ant-progress-bg {
  height: 4px !important;
  border-radius: 0px !important;
}
.trend-label {
  color: #747474;
  font-style: italic;
}
.hashtag-input.ant-input-affix-wrapper .ant-input {
  padding-left: 27px !important;
}
.hashtag-input.ant-input-affix-wrapper.dark.focused .ant-input-prefix {
  color: #FFB917;
}
.brandtag-input.ant-input-affix-wrapper.dark.focused .ant-input-prefix {
  color: #FFB917;
}
.ant-input-affix-wrapper.trend-input .ant-input {
  border-top: 0 !important;
  border-left: 0 !important;
  border-right: 0 !important;
  border-color: #2a2a2a !important;
  border-radius: 0;
  background-color: transparent;
  box-shadow: none !important;
  color: #FFB917;
  padding-bottom: 12px;
}
.ant-input-affix-wrapper.trend-input .ant-input:focus {
  border-color: #FFFFFF !important;
}
.ant-input-affix-wrapper.trend-input .ant-input-prefix {
  font-size: 16px;
  padding-bottom: 9px;
}
.ant-input-affix-wrapper.trend-input.dark .ant-input-prefix {
  color: #3A3A3A;
}
.ant-input-affix-wrapper.trend-input.dark .ant-input {
  background: none;
}
.ant-input-affix-wrapper.trend-input.dark .ant-input::-webkit-input-placeholder {
  color: #3A3A3A;
}
.ant-input-affix-wrapper.trend-input.dark .ant-input::placeholder {
  color: #3A3A3A;
}
input.ant-form-item-control.has-error .trend-input.dark {
  background: none;
}
.ant-input.trend-input,
.ant-input-password.trend-input,
.ant-input-number.trend-input,
.product-form .trend-tag-plain {
  border-top: 0 !important;
  border-left: 0 !important;
  border-right: 0 !important;
  border-color: #2a2a2a !important;
  border-radius: 0;
  background-color: transparent;
  box-shadow: none !important;
  color: #FFB917;
  padding-bottom: 12px;
}
.ant-input.trend-input:focus:not(.inverse),
.ant-input-password.trend-input:focus:not(.inverse),
.ant-input-number.trend-input:focus:not(.inverse) {
  border-color: #000000 !important;
}
.ant-input.trend-input.large,
.ant-input-password.trend-input.large,
.ant-input-number.trend-input.large {
  height: 50px;
  padding-bottom: 15px;
  font-size: 24px;
}
.ant-input.trend-input.dark::-webkit-input-placeholder, .ant-input-password.trend-input.dark::-webkit-input-placeholder, .ant-input-number.trend-input.dark::-webkit-input-placeholder {
  color: #989898;
}
.ant-input.trend-input.dark::placeholder,
.ant-input-password.trend-input.dark::placeholder,
.ant-input-number.trend-input.dark::placeholder {
  color: #989898;
}
.ant-input.trend-textarea,
.ant-input-password.trend-textarea,
.ant-input-number.trend-textarea {
  border: 1px solid #2a2a2a !important;
  background: #000000;
  border-radius: 0;
  padding: 17px;
  color: #FFB917;
  font-size: 15px;
}
.ant-input.trend-textarea::-webkit-input-placeholder, .ant-input-password.trend-textarea::-webkit-input-placeholder, .ant-input-number.trend-textarea::-webkit-input-placeholder {
  color: #3A3A3A;
}
.ant-input.trend-textarea::placeholder,
.ant-input-password.trend-textarea::placeholder,
.ant-input-number.trend-textarea::placeholder {
  color: #3A3A3A;
}
.ant-input.trend-textarea:focus,
.ant-input-password.trend-textarea:focus,
.ant-input-number.trend-textarea:focus {
  border-color: #FFFFFF !important;
}
.ant-form-item-label label,
.trend-form-label {
  color: #000000;
  font-family: 'TruenoExtraBold', Arial, sans-serif;
}
.trend-select .ant-select-selection {
  border-radius: 50px !important;
  border-color: #a9b0b9 !important;
}
.trend-select .ant-select-selection--single {
  height: 30px;
}
.trend-select .ant-select-selection:focus {
  box-shadow: none;
}
.trend-select .ant-select-selection__placeholder {
  font-size: 10px !important;
  color: #5B6572 !important;
}
.trend-select .ant-select-selection__rendered {
  margin-left: 15px !important;
  margin-right: 15px !important;
  font-size: 10px !important;
  font-family: SFProDisplay;
  font-weight: normal;
  font-style: normal;
  font-weight: 400 !important;
}
.trend-select-dark .ant-select-selection--multiple .ant-select-selection__rendered > ul > li {
  height: 34px;
  line-height: 34px;
  width: 155px;
}
.trend-select-dark .ant-select-selection--multiple .ant-select-selection__rendered > ul > li .anticon {
  padding-top: 2px;
  line-height: 34px;
  font-size: 20px;
  color: #000;
}
.trend-select-dark:not(.ant-select-open) .ant-select-selection::before {
  content: '';
  position: absolute;
  top: calc(50% - 5px);
  right: 15px;
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid #fff;
  border-bottom: 0px;
}
.trend-select-dark .ant-select-selection {
  min-height: 51px;
  border-radius: 2px;
  background-color: #151515;
  border: 1px solid #3c3c3c;
  color: #747474;
  padding: 10px 10px 12px;
}
.trend-select-dark .ant-select-selection::before {
  content: '';
  position: absolute;
  top: calc(50% - 5px);
  right: 15px;
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #fff;
}
.trend-select-dark .ant-select-arrow-icon {
  color: #fff;
}
.trend-select-dark .ant-select-selection-selected-value {
  color: #ffb917;
  font-size: 15px;
}
.trend-select-dark .ant-select-selection--multiple .ant-select-selection__rendered {
  line-height: 37px;
  margin: 0;
}
.trend-select-dark .ant-select-selection--multiple .ant-select-selection__rendered .ant-select-selection__placeholder {
  margin-left: 0;
}
.trend-select-dark.ant-select-no-arrow .ant-select-selection__rendered {
  margin: 0 !important;
}
.trend-select-dark ul li.ant-select-selection__choice {
  background: #ffb917;
  border: 0 !important;
  color: #000000;
}
.trend-select-dark-v2 {
  background: #272727;
  border-radius: 5px;
}
.trend-select-dark-v2 .ant-select-selection--multiple .ant-select-selection__rendered > ul > li {
  height: 34px;
  line-height: 34px;
  width: 155px;
}
.trend-select-dark-v2 .ant-select-selection--multiple .ant-select-selection__rendered > ul > li .anticon {
  padding-top: 2px;
  line-height: 34px;
  font-size: 20px;
  color: #000;
}
.trend-select-dark-v2:not(.ant-select-open) .ant-select-selection::before {
  content: '';
  position: absolute;
  top: calc(50% - 5px);
  right: 15px;
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid white;
  border-bottom: 0px;
}
.trend-select-dark-v2 .remove-glow-effect {
  border-color: transparent !important;
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
}
.trend-select-dark-v2 .ant-select-selection {
  border-color: transparent !important;
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
  min-height: 55px;
  border-radius: 5px;
  background-color: #272727;
  border: none;
  color: black;
  padding: 15px;
}
.trend-select-dark-v2 .ant-select-selection:active,
.trend-select-dark-v2 .ant-select-selection:focus {
  border-color: transparent !important;
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
}
.trend-select-dark-v2 .ant-select-selection .ant-select-selection__placeholder {
  font-family: SFProDisplay;
  font-weight: normal;
  font-weight: bold !important;
  font-size: 14px;
  line-height: 16px;
  opacity: 0.75;
}
.trend-select-dark-v2 .ant-select-selection::before {
  content: '';
  position: absolute;
  top: calc(50% - 5px);
  right: 15px;
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid white;
}
.trend-select-dark-v2 .ant-select-arrow-icon {
  color: white !important;
}
.trend-select-dark-v2 .ant-select-selection-selected-value {
  font-family: SFProDisplay;
  font-weight: bold;
  color: white;
  font-size: 15px;
}
.trend-select-dark-v2 .ant-select-selection--multiple .ant-select-selection__rendered {
  line-height: 37px;
  margin: 0;
}
.trend-select-dark-v2 .ant-select-selection--multiple .ant-select-selection__rendered .ant-select-selection__placeholder {
  margin-left: 0;
}
.trend-select-dark-v2.ant-select-no-arrow .ant-select-selection__rendered {
  margin: 0 !important;
}
.trend-select-dark-v2 ul li.ant-select-selection__choice {
  background: #ffb917;
  border: 0 !important;
  color: #000000;
}
.trend-select.ant-select-open .ant-select-selection {
  border-radius: 17px 17px 0px 0px !important;
  border-color: #a9b0b9 !important;
}
.trend-select.ant-select-open:after {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-bottom: 4px solid #5b6572;
  border-top: 0;
}
.trend-select:after {
  width: 0;
  height: 0;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid #5b6572;
  content: '';
  display: inline-block;
  position: absolute;
  z-index: 1;
  top: 14px;
  right: 13px;
}
.ant-select-dropdown {
  border: 1px solid #a9b0b9;
  border-radius: 0px 0px 17px 17px !important;
}
.ant-select-dropdown .ant-select-dropdown-menu {
  margin-top: 12px;
  margin-bottom: 12px;
}
.ant-select-dropdown .ant-select-dropdown-menu .ant-select-dropdown-menu-item {
  font-family: SFProDisplay;
  font-weight: bold;
  font-size: 10px !important;
  color: #5b6572 !important;
}
.ant-select-dropdown .ant-select-dropdown-menu-item-selected {
  border-radius: 0px !important;
}
.ant-select-dropdown .ant-select-dropdown-menu-item-active,
.ant-select-dropdown .ant-select-dropdown-menu-item:hover {
  background-color: #e8e8e8 !important;
  border-radius: 0px !important;
}
.item-campaign .ant-card {
  border: 0;
}
.item-campaign .ant-card-cover div {
  transition: all 0.18s ease-in;
  border-radius: 4px;
}
.item-campaign .ant-card-body {
  padding-left: 0;
  padding-right: 0;
}
.item-campaign .ant-card:hover {
  box-shadow: none;
}
.item-campaign .ant-card:hover .ant-card-cover div {
  box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.26);
}
.item-campaign .ant-card-cover div {
  border-radius: 4px;
}
.item-campaign .campaign-user {
  margin-bottom: 5px !important;
  color: #5B6572;
  letter-spacing: 0.5px;
  font-size: 10px !important;
  font-family: 'TruenoSemiBold', Arial, sans-serif !important;
  text-transform: uppercase;
}
.item-campaign .campaign-name {
  font-size: 16px !important;
  color: #000000;
  line-height: 1.38;
}
.item-campaign .campaign-value {
  display: inline-block;
  position: relative;
  z-index: 2;
  font-size: 14px !important;
  font-family: 'TruenoBold', Arial, sans-serif;
  color: #000000;
  margin-top: 6.8px;
}
.item-campaign .campaign-value:after {
  content: '';
  position: absolute;
  z-index: -1;
  bottom: 0;
  left: 0;
  width: 70%;
  height: 11px;
  background: #ffe9b7;
}
.trend-message-input {
  display: flex;
  flex-direction: row;
}
.trend-message-input input::-webkit-input-placeholder {
  font-size: 16px;
  color: #000000;
}
.trend-message-input input::placeholder {
  font-size: 16px;
  color: #000000;
}
@media screen and (max-width: 600px) {
  .product-form .ant-tabs-left-bar {
    display: none;
  }
  .product-form .ant-tabs-left-content {
    border: none;
  }
}
.product-form .mobile-content-container {
  padding: 20px;
}
.product-form .ant-form-item-label label {
  font-size: 14px;
  font-family: 'SFProDisplay', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  color: #a6a6a6;
}
.product-form .ant-input-number {
  color: #FFB917;
  width: 100%;
  height: 40px;
  border-top: 0 !important;
  border-left: 0 !important;
  border-right: 0 !important;
  border-color: #2a2a2a !important;
  border-radius: 0;
  font-size: 16px;
  background-color: transparent;
  box-shadow: none !important;
  padding-left: 0;
}
.product-form .ant-input-number:focus-within {
  border-bottom: 1px solid #FFFFFF !important;
}
.product-form .ant-input-number:focus-within,
.product-form .orange {
  color: #ffb917;
}
.product-form .ant-input-number-handler-wrap {
  background: transparent;
  border-left: 0;
}
.product-form .ant-input-number-handler-wrap .ant-input-number-handler-down {
  border-top: 0;
}
.product-form .ant-input-number-handler-wrap .ant-input-number-handler-active {
  background-color: #151515;
}
.product-form .trend-tag-plain {
  color: #3A3A3A;
  height: 40px;
  font-size: 15px;
  line-height: 40px;
  width: 100%;
  cursor: text;
}
.product-form input.trend-tag-plain {
  color: #FFB917;
}
.product-form .trend-tag {
  font-family: SFProDisplay;
  font-weight: normal;
  font-style: normal;
  font-size: 14px;
  display: inline-block;
  background: #FFB917;
  border-radius: 2px;
  border: 0 !important;
  height: 34px;
  line-height: 34px;
  color: #000000;
}
.product-form .trend-tag.ant-tag .anticon.anticon-close {
  font-size: 20px;
  line-height: 21px;
  color: #000;
  vertical-align: middle;
}
.product-form .ant-switch[aria-checked='false'] {
  background: #3A3A3A;
}
.ant-radio-checked .ant-radio-inner:after {
  -webkit-transform: scale(1) !important;
          transform: scale(1) !important;
}
.ant-radio-inner {
  width: 20px !important;
  height: 20px !important;
  background: #f4f4f4 !important;
  border-color: #dadada !important;
}
.ant-radio-inner:after {
  width: 20px !important;
  height: 20px !important;
  left: -1px !important;
  top: -1px !important;
  border-radius: 30px !important;
}
.plain-radio-group .ant-radio-button-wrapper {
  color: #5B6572;
  font-size: 10px !important;
  letter-spacing: 0.5px;
  border-color: #a9b0b9;
}
.plain-radio-group .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  background: #000 !important;
  border-color: #000 !important;
}
.plain-radio-group .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):before {
  opacity: 1;
  background-color: #fff !important;
}
.status-tag {
  font-family: 'FirmeBlackItalic', Arial, sans-serif !important;
  background-color: #FFB917;
  border-radius: 0.25rem;
  color: #000000;
  font-size: 0.5rem;
  left: 0;
  letter-spacing: 0.0625rem;
  margin: 0;
  padding: 0.25rem 0.5rem;
  position: absolute;
  text-transform: uppercase;
  top: 3.125rem;
}
.status-tag::after {
  content: '';
  position: absolute;
  top: -0.3125rem;
  left: 1.125rem;
  width: 0.8125rem;
  height: 0.8125rem;
  background-color: #FFB917;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}
.trend-selected-tags {
  margin-top: 15px !important;
}
.trend-selected-tags-dropdown {
  border: 1px solid #666;
}
.trend-selected-tags .ant-select-selection__rendered {
  width: 600px;
  overflow-x: auto;
  height: 55px !important;
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0), #000000 100%, #000000);
}
.trend-selected-tags .ant-select-selection__rendered ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: inline-flex;
}
.trend-selected-tags .ant-select-selection__rendered ul li {
  width: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.trend-chat-input:focus-within {
  border: none !important;
  box-shadow: none !important;
}
.trend-badge {
  align-items: center;
  background-color: #ffb917;
  border-radius: 50%;
  display: flex;
  font-size: 0.75rem;
  height: 1.25rem;
  justify-content: center;
  margin: 0 auto;
  width: 1.25rem;
}
.ant-btn.trend-btn {
  background: #FFB917;
  border: 0;
  color: #000000;
  font-size: 11px;
  font-family: 'TruenoBold', Arial, sans-serif;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  border-radius: 2px;
}
.ant-btn.trend-btn:hover,
.ant-btn.trend-btn:focus-within {
  background: #ffe8bc;
}
.ant-btn.trend-btn.secondary {
  height: 35px;
  padding-top: 2px;
  background: #f8faff;
  border: 1px solid #dbdfe8;
  box-shadow: none;
  font-size: 9px;
  color: #5B6572;
  letter-spacing: 1px;
}
.ant-btn.trend-btn.secondary:hover,
.ant-btn.trend-btn.secondary:focus-within {
  background: #f0f2f9 !important;
}
.ant-btn.trend-btn.gray {
  background: #3A3A3A;
  color: #FFFFFF !important;
}
.ant-btn.huge-btn {
  height: 57px !important;
  font-size: 14px !important;
  color: #000000 !important;
  text-transform: none !important;
  letter-spacing: 0;
}
.ant-btn.medium-btn {
  height: 45px !important;
  font-size: 14px !important;
  color: #000000 !important;
  text-transform: none !important;
  letter-spacing: 0;
}
.ant-btn.reject {
  background: #ff5a5a !important;
}
.ant-btn.product-btn {
  height: 57px;
  padding: 16px 68px;
  font-size: 16px;
  font-family: 'TruenoBold', Arial, sans-serif;
  text-transform: none;
  letter-spacing: 0;
}
.ant-btn.product-btn.ant-btn-loading {
  padding: 16px 68px !important;
}
.action-link .back {
  color: #FFB917;
  flex-direction: column;
  font-family: 'TruenoSemiBold', Arial, sans-serif;
  left: 2.1875rem;
  position: absolute;
  top: 1.9375rem;
}
.action-link .sign-out {
  flex-direction: column;
  font-family: 'TruenoSemiBold', Arial, sans-serif;
  position: absolute;
  right: 2.1875rem;
  top: 1.9375rem;
}
.action-btn {
  font-family: 'SFProDisplay', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: bold;
  padding: 0.5rem 1rem !important;
  background-color: #000000 !important;
  border: 0.0625rem solid #3c3c3c !important;
  font-size: 0.625rem;
  color: #E8E8E8;
  border-radius: 0.125rem;
  text-transform: uppercase;
}
.action-btn:focus-visible {
  outline: revert !important;
}
.sub-menu-btn {
  float: right;
  color: #b0b0b0;
  border-radius: 2px;
  border: 1px solid #3c3c3c;
  background: #151515;
  letter-spacing: 0.5px;
  font-family: SFProDisplay;
  font-weight: normal;
  font-style: normal;
  text-transform: uppercase;
}
.sub-menu-btn.archive {
  display: flex;
  font-size: 9px;
  line-height: 11px;
  border-radius: 30px;
  justify-content: center;
  font-weight: 700;
  letter-spacing: 0.1em;
  padding: 0.375rem 1rem;
}
.sub-menu-btn.archive:focus-visible {
  outline: revert !important;
}
.post-filter-action {
  text-align: right !important;
  position: relative;
  z-index: 2;
}
.post-filter-action .ant-btn-group {
  height: 25px;
  padding: 3px 0;
  background: #f8f9fb;
  border-radius: 2px;
}
.remove-dropdown span {
  font-size: 9px !important;
  letter-spacing: 0.5px;
  font-family: 'TruenoSemiBold', Arial, sans-serif;
  color: #5B6572;
}
.remove-dropdown .ant-dropdown-menu-item:hover {
  background-color: #f8faff !important;
}
.yotpo-btn.ant-btn:not(.ant-btn-link) {
  background-color: #1776ec;
  color: #fff;
  border-radius: 2px;
  border: 0;
  font-family: 'FirmeBlackItalic', Arial, sans-serif !important;
  font-size: 10px;
  height: 33px;
  padding-right: 15px;
  padding-left: 15px;
}
.yotpo-btn.ant-btn:not(.ant-btn-link).ant-btn-loading {
  padding-right: 42px;
}
.yotpo-btn.ant-btn:not(.ant-btn-link):hover,
.yotpo-btn.ant-btn:not(.ant-btn-link):focus {
  color: #fff;
  background-color: #528cd4;
}
.ant-layout-sider,
.ant-menu-dark,
.ant-menu-dark .ant-menu-sub {
  background: #000000 !important;
}
.ant-layout-sider .sub-menu-item span,
.ant-menu-dark .sub-menu-item span,
.ant-menu-dark .ant-menu-sub .sub-menu-item span {
  vertical-align: middle;
}
.ant-layout-sider .sub-menu-item .icon-posts,
.ant-menu-dark .sub-menu-item .icon-posts,
.ant-menu-dark .ant-menu-sub .sub-menu-item .icon-posts {
  background-color: #151515;
}
.ant-layout-sider .sub-menu-item .icon-posts svg,
.ant-menu-dark .sub-menu-item .icon-posts svg,
.ant-menu-dark .ant-menu-sub .sub-menu-item .icon-posts svg {
  margin: 6px 15px 15px 6px !important;
}
.ant-layout {
  height: 100vh;
}
.ant-layout.with-top-banner {
  height: calc(100vh - 41px);
}
.trend-table {
  max-width: 742px;
  padding: 0 1rem;
  width: 100%;
}
.trend-table--wrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
}
.trend-table--wrapper > div {
  width: 100%;
}
.trend-table-actions,
.trend-table--actions {
  background: #f8faff;
  border-bottom: solid 1px #dbdfe8;
  padding: 8px 16px;
}
.trend-table-actions .actions,
.trend-table--actions .actions {
  position: relative;
  margin: auto;
  max-width: 742px;
}
.trend-table-actions .actions .selected-count,
.trend-table--actions .actions .selected-count {
  position: absolute;
  top: -8px;
  right: 0;
  font-family: 'FirmeBlackItalic';
  font-size: 16px;
  font-weight: 900;
  font-stretch: normal;
  font-style: italic;
  line-height: 1.38;
  letter-spacing: 1px;
  text-align: right;
  color: #5b6572;
  height: calc(100% + 16px);
  max-width: 40%;
  white-space: wrap;
  align-content: center;
}
.trend-table-actions .actions .hide,
.trend-table--actions .actions .hide {
  display: none;
}
.trend-table-actions .actions button.ant-btn,
.trend-table--actions .actions button.ant-btn {
  border-radius: 0;
  height: 35px;
  text-transform: uppercase;
  font-family: 'FirmeBlackItalic', Arial, sans-serif !important;
  font-size: 9px;
  margin-right: 10px;
  color: #5b6572;
  vertical-align: middle;
  text-align: right;
  padding-right: 13px;
  line-height: 1.11;
  letter-spacing: 1px;
}
.trend-table-actions .actions button.ant-btn .anticon,
.trend-table--actions .actions button.ant-btn .anticon,
.trend-table-actions .actions button.ant-btn img,
.trend-table--actions .actions button.ant-btn img {
  position: absolute;
}
.trend-table-actions .actions button.ant-btn.message,
.trend-table--actions .actions button.ant-btn.message {
  width: 141px;
}
.trend-table-actions .actions button.ant-btn.message img,
.trend-table--actions .actions button.ant-btn.message img {
  width: 20px;
  font-size: 20px;
  left: 12px;
  top: 8px;
}
.trend-table-actions .actions button.ant-btn.cancel,
.trend-table--actions .actions button.ant-btn.cancel {
  width: 96px;
}
.trend-table-actions .actions button.ant-btn.cancel .anticon,
.trend-table--actions .actions button.ant-btn.cancel .anticon {
  font-size: 21px;
  left: 12px;
  top: 7px;
}
.trend-table-actions .actions button.ant-btn.send,
.trend-table--actions .actions button.ant-btn.send {
  width: 130px;
}
.trend-table-actions .actions button.ant-btn.send img,
.trend-table--actions .actions button.ant-btn.send img {
  width: 17px;
  font-size: 17px;
  left: 12px;
  top: 9px;
}
.trend-table .ant-table-tbody > tr > td {
  border: 0;
}
.trend-table td.hidden-xs,
.trend-table th.hidden-xs {
  display: none;
}
@media (min-width: 700px) {
  .trend-table td.hidden-xs,
  .trend-table th.hidden-xs {
    display: table-cell;
  }
}
.trend-table .multi-select {
  width: 66px;
}
.trend-table .col-action {
  max-width: 200px;
  text-align: right;
  flex-wrap: wrap;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
.trend-table .cell-action {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
  border-left: 1px solid #e8e8e8;
  width: 100%;
  grid-gap: 0.25rem;
  gap: 0.25rem;
}
@media (min-width: 700px) {
  .trend-table .cell-action {
    flex-wrap: nowrap;
  }
}
.trend-table .cell-action--primary {
  background: #ffb917;
  border: 0;
  width: 35px;
  height: 35px;
}
.trend-table .cell-action--primary:hover,
.trend-table .cell-action--primary:focus {
  background-color: #FFB917 !important;
}
.trend-table .cell-action .link span:hover {
  font-family: 'TruenoBold', Arial, sans-serif;
  color: #000 !important;
}
.trend-table .cell-action .ant-btn-circle:hover,
.trend-table .cell-action .ant-btn-circle:focus {
  color: #000000 !important;
  border: 0.0625rem solid #000000 !important;
}
.trend-table .ant-table-thead:before,
.trend-table .ant-table-thead:after {
  content: '';
  position: absolute;
  top: 35px;
  width: 100%;
  height: 1px;
  background: #e8e8e8;
}
.trend-table .ant-table-thead:before {
  left: -100%;
}
.trend-table .ant-table-thead:after {
  right: -100%;
}
.trend-table .ant-table-tbody > tr.ant-table-row-selected > td:first-child:before,
.trend-table .ant-table-tbody > tr.ant-table-row-selected > td:last-child:before {
  background: #fafafa;
}
.trend-table .ant-table-tbody > tr > td {
  position: relative;
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.trend-table .ant-table-tbody > tr > td.ant-table-selection-column {
  text-align: left;
}
.trend-table .ant-table-tbody > tr:hover {
  background: #f8faff;
}
.trend-table .ant-table-tbody > tr:hover > td:not(.ant-table-expanded-row):not(.ant-table-row-selected) {
  background: #f8faff;
}
.trend-table .ant-table-tbody > tr:hover > td:first-child:before,
.trend-table .ant-table-tbody > tr:hover > td:last-child:before {
  background: #f8faff;
}
.trend-table .ant-table-thead > tr > th {
  font-family: 'TruenoSemiBold', Arial, sans-serif;
  font-size: 8px;
  letter-spacing: 0.5px;
  color: #5B6572 !important;
  background: transparent !important;
  padding: 8px 0 !important;
  white-space: nowrap;
}
.trend-table .ant-table-thead > tr > th.ant-table-selection-column-custom {
  padding: 4px 0 0 0 !important;
}
.trend-table .ant-table-thead > tr > th div {
  display: inline-block;
}
.trend-table .ant-table-thead > tr > th.influencer-width {
  width: 30%;
  padding-left: 0 !important;
}
.trend-table .ant-table-thead > tr > th.influencer-width-shipment {
  width: 40%;
  padding-left: 0 !important;
}
.trend-table .ant-table-thead > tr > th.post-width {
  width: 15%;
}
.trend-table .ant-table-thead > tr > th.comments,
.trend-table .ant-table-thead > tr > th.likes {
  width: 12%;
}
.trend-table .ant-table-tbody > tr > td {
  font-family: 'TruenoRegular', Arial, sans-serif;
  font-size: 11px;
  color: #000000;
}
.trend-table .ant-table-tbody > tr > td.avatar {
  padding-right: 0;
}
.trend-table .ant-table-tbody > tr > td.influencer-width {
  width: 25%;
  padding-left: 0 !important;
}
.trend-table .ant-table-tbody > tr > td.location {
  width: 45%;
}
@media (min-width: 700px) {
  .trend-table .ant-table-tbody > tr > td.location {
    width: 20%;
  }
}
.trend-table .ant-table-tbody > tr > td.author p:first-child {
  margin: 0;
  padding-top: 5px;
  font-size: 12px;
  font-family: 'truenoextrabold';
  color: #000000;
}
.trend-table .ant-table-tbody > tr > td.author p {
  font-size: 11px;
  color: #5B6572;
}
.trend-table .ant-table-tbody > tr > td.time {
  padding-left: 0;
  width: 15%;
  color: #5B6572;
  font-size: 12px;
  text-align: right;
}
@media (min-width: 700px) {
  .trend-table .ant-table-tbody > tr > td.time {
    font-size: 9px;
  }
}
.trend-table .ant-table-tbody > tr > td.count .ant-avatar {
  color: #000000;
  font-size: 9px;
  width: 28px;
  height: 28px;
}
.trend-table .ant-table-tbody > tr > td .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #FFB917;
  border-color: #FFB917;
}
.trend-table .ant-table-tbody > tr > td .ant-checkbox-inner {
  width: 25px;
  height: 25px;
  border-radius: 25px;
  background: #f4f4f4;
  border: 1px solid #dadada;
}
.trend-table .ant-table-tbody > tr > td .ant-checkbox-inner:after {
  display: none;
}
.messages-table .ant-table-thead {
  display: none;
}
.messages-table .author {
  width: 15%;
}
.messages-table .padded-table-list tbody td.avatar {
  padding-left: 0 !important;
}
.messages-table tbody > tr:before,
.messages-table tbody > tr:after {
  content: '';
  position: absolute;
  width: 100%;
  height: 1px;
  background: #e8e8e8;
}
.messages-table tbody > tr:before {
  left: -100%;
}
.messages-table tbody > tr:after {
  right: -100%;
}
.table-awaiting .post-width .ant-avatar {
  background: #fff;
  width: 50px;
  height: 50px;
  border: 1px dashed #888;
  border-radius: 0;
}
.table-approved .post-width .ant-avatar {
  border-radius: 0;
}
.ant-table-placeholder {
  border-bottom: 0 !important;
}
@media (min-width: 1441px) {
  .padded-table-list tbody td.avatar {
    padding-left: 300px !important;
  }
  .padded-table-list tbody td.count {
    padding-right: 300px !important;
  }
  .padded-table-list ul.ant-table-pagination {
    margin-right: 300px !important;
  }
}
/*
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/
@media (min-width: 1281px) and (max-width: 1440px) {
  .padded-table-list tbody td.avatar {
    padding-left: 110px !important;
  }
  .padded-table-list tbody td.count {
    padding-right: 110px !important;
  }
  .padded-table-list ul.ant-table-pagination {
    margin-right: 110px !important;
  }
}
/*
  ##Device = Laptops, Desktops
  ##Screen = B/w 1025px to 1280px
*/
@media (min-width: 1025px) and (max-width: 1280px) {
  .padded-table-list tbody td.avatar {
    padding-left: 105px !important;
  }
  .padded-table-list tbody td.count {
    padding-right: 105px !important;
  }
  .padded-table-list ul.ant-table-pagination {
    margin-right: 105px !important;
  }
}
/*
##Device = Tablets, Ipads (portrait)
##Screen = B/w 768px to 1024px
*/
@media (min-width: 768px) and (max-width: 1024px) {
  .padded-table-list tbody td.avatar {
    padding-left: 20px !important;
  }
  .padded-table-list tbody td.count {
    padding-right: 20px !important;
  }
  .padded-table-list ul.ant-table-pagination {
    margin-right: 20px !important;
  }
}
/*
##Device = Most of the Smartphones Mobiles (Portrait)
##Screen = B/w 320px to 479px
*/
@media (min-width: 320px) and (max-width: 480px) {
  .padded-table-list tbody td.avatar {
    padding-left: 20px !important;
  }
  .padded-table-list tbody td.count {
    padding-right: 20px !important;
  }
  .padded-table-list ul.ant-table-pagination {
    margin-right: 20px !important;
  }
}
.ant-menu-item-group-title {
  font-family: SFProDisplay;
  font-weight: 800;
  color: white !important;
  font-style: normal !important;
  font-weight: 800 !important;
  font-size: 12px !important;
  line-height: 18px !important;
}
.ant-menu.trend-menu-horizontal {
  border: 0;
  max-width: 742px;
  margin: auto;
  display: flex;
  align-items: stretch;
  justify-content: center;
}
@media (min-width: 700px) {
  .ant-menu.trend-menu-horizontal {
    padding: 0;
  }
}
.ant-menu.trend-menu-horizontal .trend-badge {
  margin-top: -1.5rem;
}
.ant-menu.trend-menu-horizontal .ant-menu-item {
  flex: 1 1;
  width: auto;
  align-content: center;
  text-align: center;
  line-height: 1rem;
  white-space: wrap;
}
@media (min-width: 700px) {
  .ant-menu.trend-menu-horizontal .ant-menu-item {
    white-space: nowrap;
  }
}
.ant-menu.trend-menu-horizontal .ant-menu-item a {
  padding: 12px 0;
  font-size: 14px;
  font-family: SFProDisplay;
  font-weight: normal;
  font-style: normal;
}
.ant-menu.trend-menu-horizontal .ant-menu-item:hover a {
  color: #000000 !important;
}
.ant-menu.trend-menu-horizontal > .ant-menu-item-selected {
  border-color: transparent;
}
.ant-menu.trend-menu-horizontal > .ant-menu-item-selected > a {
  font-family: SFProDisplay;
  font-weight: bold;
  color: #000000 !important;
}
.ant-menu.trend-menu-horizontal > .ant-menu-item-selected > a:after {
  content: '';
  clear: both;
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  bottom: -4px;
  margin: auto;
  width: 31px;
  height: 4px;
  background-color: #FFB917;
}
.ant-menu.trend-menu-horizontal > .ant-menu-item-selected:hover {
  border-color: transparent;
}
.account-menu {
  border: 0 !important;
  background: transparent !important;
}
.account-menu > .ant-menu-item > a {
  padding-bottom: 14px;
  font-size: 15px;
  color: #747474 !important;
  font-family: 'SFProDisplay', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
.account-menu > .ant-menu-item > a:focus-visible {
  outline: revert !important;
}
.account-menu > .ant-menu-item-selected > a {
  font-family: 'SFProDisplay', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: bold;
  color: #FFFFFF !important;
}
.trend-menu-inline.ant-menu-inline-collapsed > .ant-menu-item {
  padding: 0px 16px !important;
}
.trend-menu-inline a.campaign-link.first-campaign {
  display: inline;
}
.trend-menu-inline a.campaign-link.first-campaign .add-campaign {
  -webkit-animation: radar-animation 1s ease-out infinite;
          animation: radar-animation 1s ease-out infinite;
  border-radius: 100%;
  z-index: 10;
}
@-webkit-keyframes radar-animation {
  0% {
    box-shadow: 0 0 0 0 #f9f9f9;
  }
  100% {
    box-shadow: 0 0 0 7px rgba(249, 193, 202, 0);
  }
}
@keyframes radar-animation {
  0% {
    box-shadow: 0 0 0 0 #f9f9f9;
  }
  100% {
    box-shadow: 0 0 0 7px rgba(249, 193, 202, 0);
  }
}
.ant-menu-inline.settings .ant-menu-item {
  position: fixed;
  bottom: 0px;
  width: 20px !important;
}
.ant-menu-inline.settings .ant-menu-item a {
  position: fixed;
  bottom: 0;
  left: 0;
  height: 65px;
  background: #151515 !important;
  border-top: 1px solid #3c3c3c;
  padding: 12px;
  font-family: SFProDisplay;
  font-weight: normal;
  font-style: normal;
}
.ant-menu-inline.settings .ant-menu-item a svg {
  vertical-align: middle;
  margin-bottom: 3px;
}
.ant-menu-inline.settings.with-notification .ant-menu-item a {
  margin-bottom: 68px;
}
.ant-menu-inline.secondary {
  padding-bottom: 30px !important;
  min-height: 80px;
}
.ant-menu-inline.secondary .ant-menu-item {
  height: 50px !important;
  margin-bottom: 0 !important;
  margin-top: 2px;
}
.ant-menu-inline.secondary .ant-menu-item a {
  padding: 5px 0;
}
.ant-menu-inline.secondary .ant-menu-item svg {
  float: left;
  margin: 8px 15px 15px 8px;
}
.ant-menu-inline.trend-menu-inline .ant-menu-submenu-title {
  margin: 0 !important;
  height: 100% !important;
  padding-top: 10px;
  padding-bottom: 10px;
  line-height: 1 !important;
  cursor: default;
  pointer-events: none;
}
.ant-menu-inline.trend-menu-inline .ant-menu-submenu-title:hover {
  background: transparent !important;
}
.ant-menu-inline.trend-menu-inline .ant-menu-submenu-title,
.ant-menu-inline.trend-menu-inline .ant-menu-item {
  padding-left: 15px !important;
  margin-bottom: 4px !important;
}
.ant-menu-inline.trend-menu-inline .ant-menu-submenu-title:hover,
.ant-menu-inline.trend-menu-inline .ant-menu-item:hover {
  background-color: #161616;
}
.ant-menu-inline.trend-menu-inline .ant-menu-submenu-title a,
.ant-menu-inline.trend-menu-inline .ant-menu-item a {
  font-size: 11px !important;
  color: #999999;
  font-family: SFProDisplay;
  font-weight: normal;
  font-style: normal;
}
.ant-menu-inline.trend-menu-inline .ant-menu-submenu-title a span:not(.box-border, .new-pill),
.ant-menu-inline.trend-menu-inline .ant-menu-item a span:not(.box-border, .new-pill) {
  font-family: SFProDisplay;
  font-weight: normal;
  font-style: normal;
  font-weight: 600;
  font-size: 11px !important;
}
.ant-menu-inline.trend-menu-inline .ant-menu-sub .ant-menu-item {
  height: 50px;
  margin: 0;
  padding: 5px 0;
  margin-bottom: 4px;
}
.ant-menu-sub .trend-badge {
  display: inline-flex;
  margin-right: 0.5rem;
}
.ant-layout-sider-children .ant-menu-item-selected {
  background-color: #2b2b2b !important;
}
.ant-layout-sider-children .ant-menu-item-selected a {
  color: #fff !important;
}
.ant-layout-sider-children .ant-menu-item-selected:after {
  content: '';
  position: absolute;
  right: 0px !important;
  width: 3px;
  height: 100%;
  background-color: #FFB917;
}
.add-credit-message {
  width: 100%;
  position: fixed;
  bottom: 0;
  height: 68px;
  line-height: 68px;
  font-family: SFProDisplay;
  font-weight: 800;
  color: #fff;
  background: #000000;
  overflow: hidden;
  white-space: nowrap;
  box-sizing: border-box;
  margin: 0 auto;
  cursor: pointer;
  z-index: 50;
}
.add-credit-message.expired p {
  padding-right: 50px;
  color: #ffb917;
  font-weight: 800;
}
.add-credit-message p {
  font-size: 22px;
  font-weight: bold;
  font-style: italic;
  letter-spacing: -0.008em;
  display: inline-block;
  /* show the marquee just outside the paragraph */
  -webkit-animation: marquee 8s linear infinite;
          animation: marquee 8s linear infinite;
}
.add-credit-message .countdown {
  margin-left: 24px;
  margin-right: 24px;
  color: #27aa5c;
}
.add-credit-message .countdown-dot {
  font-size: 12px;
  line-height: 22px;
  font-style: normal;
}
/* Make it move */
@-webkit-keyframes marquee {
  0% {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
  }
  100% {
    display: none;
    -webkit-transform: translate(-25%, 0);
            transform: translate(-25%, 0);
  }
}
@keyframes marquee {
  0% {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
  }
  100% {
    display: none;
    -webkit-transform: translate(-25%, 0);
            transform: translate(-25%, 0);
  }
}
.with-notification {
  margin-bottom: 68px;
}
.menu-section {
  font-family: SFProDisplay;
  font-weight: normal;
  font-style: normal;
}
.menu-section .campaign-menu .ant-menu-item-group .ant-menu-item-group-title {
  display: none;
}
.campaign-menu .ant-menu-sub li {
  padding-left: 30px !important;
}
.campaign-menu .ant-menu-item {
  height: 50px !important;
  line-height: 49px !important;
  padding-right: 0 !important;
}
.campaign-menu .campaign-link {
  align-items: center;
  display: flex;
  grid-gap: 1rem;
  gap: 1rem;
  height: 100%;
  white-space: normal !important;
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
}
.campaign-menu .campaign-link:focus-visible {
  outline: revert !important;
  outline-offset: -0.3125rem;
}
.campaign-menu .campaign-link .ant-row-flex {
  height: 50px;
  max-height: 50px;
}
.campaign-menu .campaign-link .ant-avatar {
  width: 36px;
  float: left;
}
.campaign-menu .campaign-avatar {
  flex-shrink: 0;
}
.campaign-menu .campaign-item {
  font-family: SFProDisplay;
  font-weight: normal;
  font-style: normal;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  display: -webkit-box;
  font-size: 0.6875rem;
  font-weight: 600;
  line-clamp: 3;
  line-height: 1.3;
  margin-bottom: 0;
  overflow: hidden;
  padding-right: 1rem;
  text-align: left;
}
.trend-campaign-avatar {
  position: relative;
  overflow: hidden;
  background: #1f1f1f !important;
}
.trend-campaign-avatar img {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.platform-header {
  align-items: center;
  background-color: #FFFFFF;
  display: flex;
  padding: 1rem 1.25rem;
  position: fixed;
  top: 0;
  width: 100%;
}
@media (min-width: 48rem) {
  .platform-header {
    display: none;
  }
}
.nav-toggle {
  align-items: center;
  background: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  height: 1.25rem;
  justify-content: center;
  padding: 0;
  position: relative;
  width: 1.5rem;
}
.nav-toggle::before {
  background-color: #000000;
  border-radius: 0.1875rem;
  content: '';
  height: 0.1875rem;
  left: 0;
  position: absolute;
  top: 0;
  transition: -webkit-transform 0.2s;
  transition: transform 0.2s;
  transition: transform 0.2s, -webkit-transform 0.2s;
  width: 100%;
}
.nav-toggle::after {
  background-color: #000000;
  border-radius: 0.1875rem;
  bottom: 0;
  content: '';
  height: 0.1875rem;
  position: absolute;
  right: 0;
  transition: -webkit-transform 0.2s;
  transition: transform 0.2s;
  transition: transform 0.2s, -webkit-transform 0.2s;
  width: 100%;
}
.nav-toggle span {
  background-color: #000000;
  border-radius: 0.1875rem;
  height: 0.1875rem;
  opacity: 1;
  transition: opacity 0.5s;
  width: 100%;
}
.nav-toggle[aria-expanded='true']::before {
  top: 41%;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}
.nav-toggle[aria-expanded='true']::after {
  bottom: 41%;
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
}
.nav-toggle[aria-expanded='true'] span {
  opacity: 0;
  visibility: hidden;
  transition: 0s;
}
.header-logo {
  display: block;
  height: 1.4375rem;
  margin: 0 auto;
  width: 5.0625rem;
}
.sidenav-logo {
  display: none;
}
@media (min-width: 48rem) {
  .sidenav-logo {
    display: block;
    height: 3.75rem;
    margin: 0 auto;
    width: 7.5rem;
  }
}
.promo-banner {
  background-color: #FFB917;
  position: -webkit-sticky !important;
  position: sticky !important;
  text-align: center;
  top: 4rem;
}
.promo-banner a {
  color: #000000;
  display: block;
  padding: 0.612rem 0;
  transition: background-color 0.3s;
}
.promo-banner a:hover,
.promo-banner a:focus {
  background-color: #ffc84a;
  color: #000000;
}
.promo-banner a:focus-visible {
  outline: revert !important;
  outline-offset: -0.125rem;
}
.promo-banner a:active {
  background-color: #e39f00;
}
@media (min-width: 48rem) {
  .promo-banner {
    top: 0;
  }
}
.sidenav-wrapper {
  flex: 0 0 15rem !important;
  height: calc(100dvh - 4rem);
  max-width: none !important;
  min-height: calc(100dvh - 4rem);
  min-width: auto !important;
  opacity: 0;
  overflow: hidden;
  position: fixed !important;
  top: 4rem;
  visibility: hidden;
  width: 0 !important;
  z-index: 90;
}
.sidenav-wrapper--visible {
  opacity: 1;
  visibility: visible;
  width: 100% !important;
}
@media (min-width: 48rem) {
  .sidenav-wrapper {
    flex: 0 0 15rem !important;
    height: 100%;
    min-height: 100%;
    opacity: 1;
    position: relative !important;
    top: 0;
    visibility: visible;
    width: auto !important;
  }
}
.ant-layout-sider-children {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}
.sidenav-header {
  background: linear-gradient(66deg, #2c2c2c 0.99%, #000000 96.59%);
  padding: 1.25rem;
}
@media (min-width: 48rem) {
  .sidenav-header {
    padding: 1.25rem 1rem;
  }
}
.sidenav-main-wrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  grid-gap: 1rem;
  gap: 1rem;
}
.sidenav-campaigns {
  flex-grow: 1;
}
.sidenav-footer {
  background-color: #000000;
  border-top: 0.0625rem solid #2f313c;
  list-style: none;
  padding: 0;
}
.side-small-title {
  color: #969696;
  letter-spacing: 0.5px;
  font-size: 8px !important;
  font-family: 'TruenoSemiBold', Arial, sans-serif;
}
.credit-title-block {
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  grid-gap: 1rem;
  gap: 1rem;
  justify-content: space-between;
}
@media (min-width: 48rem) {
  .credit-title-block {
    flex-direction: column;
  }
}
.credit-value {
  font-family: SFProDisplay;
  font-weight: 800;
  color: #FFFFFF;
  font-size: 1.625rem;
  line-height: 1.9375rem;
  display: block;
  text-align: center;
}
.credit-title {
  font-family: SFProDisplay;
  font-weight: 800;
  color: #FFFFFF;
  font-size: 0.75rem;
  letter-spacing: 0.075rem;
  text-transform: uppercase;
}
.credit-desc {
  font-family: SFProDisplay;
  font-weight: normal;
  font-style: normal;
  color: #b8b9c1;
  font-size: 0.75rem;
  letter-spacing: 0em;
  line-height: 1.25rem;
  margin-bottom: 0;
}
.credit-add.ant-btn {
  font-family: SFProDisplay;
  font-weight: 800;
  color: #000000;
  border-radius: 0.3125rem;
  font-size: 0.875rem;
  height: auto;
  line-height: 1.125rem;
  padding: 0.5rem 1rem;
  text-shadow: none;
  width: -webkit-max-content;
  width: max-content;
}
.credit-add.ant-btn:hover,
.credit-add.ant-btn:focus,
.credit-add.ant-btn:active {
  color: #000000;
}
.credit-add.ant-btn:focus-visible {
  color: #000000;
  outline: revert !important;
}
.package-title {
  font-family: 'TruenoExtraBold', Arial, sans-serif;
  font-size: 20px !important;
  padding-top: 2px;
  margin-bottom: 7px;
  color: #3c3c3c;
}
.trend-menu-item-dark {
  background-color: #151515 !important;
  overflow: scroll;
}
.trend-menu-item-dark .ant-dropdown-menu-item {
  background-color: #151515 !important;
  color: #5a626e !important;
  font-size: 10px;
}
.trend-menu-item-dark .ant-dropdown-menu-item:hover {
  background-color: #000000 !important;
}
.trend-selected-tags-dropdown {
  background-color: #151515 !important;
}
.trend-select-states-selector .trend-selected-tags .ant-select-selection__rendered ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: inline;
}
.trend-select-states-selector .trend-selected-tags .ant-select-selection__rendered ul li.ant-select-selection__choice {
  display: inline-block;
  background: #ffb917 !important;
  border-radius: 2px;
  border: 0 !important;
  height: 34px;
  padding: 7px 12px;
  margin-bottom: 8px;
  margin-right: 10px;
  font-size: 11px;
  color: #000000;
}
.trend-select-states-selector .trend-selected-tags .ant-select-selection__rendered {
  background-image: none;
  width: auto;
  height: auto !important;
  margin-right: 33px;
  margin-top: 15px;
  margin-bottom: 5px;
  margin-left: 15px;
}
.trend-select-states-selector .trend-dropdown-option:hover {
  background-color: #000000 !important;
}
.trend-select-states-selector .trend-states-custom-caret {
  position: absolute;
  color: #ffffff;
  left: 100%;
  margin-left: -25px;
  top: 50% !important;
  z-index: 1 !important;
  cursor: pointer;
}
.trend-selected-tags .ant-select-selection--multiple {
  width: 100% important;
  display: 'inline-block';
  cursor: pointer;
}
.trend-selected-tags .ant-select-selection--multiple::before {
  font-family: FontAwesome;
  content: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='14' height='14' fill='white' stroke='none'><path d='M0,0 L5,6 L10,0 L0,0 Z'></path></svg>");
  padding-left: 0.5em;
  vertical-align: middle;
  position: absolute;
  right: 9px;
  top: 48%;
  color: #ffffff;
}
.trend-states-custom-caret {
  position: absolute;
  color: #ffffff;
  left: 100%;
  margin-left: -25px;
  top: 64px;
  z-index: 1 !important;
  cursor: pointer;
}
.trend-selection_choice {
  display: inline-block;
  background: #ffb917 !important;
  border-radius: 2px;
  border: 0 !important;
  padding: 7px 24px 7px 12px;
  font-size: 11px;
  color: #000000;
  margin-right: 8px;
  min-width: 34px;
  margin-right: 10px;
  margin-bottom: 10px !important;
}
.trend-dropdown-option:hover {
  background-color: #000000 !important;
}
.side-menu {
  padding-top: 1rem;
}
.side-menu .anticon.add-campaign {
  font-size: 36px;
  color: #fff;
  vertical-align: middle;
}
.post-card.ant-card .ant-card-cover > * {
  height: 100%;
}
.post-card.ant-card .ant-card-body .ant-card-meta-title {
  color: #000000;
  font-family: 'TruenoSemiBold', Arial, sans-serif;
  font-size: 16px !important;
  margin: 0 !important;
}
.post-card-video,
.post-card-image {
  position: relative;
  display: flex !important;
  align-items: center;
  justify-content: center;
}
.post-card-video .post-type-wrapper,
.post-card-image .post-type-wrapper {
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center;
  z-index: 10;
}
.post-card-video .post-type-wrapper .post-type-label,
.post-card-image .post-type-wrapper .post-type-label {
  display: inline-block;
  width: 59px;
  height: 15px;
  padding-top: 2px;
  text-transform: uppercase;
  font-family: FirmeBlackItalic;
  font-size: 8px;
  font-weight: 900;
  font-style: italic;
  font-stretch: normal;
  line-height: 1.38;
  letter-spacing: 0.4px;
  text-align: center;
  color: #000000;
}
.post-card-video .post-type-wrapper .post-type-label.type-image,
.post-card-image .post-type-wrapper .post-type-label.type-image {
  background: #ffd982;
}
.post-card-video .post-type-wrapper .post-type-label.type-video,
.post-card-image .post-type-wrapper .post-type-label.type-video {
  background: #4df1ff;
}
.post-card-video .post-type-wrapper .post-type-label.type-story,
.post-card-image .post-type-wrapper .post-type-label.type-story {
  background: #66bdff;
}
.post-card-video-actions,
.post-card-image-actions {
  position: absolute;
  align-self: center;
  width: 100%;
  text-align: center;
  z-index: 4;
}
.post-card-video-actions-button,
.post-card-image-actions-button {
  display: inline-block;
  font-size: 80px;
  color: #fff;
  opacity: 1;
}
.post-card-video:hover .post-card-video-actions-button,
.post-card-image:hover .post-card-video-actions-button {
  opacity: 1;
}
.post-card-image {
  position: relative;
}
.post-card-image a,
.post-card-image img {
  display: block;
  width: 100%;
  height: 100%;
}
.post-card-image .ant-spin-nested-loading,
.post-card-image .ant-spin-container {
  width: 100%;
  height: 100%;
}
.post-card-image .post-gallery-actions button {
  position: absolute;
  top: 144px;
  width: 32px;
  height: 32px;
  padding: 0px;
  margin: 0px;
  font-size: 32px;
  border: none;
}
.post-card-image .post-gallery-actions button.action-next {
  right: 10px;
}
.post-card-image .post-gallery-actions button.action-previous {
  left: 10px;
}
.post-card-image .post-gallery-actions button .anticon {
  display: block;
}
.ant-modal-mask {
  background-color: rgba(0, 0, 0, 0.19);
}
.trend-modal .ant-modal-body {
  padding: 26px;
}
.trend-modal .ant-modal-footer {
  padding: 24px 26px;
  border: 0;
}
.trend-modal .ant-radio-wrapper {
  margin-bottom: 10px !important;
  color: #000000;
}
.trend-modal .ant-radio {
  margin-right: 5px !important;
}
.trend-modal textarea {
  padding: 16px;
  border: 1px solid #D6D6D6 !important;
  line-height: 18px;
  font-size: 12px !important;
  color: #5B6572 !important;
}
.trend-modal .label {
  font-size: 18px;
  color: #000000;
  font-weight: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  font-family: truenoextrabold;
}
.trend-modal .label .highlighted {
  color: #AF4C4C;
}
.yotpo-modal .ant-modal-body {
  padding: 42px 45px 38px;
}
.yotpo-modal .ant-modal-content {
  width: 423px;
  min-height: 504px;
  border-radius: 0;
}
.yotpo-modal.sent .ant-modal-body {
  padding: 63px 45px 43px;
}
.yotpo-modal.sent .ant-modal-content {
  min-height: 423px;
}
.yotpo-modal.sent .logos {
  margin-bottom: 39px;
}
.yotpo-modal.sent .ant-form-item.submit {
  margin-bottom: 0;
}
.yotpo-modal.transfer .ant-modal-body {
  padding: 63px 45px 43px;
}
.yotpo-modal.transfer .ant-modal-content {
  min-height: 423px;
}
.yotpo-modal.transfer .logos {
  margin-bottom: 39px;
}
.yotpo-modal.transfer .ant-form-item.submit {
  margin-bottom: 0;
}
.yotpo-modal.transfer .ant-modal-body {
  padding-top: 81px;
}
.yotpo-modal.transfer .logos img {
  width: 288px;
  height: 108px;
}
.yotpo-modal .logos {
  font-family: 'TruenoRegular', Arial, sans-serif;
  font-size: 20px;
  vertical-align: middle;
  margin-bottom: 23px;
}
.yotpo-modal .logos > span {
  display: inline-block;
  vertical-align: top;
  margin-top: 5px;
}
.yotpo-modal .logos .content-sent-image {
  height: 119px;
  width: 199px;
}
.yotpo-modal .logos .yotpo-image {
  vertical-align: middle;
  width: 40px;
  height: 40px;
  margin-left: 18px;
}
.yotpo-modal .logos .trend-image {
  vertical-align: middle;
  width: 95px;
  height: 18px;
  margin-right: 18px;
}
.yotpo-modal h1 {
  font-size: 18px;
  text-align: center;
  margin-bottom: 39px;
}
.yotpo-modal .label {
  font-size: 12px;
  color: #000;
  font-family: 'TruenoBold', Arial, sans-serif;
  line-height: 1;
  margin-bottom: 11px;
}
.yotpo-modal .ant-input {
  height: 52px;
  color: #000;
  border: 0;
  border-radius: 3px;
  background-color: #f8f9fb;
  padding: 15px 16px;
}
.yotpo-modal .ant-input ::-webkit-input-placeholder {
  color: #bababa;
}
.yotpo-modal .ant-input ::placeholder {
  color: #bababa;
}
.yotpo-modal .ant-select {
  width: 100%;
}
.yotpo-modal .ant-select .ant-select-selection {
  background-color: #f4f4f4;
  border: 0;
  height: 52px;
  border-radius: 3px;
}
.yotpo-modal .ant-select .ant-select-selection .ant-select-selection-selected-value {
  margin-top: 17px;
  line-height: normal;
}
.yotpo-modal .ant-select .ant-select-selection .ant-select-selection__placeholder {
  margin-top: 2px;
  line-height: normal;
  color: #000;
}
.yotpo-modal .ant-select .ant-select-search--inline .ant-select-search__field {
  margin-top: 10px;
}
.yotpo-modal .ant-select .ant-select-selection-selected-value {
  color: #000;
}
.yotpo-modal .ant-select .ant-select-arrow {
  right: 22px;
}
.yotpo-modal .ant-select.ant-select-open:not(.ant-select-loading):after {
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid #000;
  border-top: 0;
}
.yotpo-modal .ant-select:not(.ant-select-loading):after {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #000;
  content: '';
  display: inline-block;
  position: absolute;
  z-index: 1;
  top: 21.5px;
  right: 23.4px;
}
.yotpo-modal .ant-form-item.submit {
  margin-bottom: 38px;
}
.yotpo-modal .ant-alert {
  margin-top: 10px;
}
.yotpo-modal .ant-alert-description {
  text-align: center;
}
.yotpo-modal .submit .ant-btn {
  height: 52px;
  color: #fff;
  background-color: #1776ec;
  border: 0;
  font-family: 'TruenoBold', Arial, sans-serif;
}
.yotpo-modal .submit .ant-btn:hover {
  background-color: #5495e6;
  border: 0;
  color: #fff;
}
.yotpo-modal .help {
  margin-bottom: 0;
}
.yotpo-modal .help a {
  font-family: 'TruenoBold', Arial, sans-serif;
  color: #1776ec;
  font-size: 12px;
}
.yotpo-modal .error {
  font-family: 'TruenoBold', Arial, sans-serif;
  color: #ff0000;
  position: absolute;
  bottom: 23px;
  text-align: center;
  width: 100%;
}
.yotpo-modal .close {
  cursor: pointer;
  position: absolute;
  top: 22px;
  right: 24px;
  font-size: 18px;
  padding: 0;
}
.yotpo-modal .close svg g {
  stroke: #000;
}
.yotpo-product-select.ant-select-dropdown {
  margin-top: -10px;
  border-radius: 0px !important;
  border: none;
  background-color: #f4f4f4;
}
.yotpo-product-select .ant-select-dropdown-menu-item {
  height: 52px;
  font-size: 15px;
  padding-top: 15px;
}
.yotpo-product-select.ant-select-dropdown--empty .ant-select-dropdown-menu-item {
  font-size: 15px !important;
}
.story-modal .ant-modal-close {
  right: -56px;
  color: #000;
  background: #efefef;
}
.story-modal .video-loader video {
  width: 100%;
}
.pending-trend-review-modal .ant-modal-body {
  padding: 0;
}
.pending-trend-review-modal .ant-modal-content {
  width: 423px;
  height: 349px;
  box-shadow: 0 2px 19px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
}
.pending-trend-review-modal .ant-modal-content .review-photo {
  text-align: center;
  padding-top: 43px;
  padding-bottom: 31px;
  position: relative;
}
.pending-trend-review-modal .ant-modal-content .review-photo .Earn_Credits {
  width: 151px;
  height: 114px;
  object-fit: contain;
}
.pending-trend-review-modal .ant-modal-content .review-message {
  padding-left: 71px;
  padding-right: 72px;
  text-align: center;
  padding-bottom: 35px;
}
.pending-trend-review-modal .ant-modal-content .review-message .message {
  font-family: 'TruenoExtraBold';
  font-size: 18px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #000000;
  margin-bottom: 0;
}
.pending-trend-review-modal .ant-modal-content .review-footer {
  padding-bottom: 33px;
  padding-left: 26px;
}
.pending-trend-review-modal .ant-modal-content .review-footer .no-thank-you {
  width: 177.5px;
  height: 45px;
  object-fit: contain;
  border-radius: 4px;
  border: solid 1px #dbdfe8;
  background-color: #f8faff;
}
.pending-trend-review-modal .ant-modal-content .review-footer .no-thank-you span {
  font-family: 'TruenoBold';
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
  color: #000000;
}
.pending-trend-review-modal .ant-modal-content .review-footer .earn-credits {
  margin-left: 12.5px;
  width: 181.3px;
  height: 45px;
  object-fit: contain;
  border-radius: 4px;
  background-color: #ffb917;
}
.pending-trend-review-modal .ant-modal-content .review-footer .earn-credits span {
  font-family: 'TruenoBold';
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
  color: #000000;
}
.ant-spin-nested-loading.dark-theme .ant-spin-blur {
  opacity: 0;
}
.empty-campaign {
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 2rem 1rem 0 1rem;
}
.empty-campaign img {
  max-width: 100%;
  max-height: 17.5rem;
}
.empty-campaign h2 {
  font-family: SFProDisplay;
  font-weight: bold;
  color: #000000;
  display: block;
  font-size: 1.5rem;
  margin: 0 auto;
  padding: 2rem 0 1rem;
}
.empty-campaign p {
  font-family: SFProDisplay;
  font-weight: normal;
  font-style: normal;
  color: #000000;
  font-size: 1rem;
  margin: 0 auto;
  max-width: 31rem;
  text-align: center;
}
#chartContainer {
  height: 100;
  width: 100%;
  margin: 0 auto;
  position: block;
}
#chart-cursor {
  position: absolute;
  height: 100;
  width: 100%;
  margin: 0 auto;
  top: 0;
  left: 0;
}
#postGraph {
  position: relative;
  width: 100% !important;
  overflow: hidden;
}
#chartjs-tooltip {
  background-color: #fff;
  box-shadow: -4px 4px 10px -2px #ddd;
  padding: 8px 15px 10px;
}
#chartjs-tooltip .tooltip-container {
  width: 140px;
}
#chartjs-tooltip .tooltip-container .tooltip-title {
  text-transform: uppercase;
  font-size: 8pt;
  color: #a7afbe;
}
#chartjs-tooltip .tooltip-container .tooltip-item {
  font-family: 'TruenoBold';
  font-size: 9pt;
}
#chartjs-tooltip .tooltip-container .tooltip-item-icon {
  width: 10px;
  height: 10px;
  border-radius: 10px;
  display: inline-block;
}
#chartjs-tooltip .tooltip-container .tooltip-item-label {
  display: inline-block;
  padding-left: 5px;
}
#chartjs-tooltip .tooltip-container .tooltip-item-value {
  float: right;
}
.trend-table-actions .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #FFB917;
  border-color: #FFB917;
}
.trend-table-actions .ant-checkbox-checked .ant-checkbox-inner:after {
  display: none;
}
.trend-table-actions .ant-checkbox-indeterminate .ant-checkbox-inner {
  width: 25px;
  height: 25px;
  border-radius: 25px;
  background: #f4f4f4;
  border: 1px solid #dadada;
}
.trend-table-actions .ant-checkbox-indeterminate .ant-checkbox-inner:before {
  display: block;
  margin-top: 4px;
  margin-left: 4px;
  content: "";
  background: #FFB917;
  width: 15px;
  height: 15px;
  border-radius: 25px;
}
.trend-table-actions .ant-checkbox-inner {
  width: 25px;
  height: 25px;
  border-radius: 25px;
  background: #f4f4f4;
  border: 1px solid #dadada;
}
.ant-row.page-filters {
  margin-bottom: 49px;
}
.ant-row.page-filters .ant-select {
  margin-right: 25px;
}
.ant-row.page-filters .ant-select .ant-select-selection-selected-value {
  font-size: 10px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  color: #5b6572;
}
.ant-row.page-filters .ant-checkbox .ant-checkbox-inner {
  width: 20px;
  height: 20px;
}
.ant-row.page-filters .ant-checkbox .ant-checkbox-inner:after {
  left: 6.571429px;
  top: 3.142857px;
}
.ant-row.page-filters .ant-checkbox + span {
  margin: 0 9px;
}
.trend-radio-buttons.ant-radio-group-solid {
  width: 100%;
}
.trend-radio-buttons.ant-radio-group-solid .ant-radio-button-wrapper {
  width: 50%;
  text-align: center;
  padding: 10px 10px;
  height: 51px;
  background-color: #151515;
  color: #d9d9d9;
  border-color: #3c3c3c;
}
.trend-radio-buttons.ant-radio-group-solid .ant-radio-button-wrapper:not(:first-child):before {
  background-color: #3c3c3c;
}
.trend-radio-buttons.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  color: #000;
  background: #fff;
  border-color: #3c3c3c;
  box-shadow: -1px 0 0 0 #fff;
}
.trend-radio-buttons.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
  color: #000;
  border-color: #fff;
  background: #fff;
}
.trend-radio-buttons.ant-radio-group-solid .ant-radio-button-wrapper .ant-radio-button {
  font-family: SFProDisplay;
  font-weight: normal;
  font-style: normal;
}
.trend-radio-buttons.ant-radio-group-solid .ant-radio-button-wrapper:first-child {
  border-radius: 2px 0 0 2px;
}
.trend-radio-buttons.ant-radio-group-solid .ant-radio-button-wrapper:last-child {
  border-radius: 0 2px 2px 0;
}
.trend-radio-buttons-v2 {
  display: flex !important;
  flex-direction: row !important;
}
.trend-radio-buttons-v2.svg-black-fill .ant-radio-button-wrapper:hover svg path,
.trend-radio-buttons-v2.svg-black-fill .ant-radio-button-wrapper-checked svg path {
  fill: #000000;
}
.trend-radio-buttons-v2.svg-white-stroke .ant-radio-button-wrapper-checked svg path {
  stroke: #FFFFFF;
}
.trend-radio-buttons-v2 .ant-radio-button-wrapper {
  color: #FFFFFF;
  background-color: #272727;
  border: none;
}
.trend-radio-buttons-v2 .ant-radio-button-wrapper:first-child {
  border-left: none !important;
}
.trend-radio-buttons-v2 .ant-radio-button-wrapper::before {
  content: none !important;
}
.trend-radio-buttons-v2 .ant-radio-button-wrapper.ant-radio-button-wrapper-checked {
  color: black !important;
}
.trend-radio-buttons-v2 .ant-radio-button-wrapper:hover:not(.ant-radio-button-wrapper-checked) {
  color: black;
  background-color: #FFB917;
  border: none;
}
#campaign-form_gender.trend-radio-buttons .ant-radio-button-wrapper {
  width: 33.3333%;
}
.video-player {
  position: relative;
}
.video-player-actions {
  width: 100%;
  height: 100%;
  z-index: 2;
  position: absolute;
}
.video-player-actions i {
  font-size: 85px;
}
.trend-steps.steps {
  color: #fff;
  background: #151515;
  border-radius: 28.5px;
  height: 59px;
  width: 100%;
  padding-top: 15px;
  padding-left: 30px;
}
.trend-steps.steps .steps-item {
  display: inline-block;
}
.trend-steps.steps .steps-item:first-child {
  margin-right: 34px;
}
.trend-steps.steps .steps-item:nth-child(2) {
  margin-right: 16px;
}
.trend-steps.steps .steps-item-icon {
  font-family: SFProDisplay;
  font-weight: normal;
  font-style: normal;
  background: #151515;
  border: 1px solid #3c3c3c;
  color: #3c3c3c;
  display: inline-block;
  width: 28px;
  height: 28px;
  font-size: 12px;
  border-radius: 100%;
  text-align: center;
  line-height: 26px;
  margin-right: 8px;
}
.trend-steps.steps .steps-item-label {
  font-family: SFProDisplay;
  font-weight: normal;
  font-style: normal;
  color: #747474;
  font-size: 12px;
}
.trend-steps.steps .steps-item.active .steps-item-icon {
  color: #000;
  background: #ffb917;
  border-color: #ffb917;
}
.trend-steps.steps .steps-item.active .steps-item-label {
  color: #fff;
  font-weight: bold;
}
.ant-input.trend-input-bordered {
  border: 1px solid #3c3c3c;
  background: #151515;
  border-radius: 2px;
  padding: 16px 15px 17px 15px;
  font-size: 15px;
  color: #ffb917;
  height: 51px;
}
.ant-input.trend-input-bordered::-webkit-input-placeholder {
  color: #989898;
}
.ant-input.trend-input-bordered::placeholder {
  color: #989898;
}
.notifications-checkbox {
  align-items: center;
  background-color: #545454;
  border: 1px solid #989898;
  border-radius: 1px;
  cursor: pointer;
  display: flex;
  height: 1.25rem;
  justify-content: center;
  transition: all 150ms ease;
  width: 1.25rem;
}
.notifications-checkbox svg {
  margin-top: 1px;
  -webkit-transform: scale(1.75);
          transform: scale(1.75);
}
.notifications-checkbox svg path {
  stroke: #000000;
}
.notifications-checkbox:hover {
  cursor: pointer;
  border-color: #FFFFFF;
}
.notifications-checkbox--active {
  background-color: #FFB917;
  border-color: #FFB917;
}
.ant-menu-horizontal > .ant-menu-item:hover,
.ant-menu-horizontal > .ant-menu-submenu:hover,
.ant-menu-horizontal > .ant-menu-item-active,
.ant-menu-horizontal > .ant-menu-submenu-active,
.ant-menu-horizontal > .ant-menu-item-open,
.ant-menu-horizontal > .ant-menu-submenu-open,
.ant-menu-horizontal > .ant-menu-item-selected,
.ant-menu-horizontal > .ant-menu-submenu-selected {
  border-bottom-width: 4px !important;
}
.ant-notification.trend-notifcation.notif-on {
  width: 261px;
  height: 85px;
  background: #FFFFFF;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  border-radius: 0px 2px 2px 0px;
  padding: 14px;
  float: right;
}
.ant-notification.trend-notifcation.notif-on .ant-notification-notice-content .ant-notification-notice-with-icon .ant-notification-notice-icon {
  margin-left: 0;
}
.ant-notification.trend-notifcation.notif-on .ant-notification-notice-content .ant-notification-notice-with-icon .ant-notification-notice-message {
  font-family: SFProDisplay;
  font-weight: 800;
  color: #000;
  font-size: 14px;
  line-height: 14px;
  margin-left: 72px;
}
.ant-notification.trend-notifcation.notif-on .ant-notification-notice-content .ant-notification-notice-with-icon .ant-notification-notice-description {
  font-family: SFProDisplay;
  font-weight: normal;
  font-style: normal;
  color: #000;
  font-size: 12px;
  line-height: 16px;
  margin-left: 72px;
}
.ant-notification.trend-notifcation.notif-on .ant-notification-notice-close {
  display: none;
}
.influencer-avatar {
  color: #000;
}
.influencer-avatar:hover,
.influencer-avatar:active {
  color: #000;
}
.influencer-avatar span {
  display: block;
}
.influencer-avatar-image {
  float: left;
  position: relative;
  margin-right: 10px;
}
.influencer-avatar .influencer-vip {
  position: absolute;
  bottom: 1px;
  left: 35px;
}
.influencer-avatar .influencer-info {
  font-family: SFProDisplay;
  font-weight: 400;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 0.625rem;
}
.influencer-avatar .influencer-info-username {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  margin: 0;
}
.influencer-avatar .influencer-info-state {
  font-weight: 400;
  font-size: 11px;
  line-height: 18px;
  margin: 0;
}
.ant-btn.influencer-instagram-button {
  font-family: SFProDisplay;
  font-weight: normal;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  border: none;
  border-radius: 3px;
  display: inline-block;
  height: 48px;
  line-height: 47px;
  background: #f1f5fd;
  color: #000;
}
.ant-btn.influencer-instagram-button:hover {
  color: #000;
  background: #dce5f7;
}
.ant-btn.influencer-instagram-button img {
  vertical-align: middle;
  height: 28px;
  width: 28px;
  margin-right: 9px;
}
#basicsCampaign {
  color: #FFFFFF;
}
#basicsCampaign .title-container {
  display: inline-block;
  text-align: left;
  padding-bottom: 22px;
}
#basicsCampaign .title-container .title {
  font-family: SFProDisplay;
  font-weight: 800;
  font-size: 24px;
  line-height: 30px;
}
#basicsCampaign .title-container .subtitle {
  font-family: SFProDisplay;
  font-weight: normal;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
}
#basicsCampaign .video-wrapper {
  border-radius: 5px;
  background: #000000;
  margin-bottom: 51px;
  width: 439px;
  height: 209px;
}
#basicsCampaign .video-wrapper iframe {
  width: 100%;
  height: 100%;
}
#basicsCampaign .video-wrapper #text-over {
  position: absolute;
  top: 130px;
  left: 20px;
  z-index: 2;
}
#basicsCampaign .video-wrapper #text-over #title {
  font-weight: 800;
  font-size: 24px;
  line-height: 30px;
}
#creatorsCampaign {
  max-width: 480px;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
}
#creatorsCampaign .page-title {
  font-family: SFProDisplay;
  font-weight: 800;
  font-size: 24px;
  line-height: 30px;
  color: white;
}
#creatorsCampaign .trend-radio-buttons-v2 {
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
@media screen and (max-width: 600px) {
  #creatorsCampaign .trend-radio-buttons-v2 {
    justify-content: center;
  }
}
#creatorsCampaign .page-description {
  font-family: SFProDisplay;
  font-weight: normal;
  font-style: normal;
  color: white;
  font-size: 16px;
  line-height: 24px;
  margin-top: 3px;
  margin-bottom: 43px;
}
#creatorsCampaign .ant-radio-button-wrapper {
  min-width: 142px;
  max-width: 142px;
  min-height: 120px;
  max-height: 120px;
  border-radius: 5px;
  margin-right: 8px;
  margin-bottom: 8px;
}
#creatorsCampaign .ant-radio-button-wrapper .gender-label {
  font-family: SFProDisplay;
  font-weight: bold;
  font-size: 14px !important;
  font-style: normal;
  line-height: 16px;
  margin-top: 13px;
}
#creatorsCampaign .ant-radio-button-wrapper span:last-child {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}
#creatorsCampaign .ant-radio-button-wrapper:last-child {
  margin-right: 0;
  margin-bottom: 0px;
}
@media screen and (max-width: 600px) {
  #creatorsCampaign .ant-radio-button-wrapper:last-child {
    margin-right: 8px;
  }
}
#socialCreativeBrief {
  color: #FFFFFF;
  max-width: 452px;
}
#socialCreativeBrief .trend-radio-buttons-v2 {
  flex-wrap: wrap;
  justify-content: space-between;
}
@media screen and (max-width: 600px) {
  #socialCreativeBrief .trend-radio-buttons-v2 {
    justify-content: center;
  }
}
#socialCreativeBrief .page-title {
  font-family: SFProDisplay;
  font-weight: 800;
  font-size: 24px;
  line-height: 30px;
}
#socialCreativeBrief .page-description {
  font-family: SFProDisplay;
  font-weight: normal;
  font-style: normal;
  color: #FFFFFF;
  font-size: 16px;
  line-height: 24px;
  margin-top: 3px;
  margin-bottom: 24px;
}
#socialCreativeBrief .extra-title {
  font-family: SFProDisplay;
  font-weight: bold;
  font-weight: 800;
  font-size: 16px;
  line-height: 20px;
}
#socialCreativeBrief .extra-sub-label {
  font-family: SFProDisplay;
  font-weight: normal;
  font-style: normal;
  font-size: 14px;
  line-height: 22px;
  margin-top: 5px;
  margin-bottom: 32px;
}
#socialCreativeBrief .post-types .ant-radio-button-wrapper {
  width: 212px;
  height: 120px;
  border-radius: 5px;
}
#socialCreativeBrief .post-types .ant-radio-button-wrapper.image-post {
  margin-right: 8px;
}
@media screen and (max-width: 600px) {
  #socialCreativeBrief .post-types .ant-radio-button-wrapper.image-post {
    margin-right: 0px;
    margin-bottom: 8px;
  }
}
#socialCreativeBrief .post-types .ant-radio-button-wrapper .post-type-label {
  font-family: SFProDisplay;
  font-weight: bold;
  font-size: 14px !important;
  font-style: normal;
  line-height: 16px;
  margin-top: 13px;
}
#socialCreativeBrief .post-types .ant-radio-button-wrapper span:last-child {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}
#socialCreativeBrief .creator-instruction .ant-radio-button-wrapper-checked .more-examples {
  color: black !important;
}
#socialCreativeBrief .creator-instruction .ant-radio-button-wrapper-checked .card-checkbox-circle {
  background: #000000 !important;
}
#socialCreativeBrief .creator-instruction .ant-radio-button-wrapper-checked .select-label {
  color: #000000 !important;
}
#socialCreativeBrief .creator-instruction .ant-radio-button-wrapper:hover:not(.ant-radio-button-wrapper-checked) .card-checkbox-circle {
  align-items: center;
  background: #fff0cd;
  border: 1px solid #fff0cd;
}
#socialCreativeBrief .creator-instruction .ant-radio-button-wrapper:hover:not(.ant-radio-button-wrapper-checked) .more-examples {
  color: black !important;
}
#socialCreativeBrief .creator-instruction .ant-radio-button-wrapper:hover:not(.ant-radio-button-wrapper-checked) .select-label {
  color: #fff0cd;
}
#socialCreativeBrief .creator-instruction .ant-radio-button-wrapper {
  border-radius: 5px;
  height: 363px;
  padding: 0;
  width: 142px;
  margin-right: 8px;
}
#socialCreativeBrief .creator-instruction .ant-radio-button-wrapper:last-child {
  margin-right: 0;
}
@media screen and (max-width: 600px) {
  #socialCreativeBrief .creator-instruction .ant-radio-button-wrapper {
    width: 100%;
    max-width: 220px;
    margin-right: 0px;
    margin-bottom: 16px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
  }
}
#socialCreativeBrief .creator-instruction .ant-radio-button-wrapper .card {
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 16px;
}
#socialCreativeBrief .creator-instruction .ant-radio-button-wrapper .card-image {
  position: relative;
  text-align: center;
}
@media screen and (max-width: 600px) {
  #socialCreativeBrief .creator-instruction .ant-radio-button-wrapper .card-image {
    width: 100%;
    height: auto;
  }
}
#socialCreativeBrief .creator-instruction .ant-radio-button-wrapper .card-image img.thumbnail {
  height: 124px;
  border-radius: 4px;
  width: 124px;
}
@media screen and (max-width: 600px) {
  #socialCreativeBrief .creator-instruction .ant-radio-button-wrapper .card-image img.thumbnail {
    width: 100%;
    height: auto;
  }
}
#socialCreativeBrief .creator-instruction .ant-radio-button-wrapper .card-image img.play-button {
  position: absolute;
  height: 38px;
  width: 38px;
  top: calc(50% - 19px);
  left: calc(50% - 19px);
}
#socialCreativeBrief .creator-instruction .ant-radio-button-wrapper .card-title {
  font-family: SFProDisplay;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  margin-top: 8px;
  margin-bottom: 1px;
  text-align: center;
}
#socialCreativeBrief .creator-instruction .ant-radio-button-wrapper .more-examples {
  font-family: SFProDisplay;
  font-weight: normal;
  font-style: normal;
  color: white;
  font-size: 10px;
  line-height: 20px;
  margin-bottom: 38px;
  text-align: center;
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
}
#socialCreativeBrief .creator-instruction .ant-radio-button-wrapper .selector-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  bottom: 1.5rem;
}
#socialCreativeBrief .creator-instruction .ant-radio-button-wrapper .card-checkbox-circle {
  align-items: center;
  background: #545454;
  border: 1px solid #545454;
  box-sizing: border-box;
  border-radius: 41px;
  display: flex;
  justify-content: center;
  height: 53px;
  width: 53px;
}
#socialCreativeBrief .creator-instruction .ant-radio-button-wrapper .select-label {
  font-family: SFProDisplay;
  font-weight: bold;
  color: #595959;
  font-size: 12px;
  line-height: 12px;
  margin-top: 8px;
}
#socialCreativeBrief .creator-instruction .ant-radio-button-wrapper.unboxing-option {
  margin-left: 10px;
  margin-right: 10px;
}
#socialCreativeBrief .is-story-required .ant-radio-button-wrapper {
  font-family: SFProDisplay;
  font-weight: bold;
  align-items: center;
  display: flex;
  font-size: 14px;
  height: 55px;
  line-height: 16px;
  justify-content: center;
  width: 224px;
  margin-bottom: 8px;
}
@media screen and (max-width: 600px) {
  #socialCreativeBrief .is-story-required .ant-radio-button-wrapper {
    border-radius: 4px !important;
  }
}
#photosNeeded {
  color: #FFFFFF;
  max-width: 480px;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
}
#photosNeeded .page-title {
  font-family: SFProDisplay;
  font-weight: 800;
  font-size: 24px;
  line-height: 30px;
}
#photosNeeded .page-description {
  font-family: SFProDisplay;
  font-weight: normal;
  font-style: normal;
  color: #FFFFFF;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;
  margin-top: 11px;
  margin-bottom: 24px;
}
#photosNeeded .level-bar {
  display: flex;
  margin-top: 24px;
  margin-bottom: 19px;
}
#photosNeeded .level-bar .level-width-min {
  width: 87px;
}
#photosNeeded .level-bar .level-width-max {
  width: 41px;
}
#photosNeeded .level-bar div {
  height: 10px;
  background: #545454;
  -webkit-transform: rotate(-180deg);
          transform: rotate(-180deg);
  margin-right: 5px;
}
#photosNeeded .level-bar div:last-child {
  margin-right: 0 !important;
}
#photosNeeded .level-bar .level-up {
  background: #FFB917;
  -webkit-transform: rotate(-180deg);
          transform: rotate(-180deg);
}
#photosNeeded .style-category-options {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
@media screen and (max-width: 600px) {
  #photosNeeded .style-category-options {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
#photosNeeded .creator-instruction .ant-radio-button-wrapper-checked .more-examples {
  color: black !important;
}
#photosNeeded .creator-instruction .ant-radio-button-wrapper-checked .card-checkbox-circle {
  background: #000000 !important;
}
#photosNeeded .creator-instruction .ant-radio-button-wrapper-checked .select-label {
  color: #000000 !important;
}
#photosNeeded .creator-instruction .ant-radio-button-wrapper:hover:not(.ant-radio-button-wrapper-checked) .card-checkbox-circle {
  align-items: center;
  background: #fff0cd;
  border: 1px solid #fff0cd;
}
#photosNeeded .creator-instruction .ant-radio-button-wrapper:hover:not(.ant-radio-button-wrapper-checked) .select-label {
  color: #fff0cd;
}
#photosNeeded .creator-instruction .ant-radio-button-wrapper:hover:not(.ant-radio-button-wrapper-checked) .more-examples {
  color: black !important;
}
#photosNeeded .creator-instruction .ant-radio-button-wrapper {
  border-radius: 5px;
  height: 363px;
  padding: 0;
  width: 142px;
  margin-right: 12px;
  caret-color: transparent;
  user-select: none;
  -webkit-user-select: none;
}
@media screen and (max-width: 600px) {
  #photosNeeded .creator-instruction .ant-radio-button-wrapper {
    width: 100%;
    max-width: 220px;
    margin-right: 0px;
    margin-bottom: 16px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
  }
}
#photosNeeded .creator-instruction .ant-radio-button-wrapper .card {
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 16px;
  position: static !important;
}
#photosNeeded .creator-instruction .ant-radio-button-wrapper .card-image {
  position: relative;
  text-align: center;
}
@media screen and (max-width: 600px) {
  #photosNeeded .creator-instruction .ant-radio-button-wrapper .card-image {
    width: 100%;
    height: auto;
  }
}
#photosNeeded .creator-instruction .ant-radio-button-wrapper .card-image img.thumbnail {
  height: 124px;
  border-radius: 4px;
  width: 124px;
}
@media screen and (max-width: 600px) {
  #photosNeeded .creator-instruction .ant-radio-button-wrapper .card-image img.thumbnail {
    width: 100%;
    height: auto;
  }
}
#photosNeeded .creator-instruction .ant-radio-button-wrapper .card-image img.play-button {
  position: absolute;
  height: 38px;
  width: 38px;
  top: calc(50% - 19px);
  left: calc(50% - 19px);
}
#photosNeeded .creator-instruction .ant-radio-button-wrapper .card-title {
  font-family: SFProDisplay;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  margin-top: 20px;
  margin-bottom: 1px;
  text-align: center;
}
#photosNeeded .creator-instruction .ant-radio-button-wrapper .more-examples {
  font-family: SFProDisplay;
  font-weight: normal;
  font-style: normal;
  color: white;
  font-size: 10px;
  line-height: 20px;
  text-align: center;
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
  margin-bottom: 38px;
}
#photosNeeded .creator-instruction .ant-radio-button-wrapper .selector-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  bottom: 1.5rem;
}
#photosNeeded .creator-instruction .ant-radio-button-wrapper .card-checkbox-circle {
  align-items: center;
  background: #545454;
  border: 1px solid #545454;
  box-sizing: border-box;
  border-radius: 41px;
  display: flex;
  justify-content: center;
  height: 53px;
  width: 53px;
}
#photosNeeded .creator-instruction .ant-radio-button-wrapper .select-label {
  font-family: SFProDisplay;
  font-weight: bold;
  color: #595959;
  font-size: 12px;
  line-height: 12px;
  margin-top: 8px;
}
#photosNeeded .creator-instruction .ant-radio-button-wrapper .box-incrementor {
  background: black;
  border: 1px solid #424242;
  border-radius: 5px;
  height: 104px;
  margin-bottom: 24px;
  margin-top: 20px;
  width: 88px;
  position: absolute;
  bottom: 0;
}
@media screen and (max-width: 600px) {
  #photosNeeded .creator-instruction .ant-radio-button-wrapper .box-incrementor {
    position: static;
  }
}
#photosNeeded .creator-instruction .ant-radio-button-wrapper .box-incrementor .counter {
  font-family: SFProDisplay;
  font-weight: 800;
  color: #FFFFFF;
  font-size: 36px;
  line-height: 30px;
  margin-bottom: 14px;
  margin-top: 17px;
  text-align: center;
}
#photosNeeded .creator-instruction .ant-radio-button-wrapper .box-incrementor .operator {
  display: flex;
  justify-content: center;
}
#photosNeeded .creator-instruction .ant-radio-button-wrapper .box-incrementor .operator .counter-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  border-radius: 37px;
  width: 30px;
}
#photosNeeded .creator-instruction .ant-radio-button-wrapper .box-incrementor .operator .plus {
  background-color: #FFB917;
}
#photosNeeded .creator-instruction .ant-radio-button-wrapper.option2 {
  margin-left: 10px;
  margin-right: 10px;
}
#photosNeeded .style-category .ant-form-item-control .ant-form-explain {
  top: -28px !important;
}
#videosNeeded {
  color: #FFFFFF;
  max-width: 480px;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
}
#videosNeeded .page-title {
  font-family: SFProDisplay;
  font-weight: 800;
  font-size: 24px;
  line-height: 30px;
}
#videosNeeded .page-description {
  font-family: SFProDisplay;
  font-weight: normal;
  font-style: normal;
  color: #FFFFFF;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;
  margin-top: 11px;
  margin-bottom: 62px;
}
#videosNeeded .level-bar {
  display: flex;
  margin-top: 24px;
  margin-bottom: 19px;
}
#videosNeeded .level-bar .level-width-min {
  width: 224px;
}
#videosNeeded .level-bar .level-width-max {
  width: 109.5px;
}
#videosNeeded .level-bar div {
  height: 10px;
  background: #545454;
  -webkit-transform: rotate(-180deg);
          transform: rotate(-180deg);
  margin-right: 5px;
}
#videosNeeded .level-bar div:last-child {
  margin-right: 0 !important;
}
#videosNeeded .level-bar .level-up {
  background: #FFB917;
  -webkit-transform: rotate(-180deg);
          transform: rotate(-180deg);
}
#videosNeeded .platform {
  display: flex !important;
  flex-wrap: wrap;
  justify-content: space-around;
}
#videosNeeded .platform .ant-radio-button-wrapper {
  align-items: center;
  border-radius: 5px;
  display: flex;
  height: 120px;
  justify-content: center;
  width: 142px;
  margin-right: 8px;
  margin-bottom: 16px;
}
#videosNeeded .platform .ant-radio-button-wrapper span:last-child {
  font-family: SFProDisplay;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.09px;
  text-align: center;
}
#videosNeeded .platform .ant-radio-button-wrapper span:last-child div {
  margin-top: 15.94px;
}
#videosNeeded .platform-layout .common-shape {
  border: 4px solid white;
  box-sizing: border-box;
  margin-bottom: 14px;
}
#videosNeeded .platform-layout .square-shape {
  height: 40px;
  margin-top: 32px;
  width: 40px;
}
#videosNeeded .platform-layout .vertical-shape {
  height: 53px;
  margin-top: 19px;
  width: 32px;
}
#videosNeeded .platform-layout .horizontal-shape {
  height: 32px;
  margin-top: 40px;
  width: 55px;
}
#videosNeeded .platform-layout .ant-radio-button-wrapper-checked .square-shape,
#videosNeeded .platform-layout .ant-radio-button-wrapper-checked .vertical-shape,
#videosNeeded .platform-layout .ant-radio-button-wrapper-checked .horizontal-shape {
  border: 4px solid black;
}
#videosNeeded .platform-layout .ant-radio-button-wrapper-disabled {
  background-color: rgba(80, 47, 47, 0.5);
  color: #502f2f !important;
}
#videosNeeded .platform-layout .ant-radio-button-wrapper-disabled .square-shape,
#videosNeeded .platform-layout .ant-radio-button-wrapper-disabled .vertical-shape,
#videosNeeded .platform-layout .ant-radio-button-wrapper-disabled .horizontal-shape {
  border: 4px solid #502f2f;
}
#videosNeeded .platform-layout .ant-radio-button-wrapper {
  border-radius: 5px;
  display: flex;
  height: 120px;
  justify-content: center;
  padding: 0;
  width: 142px;
}
#videosNeeded .platform-layout .ant-radio-button-wrapper .icon-holder {
  width: 100%;
  text-align: right;
  position: absolute;
  top: 7px;
  right: 7px;
  color: #ffb917;
}
#videosNeeded .platform-layout .ant-radio-button-wrapper span:last-child {
  font-family: SFProDisplay;
  font-weight: bold;
  align-items: center;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.09px;
  text-align: center;
  width: 100%;
}
#videosNeeded .platform-layout .ant-radio-button-wrapper:not(.ant-radio-button-wrapper-disabled):hover .square-shape,
#videosNeeded .platform-layout .ant-radio-button-wrapper:not(.ant-radio-button-wrapper-disabled):hover .vertical-shape,
#videosNeeded .platform-layout .ant-radio-button-wrapper:not(.ant-radio-button-wrapper-disabled):hover .horizontal-shape {
  border: 4px solid #000;
}
#videosNeeded .video-length .ant-radio-button-wrapper:hover circle,
#videosNeeded .video-length .ant-radio-button-wrapper-checked circle,
#videosNeeded .video-length .ant-radio-button-wrapper:hover svg g circle,
#videosNeeded .video-length .ant-radio-button-wrapper-checked svg g circle {
  fill: black;
}
#videosNeeded .video-length .ant-radio-button-wrapper {
  align-items: center;
  border-radius: 5px;
  display: flex;
  height: 120px;
  justify-content: center;
  width: 142px;
}
#videosNeeded .video-length .ant-radio-button-wrapper span:last-child {
  font-family: SFProDisplay;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.09px;
  text-align: center;
}
#videosNeeded .style-category-options {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
@media screen and (max-width: 600px) {
  #videosNeeded .style-category-options {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
#videosNeeded .creator-instruction .ant-radio-button-wrapper-checked .more-examples {
  color: black !important;
}
#videosNeeded .creator-instruction .ant-radio-button-wrapper-checked .card-checkbox-circle {
  background: #000000 !important;
}
#videosNeeded .creator-instruction .ant-radio-button-wrapper-checked .select-label {
  color: #000000 !important;
}
#videosNeeded .creator-instruction .ant-radio-button-wrapper:hover:not(.ant-radio-button-wrapper-checked) .card-checkbox-circle {
  align-items: center;
  background: #fff0cd;
  border: 1px solid #fff0cd;
}
#videosNeeded .creator-instruction .ant-radio-button-wrapper:hover:not(.ant-radio-button-wrapper-checked) .select-label {
  color: #fff0cd;
}
#videosNeeded .creator-instruction .ant-radio-button-wrapper:hover:not(.ant-radio-button-wrapper-checked) .more-examples {
  color: black !important;
}
#videosNeeded .creator-instruction .ant-radio-button-wrapper {
  border-radius: 5px;
  height: 363px;
  padding: 0;
  width: 142px;
  margin-right: 12px;
  caret-color: transparent;
  user-select: none;
  -webkit-user-select: none;
}
@media screen and (max-width: 600px) {
  #videosNeeded .creator-instruction .ant-radio-button-wrapper {
    width: 100%;
    max-width: 220px;
    margin-right: 0px;
    margin-bottom: 16px;
    height: 400px;
  }
}
#videosNeeded .creator-instruction .ant-radio-button-wrapper .card {
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 16px;
  position: static !important;
}
#videosNeeded .creator-instruction .ant-radio-button-wrapper .card-image {
  position: relative;
  text-align: center;
}
@media screen and (max-width: 600px) {
  #videosNeeded .creator-instruction .ant-radio-button-wrapper .card-image {
    width: 100%;
    height: auto;
  }
}
#videosNeeded .creator-instruction .ant-radio-button-wrapper .card-image img.thumbnail {
  height: 124px;
  border-radius: 4px;
  width: 124px;
}
@media screen and (max-width: 600px) {
  #videosNeeded .creator-instruction .ant-radio-button-wrapper .card-image img.thumbnail {
    width: 100%;
    height: auto;
  }
}
#videosNeeded .creator-instruction .ant-radio-button-wrapper .card-image img.play-button {
  position: absolute;
  height: 38px;
  width: 38px;
  top: calc(50% - 19px);
  left: calc(50% - 19px);
}
#videosNeeded .creator-instruction .ant-radio-button-wrapper .card-title {
  font-family: SFProDisplay;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  margin-top: 20px;
  margin-bottom: 1px;
  text-align: center;
}
#videosNeeded .creator-instruction .ant-radio-button-wrapper .more-examples {
  font-family: SFProDisplay;
  font-weight: normal;
  font-style: normal;
  color: white;
  font-size: 10px;
  line-height: 20px;
  text-align: center;
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
  margin-bottom: 38px;
}
#videosNeeded .creator-instruction .ant-radio-button-wrapper .selector-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  bottom: 1.5rem;
}
#videosNeeded .creator-instruction .ant-radio-button-wrapper .card-checkbox-circle {
  align-items: center;
  background: #545454;
  border: 1px solid #545454;
  box-sizing: border-box;
  border-radius: 41px;
  display: flex;
  justify-content: center;
  height: 53px;
  width: 53px;
}
#videosNeeded .creator-instruction .ant-radio-button-wrapper .select-label {
  font-family: SFProDisplay;
  font-weight: bold;
  color: #595959;
  font-size: 12px;
  line-height: 12px;
  margin-top: 8px;
}
#videosNeeded .creator-instruction .ant-radio-button-wrapper .box-incrementor {
  background: black;
  border: 1px solid #424242;
  border-radius: 5px;
  height: 104px;
  margin-bottom: 24px;
  margin-top: 20px;
  width: 88px;
  position: absolute;
  bottom: 0;
}
#videosNeeded .creator-instruction .ant-radio-button-wrapper .box-incrementor .counter {
  font-family: SFProDisplay;
  font-weight: 800;
  color: #FFFFFF;
  font-size: 36px;
  line-height: 30px;
  margin-bottom: 14px;
  margin-top: 17px;
  text-align: center;
}
#videosNeeded .creator-instruction .ant-radio-button-wrapper .box-incrementor .operator {
  display: flex;
  justify-content: center;
}
#videosNeeded .creator-instruction .ant-radio-button-wrapper .box-incrementor .operator .counter-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  border-radius: 37px;
  width: 30px;
}
#videosNeeded .creator-instruction .ant-radio-button-wrapper .box-incrementor .operator .plus {
  background-color: #FFB917;
}
#videosNeeded .creator-instruction .ant-radio-button-wrapper.option2 {
  margin-left: 10px;
  margin-right: 10px;
}
#videosNeeded .style-category .ant-form-item-control .ant-form-explain {
  top: -28px !important;
}
#brandRegistrationForm {
  color: #FFFFFF;
  max-width: 480px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  padding-top: 48px;
}
@media screen and (max-width: 600px) {
  #brandRegistrationForm {
    padding: 20px;
    padding-top: 24px;
  }
}
#brandRegistrationForm div {
  max-width: 100%;
}
#brandRegistrationForm .creator-note {
  font-family: SFProDisplay;
  font-weight: bold;
  font-size: 12px;
  line-height: 24px;
}
#brandRegistrationForm .page-description {
  font-family: SFProDisplay;
  font-weight: 800;
  color: #FFFFFF;
  font-size: 24px;
  line-height: 30px;
  margin: 10px 0 46px;
}
#brandRegistrationForm .ant-checkbox .ant-checkbox-inner {
  height: 35px;
  width: 35px;
  background: #141414;
  border: 1px solid #4e4e4e;
  border-radius: 80px;
}
#brandRegistrationForm .ant-checkbox .ant-checkbox-inner::after {
  border: 0;
}
#brandRegistrationForm .ant-checkbox.ant-checkbox-checked::after {
  height: 35px;
  width: 35px;
  border-radius: 80px;
  border: 1px solid #FFB917;
}
#brandRegistrationForm .terms-of-service {
  margin-top: 3px;
}
#brandRegistrationForm .terms-of-service .tos-description {
  font-family: SFProDisplay;
  font-weight: bold;
  color: #FFFFFF !important;
  font-size: 12px;
  line-height: 18px;
}
#brandRegistrationForm .terms-of-service .ant-form-explain {
  margin-top: 0;
}
#brandRegistrationForm .terms-of-service .tos-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.style-guide {
  border-top: 0.1875rem solid #000000;
  color: #000000;
  padding: 1.5rem 1rem;
}
.style-guide .link-list {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 0.5rem;
  gap: 0.5rem;
}
.style-guide .link-list .style-guide-link {
  font-family: SFProDisplay;
  font-weight: bold;
  background-color: #F8FAFF;
  border: 0.0625rem solid #DBDFE8;
  border-radius: 0.125rem;
  color: #000000;
  font-size: 0.6875rem;
  padding: 0.25rem 0.625rem;
}
.style-guide .link-list .style-guide-link:hover {
  background-color: #dfe8ff;
}
.style-guide .link-list .style-guide-link:active {
  background-color: #F8FAFF;
}
.style-guide .asset-list {
  display: grid;
  grid-gap: 1rem;
  gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(7rem, 1fr));
}
.style-guide .style-guide-asset {
  position: relative;
}
.style-guide .style-guide-asset img {
  height: 7rem;
  object-fit: cover;
  width: 100%;
}
.style-guide .style-guide-asset video {
  background-color: #000000;
  height: 7rem;
  width: 100%;
}
.style-guide .style-guide-asset svg {
  height: 1.875rem;
  width: 1.875rem;
}
.style-guide .style-guide-asset .video-mask {
  background-color: transparent;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
}
.campaign-stats {
  border-bottom: 0.1875rem solid #000000;
  display: flex;
  flex-wrap: wrap;
  grid-gap: 1rem;
  gap: 1rem;
  justify-content: space-between;
  padding: 0 1rem 1.5rem 1rem;
}
.campaign-stats .stats-heading {
  font-family: SFProDisplay;
  font-weight: bold;
  font-size: 1rem;
  line-height: 1.875rem;
  margin: 0;
}
.campaign-stats p {
  font-family: 'SFProDisplay', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  color: #5B6572;
  font-size: 0.625rem;
  font-weight: 600;
  letter-spacing: 0.03125rem;
  line-height: 0.75rem;
  margin: 0;
  text-transform: uppercase;
}
#campaignRecentPosts {
  border-bottom: 1px solid #d6d6d6;
  padding-bottom: 28px;
  width: 222px;
}
#campaignRecentPosts .side-header-text {
  margin-bottom: 0 !important;
}
#campaignRecentPosts .see-all {
  font-family: SFProDisplay;
  font-weight: normal;
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 12px;
  text-align: right;
}
#campaignRecentPosts .post-image,
#campaignRecentPosts .empty-box {
  border-radius: 4px;
  height: 107.36px;
  width: 107.84px;
}
#campaignRecentPosts .post-image.with-border,
#campaignRecentPosts .empty-box.with-border {
  border: 1px dashed #888;
}
#campaignRecentPosts .row-container {
  display: flex;
}
#campaignRecentPosts .creatorName {
  font-family: SFProDisplay;
  font-weight: normal;
  font-size: 10px;
  font-weight: 600;
  line-height: 14px;
  letter-spacing: -0.008em;
  margin-top: 3px;
}
#campaignDetails {
  border-bottom: 0.0625rem solid #D6D6D6;
  padding: 1.25rem 1rem;
  width: 100%;
}
#campaignDetails .side-header-text {
  margin-bottom: 0.5rem !important;
}
.campaign-post-requirements {
  border-bottom: 0.0625rem solid #D6D6D6;
  padding: 1.5rem 1rem;
}
.campaign-post-requirements .tag-list {
  font-family: SFProDisplay;
  font-weight: bold;
  color: #000000;
  display: flex;
  flex-direction: column;
  font-size: 0.75rem;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  list-style-type: none;
  margin-bottom: 1.5rem;
  padding: 0;
}
.campaign-post-requirements .tag-list li {
  display: flex;
  grid-gap: 0.5rem;
  gap: 0.5rem;
}
.campaign-post-requirements .tag-list span {
  display: flex;
  flex-direction: column;
}
.campaign-post-requirements .caption {
  font-weight: 400;
}
.campaign-post-requirements .post-content {
  font-family: SFProDisplay;
  font-weight: bold;
  align-items: center;
  color: #000000;
  display: flex;
  font-size: 0.75rem;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  line-height: 1rem;
  margin-bottom: 1.5rem;
}
#campaignStyleCategory {
  border-bottom: 0.1875rem solid #000000;
  border-top: 0.1875rem solid #000000;
}
#campaignStyleCategory .style-category {
  padding: 1.5rem 1rem;
  width: 100%;
}
#campaignStyleCategory .style-category .title {
  font-family: SFProDisplay;
  font-weight: 800;
  font-size: 1rem;
  line-height: 1.125rem;
  margin: 0;
}
#campaignStyleCategory .style-category .description {
  font-family: 'SFProDisplay', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: 600;
  font-size: 0.75rem;
  line-height: 0.75rem;
  margin: 0;
  padding-top: 0.5rem;
}
#campaignStyleCategory .video-details-wrapper {
  display: flex;
  grid-gap: 2rem;
  gap: 2rem;
  padding-top: 1rem;
}
#campaignStyleCategory .video-details {
  align-items: center;
  display: flex;
  grid-gap: 0.75rem;
  gap: 0.75rem;
  width: -webkit-min-content;
  width: min-content;
}
#campaignStyleCategory .video-details p {
  font-family: 'SFProDisplay', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 0.75rem;
  font-weight: 600;
  line-height: 0.875rem;
  margin: 0;
  text-transform: capitalize;
  width: -webkit-min-content;
  width: min-content;
}
#campaignStyleCategory .video-details svg {
  flex-shrink: 0;
}
#campaignStyleCategory .video-details svg path,
#campaignStyleCategory .video-details svg g circle,
#campaignStyleCategory .video-details svg circle {
  fill: #000000;
}
#campaignStyleCategory .video-details .layout-shape {
  background: #FFFFFF;
  border: 0.1875rem solid #000000;
  flex-shrink: 0;
}
#campaignStyleCategory .video-details .layout-shape--square {
  height: 1.4375rem;
  width: 1.4375rem;
}
#campaignStyleCategory .video-details .layout-shape--horizontal {
  height: 1.4375rem;
  width: 2.4375rem;
}
#campaignStyleCategory .video-details .layout-shape--vertical {
  height: 2.4375rem;
  width: 1.4375rem;
}
.trend-select-dark-v2 {
  width: 448px;
}
.trend-selected-tags-dropdown-v2 {
  background: #272727 !important;
  border: 0;
}
.trend-selected-tags-dropdown-v2.ant-select-dropdown--single .ant-select-dropdown-menu .ant-select-dropdown-menu-item-selected,
.trend-selected-tags-dropdown-v2.ant-select-dropdown--single .ant-select-dropdown-menu .ant-select-dropdown-menu-item-active {
  color: black !important;
}
.trend-selected-tags-dropdown-v2.ant-select-dropdown--single .ant-select-dropdown-menu .ant-select-dropdown-menu-item:not(.ant-select-dropdown-menu-item-active, .ant-select-dropdown-menu-item-selected) {
  color: #989898 !important;
}
.tag-select-v2 .ant-tag {
  padding: 0 10px;
}
.tag-select-v2 .trend-tag {
  font-family: SFProDisplay;
  font-weight: bold;
  background-color: #FFFFFF !important;
  font-size: 11px;
}
.tag-select-v2 svg {
  height: 10px;
  margin-bottom: -2px;
  margin-left: 10px;
  width: 10px;
}
.video-loader {
  position: relative;
  width: 100%;
}
.video-loader .ant-spin {
  top: calc(50% - 12.5px);
  left: calc(50% - 10px);
  position: absolute;
}
.content-approval-modal {
  position: relative;
}
.content-approval-modal .close {
  position: absolute;
  width: 45px;
  height: 45px;
  font-size: 15px;
  border: 1px solid #000;
  border-radius: 4px;
  padding: 0px;
  top: 33px;
  right: 25px;
  z-index: 1;
}
.content-approval-modal .ant-modal-body {
  padding: 0px;
}
.content-approval-modal .ant-modal-content {
  border-radius: 10px;
}
.content-approval-modal .header {
  padding: 23px 33px 0;
}
.content-approval-modal .modal-content {
  border-radius: 10px 10px 0 0;
  background-color: #f0f5fd;
}
.content-approval-modal .footer {
  padding: 23px 20px 17px 36px;
  box-shadow: 0px 2px 19px rgba(0, 0, 0, 0.162024);
  color: #000;
  font-family: SFProDisplay;
  font-weight: normal;
  font-style: normal;
}
.content-approval-modal .footer h3 {
  font-weight: 800;
  font-size: 18px;
  line-height: 23px;
}
.content-approval-modal .footer p {
  font-weight: normal;
  font-size: 15px;
  line-height: 23px;
}
.content-approval-modal .footer .confirm {
  width: 183px;
  font-family: SFProDisplay;
  font-weight: normal;
  font-style: normal;
  height: 55px;
  background-color: #ffb917;
  color: #000;
  border: none;
  font-weight: bold;
  font-size: 14px;
  line-height: 13px;
}
.content-approval-modal .footer .confirm .timer {
  font-weight: normal;
  font-size: 10px;
  line-height: 13px;
}
.content-approval-modal .footer .confirm .timer .ant-statistic {
  display: inline-block;
}
.content-approval-modal .footer .pass {
  font-weight: bold;
  color: #e58989;
  font-size: 11px;
  line-height: 20px;
  text-align: center;
  height: 20px;
  margin-top: 9px;
}
.content-approval-modal .influencer-details {
  float: left;
}
.content-approval-modal .influencer-details .ant-avatar {
  margin-right: 14px;
}
.content-approval-modal .influencer-details-username,
.content-approval-modal .influencer-details .content-stats {
  font-family: SFProDisplay;
  font-weight: normal;
  font-style: normal;
  color: #000;
  font-size: 16px;
  line-height: 23px;
}
.content-approval-modal .influencer-details-username {
  margin-top: 7px;
  font-family: SFProDisplay;
  font-weight: normal;
  font-style: normal;
  font-weight: 800;
}
.content-approval-modal .content-count {
  color: #000;
  text-align: right;
  padding-right: 70px;
}
.content-approval-modal .content-count-number {
  margin-top: 7px;
  font-family: SFProDisplay;
  font-weight: normal;
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  line-height: 23px;
}
.content-approval-modal .content-count-label {
  font-family: SFProDisplay;
  font-weight: normal;
  font-style: normal;
  font-size: 16px;
  line-height: 23px;
}
.content-approval-modal .submitted-contents {
  margin-top: 29px;
  padding-bottom: 26px;
  width: 100%;
}
.content-approval-modal .submitted-contents .creator-content-content-card {
  float: left;
  margin-right: 20px;
  background-color: #000;
}
.content-approval-modal .submitted-contents .creator-content-content-card img,
.content-approval-modal .submitted-contents .creator-content-content-card video {
  width: 100%;
  height: 200px;
}
.content-approval-modal .submitted-contents .creator-content-content-card .video-loader .anticon {
  font-size: 38px;
}
.trend-modal.reject-creator-content .modal-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #46c5ff;
  width: 60px;
  height: 60px;
  border-radius: 100%;
  margin-bottom: 11px;
  font-size: 35px;
}
.trend-modal.reject-creator-content .close-modal {
  border: 1px solid #000000;
  box-sizing: border-box;
  border-radius: 4px;
  width: 45px;
  height: 45px;
  position: absolute;
  top: 20px;
  left: 20px;
  text-align: center;
  padding: 0px;
}
.trend-modal.reject-creator-content .close-modal .anticon {
  line-height: 45px;
  margin-top: 1px;
  font-size: 20px;
}
.trend-modal.reject-creator-content .close-modal .anticon svg path {
  transition: stroke 0.5s;
}
.trend-modal.reject-creator-content .close-modal:hover {
  border-color: #ffcc40;
}
.trend-modal.reject-creator-content .close-modal:hover .anticon svg path {
  transition: stroke 0.5s;
  stroke: #ffcc40;
}
.trend-modal.reject-creator-content .center-horizontally {
  display: flex;
  justify-content: center;
}
.trend-modal.reject-creator-content .reject-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #e58989;
  width: 60px;
  height: 60px;
  border-radius: 100%;
  margin-bottom: 11px;
}
.trend-modal.reject-creator-content .reject-icon .dash {
  height: 7px;
  background: #FFFFFF;
  width: 32px;
  border-radius: 50px;
}
.trend-modal.reject-creator-content .ant-modal-body {
  padding-top: 38px;
  padding-bottom: 39px;
}
.trend-modal.reject-creator-content h2 {
  font-family: SFProDisplay;
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  line-height: 26px;
  color: #000000;
  margin-bottom: 0px;
}
.trend-modal.reject-creator-content p {
  color: #000;
  font-family: SFProDisplay;
  font-weight: 400;
  font-style: normal;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 23px;
}
.trend-modal.reject-creator-content .ant-btn.submit {
  font-family: SFProDisplay;
  font-weight: 800;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  color: #000;
  height: 45px;
  width: 217px;
}
.trend-modal.reject-creator-content textarea {
  font-family: SFProDisplay;
  font-weight: 400;
  font-style: normal;
  font-size: 14px !important;
  line-height: 20px;
  background: #f1f5fd;
  border-radius: 8px;
  border: none !important;
  width: 100%;
  min-height: 159px;
}
.trend-modal.reject-creator-content textarea::-webkit-input-placeholder {
  color: #5b6572;
}
.trend-modal.reject-creator-content textarea::placeholder {
  color: #5b6572;
}
.trend-modal.creator-approval-modal .ant-modal-body {
  padding: 0;
}
.trend-modal .modal-notification {
  background: #124345;
  border-radius: 10px 10px 4px 4px;
  color: white;
  padding: 20px 25px 22px 17px;
  font-family: SFProDisplay;
  font-weight: normal;
  font-style: normal;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.trend-modal .modal-notification #title {
  font-weight: 600;
  font-size: 13px;
  line-height: 12px;
  letter-spacing: 1px;
  margin-bottom: 7px;
}
.trend-modal .modal-notification p {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #FFFFFF;
}
.trend-modal .modal-notification button {
  background: #4C8F92;
  border: none;
  border-radius: 4px;
  color: white;
  padding: 16px 0px;
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  width: 123px;
  cursor: pointer;
}
.creator-content-content-card {
  width: 200px;
  box-shadow: 0px 1px 5px rgba(143, 158, 187, 0.38);
  border-radius: 10px 10px;
}
.creator-content-content-card .video-loader {
  width: 200px;
  height: 200px;
  background-color: #000;
  border-radius: 10px 10px 0 0;
}
.creator-content-content-card .video-loader img,
.creator-content-content-card .video-loader video {
  border-radius: 10px 10px 0 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.creator-content-content-card .card-action {
  border-radius: 0 0 10px 10px;
  background: #ffffff;
  height: 80px;
  padding: 23px 11px;
}
.creator-content-content-card .card-action .ant-btn {
  font-family: SFProDisplay;
  font-weight: normal;
  font-style: normal;
  width: 100%;
  height: 40px;
  line-height: 40px;
  font-weight: bold;
  font-size: 12px;
  line-height: 12px;
  border-radius: 5px;
  border: none;
  background-color: #f1f5fd;
  color: #8f9ebb;
}
.creator-content-content-card .card-action .ant-btn span {
  vertical-align: middle;
}
.creator-content-content-card .card-action .ant-btn .anticon {
  font-size: 19px;
  vertical-align: middle;
}
.creator-content-content-card .card-action .ant-btn .anticon path.line {
  fill: #f1f5fd;
  stroke: #a8b3c8;
}
.creator-content-content-card .card-action .ant-btn .anticon path.circle {
  fill: #f1f5fd;
  stroke: #a8b3c8;
}
.creator-content-content-card.approved .ant-btn {
  background-color: #00b267;
  color: #fff;
}
.creator-content-content-card.approved .ant-btn .anticon path.line {
  fill: #fff;
  stroke: #fff;
}
.creator-content-content-card.approved .ant-btn .anticon path.circle {
  stroke: #00b267;
}
.creator-content-avatar {
  position: relative;
}
.creator-content-avatar:nth-child(n + 6) {
  margin-top: 10px;
}
.creator-content-avatar.pending .ant-avatar,
.creator-content-avatar.rejected .ant-avatar,
.creator-content-avatar.submitted .ant-avatar {
  background-color: #fff;
  border: 1px dashed #000;
}
.creator-content-avatar .ant-avatar .ant-avatar-string {
  left: 0 !important;
  -webkit-transform: unset !important;
          transform: unset !important;
  width: 100%;
  height: 100%;
}
.creator-content-avatar .creator-content-type {
  font-family: SFProDisplay;
  font-weight: normal;
  font-style: normal;
  width: 59px;
  height: 15px;
  position: absolute;
  left: -4px;
  bottom: -4px;
  font-weight: 600;
  font-style: italic;
  font-size: 7px;
  line-height: 15px;
  /* identical to box height, or 157% */
  text-align: center;
  letter-spacing: 0.4px;
  text-transform: uppercase;
}
.creator-content-avatar .creator-content-type.type-video {
  background-color: #4df1ff;
}
.creator-content-avatar .creator-content-type.type-image {
  background-color: #ffd982;
}
.creator-content-table .creator-content-avatar {
  float: left;
  margin-right: 17px;
}
.creator-content-media-card {
  position: relative;
  max-width: 236px;
  max-height: 240px;
  overflow: hidden;
}
.creator-content-media-card .video-loader {
  width: 100%;
}
.creator-content-media-card .video-loader .video-player-actions {
  cursor: pointer;
  left: calc(50% - 30px);
  top: calc(50% - 30px);
  position: absolute;
  width: 60px;
  height: 60px;
  z-index: 2;
}
.creator-content-media-card .video-loader .video-player-actions .anticon {
  font-size: 60px;
}
.creator-content-media-card .video-loader img {
  height: 15rem;
  max-width: 15rem;
}
.creator-content-media-card .video-loader video {
  width: 100%;
  height: 236px !important;
  object-fit: cover !important;
  background: rgba(0, 0, 0, 0.65);
}
.creator-content-media-card .media-actions {
  border-radius: 0.5rem;
  overflow: hidden;
  height: 100%;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
}
.creator-content-media-card .media-actions .influencer-section {
  align-items: center;
  display: flex;
  flex-direction: column;
  left: 0;
  position: absolute;
  right: 0;
  top: 10px;
  width: 100%;
}
.creator-content-media-card .media-actions .influencer-section .username {
  font-family: SFProDisplay;
  font-weight: bold;
  font-size: 11px;
  line-height: 15px;
  color: #ffffff;
  margin-top: 6px;
}
.creator-content-media-card .media-actions .action-button {
  bottom: 11px;
  position: absolute;
  text-align: center;
  width: 100%;
  padding: 0 10px;
}
.creator-content-media-card .media-actions .action-button button {
  align-items: center;
  background: rgba(255, 255, 255, 0.3);
  border: 0 !important;
  border-radius: 2px;
  display: flex;
  height: 44px;
  justify-content: center;
  padding: 0;
  width: 100%;
}
.creator-content-media-card .media-actions .action-button button:hover {
  background: rgba(255, 255, 255, 0.5);
}
.creator-content-media-card .media-actions .action-button button .label {
  font-family: SFProDisplay;
  font-weight: bold;
  color: white;
  font-size: 10px;
  line-height: 12px;
  margin-left: 8px;
  text-align: left;
}
.creator-content-media-card .media-actions .action-button .rehire-button .image-wrapper {
  margin-right: 6px;
}
.creator-content-media-card .media-actions .action-button .rehire-button .image-wrapper i {
  font-size: 16px;
}
.creator-content-media-card .media-actions .action-button .rehire-button.awaiting .image-wrapper {
  display: inline-block;
  width: 28px;
  height: 28px;
  background-color: #fff;
  border-radius: 100%;
  padding: 5px 0 0 4px;
}
.creator-content-media-card .media-actions .action-button .rehire-button.awaiting .image-wrapper img {
  width: 19px;
  height: auto;
}
.creator-content-media-card .media-actions .action-button .rehire-button span {
  font-family: SFProDisplay;
  font-weight: bold;
  color: white;
  font-size: 10px;
  line-height: 12px;
  text-align: left;
}
.creator-content-media-card .media-actions .action-button .shape {
  align-items: center;
  border-radius: 35px;
  display: flex;
  height: 28px;
  justify-content: center;
  width: 28px;
}
.creator-content-media-card .media-actions .action-button .shape.send {
  background: #00b267;
}
.creator-content-media-card .media-actions .action-button .shape.download {
  background: #0056fe;
}
.creator-content-media-card .media-actions {
  opacity: 0;
  transition: all 250ms linear;
}
.creator-content-media-card .media-actions .media-actions-overlay {
  background: rgba(0, 0, 0, 0.65);
  height: 100%;
  width: 100%;
  cursor: pointer;
  transition: all 500ms;
}
.creator-content-media-card:hover .media-actions {
  opacity: 1;
}
.creator-content-recent-card span {
  display: block;
  max-width: 100%;
}
.creator-content-recent-card img {
  height: 6rem;
  object-fit: cover;
  width: 100%;
}
.creator-content-recent-card .username {
  font-family: SFProDisplay;
  font-weight: normal;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 14px;
}
.post-approval-card .post-btn-group.revise .ant-btn {
  background-color: #46c5ff;
  font-size: 20px;
  border: none;
}
.post-approval-card .video-loader {
  width: 195px;
  height: 160px;
  margin-left: 12px;
  margin-bottom: 7px;
}
.post-approval-card .video-loader img,
.post-approval-card .video-loader video {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  object-fit: cover;
}
.trend-modal.revise-post-modal .close-modal {
  border: 1px solid #000000;
  box-sizing: border-box;
  border-radius: 4px;
  width: 45px;
  height: 45px;
  position: absolute;
  top: 20px;
  left: 20px;
  text-align: center;
  padding: 0px;
}
.trend-modal.revise-post-modal .close-modal .anticon {
  line-height: 45px;
  margin-top: 1px;
  font-size: 20px;
}
.trend-modal.revise-post-modal .close-modal .anticon svg path {
  transition: stroke 0.5s;
}
.trend-modal.revise-post-modal .close-modal:hover {
  border-color: #ffcc40;
}
.trend-modal.revise-post-modal .close-modal:hover .anticon svg path {
  transition: stroke 0.5s;
  stroke: #ffcc40;
}
.trend-modal.revise-post-modal .center-horizontally {
  display: flex;
  justify-content: center;
}
.trend-modal.revise-post-modal .modal-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #46c5ff;
  width: 60px;
  height: 60px;
  border-radius: 100%;
  margin-bottom: 11px;
  font-size: 35px;
}
.trend-modal.revise-post-modal .ant-modal-body {
  padding-top: 38px;
  padding-bottom: 39px;
}
.trend-modal.revise-post-modal h2 {
  font-family: SFProDisplay;
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  line-height: 26px;
  color: #000000;
  margin-bottom: 0px;
}
.trend-modal.revise-post-modal p {
  color: #000;
  font-family: SFProDisplay;
  font-weight: 400;
  font-style: normal;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 23px;
}
.trend-modal.revise-post-modal .ant-btn.submit {
  font-family: SFProDisplay;
  font-weight: 800;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  color: #000;
  height: 45px;
  width: 155px;
}
.trend-modal.revise-post-modal textarea {
  font-family: SFProDisplay;
  font-weight: 400;
  font-style: normal;
  font-size: 14px !important;
  line-height: 20px;
  background: #f1f5fd;
  border-radius: 8px;
  border: none !important;
  width: 100%;
  min-height: 159px;
}
.trend-modal.revise-post-modal textarea::-webkit-input-placeholder {
  color: #5b6572;
}
.trend-modal.revise-post-modal textarea::placeholder {
  color: #5b6572;
}
.trend-modal.reject-post-modal .close-modal {
  border: 1px solid #000000;
  box-sizing: border-box;
  border-radius: 4px;
  width: 45px;
  height: 45px;
  position: absolute;
  top: 20px;
  left: 20px;
  text-align: center;
  padding: 0px;
}
.trend-modal.reject-post-modal .close-modal .anticon {
  line-height: 45px;
  margin-top: 1px;
  font-size: 20px;
}
.trend-modal.reject-post-modal .close-modal .anticon svg path {
  transition: stroke 0.5s;
}
.trend-modal.reject-post-modal .close-modal:hover {
  border-color: #ffcc40;
}
.trend-modal.reject-post-modal .close-modal:hover .anticon svg path {
  transition: stroke 0.5s;
  stroke: #ffcc40;
}
.trend-modal.reject-post-modal .center-horizontally {
  display: flex;
  justify-content: center;
}
.trend-modal.reject-post-modal .modal-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #46c5ff;
  width: 60px;
  height: 60px;
  border-radius: 100%;
  margin-bottom: 11px;
  font-size: 35px;
}
.trend-modal.reject-post-modal .reject-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #e58989;
  width: 60px;
  height: 60px;
  border-radius: 100%;
  margin-bottom: 11px;
}
.trend-modal.reject-post-modal .reject-icon .dash {
  height: 7px;
  background: #FFFFFF;
  width: 32px;
  border-radius: 50px;
}
.trend-modal.reject-post-modal .ant-modal-body {
  padding-top: 38px;
  padding-bottom: 39px;
}
.trend-modal.reject-post-modal h2 {
  font-family: SFProDisplay;
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  line-height: 26px;
  color: #000000;
  margin-bottom: 0px;
}
.trend-modal.reject-post-modal p {
  color: #000;
  font-family: SFProDisplay;
  font-weight: 400;
  font-style: normal;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 23px;
}
.trend-modal.reject-post-modal .ant-btn.submit {
  font-family: SFProDisplay;
  font-weight: 800;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  color: #000;
  height: 45px;
  width: 155px;
}
.trend-modal.reject-post-modal textarea {
  font-family: SFProDisplay;
  font-weight: 400;
  font-style: normal;
  font-size: 14px !important;
  line-height: 20px;
  background: #f1f5fd;
  border-radius: 8px;
  border: none !important;
  width: 100%;
  min-height: 159px;
}
.trend-modal.reject-post-modal textarea::-webkit-input-placeholder {
  color: #5b6572;
}
.trend-modal.reject-post-modal textarea::placeholder {
  color: #5b6572;
}
#mediaFilters .ant-select .ant-select-selection__placeholder {
  font-family: SFProDisplay;
  font-weight: bold;
  color: #5b6572;
  font-size: 10px;
}
.post-approved-card {
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
}
.post-approved-card:hover {
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.3);
}
.post-approved-card.ant-card {
  border: none;
  border-radius: 0 0 5px 5px;
}
.post-approved-card .additional-contents {
  margin-bottom: 14.91px;
}
.post-approved-card .additional-contents .slick-arrow {
  height: 100%;
  margin-top: 0;
  top: 0;
  z-index: 5;
}
.post-approved-card .additional-contents .slick-disabled {
  visibility: hidden;
}
.post-approved-card .additional-contents .slick-prev:hover,
.post-approved-card .additional-contents .slick-prev {
  background: linear-gradient(270deg, #ffffff 22.5%, rgba(255, 255, 255, 0) 100%);
  left: -8px;
  -webkit-transform: matrix(-1, 0, 0, 1, 0, 0);
          transform: matrix(-1, 0, 0, 1, 0, 0);
}
.post-approved-card .additional-contents .slick-prev:hover::before,
.post-approved-card .additional-contents .slick-prev::before {
  content: url(/static/media/ArrowRight.25aff6b1.svg);
}
.post-approved-card .additional-contents .slick-next:hover,
.post-approved-card .additional-contents .slick-next {
  background: linear-gradient(270deg, #ffffff 22.5%, rgba(255, 255, 255, 0) 100%);
  right: -8px;
}
.post-approved-card .additional-contents .slick-next:hover::before,
.post-approved-card .additional-contents .slick-next::before {
  content: url(/static/media/ArrowRight.25aff6b1.svg);
}
.post-approved-card .additional-contents .slick-list {
  margin: 0 -3px;
}
.post-approved-card .additional-contents .slick-slide > div {
  padding: 0 3px;
}
.post-approved-card .additional-contents .video-image {
  position: relative;
}
.post-approved-card .additional-contents .video-image svg {
  position: absolute;
  top: 17px;
  left: 17px;
}
.post-approved-card .additional-contents .content {
  align-items: center;
  background-color: #fff;
  width: 62px !important;
  height: 62px !important;
  border: 2px solid #fff;
  border-radius: 3px;
  display: flex !important;
  justify-content: center;
}
.post-approved-card .additional-contents .content.active {
  border-color: black;
}
.post-approved-card .additional-contents .content:hover {
  border-color: black;
}
.post-approved-card .additional-contents .content .video-player-action-container {
  height: 55.76px;
  width: 55.76px;
}
.post-approved-card .ant-card-cover {
  min-height: 220px;
  max-height: 220px;
  position: relative;
  overflow: hidden;
}
.post-approved-card .ant-card-cover .media-actions {
  height: 100%;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
}
.post-approved-card .ant-card-cover .media-actions .influencer-section {
  align-items: center;
  display: flex;
  flex-direction: column;
  left: 0;
  position: absolute;
  right: 0;
  top: 10px;
  width: 100%;
}
.post-approved-card .ant-card-cover .media-actions .influencer-section .username {
  font-family: SFProDisplay;
  font-weight: bold;
  font-size: 11px;
  line-height: 15px;
  color: #ffffff;
  margin-top: 6px;
}
.post-approved-card .ant-card-cover .media-actions .action-button {
  bottom: 11px;
  position: absolute;
  text-align: center;
  width: 100%;
  padding: 0 10px;
}
.post-approved-card .ant-card-cover .media-actions .action-button button {
  align-items: center;
  background: rgba(255, 255, 255, 0.3);
  border: 0 !important;
  border-radius: 2px;
  display: flex;
  height: 44px;
  justify-content: center;
  padding: 0;
  width: 100%;
}
.post-approved-card .ant-card-cover .media-actions .action-button button:hover {
  background: rgba(255, 255, 255, 0.5);
}
.post-approved-card .ant-card-cover .media-actions .action-button button .label {
  font-family: SFProDisplay;
  font-weight: bold;
  color: white;
  font-size: 10px;
  line-height: 12px;
  margin-left: 8px;
  text-align: left;
}
.post-approved-card .ant-card-cover .media-actions .action-button .rehire-button img {
  margin-right: 6px;
}
.post-approved-card .ant-card-cover .media-actions .action-button .rehire-button span {
  font-family: SFProDisplay;
  font-weight: bold;
  color: white;
  font-size: 10px;
  line-height: 12px;
  text-align: left;
}
.post-approved-card .ant-card-cover .media-actions .action-button .rehire-button.awaiting .image-wrapper {
  background: #fff;
  border-radius: 100%;
  width: 28px;
  height: 28px;
}
.post-approved-card .ant-card-cover .media-actions .action-button .rehire-button.awaiting .image-wrapper img {
  width: 19px;
  height: auto;
  margin-top: 5px;
  margin-left: 4px;
}
.post-approved-card .ant-card-cover .media-actions .action-button .shape {
  align-items: center;
  border-radius: 35px;
  display: flex;
  height: 28px;
  justify-content: center;
  width: 28px;
}
.post-approved-card .ant-card-cover .media-actions .action-button .shape.send {
  background: #00b267;
}
.post-approved-card .ant-card-cover .media-actions .action-button .shape.download {
  background: #0056fe;
}
.post-approved-card .ant-card-cover .media-actions {
  opacity: 0;
  transition: all 250ms linear;
}
.post-approved-card .ant-card-cover .media-actions .media-actions-overlay {
  background: rgba(0, 0, 0, 0.65);
  height: 100%;
  width: 100%;
  cursor: pointer;
}
.post-approved-card .ant-card-cover:hover .media-actions {
  opacity: 1;
}
.post-approved-card .ant-card-cover .post-type-label {
  font-family: SFProDisplay;
  font-weight: normal;
  font-style: normal;
  display: inline-block;
  width: 59px;
  height: 15px;
  padding-top: 2px;
  text-transform: uppercase;
  font-size: 7px;
  font-weight: bold;
  font-style: italic;
  font-stretch: normal;
  line-height: 1.38;
  letter-spacing: 0.4px;
  text-align: center;
  color: #000000;
  position: absolute;
  z-index: 1;
  left: calc(50% - 59px / 2);
}
.post-approved-card .ant-card-cover .post-type-label.type-image {
  background: #ffd982;
}
.post-approved-card .ant-card-cover .post-type-label.type-video {
  background: #4df1ff;
}
.post-approved-card .ant-card-cover .post-type-label.type-story {
  background: #66bdff;
}
.post-approved-card .ant-card-cover .ambassador-tag {
  position: absolute;
  z-index: 1;
  top: 13px;
  right: 10px;
  width: 62px;
}
.post-approved-card .ant-card-body {
  padding: 12px 12px 15px;
}
.post-approved-card .ant-card-meta {
  margin-top: 0;
  display: flex;
  align-items: center;
  justify-items: flex-start;
  margin-bottom: 12px;
}
.post-approved-card .ant-card-meta-title {
  font-family: SFProDisplay;
  font-weight: normal;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 13px;
  vertical-align: middle;
}
.post-approved-card .video-loader {
  height: 100%;
}
.post-approved-card .video-loader img,
.post-approved-card .video-loader video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.post-approved-card .video-loader .video-player-actions {
  cursor: pointer;
  left: calc(50% - 30px);
  top: calc(50% - 30px);
  position: absolute;
  width: 60px;
  height: 60px;
}
.post-approved-card .video-loader .video-player-actions .anticon {
  font-size: 60px;
}
.post-approved-card .stats {
  height: 34px;
}
.post-approved-card .stats .label {
  font-family: SFProDisplay;
  font-weight: normal;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 10px;
  height: 15px;
  color: #000;
}
.post-approved-card .stats .value {
  font-family: SFProDisplay;
  font-weight: normal;
  font-style: normal;
  font-style: italic;
  font-weight: 800;
  font-size: 17px;
  line-height: 12px;
  color: #000;
  margin-bottom: 2px;
  height: 17px;
}
.post-approved-card .stats .story-post-label {
  margin-top: calc(16px - 9px);
  font-family: SFProDisplay;
  font-weight: normal;
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  line-height: 15px;
  color: #000000;
}
.post-filter button {
  height: 100%;
  color: #5b6572;
  padding-bottom: 14px;
}
.post-filter button:focus,
.post-filter button:active,
.post-filter button:hover {
  color: #000;
}
.post-filter.post-filter:not(.post-filter-selected) button:focus,
.post-filter.post-filter:not(.post-filter-selected) button:active,
.post-filter.post-filter:not(.post-filter-selected) button:hover {
  color: #000;
}
.post-filter.post-filter:not(.post-filter-selected) button:focus .post-filter-icon,
.post-filter.post-filter:not(.post-filter-selected) button:active .post-filter-icon,
.post-filter.post-filter:not(.post-filter-selected) button:hover .post-filter-icon {
  background: #000;
  color: #fff;
  transition: all 0.5s;
}
.post-filter.post-filter:not(.post-filter-selected) button:focus .post-filter-icon svg g,
.post-filter.post-filter:not(.post-filter-selected) button:active .post-filter-icon svg g,
.post-filter.post-filter:not(.post-filter-selected) button:hover .post-filter-icon svg g,
.post-filter.post-filter:not(.post-filter-selected) button:focus .post-filter-icon svg path,
.post-filter.post-filter:not(.post-filter-selected) button:active .post-filter-icon svg path,
.post-filter.post-filter:not(.post-filter-selected) button:hover .post-filter-icon svg path {
  fill: #fff;
  stroke: #fff;
}
.post-filter.post-filter:not(.post-filter-selected) button:focus .post-filter-label,
.post-filter.post-filter:not(.post-filter-selected) button:active .post-filter-label,
.post-filter.post-filter:not(.post-filter-selected) button:hover .post-filter-label {
  color: #000;
}
.post-filter-selected {
  border-bottom: 3px solid #ffb917;
}
.post-filter-selected button {
  color: #000;
}
.post-filter-selected button:focus,
.post-filter-selected button:active,
.post-filter-selected button:hover {
  color: #000;
}
.post-filter-selected .post-filter-icon {
  background: #000;
}
.post-filter-selected.post-filter-all .post-filter-icon,
.post-filter-selected.post-filter-images .post-filter-icon,
.post-filter-selected.post-filter-videos .post-filter-icon,
.post-filter-selected.post-filter-stories .post-filter-icon {
  color: #fff;
  transition: all 0.5s;
}
.post-filter-selected.post-filter-all .post-filter-icon svg g,
.post-filter-selected.post-filter-images .post-filter-icon svg g,
.post-filter-selected.post-filter-videos .post-filter-icon svg g,
.post-filter-selected.post-filter-stories .post-filter-icon svg g,
.post-filter-selected.post-filter-all .post-filter-icon svg path,
.post-filter-selected.post-filter-images .post-filter-icon svg path,
.post-filter-selected.post-filter-videos .post-filter-icon svg path,
.post-filter-selected.post-filter-stories .post-filter-icon svg path {
  fill: #fff;
  stroke: #fff;
}
.post-filter-selected .post-filter-label {
  color: #000;
}
.post-filter-icon {
  height: 51px;
  width: 51px;
  display: inline-block;
  border: 1px solid #a9b0b9;
  border-radius: 100%;
  margin-right: 15px;
}
.post-filter-count {
  font-family: SFProDisplay;
  font-weight: normal;
  font-style: normal;
  text-align: left;
  font-weight: 800;
  font-size: 16px;
  line-height: 16px;
}
.post-filter-label {
  font-family: SFProDisplay;
  font-weight: normal;
  font-style: normal;
  color: #5b6572;
  font-weight: 600;
  font-size: 9px;
  line-height: 11px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
}
.post-filter-all .post-filter-icon {
  font-family: 'TruenoBold';
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000;
  object-fit: contain;
  padding: 16px 13px;
  border-radius: 100%;
}
.post-filter-images .post-filter-icon {
  font-size: 18px;
  padding-top: 16px;
}
.post-filter-images .post-filter-icon .anticon {
  vertical-align: top;
}
.post-filter-videos .post-filter-icon {
  font-size: 23px;
  padding-top: 16px;
}
.post-filter-videos .anticon {
  vertical-align: top;
}
.post-filter-videos .anticon svg {
  height: 18px;
}
.post-filter-stories .post-filter-icon {
  font-size: 24px;
  padding-top: 16px;
}
.post-filter-stories .anticon {
  vertical-align: top;
}
.post-filter-stories .anticon svg {
  height: 18px;
}
.pending-review-modal .ant-modal-content {
  box-shadow: 0 2px 19px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
  border-radius: 0;
}
.pending-review-modal .ant-modal-content .close {
  position: absolute;
  right: 25px;
  top: 25px;
}
.pending-review-modal .ant-modal-content .close .ant-btn {
  width: 45px;
  height: 45px;
  border: 1px solid #000;
}
.pending-review-modal .ant-modal-content .ant-modal-body {
  min-height: 686px;
  margin: 0px;
  padding: 0px;
  padding-bottom: 32px;
}
.pending-review-modal .ant-modal-content .review-photo {
  text-align: center;
  position: relative;
  width: 200px;
  height: 200px;
  margin-top: 50px;
}
.pending-review-modal .ant-modal-content .review-photo img {
  display: inline-block;
  width: 200px;
  height: 200px;
  box-shadow: 0 2px 19px 0 rgba(0, 0, 0, 0.44);
  background-color: #d8d8d8;
  object-fit: cover;
}
.pending-review-modal .ant-modal-content .review-photo .instagram-logo {
  position: absolute;
  bottom: 173px;
  left: 170px;
  background-image: url(/static/media/instagram-logo@3x.36e0db89.png);
  background-size: cover;
  height: 43px;
  width: 43px;
}
.pending-review-modal .ant-modal-content .review-photo .emoji-heart {
  position: absolute;
  top: 106px;
  right: 185px;
  background-size: cover;
  background-image: url(/static/media/heart-eyes-emoji@3x.d347dbd6.png);
  height: 44px;
  width: 44px;
}
.pending-review-modal .ant-modal-content .review-photo .link {
  position: absolute;
  top: 177px;
  left: 45px;
  width: 110px;
  height: 45px;
}
.pending-review-modal .ant-modal-content .review-photo .link span {
  font-family: SFProDisplay;
  font-weight: bold;
  margin-top: 15px;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #000000;
}
.pending-review-modal .ant-modal-content .review-message {
  font-family: SFProDisplay;
  font-weight: bold;
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  line-height: 26px;
  margin-top: 45px;
  text-align: center;
  color: #000000;
  margin-bottom: 19px;
}
.pending-review-modal .ant-modal-content .review-message p {
  display: inline-block;
  width: 279px;
  margin-bottom: 0px;
}
.pending-review-modal .ant-modal-content .review-form {
  margin-bottom: 24.8px;
}
.pending-review-modal .ant-modal-content .review-form .review-input .ant-form-item-control {
  display: flex;
  justify-content: center;
}
.pending-review-modal .ant-modal-content .review-form .review-input .ant-form-item-control .ant-form-explain {
  text-align: center;
  position: absolute;
  top: -10px;
  font-size: 10px;
}
.pending-review-modal .ant-modal-content .review-form .review-input.ant-form-item {
  margin-bottom: 17.8px;
}
.pending-review-modal .ant-modal-content .review-form .review-input:last-child {
  margin-bottom: 0;
}
.pending-review-modal .ant-modal-content .review-form .review-input-label {
  display: block;
  font-family: SFProDisplay;
  font-weight: bold;
  font-style: normal;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  color: #000000;
  margin-bottom: 9px;
}
.pending-review-modal .ant-modal-content .review-form .review-input-control {
  text-align: center;
}
.pending-review-modal .ant-modal-content .review-form .review-input-control .ant-rate .ant-rate-star .anticon {
  width: 38.2px;
  height: 38.2px;
  font-size: 38.2px;
}
.pending-review-modal .ant-modal-content .review-form .review-input-control .ant-rate .ant-rate-star.ant-rate-star-full .anticon svg path {
  fill: #ffb917;
  stroke: #ffb917;
}
.pending-review-modal .ant-modal-content .review-additional-comments {
  margin-bottom: 31px;
  display: flex;
  justify-content: center;
}
.pending-review-modal .ant-modal-content .review-additional-comments .ant-form-item {
  margin-bottom: 0;
}
.pending-review-modal .ant-modal-content .review-additional-comments .ant-form-item-control {
  display: flex;
  justify-content: center;
}
.pending-review-modal .ant-modal-content .review-additional-comments .ant-form-item-control .ant-form-explain {
  text-align: center;
  position: absolute;
  top: -17px;
  font-size: 10px;
}
.pending-review-modal .ant-modal-content .review-additional-comments textarea {
  font-family: SFProDisplay;
  font-weight: normal;
  font-style: normal;
  font-size: 14px;
  line-height: 20px;
  padding: 13px 18px;
  resize: none;
  width: 448px;
  height: 99px;
  object-fit: contain;
  border-radius: 8px;
  border: none;
  background-color: #f1f5fd;
}
.pending-review-modal .ant-modal-content .review-additional-comments textarea::-webkit-input-placeholder {
  color: #5b6572;
}
.pending-review-modal .ant-modal-content .review-additional-comments textarea::placeholder {
  color: #5b6572;
}
.pending-review-modal .ant-modal-content .review-footer {
  text-align: center;
}
.pending-review-modal .ant-modal-content .review-footer .skip-button,
.pending-review-modal .ant-modal-content .review-footer .submit-button {
  font-family: SFProDisplay;
  width: 177px;
  height: 55px;
  color: #000;
  border: none;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  text-shadow: none;
}
.pending-review-modal .ant-modal-content .review-footer .skip-button {
  background: #f1f5fd;
  margin-right: 14px;
}
.videos-preview {
  background: rgba(0, 0, 0, 0.75);
}
.videos-preview .ant-modal-content {
  background: transparent;
  box-shadow: none;
}
.videos-preview .ant-modal-body {
  padding: 0 !important;
}
.videos-preview .video-loader {
  display: flex;
  justify-content: center;
}
.videos-preview .video-loader video {
  border-radius: 4px;
  height: 85dvh;
}
.image-preview {
  background: rgba(0, 0, 0, 0.75);
}
.image-preview .ant-modal {
  width: 100% !important;
}
.image-preview .ant-modal-content {
  background: transparent;
  box-shadow: none;
}
.image-preview .ant-modal-body {
  padding: 0 !important;
}
.image-preview .ant-modal-body img,
.image-preview .ant-modal-body video {
  display: block;
  height: 85dvh;
  margin: 0 auto;
}
.media-viewer-modal .ant-modal-content {
  width: '100vh';
  background-color: transparent;
}
.media-viewer-modal .ant-modal-body {
  padding: 0;
  width: '100vh';
}
.media-viewer-modal .footer {
  text-align: right;
}
.media-viewer-modal .footer .close.ant-btn {
  margin-top: 15px;
  height: 20px;
  padding: 0;
  font-family: SFProDisplay;
  color: #fff;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
}
.rehire-modal {
  font-family: SFProDisplay;
  font-weight: normal;
  font-style: normal;
}
@media (min-width: 700px) {
  .rehire-modal .ant-modal-body {
    padding: 0px;
  }
}
.rehire-modal .ant-modal-content {
  border-radius: 10px;
}
.rehire-modal .close {
  background: #ffffff;
  border: 1px solid #000000;
  box-sizing: border-box;
  border-radius: 4px;
  width: 45px;
  height: 45px;
  position: absolute;
  right: 25px;
  top: 25px;
  padding: 0px;
}
.rehire-modal .close i {
  font-size: 15px;
}
.rehire-modal .close img {
  width: 14px;
  height: 15px;
}
.rehire-modal .user-avatar {
  text-align: center;
  position: relative;
  margin-top: 23px;
  margin-bottom: 13px;
}
.rehire-modal .user-avatar .ambassador-label {
  position: absolute;
  top: 73px;
  left: -11px;
  width: 112px;
  height: 26px;
  background: #ffffff;
  border: 1px solid #000000;
  box-sizing: border-box;
}
.rehire-modal .user-avatar .ambassador-label img {
  width: 93.35px;
  height: 7.13px;
}
.rehire-modal .credit-cost-rehire {
  margin-top: 31px;
  margin-bottom: 47px;
  border: 1px solid #d8d8d8;
  border-radius: 9px;
  width: 448px;
  height: 104px;
}
.rehire-modal h1 {
  font-family: SFProDisplay;
  font-weight: bold;
  font-style: normal;
  font-weight: 800;
  font-size: 22px;
  line-height: 26px;
  margin-bottom: 40px;
  text-align: center;
}
.rehire-modal .ant-form .ant-form-item-label {
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 20px;
  padding-bottom: 9px;
  color: #000;
}
.rehire-modal .ant-form .ant-form-item-label label {
  font-family: SFProDisplay;
  font-weight: bold;
}
.rehire-modal .ant-form .ant-form-item-label p {
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
}
.rehire-modal .ant-form .ant-input {
  font-weight: bold;
  font-style: normal;
  line-height: 16px;
  background: #f1f5fd;
  border-radius: 8px;
  border: none;
  padding: 18px 16px;
  color: #000;
}
.rehire-modal .ant-form .ant-input::-webkit-input-placeholder {
  color: #5b6572;
}
.rehire-modal .ant-form .ant-input::placeholder {
  color: #5b6572;
}
.rehire-modal .ant-form input.ant-input {
  height: 55px;
}
.rehire-modal .ant-form textarea.ant-input {
  height: 142px;
}
.rehire-modal .ant-form .ant-form-item {
  margin-bottom: 40px;
  padding-bottom: 0px;
}
.rehire-modal .ant-form .shipping .ant-form-item-control {
  height: 55px;
}
.rehire-modal .ant-form .ant-radio-group.ant-radio-group-outline {
  width: 100%;
}
.rehire-modal .ant-form .ant-radio-group.ant-radio-group-outline .ant-radio-button-wrapper {
  height: 51px;
  line-height: 51px;
  text-align: center;
  display: inline-block;
  width: 50%;
  border: none;
  background: #f1f5fd;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  box-shadow: none;
}
.rehire-modal .ant-form .ant-radio-group.ant-radio-group-outline .ant-radio-button-wrapper:hover {
  background-color: #dce5f7;
  color: #000;
}
.rehire-modal .ant-form .ant-radio-group.ant-radio-group-outline .ant-radio-button-wrapper-checked {
  background: #ffb917;
  color: #000;
}
.rehire-modal .ant-form .ant-radio-group.ant-radio-group-outline .ant-radio-button-wrapper-checked:hover {
  background: #df9e09;
}
.rehire-modal .ant-form .ant-radio-group.ant-radio-group-outline .ant-radio-button-wrapper:before {
  background: transparent;
}
.rehire-modal .ant-form .ant-select-selection {
  padding-top: 19px;
  padding-left: 16px;
  padding-right: 16px;
  background: #f1f5fd;
  border-radius: 8px;
  border: none;
  height: 75px;
}
.rehire-modal .ant-form .ant-select-selection .ant-select-search__field__wrap {
  vertical-align: top;
  line-height: 16px;
}
.rehire-modal .ant-form .ant-select-selection .ant-select-search__field__mirror {
  vertical-align: top;
  line-height: 16px;
}
.rehire-modal .ant-form .ant-select-selection .ant-select-search__field {
  font-size: 14px;
  line-height: 16px;
  padding: 0;
  margin-top: -10px;
}
.rehire-modal .ant-form .ant-select-selection .ant-select-selection__rendered {
  margin: 0px;
}
.rehire-modal .ant-form .ant-select-selection .ant-select-selection__placeholder {
  font-weight: bold;
  margin-top: -16px;
  color: #5b6572;
  height: auto;
}
.rehire-modal .ant-form .ant-select-selection .ant-select-selection-item {
  font-size: 14px;
}
.rehire-modal .ant-form .ant-select-selection .ant-select-selection-item .rehire-selector-name {
  font-style: normal;
  font-weight: bold;
  line-height: 16px;
}
.rehire-modal .ant-form .ant-select-selection .ant-select-selection-item .rehire-selector-details {
  line-height: 20px;
}
.rehire-modal .ant-form .ant-select-selection .ant-select-arrow {
  right: 16px;
}
.rehire-modal .ant-form .ant-select-selection .ant-select-arrow i svg {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 6px solid #000;
  display: inline-block;
}
.rehire-modal .ant-form .ant-select-selection .ant-select-arrow i svg path {
  display: none;
}
.rehire-modal .footer {
  text-align: center;
}
.rehire-modal .footer .submit-btn {
  width: 183px;
  height: 55px;
  background: #ffb917;
  border-radius: 5px;
  color: #000;
  border: none;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
}
.rehire-modal .footer .submit-btn:hover {
  background: #df9e09;
}
.rehire-modal .footer p {
  font-family: SFProDisplay;
  width: 318px;
  margin-top: 21px;
  margin-bottom: 41px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: #000;
}
.rehire-modal.awaiting .ant-modal-body {
  padding-top: 40px;
}
.rehire-modal.awaiting img.trend-ambassador {
  width: 175px;
  height: 23.89px;
  margin-bottom: 29.31px;
}
.rehire-modal.awaiting img.banner {
  height: 150px;
  width: 162px;
  margin-bottom: 31px;
}
.rehire-modal.awaiting h1 {
  font-style: normal;
  font-weight: 800;
  font-size: 22px;
  line-height: 26px;
  margin-bottom: 8px;
}
.rehire-modal.awaiting p {
  width: 603px;
  text-align: center;
  margin-bottom: 29px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #000;
}
.rehire-modal.awaiting p a {
  color: #000;
  text-decoration: underline;
}
.rehire-modal.awaiting .footer .ant-btn {
  margin-bottom: 47px;
}
.rehire-modal.accepted {
  color: #000;
}
.rehire-modal.accepted .ant-modal-content {
  min-height: 640px;
}
.rehire-modal.accepted .ant-modal-body {
  padding-top: 40px;
}
.rehire-modal.accepted img.trend-ambassador {
  width: 175px;
  height: 23.89px;
  margin-bottom: 29.31px;
}
.rehire-modal.accepted h1 {
  font-style: normal;
  font-weight: 800;
  font-size: 36px;
  line-height: 42px;
  margin-bottom: 5px;
}
.rehire-modal.accepted .ant-avatar {
  margin-bottom: 15px;
}
.rehire-modal.accepted .username {
  text-align: center;
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  line-height: 23px;
  margin-bottom: 4px;
}
.rehire-modal.accepted p {
  font-family: SFProDisplay;
  width: 453px;
  text-align: center;
  margin-bottom: 47px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
}
.rehire-modal.accepted .footer .ant-btn {
  margin-bottom: 48px;
  width: 288px;
}
.rehire-modal.accepted .rehire-list-item {
  padding: 18px 0px 18px 34px;
  border-bottom: 1px solid #e8e8e8;
}
.rehire-modal.accepted .rehire-list-item:first-child {
  border-top: 1px solid #e8e8e8;
}
.rehire-modal.accepted .rehire-list-item .ant-avatar {
  vertical-align: middle;
  margin-bottom: 0;
  margin-right: 15px;
}
.rehire-modal.accepted .rehire-list-item .rehire-details {
  display: inline-block;
  margin-top: 1;
  vertical-align: middle;
}
.rehire-modal.accepted .rehire-list-item .rehire-details-username {
  font-family: SFProDisplay;
  font-weight: normal;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 23px;
}
.rehire-modal.accepted .rehire-list-item .rehire-details-campaign {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 23px;
}
.rehire-modal.accepted .rehire-list-item .rehire-list-item-actions {
  text-align: right;
  padding-right: 41px;
}
.rehire-modal.accepted .rehire-list-item .rehire-list-item-actions .ant-btn {
  font-family: SFProDisplay;
  font-weight: normal;
  height: 41px;
  width: 110px;
  font-style: normal;
  font-weight: bold;
  font-size: 11px;
  line-height: 41px;
  background-color: #f1f5fd;
  border: none;
}
.rehire-modal.accepted .rehire-list-item .rehire-list-item-actions .ant-btn:hover,
.rehire-modal.accepted .rehire-list-item .rehire-list-item-actions .ant-btn:active,
.rehire-modal.accepted .rehire-list-item .rehire-list-item-actions .ant-btn:focus {
  color: #000;
  background-color: #dce5f7;
}
.rehire-modal.accepted .rehire-list-item .rehire-list-item-actions .ant-btn.ant-btn-primary {
  background-color: #ffb917;
  margin-left: 11px;
  text-shadow: none;
  color: #000;
}
.rehire-modal.accepted .rehire-list-item .rehire-list-item-actions .ant-btn.ant-btn-primary:hover {
  background-color: #df9e09;
}
.rehire-selector-name {
  color: #000;
  font-style: normal !important;
  font-weight: bold !important;
  line-height: 16px !important ;
}
.rehire-selector-details {
  font-weight: normal !important;
}
.rehire-label {
  display: block;
  text-align: center;
  margin-top: 8px;
  margin-left: 18px;
  color: #5b6572 !important;
  font-style: normal;
  font-weight: 600;
  font-size: 9px;
  line-height: 10px;
  justify-content: center;
}
.rehire-button.ant-btn {
  font-family: SFProDisplay;
  background: #f1f5fd;
  color: #000;
  border-radius: 3px;
  height: 44px;
  border: none;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
}
.rehire-button.ant-btn:hover,
.rehire-button.ant-btn:focus-within {
  background-color: #dce5f7;
  color: #000;
}
.rehire-button.ant-btn:active {
  color: #000;
  background: #f1f5fd;
}
.rehire-button.ant-btn:not(.awaiting) .image-wrapper {
  background-color: #00b267;
  border-radius: 100%;
  height: 28px;
  width: 28px;
  padding-top: 7px;
  padding-left: 6px;
  text-align: left;
}
.rehire-button.ant-btn.awaiting {
  background-color: #fff;
  border: 1px solid #dbdfe8;
  color: #000;
  cursor: default;
}
.rehire-button.ant-btn.awaiting:hover:focus {
  background-color: #fff;
  color: #000;
}
.rehire-button.ant-btn .image-wrapper {
  margin-right: 9px;
}
.rehire-button.ant-btn .image-wrapper i {
  font-size: 16px;
}
.rehire-button.ant-btn .image-wrapper img {
  vertical-align: middle;
  width: 28px;
  height: 28px;
}
.rehire-button.ant-btn span {
  vertical-align: middle;
}
.rehire-button.ant-btn.waiting {
  border: 1px solid #dbdfe8;
  background: #fff;
}
.influencer-avatar {
  position: relative;
  display: inline-block;
}
.influencer-avatar .ambassador-tag {
  text-align: center;
  position: absolute;
  left: -6px;
  top: 46px;
  background: #ffffff;
  border: 1px solid #000000;
  box-sizing: border-box;
  width: 62px;
  height: 14.67px;
}
.influencer-avatar .ambassador-tag svg {
  position: absolute;
  top: 4px;
  left: 5px;
  width: 51.67px;
}
.ambassador-tag {
  text-align: center;
  background: #ffffff;
  border: 1px solid #000000;
  box-sizing: border-box;
  width: 62px;
  height: 14.67px;
}
.ambassador-tag svg {
  position: absolute;
  top: 4px;
  left: 5px;
  width: 51.67px;
}
.ant-select-dropdown.trend-select {
  background: transparent;
  border: none;
  box-shadow: none;
}
.ant-select-dropdown.trend-select > div {
  margin-top: 20px;
  background: #f1f5fd;
  border-radius: 8px;
}
.ant-select-dropdown.trend-select > div .ant-select-dropdown-menu {
  padding: 12.5px 0;
  margin: 0px;
}
.ant-select-dropdown.trend-select > div .ant-select-dropdown-menu-item {
  font-family: SFProDisplay;
  font-weight: normal;
  font-style: normal;
  padding: 12.5px 25px;
}
.ant-select-dropdown.trend-select > div .ant-select-dropdown-menu-item .rehire-selector-name {
  font-size: 14px;
  line-height: 16px;
}
.ant-select-dropdown.trend-select > div .ant-select-dropdown-menu-item .rehire-selector-details {
  font-size: 14px;
}
.ant-select-dropdown.trend-select > div .ant-select-dropdown-menu-item-selected,
.ant-select-dropdown.trend-select > div .ant-select-dropdown-menu-item-active {
  background-color: transparent !important;
  color: #0276ff !important;
}
.ant-select-dropdown.trend-select > div .ant-select-dropdown-menu-item-selected .rehire-selector-name,
.ant-select-dropdown.trend-select > div .ant-select-dropdown-menu-item-active .rehire-selector-name {
  color: #0276ff !important;
}
.ant-select-dropdown.trend-select::after {
  display: none;
}
.campaign-image {
  position: relative;
  min-height: 260px;
  overflow: hidden;
  background: #D6D6D6;
  border-radius: 4px;
}
.campaign-image img {
  border-radius: 4px;
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.campaign-image .trend-btn {
  position: absolute;
  bottom: 15px;
  right: 0;
  box-shadow: -2px 1px 4px 0 rgba(0, 0, 0, 0.28);
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.item-campaign {
  height: 359px;
}
.post-requirements .required-posts .post-icons {
  font-size: 20px;
  margin-right: 10px;
  vertical-align: middle;
}
.post-requirements .label-value {
  vertical-align: middle;
}
.post-requirements-states-state {
  margin-top: 13px;
}
.post-requirements-states .circle {
  width: 22px;
  height: 22px;
  border-radius: 100%;
  font-size: 8px;
  display: inline-block;
  border: 1px solid #000000;
  padding-top: 4px;
  text-align: center;
  margin-right: 10px;
}
.campaign-content .nav-header {
  padding-top: 35px;
}
.campaign-content .nav-header .campaign-header {
  max-width: 742px;
  margin: auto;
}
.campaign-content .nav-header h1 {
  font-size: 26px;
}
#application-page .trend-table,
#influencers-page .trend-table {
  max-width: 100%;
}
#application-page .trend-table .ant-table-thead th,
#influencers-page .trend-table .ant-table-thead th {
  text-align: center !important;
}
#application-page .trend-table .ant-table-thead th.influencer-details,
#influencers-page .trend-table .ant-table-thead th.influencer-details {
  text-align: left !important;
}
#application-page .trend-table .ant-table-thead th.age-range,
#influencers-page .trend-table .ant-table-thead th.age-range {
  width: 70px;
}
#application-page .trend-table .ant-table-thead th.followers,
#influencers-page .trend-table .ant-table-thead th.followers {
  width: 90px;
}
#application-page .trend-table .ant-table-thead th.like-count,
#influencers-page .trend-table .ant-table-thead th.like-count {
  width: 90px;
}
#application-page .trend-table .ant-table-thead th.date-applied,
#influencers-page .trend-table .ant-table-thead th.date-applied {
  width: 120px;
}
#application-page .trend-table .ant-table-thead th.comment-count,
#influencers-page .trend-table .ant-table-thead th.comment-count {
  width: 120px;
}
#application-page .trend-table .ant-table-thead th.actions,
#influencers-page .trend-table .ant-table-thead th.actions {
  width: 150px;
}
#application-page .trend-table .ant-table-thead th.engagement-rate,
#influencers-page .trend-table .ant-table-thead th.engagement-rate {
  width: 150px;
}
#application-page .trend-table .ant-table-thead th:first-child,
#influencers-page .trend-table .ant-table-thead th:first-child {
  text-align: left !important;
}
#application-page .trend-table .ant-table-thead .ant-table-column-has-sorters:not(.ant-table-column-sort) .ant-table-column-sorter .ant-table-column-sorter-inner:after,
#influencers-page .trend-table .ant-table-thead .ant-table-column-has-sorters:not(.ant-table-column-sort) .ant-table-column-sorter .ant-table-column-sorter-inner:after {
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 0 6px 6px;
  border-color: transparent transparent #5b6572 transparent;
}
#application-page .trend-table .ant-table-thead .ant-table-column-has-sorters:not(.ant-table-column-sort) .ant-table-column-sorter .ant-table-column-sorter-inner .ant-table-column-sorter-up.off,
#influencers-page .trend-table .ant-table-thead .ant-table-column-has-sorters:not(.ant-table-column-sort) .ant-table-column-sorter .ant-table-column-sorter-inner .ant-table-column-sorter-up.off {
  display: none;
}
#application-page .trend-table .ant-table-thead .ant-table-column-has-sorters:not(.ant-table-column-sort) .ant-table-column-sorter .ant-table-column-sorter-inner .ant-table-column-sorter-down.off,
#influencers-page .trend-table .ant-table-thead .ant-table-column-has-sorters:not(.ant-table-column-sort) .ant-table-column-sorter .ant-table-column-sorter-inner .ant-table-column-sorter-down.off {
  display: none;
}
#application-page .trend-table .ant-table-thead .ant-table-column-sort,
#influencers-page .trend-table .ant-table-thead .ant-table-column-sort {
  background-color: #fffcf3 !important;
}
#application-page .trend-table .ant-table-thead .ant-table-column-sort .ant-table-column-title,
#influencers-page .trend-table .ant-table-thead .ant-table-column-sort .ant-table-column-title {
  font-family: TruenoSemiBold;
  color: #000000;
}
#application-page .trend-table .ant-table-thead .ant-table-column-sort .ant-table-column-sorter .ant-table-column-sorter-inner .ant-table-column-sorter-up.on,
#influencers-page .trend-table .ant-table-thead .ant-table-column-sort .ant-table-column-sorter .ant-table-column-sorter-inner .ant-table-column-sorter-up.on {
  display: inline-block;
}
#application-page .trend-table .ant-table-thead .ant-table-column-sort .ant-table-column-sorter .ant-table-column-sorter-inner .ant-table-column-sorter-up.off,
#influencers-page .trend-table .ant-table-thead .ant-table-column-sort .ant-table-column-sorter .ant-table-column-sorter-inner .ant-table-column-sorter-up.off {
  display: none;
}
#application-page .trend-table .ant-table-thead .ant-table-column-sort .ant-table-column-sorter .ant-table-column-sorter-inner .ant-table-column-sorter-down.on,
#influencers-page .trend-table .ant-table-thead .ant-table-column-sort .ant-table-column-sorter .ant-table-column-sorter-inner .ant-table-column-sorter-down.on {
  color: #000000;
}
#application-page .trend-table .ant-table-thead .ant-table-column-sort .ant-table-column-sorter .ant-table-column-sorter-inner .ant-table-column-sorter-down.off,
#influencers-page .trend-table .ant-table-thead .ant-table-column-sort .ant-table-column-sorter .ant-table-column-sorter-inner .ant-table-column-sorter-down.off {
  display: none;
}
#application-page .trend-table .ant-table-body,
#influencers-page .trend-table .ant-table-body {
  overflow-x: auto;
}
#application-page .trend-table .ant-table-row td,
#influencers-page .trend-table .ant-table-row td {
  cursor: pointer;
}
#application-page .trend-table .ant-table-row td.influencer-details,
#influencers-page .trend-table .ant-table-row td.influencer-details {
  width: 291px;
}
#application-page .trend-table .ant-table-row td.favorite-creator,
#influencers-page .trend-table .ant-table-row td.favorite-creator {
  padding-right: 5px !important;
  width: -webkit-min-content;
  width: min-content;
}
#application-page .trend-table .ant-table-row td.followers,
#influencers-page .trend-table .ant-table-row td.followers {
  padding-right: 10px !important;
}
#application-page .trend-table .ant-table-row td.age-range,
#influencers-page .trend-table .ant-table-row td.age-range {
  padding-right: 19px !important;
}
#application-page .trend-table .ant-table-row td.like-count,
#influencers-page .trend-table .ant-table-row td.like-count {
  padding-right: 10px !important;
}
#application-page .trend-table .ant-table-row td.comment-count,
#influencers-page .trend-table .ant-table-row td.comment-count {
  padding-right: 10px !important;
}
#application-page .trend-table .ant-table-row td.engagement-rate,
#influencers-page .trend-table .ant-table-row td.engagement-rate {
  padding-right: 10px !important;
}
#application-page .trend-table .ant-table-row td.date-applied,
#influencers-page .trend-table .ant-table-row td.date-applied {
  padding-right: 34px !important;
}
#application-page .trend-table .ant-table-row td.influencer-details .influencer-info,
#influencers-page .trend-table .ant-table-row td.influencer-details .influencer-info,
#application-page .trend-table .ant-table-row td.influencer-details .influencer-image,
#influencers-page .trend-table .ant-table-row td.influencer-details .influencer-image {
  cursor: pointer;
}
#application-page .trend-table .ant-table-row td.creator-credits,
#influencers-page .trend-table .ant-table-row td.creator-credits {
  padding-right: 19px !important;
  width: 182px;
}
#application-page .trend-table .ant-table-row td.creator-credits .credits-circle,
#influencers-page .trend-table .ant-table-row td.creator-credits .credits-circle {
  background: #ffefd2;
  float: right;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  overflow: hidden;
  font-size: 13;
  font-weight: bold;
}
#application-page .trend-table .ant-table-row .ant-table-column-sort,
#influencers-page .trend-table .ant-table-row .ant-table-column-sort {
  font-family: TruenoBold;
  background-color: #fffcf3 !important;
}
#application-page .application-filters,
#influencers-page .application-filters {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
}
@media (min-width: 700px) {
  #application-page .application-filters,
  #influencers-page .application-filters {
    flex-direction: row;
  }
}
#application-page .application-filters .favorites-wrapper,
#influencers-page .application-filters .favorites-wrapper {
  display: inline;
}
@media (min-width: 700px) {
  #application-page .application-filters .favorites-wrapper,
  #influencers-page .application-filters .favorites-wrapper {
    border-right: 1px solid #a9b0b9;
    padding-right: 10px;
  }
}
#application-page .empty-application,
#influencers-page .empty-application {
  padding-top: 120px;
}
#application-page .empty-application .media,
#influencers-page .empty-application .media {
  width: 440px;
  height: 250px;
  margin-bottom: 22px;
}
#application-page .empty-application p,
#influencers-page .empty-application p {
  font-family: SFProDisplay;
  font-weight: 800;
  font-size: 26px;
  line-height: 33px;
  text-align: center;
  color: #000000;
}
#influencers-page .trend-table .ant-table-thead th {
  text-align: right !important;
}
#influencers-page #post-approval-content .trend-table .ant-table-thead th {
  text-align: left !important;
}
#influencers-page #shipments-content .trend-table .ant-table-thead th {
  text-align: left !important;
}
#influencers-page #post-approval-content,
#influencers-page #creator-content-approval-content {
  color: #000000;
}
#influencers-page #post-approval-content .page-header,
#influencers-page #creator-content-approval-content .page-header {
  padding: 32px 16px;
  border-bottom: 1px solid #e8e8e8;
}
#influencers-page #post-approval-content .page-header h2,
#influencers-page #creator-content-approval-content .page-header h2 {
  font-family: SFProDisplay;
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  line-height: 12px;
  color: #000000;
}
#influencers-page #post-approval-content .page-header p,
#influencers-page #creator-content-approval-content .page-header p {
  width: 100%;
  font-family: SFProDisplay;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  /* or 143% */
  color: #000000;
}
#influencers-page #post-approval-content .submitted-posts,
#influencers-page #creator-content-approval-content .submitted-posts {
  padding-left: 20px;
  background-color: #f1f5fd;
  padding-top: 31px;
  padding-bottom: 39px;
  overflow-x: scroll;
  overflow-y: hidden;
}
#influencers-page #post-approval-content .submitted-posts .empty,
#influencers-page #creator-content-approval-content .submitted-posts .empty {
  font-family: SFProDisplay;
  font-style: italic;
  font-weight: 800;
  font-size: 26px;
  line-height: 16px;
  /* identical to box height, or 62% */
  text-align: center;
  text-transform: uppercase;
  margin-top: 35px;
  margin-bottom: 26px;
  color: #dbe3ee;
}
#influencers-page #post-approval-content .submitted-posts .post-card,
#influencers-page #creator-content-approval-content .submitted-posts .post-card {
  display: inline-block;
  margin-right: 20px;
}
#influencers-page #post-approval-content .post-card,
#influencers-page #creator-content-approval-content .post-card {
  border-radius: 10px;
  width: 219px;
  background-color: #FFFFFF;
  padding-top: 12px;
}
#influencers-page #post-approval-content .post-card .post-card-image,
#influencers-page #creator-content-approval-content .post-card .post-card-image,
#influencers-page #post-approval-content .post-card .post-card-video,
#influencers-page #creator-content-approval-content .post-card .post-card-video {
  width: 195px;
  height: 166px;
  margin-left: 12px;
  margin-bottom: 9px;
}
#influencers-page #post-approval-content .post-card .post-card-image img,
#influencers-page #creator-content-approval-content .post-card .post-card-image img,
#influencers-page #post-approval-content .post-card .post-card-video img,
#influencers-page #creator-content-approval-content .post-card .post-card-video img,
#influencers-page #post-approval-content .post-card .post-card-image video,
#influencers-page #creator-content-approval-content .post-card .post-card-image video,
#influencers-page #post-approval-content .post-card .post-card-video video,
#influencers-page #creator-content-approval-content .post-card .post-card-video video {
  width: 195px;
  height: 166px;
  background-color: #eee;
  border-radius: 10px;
}
#influencers-page #post-approval-content .post-actions,
#influencers-page #creator-content-approval-content .post-actions {
  padding-left: 12px;
  padding-right: 12px;
}
#influencers-page #post-approval-content .post-label,
#influencers-page #creator-content-approval-content .post-label {
  font-family: SFProDisplay;
  font-weight: bold;
  font-weight: 800;
  font-size: 18px;
  line-height: 20px;
  color: #000000;
}
#influencers-page #post-approval-content .post-link a,
#influencers-page #creator-content-approval-content .post-link a {
  font-family: SFProDisplay;
  text-decoration: underline;
  color: #000000;
  font-weight: bold;
  font-size: 11px;
  line-height: 10px;
}
#influencers-page #post-approval-content .post-btn-group,
#influencers-page #creator-content-approval-content .post-btn-group {
  font-family: SFProDisplay;
  text-align: center;
  font-style: normal;
  font-weight: bold;
  font-size: 9px;
  line-height: 11px;
}
#influencers-page #post-approval-content .post-btn-group .ant-btn,
#influencers-page #creator-content-approval-content .post-btn-group .ant-btn {
  padding: 0;
  width: 37px;
  height: 37px;
  border-radius: 100%;
  margin-bottom: 4px;
}
#influencers-page #post-approval-content .post-btn-group .ant-btn:hover,
#influencers-page #creator-content-approval-content .post-btn-group .ant-btn:hover {
  opacity: 0.7;
}
#influencers-page #post-approval-content .post-btn-group.reject,
#influencers-page #creator-content-approval-content .post-btn-group.reject {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#influencers-page #post-approval-content .post-btn-group.reject .dash,
#influencers-page #creator-content-approval-content .post-btn-group.reject .dash {
  height: 4px;
  background-color: #000000;
  border-radius: 50px;
  width: 19.73px;
}
#influencers-page #post-approval-content .post-btn-group.reject .ant-btn,
#influencers-page #creator-content-approval-content .post-btn-group.reject .ant-btn {
  background: #e58989;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
}
#influencers-page #post-approval-content .post-btn-group.approve .anticon,
#influencers-page #creator-content-approval-content .post-btn-group.approve .anticon {
  margin-top: 4px;
  display: inline-block;
  font-size: 22px;
}
#influencers-page #post-approval-content .post-btn-group.approve .ant-btn,
#influencers-page #creator-content-approval-content .post-btn-group.approve .ant-btn {
  background: #00b267;
  border: none;
}
#influencers-page #post-approval-content .post-details,
#influencers-page #creator-content-approval-content .post-details {
  color: #000000;
  margin-top: 9px;
  border-top: 1px solid #dbdfe8;
  padding: 12px;
}
#influencers-page #post-approval-content .post-details .post-username,
#influencers-page #creator-content-approval-content .post-details .post-username {
  font-family: SFProDisplay;
  font-weight: bold;
  font-size: 12px;
  line-height: 18px;
}
#influencers-page #post-approval-content .post-details .post-stats,
#influencers-page #creator-content-approval-content .post-details .post-stats {
  font-family: SFProDisplay;
  font-weight: normal;
  font-style: normal;
  width: 100%;
  font-size: 11px;
  line-height: 17px;
}
#influencers-page #post-approval-content .post-details .post-stats .post-stat-type,
#influencers-page #creator-content-approval-content .post-details .post-stats .post-stat-type {
  float: left;
}
#influencers-page #post-approval-content .post-details .post-stats .post-stat-timer,
#influencers-page #creator-content-approval-content .post-details .post-stats .post-stat-timer {
  font-weight: bold;
  float: right;
}
#influencers-page #post-approval-content .ant-table .post,
#influencers-page #creator-content-approval-content .ant-table .post {
  width: 190px;
}
#influencers-page #post-approval-content .ant-table tr:first-child > th,
#influencers-page #creator-content-approval-content .ant-table tr:first-child > th {
  width: 50%;
}
#influencers-page #post-approval-content .ant-table .post-avatar,
#influencers-page #creator-content-approval-content .ant-table .post-avatar,
#influencers-page #post-approval-content .ant-table .creator-content-avatar,
#influencers-page #creator-content-approval-content .ant-table .creator-content-avatar {
  margin-right: 20px;
}
#influencers-page #post-approval-content .ant-table .post-avatar .story-type,
#influencers-page #creator-content-approval-content .ant-table .post-avatar .story-type,
#influencers-page #post-approval-content .ant-table .creator-content-avatar .story-type,
#influencers-page #creator-content-approval-content .ant-table .creator-content-avatar .story-type,
#influencers-page #post-approval-content .ant-table .post-avatar .video-type,
#influencers-page #creator-content-approval-content .ant-table .post-avatar .video-type,
#influencers-page #post-approval-content .ant-table .creator-content-avatar .video-type,
#influencers-page #creator-content-approval-content .ant-table .creator-content-avatar .video-type,
#influencers-page #post-approval-content .ant-table .post-avatar .image-type,
#influencers-page #creator-content-approval-content .ant-table .post-avatar .image-type,
#influencers-page #post-approval-content .ant-table .creator-content-avatar .image-type,
#influencers-page #creator-content-approval-content .ant-table .creator-content-avatar .image-type {
  font-family: SFProDisplay;
  font-weight: bold;
  font-size: 7px;
  line-height: 11px;
  text-align: center;
  letter-spacing: 0.4px;
  text-transform: uppercase;
}
#influencers-page #post-approval-content .ant-table .post-avatar:nth-child(n + 4),
#influencers-page #creator-content-approval-content .ant-table .post-avatar:nth-child(n + 4),
#influencers-page #post-approval-content .ant-table .creator-content-avatar:nth-child(n + 4),
#influencers-page #creator-content-approval-content .ant-table .creator-content-avatar:nth-child(n + 4) {
  margin-top: 20px;
}
#influencers-page #post-approval-content .rehire-button,
#influencers-page #creator-content-approval-content .rehire-button {
  font-family: SFProDisplay;
  font-weight: normal;
  font-style: normal;
  font-weight: bold;
  font-size: 11px;
}
#influencers-page #post-approval-content .rehire-button span,
#influencers-page #creator-content-approval-content .rehire-button span {
  white-space: initial;
  line-height: 13px;
  text-align: left;
  max-width: 88px;
}
@media (min-width: 700px) {
  #influencers-page .shipment-list,
  #influencers-page .trend-table--wrapper {
    margin: 1rem auto 0;
    max-width: 50rem;
    padding: 0;
  }
  #influencers-page .shipment-list thead::before,
  #influencers-page .trend-table--wrapper thead::before,
  #influencers-page .shipment-list thead::after,
  #influencers-page .trend-table--wrapper thead::after {
    display: none;
  }
  #influencers-page .shipment-list .trend-table,
  #influencers-page .trend-table--wrapper .trend-table {
    border: 0.0625rem solid #d6d7dc;
    border-radius: 0.3125rem;
  }
  #influencers-page .shipment-list .trend-table .table-address p,
  #influencers-page .trend-table--wrapper .trend-table .table-address p {
    margin: 0;
    width: -webkit-max-content;
    width: max-content;
  }
  #influencers-page .shipment-list .trend-table .col-action.actions,
  #influencers-page .trend-table--wrapper .trend-table .col-action.actions {
    padding-right: 1rem !important;
    max-width: -webkit-max-content;
    max-width: max-content;
    width: -webkit-max-content;
    width: max-content;
  }
  #influencers-page .shipment-list .trend-table .cell-action,
  #influencers-page .trend-table--wrapper .trend-table .cell-action {
    align-items: flex-end;
    flex-wrap: wrap;
  }
  #influencers-page .shipment-list .influencer-avatar-image,
  #influencers-page .trend-table--wrapper .influencer-avatar-image {
    padding-left: 1rem !important;
  }
}
#influencer-drawer.ant-drawer:not(.ant-drawer-open) .ant-drawer-content,
#influencer-drawer-admin.ant-drawer:not(.ant-drawer-open) .ant-drawer-content {
  box-shadow: none;
}
#influencer-drawer .ant-drawer-body,
#influencer-drawer-admin .ant-drawer-body {
  padding: 0;
  overflow: hidden;
}
#influencer-drawer .ant-drawer-header-no-title,
#influencer-drawer-admin .ant-drawer-header-no-title {
  position: relative;
}
#influencer-drawer .ant-drawer-header-no-title .ant-drawer-close,
#influencer-drawer-admin .ant-drawer-header-no-title .ant-drawer-close {
  left: 0;
  color: #000000;
}
#influencer-drawer .ant-drawer-content,
#influencer-drawer-admin .ant-drawer-content {
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.55);
  background-image: #FFFFFF;
}
#influencer-drawer .ant-drawer-content .influencer-info,
#influencer-drawer-admin .ant-drawer-content .influencer-info {
  font-family: SFProDisplay;
  font-weight: normal;
  font-style: normal;
  color: #000000;
}
#influencer-drawer .ant-drawer-content .influencer-info-label,
#influencer-drawer-admin .ant-drawer-content .influencer-info-label {
  display: block;
  font-family: SFProDisplay;
  font-weight: normal;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  color: #000000;
}
#influencer-drawer .ant-drawer-content .influencer-info-actions,
#influencer-drawer-admin .ant-drawer-content .influencer-info-actions {
  padding: 20px;
}
#influencer-drawer .ant-drawer-content .influencer-info-actions button,
#influencer-drawer-admin .ant-drawer-content .influencer-info-actions button {
  height: 45px;
  border-radius: 3px;
  font-family: SFProDisplay;
  font-weight: normal;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
}
#influencer-drawer .ant-drawer-content .influencer-info-actions button.rehire-button,
#influencer-drawer-admin .ant-drawer-content .influencer-info-actions button.rehire-button {
  font-family: SFProDisplay;
  font-weight: bold;
  font-style: normal;
  font-size: 14px;
}
#influencer-drawer .ant-drawer-content .influencer-info-actions button.action-reject,
#influencer-drawer-admin .ant-drawer-content .influencer-info-actions button.action-reject {
  color: #000000;
  border: none;
  background-color: #ff5a5a;
}
#influencer-drawer .ant-drawer-content .influencer-info-actions button.action-reject:hover,
#influencer-drawer-admin .ant-drawer-content .influencer-info-actions button.action-reject:hover {
  background-color: #fa8383;
}
#influencer-drawer .ant-drawer-content .influencer-info-actions button.action-approve,
#influencer-drawer-admin .ant-drawer-content .influencer-info-actions button.action-approve {
  color: #000000;
}
#influencer-drawer .ant-drawer-content .influencer-info-avatar,
#influencer-drawer-admin .ant-drawer-content .influencer-info-avatar {
  text-align: center;
  margin-top: 35px;
  position: relative;
}
#influencer-drawer .ant-drawer-content .influencer-info-vip,
#influencer-drawer-admin .ant-drawer-content .influencer-info-vip {
  width: 30px;
  height: 30px;
  font-size: 30px;
  position: absolute;
  display: block;
  bottom: 0;
  right: 117px;
}
#influencer-drawer .ant-drawer-content .influencer-info-name,
#influencer-drawer-admin .ant-drawer-content .influencer-info-name {
  font-family: SFProDisplay;
  font-weight: normal;
  color: #000000;
  font-size: 18px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 18px;
  letter-spacing: normal;
  text-align: center;
  padding-top: 13px;
  margin: 0;
}
#influencer-drawer .ant-drawer-content .influencer-info-address,
#influencer-drawer-admin .ant-drawer-content .influencer-info-address {
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 18px;
  letter-spacing: normal;
  text-align: center;
  color: #000000;
  margin-top: 4px;
}
#influencer-drawer .ant-drawer-content .influencer-socials,
#influencer-drawer-admin .ant-drawer-content .influencer-socials {
  display: inline-block;
  margin: 3px;
  border: 2px solid #f4f4f4;
  border-radius: 35px;
}
#influencer-drawer .ant-drawer-content .influencer-socials:hover,
#influencer-drawer-admin .ant-drawer-content .influencer-socials:hover {
  border: 2px solid #ffb917;
}
#influencer-drawer .ant-drawer-content .influencer-socials-button,
#influencer-drawer-admin .ant-drawer-content .influencer-socials-button {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  width: 30px;
  height: 30px;
  padding-top: 2px;
}
#influencer-drawer .ant-drawer-content .influencer-stats,
#influencer-drawer-admin .ant-drawer-content .influencer-stats {
  border: 1px solid #cbd6ed;
  background-color: #f1f5fd;
  color: #000000;
  border-right: 0;
  border-left: 0;
  margin-bottom: 33px;
}
#influencer-drawer .ant-drawer-content .influencer-stats-followers,
#influencer-drawer-admin .ant-drawer-content .influencer-stats-followers {
  padding-top: 13px;
  text-align: center;
  color: #000000;
  border: 1px solid #cbd6ed;
}
#influencer-drawer .ant-drawer-content .influencer-stats-followers .influencer-stats-label,
#influencer-drawer-admin .ant-drawer-content .influencer-stats-followers .influencer-stats-label {
  margin-bottom: 10px;
}
#influencer-drawer .ant-drawer-content .influencer-stats-followers .influencer-stats-label,
#influencer-drawer-admin .ant-drawer-content .influencer-stats-followers .influencer-stats-label {
  text-align: center;
}
#influencer-drawer .ant-drawer-content .influencer-stats-followers .influencer-stats-value,
#influencer-drawer-admin .ant-drawer-content .influencer-stats-followers .influencer-stats-value {
  font-family: SFProDisplay;
  font-weight: normal;
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 21px;
  padding-bottom: 21px;
}
#influencer-drawer .ant-drawer-content .influencer-stats-label,
#influencer-drawer-admin .ant-drawer-content .influencer-stats-label {
  display: block;
  font-family: SFProDisplay;
  font-weight: normal;
  font-style: normal;
  color: #000000;
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 2px;
  text-transform: uppercase;
}
#influencer-drawer .ant-drawer-content .influencer-stats-other-details,
#influencer-drawer-admin .ant-drawer-content .influencer-stats-other-details {
  padding: 14px 0 21px;
}
#influencer-drawer .ant-drawer-content .influencer-stats-engagement,
#influencer-drawer-admin .ant-drawer-content .influencer-stats-engagement {
  font-family: 'TruenoSemiBold', Arial, sans-serif;
  font-size: 10px !important;
  text-transform: uppercase;
  padding: 10px 11px;
  text-align: center;
  background: #3b1f5c;
  border-top: 1px solid #46266d;
  letter-spacing: 2px;
}
#influencer-drawer .ant-drawer-content .influencer-stats-likes,
#influencer-drawer-admin .ant-drawer-content .influencer-stats-likes,
#influencer-drawer .ant-drawer-content .influencer-stats-comments,
#influencer-drawer-admin .ant-drawer-content .influencer-stats-comments {
  padding-left: 19px;
}
#influencer-drawer .ant-drawer-content .influencer-stats-likes .influencer-stats-value,
#influencer-drawer-admin .ant-drawer-content .influencer-stats-likes .influencer-stats-value,
#influencer-drawer .ant-drawer-content .influencer-stats-comments .influencer-stats-value,
#influencer-drawer-admin .ant-drawer-content .influencer-stats-comments .influencer-stats-value {
  font-size: 21px;
  font-family: SFProDisplay;
  font-weight: bold;
  color: #000000;
  vertical-align: middle;
}
#influencer-drawer .ant-drawer-content .influencer-stats-likes .influencer-stats-value-indicator-icon,
#influencer-drawer-admin .ant-drawer-content .influencer-stats-likes .influencer-stats-value-indicator-icon,
#influencer-drawer .ant-drawer-content .influencer-stats-comments .influencer-stats-value-indicator-icon,
#influencer-drawer-admin .ant-drawer-content .influencer-stats-comments .influencer-stats-value-indicator-icon {
  display: inline-block;
  margin-right: 6px;
}
#influencer-drawer .ant-drawer-content .influencer-stats-likes .influencer-stats-value-count,
#influencer-drawer-admin .ant-drawer-content .influencer-stats-likes .influencer-stats-value-count,
#influencer-drawer .ant-drawer-content .influencer-stats-comments .influencer-stats-value-count,
#influencer-drawer-admin .ant-drawer-content .influencer-stats-comments .influencer-stats-value-count {
  line-height: 21px;
}
#influencer-drawer .ant-drawer-content .influencer-stats-likes .influencer-stats-value-indicator-percent,
#influencer-drawer-admin .ant-drawer-content .influencer-stats-likes .influencer-stats-value-indicator-percent,
#influencer-drawer .ant-drawer-content .influencer-stats-comments .influencer-stats-value-indicator-percent,
#influencer-drawer-admin .ant-drawer-content .influencer-stats-comments .influencer-stats-value-indicator-percent {
  display: inline-block;
  line-height: 11px;
  margin-left: 1px;
  font-size: 9px;
  color: #ffb917;
  font-family: SFProDisplay;
  font-weight: bold;
  font-weight: 600;
}
#influencer-drawer .ant-drawer-content .influencer-stats-likes .influencer-stats-value.down .influencer-stats-value-indicator-percent,
#influencer-drawer-admin .ant-drawer-content .influencer-stats-likes .influencer-stats-value.down .influencer-stats-value-indicator-percent,
#influencer-drawer .ant-drawer-content .influencer-stats-comments .influencer-stats-value.down .influencer-stats-value-indicator-percent,
#influencer-drawer-admin .ant-drawer-content .influencer-stats-comments .influencer-stats-value.down .influencer-stats-value-indicator-percent {
  color: #f54c15;
}
#influencer-drawer .ant-drawer-content .influencer-stats-likes .influencer-stats-value.up .influencer-stats-value-indicator-percent,
#influencer-drawer-admin .ant-drawer-content .influencer-stats-likes .influencer-stats-value.up .influencer-stats-value-indicator-percent,
#influencer-drawer .ant-drawer-content .influencer-stats-comments .influencer-stats-value.up .influencer-stats-value-indicator-percent,
#influencer-drawer-admin .ant-drawer-content .influencer-stats-comments .influencer-stats-value.up .influencer-stats-value-indicator-percent {
  color: #15f590;
}
#influencer-drawer .ant-drawer-content .influencer-stats-comments,
#influencer-drawer-admin .ant-drawer-content .influencer-stats-comments {
  padding-left: 23px;
  border-left: solid 1px #46266d;
}
#influencer-drawer .ant-drawer-content .influencer-review-stats,
#influencer-drawer-admin .ant-drawer-content .influencer-review-stats {
  margin-left: 21px;
  margin-right: 21px;
  margin-bottom: 30px;
  color: #000000;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  padding-bottom: 2px;
}
#influencer-drawer .ant-drawer-content .influencer-review-stats .review-stat,
#influencer-drawer-admin .ant-drawer-content .influencer-review-stats .review-stat {
  margin-bottom: 26px;
  position: relative;
}
#influencer-drawer .ant-drawer-content .influencer-review-stats .review-stat:last-child,
#influencer-drawer-admin .ant-drawer-content .influencer-review-stats .review-stat:last-child {
  margin-bottom: 0;
}
#influencer-drawer .ant-drawer-content .influencer-review-stats label.review-stat-label,
#influencer-drawer-admin .ant-drawer-content .influencer-review-stats label.review-stat-label {
  font-family: SFProDisplay;
  font-weight: normal;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 18px;
  margin-bottom: 5px;
}
#influencer-drawer .ant-drawer-content .influencer-review-stats .review-stat-value,
#influencer-drawer-admin .ant-drawer-content .influencer-review-stats .review-stat-value {
  font-family: SFProDisplay;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 21px;
  position: absolute;
  right: 0;
  bottom: 7px;
}
#influencer-drawer .ant-drawer-content .influencer-review-stats .review-stat-rate,
#influencer-drawer-admin .ant-drawer-content .influencer-review-stats .review-stat-rate {
  margin-top: 5px;
  position: relative;
}
#influencer-drawer .ant-drawer-content .influencer-review-stats .review-stat-rate-bar,
#influencer-drawer-admin .ant-drawer-content .influencer-review-stats .review-stat-rate-bar {
  height: 4px;
  background-color: #ffb917;
}
#influencer-drawer .ant-drawer-content .influencer-review-stats .review-stat-rate::before,
#influencer-drawer-admin .ant-drawer-content .influencer-review-stats .review-stat-rate::before {
  position: absolute;
  display: block;
  content: '';
  width: 100%;
  height: 2px;
  top: 1px;
  background-color: #d0d0d0;
  z-index: -1;
}
#influencer-drawer .ant-drawer-content .influencer-review-stats .ant-progress-bg,
#influencer-drawer-admin .ant-drawer-content .influencer-review-stats .ant-progress-bg,
#influencer-drawer .ant-drawer-content .influencer-review-stats .ant-progress-inner,
#influencer-drawer-admin .ant-drawer-content .influencer-review-stats .ant-progress-inner {
  display: block;
  border-radius: 0 !important;
}
#influencer-drawer .ant-drawer-content .influencer-review-stats .ant-progress,
#influencer-drawer-admin .ant-drawer-content .influencer-review-stats .ant-progress {
  display: block;
}
#influencer-drawer .ant-drawer-content .influencer-review-stats .ant-progress > div,
#influencer-drawer-admin .ant-drawer-content .influencer-review-stats .ant-progress > div {
  display: block;
}
#influencer-drawer .ant-drawer-content .influencer-review-stats .ant-progress-outer,
#influencer-drawer-admin .ant-drawer-content .influencer-review-stats .ant-progress-outer {
  display: block;
}
#influencer-drawer .ant-drawer-content .influencer-review-stats .ant-progress-inner,
#influencer-drawer-admin .ant-drawer-content .influencer-review-stats .ant-progress-inner {
  display: block;
  background: transparent;
}
#influencer-drawer .ant-drawer-content .influencer-review-stats .ant-progress-inner:before,
#influencer-drawer-admin .ant-drawer-content .influencer-review-stats .ant-progress-inner:before {
  content: '';
  height: 2px;
  border: solid 1px #46266d;
  width: 100%;
  display: block;
  position: absolute;
  top: 1px;
}
#influencer-drawer .ant-drawer-content .influencer-reviews,
#influencer-drawer-admin .ant-drawer-content .influencer-reviews {
  margin-left: 21px;
  margin-right: 21px;
  margin-bottom: 47px;
  color: #000000;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
}
#influencer-drawer .ant-drawer-content .influencer-reviews .influencer-info-label,
#influencer-drawer-admin .ant-drawer-content .influencer-reviews .influencer-info-label {
  margin-bottom: 4px;
}
#influencer-drawer .ant-drawer-content .influencer-reviews .review-comment,
#influencer-drawer-admin .ant-drawer-content .influencer-reviews .review-comment {
  font-family: SFProDisplay;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 8px;
}
#influencer-drawer .ant-drawer-content .influencer-reviews .review-reviewer,
#influencer-drawer-admin .ant-drawer-content .influencer-reviews .review-reviewer {
  font-family: SFProDisplay;
  font-weight: normal;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 18px;
}
#influencer-drawer .ant-drawer-content .influencer-reviews .influencer-review,
#influencer-drawer-admin .ant-drawer-content .influencer-reviews .influencer-review {
  border-bottom: 1px solid #46266d;
  padding-bottom: 24px;
  margin-top: 24px;
}
#influencer-drawer .ant-drawer-content .influencer-reviews .influencer-review:first-child,
#influencer-drawer-admin .ant-drawer-content .influencer-reviews .influencer-review:first-child {
  margin-top: 0;
}
#influencer-drawer .ant-drawer-content .influencer-reviews .influencer-review:last-child,
#influencer-drawer-admin .ant-drawer-content .influencer-reviews .influencer-review:last-child {
  margin-bottom: 16px;
  border-bottom: none;
  padding-bottom: 0;
}
#influencer-drawer .ant-drawer-content .influencer-reviews .load-more-reviews button.ant-btn,
#influencer-drawer-admin .ant-drawer-content .influencer-reviews .load-more-reviews button.ant-btn {
  height: 28px;
  background-color: #f1f5fd;
  font-style: normal;
  font-weight: bold;
  font-size: 8px;
  line-height: 10px;
  text-align: center;
  letter-spacing: 3px;
  text-transform: uppercase;
  color: #000000;
  border: none;
}
#influencer-drawer .ant-drawer-content .influencer-reviews .load-more-reviews button.ant-btn:hover,
#influencer-drawer-admin .ant-drawer-content .influencer-reviews .load-more-reviews button.ant-btn:hover {
  opacity: 0.5;
}
#influencer-drawer .ant-drawer-content .influencer-posts,
#influencer-drawer-admin .ant-drawer-content .influencer-posts {
  margin-left: 21px;
  margin-right: 21px;
}
#influencer-drawer .ant-drawer-content .influencer-posts .influencer-info-label,
#influencer-drawer-admin .ant-drawer-content .influencer-posts .influencer-info-label {
  margin-bottom: 12px;
}
#influencer-drawer .ant-drawer-content .influencer-posts-images a,
#influencer-drawer-admin .ant-drawer-content .influencer-posts-images a {
  display: inline-block;
  width: 141px;
  height: 141px;
  padding-right: 4px;
  padding-bottom: 4px;
}
#influencer-drawer .ant-drawer-content .influencer-posts-images a:last-child,
#influencer-drawer-admin .ant-drawer-content .influencer-posts-images a:last-child {
  padding-right: 0;
}
#influencer-drawer .ant-drawer-content .influencer-posts-images img,
#influencer-drawer-admin .ant-drawer-content .influencer-posts-images img {
  width: 100%;
  height: 100%;
}
#influencer-drawer .ant-drawer-content .influencer-info-bio,
#influencer-drawer-admin .ant-drawer-content .influencer-info-bio {
  color: #000000;
  margin: 32px 20px 22px 20px;
}
#influencer-drawer .ant-drawer-content .influencer-info-bio p,
#influencer-drawer-admin .ant-drawer-content .influencer-info-bio p {
  font-family: SFProDisplay;
  white-space: pre-wrap;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 20px;
  color: #000000;
}
#influencer-drawer .ant-drawer-content .influencer-info-work,
#influencer-drawer-admin .ant-drawer-content .influencer-info-work {
  color: #000000;
  margin: 0 20px;
}
#influencer-drawer .ant-drawer-content .influencer-info-work .influencer-info-label,
#influencer-drawer-admin .ant-drawer-content .influencer-info-work .influencer-info-label {
  margin-bottom: 8px;
}
#influencer-drawer .ant-drawer-content .influencer-info-work .influencer-info-posts .post-card,
#influencer-drawer-admin .ant-drawer-content .influencer-info-work .influencer-info-posts .post-card {
  margin-bottom: 20px;
}
#influencer-drawer .ant-drawer-content .influencer-info-work .influencer-info-posts .post-card-resource,
#influencer-drawer-admin .ant-drawer-content .influencer-info-work .influencer-info-posts .post-card-resource {
  position: relative;
  width: 100%;
  height: 287px;
}
#influencer-drawer .ant-drawer-content .influencer-info-work .influencer-info-posts .post-card-resource .post-card-video,
#influencer-drawer-admin .ant-drawer-content .influencer-info-work .influencer-info-posts .post-card-resource .post-card-video,
#influencer-drawer .ant-drawer-content .influencer-info-work .influencer-info-posts .post-card-resource .post-card-image,
#influencer-drawer-admin .ant-drawer-content .influencer-info-work .influencer-info-posts .post-card-resource .post-card-image {
  width: 100%;
  height: 100%;
}
#influencer-drawer .ant-drawer-content .influencer-info-work .influencer-info-posts .post-card-resource .post-card-image a,
#influencer-drawer-admin .ant-drawer-content .influencer-info-work .influencer-info-posts .post-card-resource .post-card-image a {
  width: 100%;
  height: 100%;
  display: block;
}
#influencer-drawer .ant-drawer-content .influencer-info-work .influencer-info-posts .post-card-resource video,
#influencer-drawer-admin .ant-drawer-content .influencer-info-work .influencer-info-posts .post-card-resource video,
#influencer-drawer .ant-drawer-content .influencer-info-work .influencer-info-posts .post-card-resource img,
#influencer-drawer-admin .ant-drawer-content .influencer-info-work .influencer-info-posts .post-card-resource img {
  object-fit: contain;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
#influencer-drawer .ant-drawer-content .influencer-info-work .influencer-info-posts .post-card-resource:after,
#influencer-drawer-admin .ant-drawer-content .influencer-info-work .influencer-info-posts .post-card-resource:after {
  content: '';
  display: block;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 62px;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), #000000);
}
#influencer-drawer .ant-drawer-content .influencer-info-work .influencer-info-posts .post-card-stats,
#influencer-drawer-admin .ant-drawer-content .influencer-info-work .influencer-info-posts .post-card-stats {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding-left: 16px;
  z-index: 2;
}
#influencer-drawer .ant-drawer-content .influencer-info-work .influencer-info-posts .post-card-stat,
#influencer-drawer-admin .ant-drawer-content .influencer-info-work .influencer-info-posts .post-card-stat {
  float: left;
  width: auto;
  margin-right: 30px;
  margin-bottom: 11px;
  color: #FFFFFF;
}
#influencer-drawer .ant-drawer-content .influencer-info-work .influencer-info-posts .post-card-stat-value,
#influencer-drawer-admin .ant-drawer-content .influencer-info-work .influencer-info-posts .post-card-stat-value {
  font-family: 'TruenoBold';
  font-size: 16px;
  line-height: 0.75;
  padding-bottom: 6px;
}
#influencer-drawer .ant-drawer-content .influencer-info-work .influencer-info-posts .post-card-stat-label,
#influencer-drawer-admin .ant-drawer-content .influencer-info-work .influencer-info-posts .post-card-stat-label {
  font-family: 'TruenoSemiBold';
  font-size: 8px;
  letter-spacing: 0.5px;
}
#influencer-drawer .ant-drawer-content .influencer-info-work .influencer-info-posts .post-card-actions,
#influencer-drawer-admin .ant-drawer-content .influencer-info-work .influencer-info-posts .post-card-actions {
  width: 100%;
}
#influencer-drawer .ant-drawer-content .influencer-info-work .influencer-info-posts .post-card-actions button,
#influencer-drawer-admin .ant-drawer-content .influencer-info-work .influencer-info-posts .post-card-actions button {
  background-color: #f1f5fd;
  height: 48px;
  border: none;
  border-radius: 0;
  color: #000000;
  font-family: 'TruenoSemiBold';
  font-size: 8px;
  letter-spacing: 3px;
}
#influencer-drawer .post-gallery-actions button,
#influencer-drawer-admin .post-gallery-actions button {
  top: 118px;
}
#influencer-drawer .post-gallery-actions button.action-next,
#influencer-drawer-admin .post-gallery-actions button.action-next {
  right: 16px;
}
#influencer-drawer .post-gallery-actions button.action-previous,
#influencer-drawer-admin .post-gallery-actions button.action-previous {
  left: 16px;
}
#influencer-drawer .ant-tabs-bar,
#influencer-drawer-admin .ant-tabs-bar {
  border-bottom: solid 1px #46266d;
  margin-bottom: 0;
}
#influencer-drawer .ant-tabs-ink-bar,
#influencer-drawer-admin .ant-tabs-ink-bar {
  background-color: #ffb917;
  margin-left: 39px;
  width: 31px !important;
}
#influencer-drawer .ant-tabs-nav,
#influencer-drawer-admin .ant-tabs-nav {
  width: 100%;
}
#influencer-drawer .ant-tabs-nav .ant-tabs-tab,
#influencer-drawer-admin .ant-tabs-nav .ant-tabs-tab {
  padding: 23px 0;
  width: calc(100% / 3);
  margin: 0;
  opacity: 0.5;
  font-family: 'TruenoLight';
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.86;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
}
#influencer-drawer .ant-tabs-nav .ant-tabs-tab.ant-tabs-tab-active,
#influencer-drawer-admin .ant-tabs-nav .ant-tabs-tab.ant-tabs-tab-active {
  font-family: 'TruenoBold';
  opacity: 1;
}
#influencer-drawer .ant-tabs-nav .ant-tabs-tab:hover,
#influencer-drawer-admin .ant-tabs-nav .ant-tabs-tab:hover {
  opacity: 1;
}
#influencer-drawer .campaign-details,
#influencer-drawer-admin .campaign-details {
  text-align: left;
  color: #FFFFFF;
  margin: 18px 19px;
  padding: 19px 20px;
  border-radius: 1;
  background-color: rgba(59, 31, 92, 0.49);
}
#influencer-drawer .campaign-details-brand-name,
#influencer-drawer-admin .campaign-details-brand-name {
  font-family: 'TruenoBold';
  font-size: 12px;
  line-height: 19px;
  margin-bottom: 3px;
}
#influencer-drawer .campaign-details-name,
#influencer-drawer-admin .campaign-details-name {
  font-family: 'TruenoBold';
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.36;
  letter-spacing: normal;
  color: #ffffff;
  margin-bottom: 18px;
}
#influencer-drawer .campaign-details .details-status,
#influencer-drawer-admin .campaign-details .details-status {
  position: relative;
  margin-bottom: 16px;
}
#influencer-drawer .campaign-details .details-status:last-child,
#influencer-drawer-admin .campaign-details .details-status:last-child {
  margin-bottom: 0;
}
#influencer-drawer .campaign-details .details-status .status,
#influencer-drawer-admin .campaign-details .details-status .status {
  margin-left: 15px;
  font-family: 'TruenoRegular';
  line-height: 0.86;
}
#influencer-drawer .campaign-details .details-status .status-value,
#influencer-drawer-admin .campaign-details .details-status .status-value {
  margin-bottom: 8px;
}
#influencer-drawer .campaign-details .details-status .status-label,
#influencer-drawer-admin .campaign-details .details-status .status-label {
  text-transform: uppercase;
  font-family: 'TruenoSemiBold';
  font-size: 8px;
  line-height: normal;
  letter-spacing: 2px;
}
#influencer-drawer .campaign-details .details-status .icon,
#influencer-drawer-admin .campaign-details .details-status .icon {
  top: 5px;
  width: 8px;
  height: 8px;
  position: absolute;
  display: inline-block;
  border-radius: 100%;
}
#influencer-drawer .campaign-details .details-status .icon.success,
#influencer-drawer-admin .campaign-details .details-status .icon.success {
  background: #15f590;
}
#influencer-drawer .campaign-details .details-status .icon.danger,
#influencer-drawer-admin .campaign-details .details-status .icon.danger {
  background-color: #ff0000;
}
#influencer-drawer .campaign-details .details-status .icon.warning,
#influencer-drawer-admin .campaign-details .details-status .icon.warning {
  background-color: #ffb917;
}
#influencer-drawer .ant-spin-nested-loading,
#influencer-drawer-admin .ant-spin-nested-loading {
  height: 100%;
  width: 100%;
}
#influencer-drawer .ant-spin-nested-loading .ant-spin-container,
#influencer-drawer-admin .ant-spin-nested-loading .ant-spin-container {
  height: 100%;
  width: 100%;
}
#influencer-drawer .brand-credit-text,
#influencer-drawer-admin .brand-credit-text {
  font-family: SFProDisplay;
  font-weight: normal;
  font-style: normal;
  color: black;
  font-weight: bold;
  padding: 10px;
}
#influencer-drawer .brand-credit-text p,
#influencer-drawer-admin .brand-credit-text p {
  margin: 0;
}
#influencer-drawer .brand-credit-text .credit-amount,
#influencer-drawer-admin .brand-credit-text .credit-amount {
  font-size: 30px;
  line-height: 1;
}
#influencer-drawer-admin .ant-drawer-content .influencer-stats {
  border-top: 0;
}
#influencer-drawer-admin .ant-drawer-content .influencer-info-actions {
  padding: 23px 34px 0 34px;
}
#influencer-drawer-admin .ant-drawer-content .influencer-info-actions button {
  margin-bottom: 6px;
}
#influencer-drawer-admin .ant-drawer-content .influencer-levels {
  padding: 23px 34px 0 34px;
}
#influencer-drawer-admin .influencer-badge {
  position: absolute;
  top: 22px;
  right: 43px;
  width: 34px;
}
#influencer-drawer-admin .admin-credit-text {
  margin: 0;
  color: black;
  text-align: center;
  text-transform: uppercase;
}
#influencer-drawer-admin .ant-tabs-nav .ant-tabs-tab {
  color: #000000;
  -webkit-user-select: none;
  /* Safari */
  /* Firefox */
  /* IE10+/Edge */
  user-select: none;
  /* Standard */
}
.admin-nav .ant-menu-item:hover,
.admin-nav .ant-menu-item:focus {
  background-color: #fff7e3 !important;
  border-radius: 0.625rem 0.625rem 0 0;
}
.admin-nav .ant-menu-item-active {
  background-color: #fff7e3 !important;
  border-radius: 0.625rem 0.625rem 0 0;
  color: #000000 !important;
}
.admin-nav .ant-menu-item-selected {
  background-color: #FFB917;
  border-radius: 0.625rem 0.625rem 0 0;
  color: #000000 !important;
}
.admin-nav .ant-menu-item-selected:hover,
.admin-nav .ant-menu-item-selected:focus {
  background-color: #FFB917 !important;
}
.influencer-cards-flex {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 1rem;
  gap: 1rem;
  justify-content: center;
}
.influencer-card {
  background-color: #FFFFFF;
  border: 0.0625rem solid #b8b9c1;
  border-radius: 0.625rem;
  min-width: 15.625rem;
  overflow: hidden;
  width: 30%;
}
.influencer-card .influencer-img-btn {
  width: 100%;
}
.influencer-card .influencer-img-btn img,
.influencer-card .influencer-img-btn video {
  height: 13.75rem;
  object-fit: cover;
  width: 100%;
}
.influencer-card .influencer-card-content {
  display: flex;
  flex-direction: column;
  grid-gap: 0.75rem;
  gap: 0.75rem;
  padding: 0.75rem;
}
.influencer-card .influencer-details {
  align-items: center;
  border-radius: 0.5rem;
  display: flex;
  grid-gap: 1rem;
  gap: 1rem;
  transition: background-color 0.3s;
}
.influencer-card .influencer-details:hover,
.influencer-card .influencer-details:focus {
  background-color: #f5f6f6;
}
.influencer-card .influencer-details:focus-visible {
  outline: revert !important;
  outline-offset: -0.125rem;
}
.influencer-card .influencer-avatar {
  border-radius: 50%;
  height: 2.25rem;
  width: 2.25rem;
}
.influencer-card .influencer-details-right {
  color: #000000;
  display: flex;
  flex-direction: column;
}
.influencer-card .influencer-download-btn {
  background-color: #000000;
  border-radius: 0.5rem;
  color: #FFFFFF;
  padding: 0.5rem 1rem 0.75rem 1rem;
  width: 100%;
}
#campaign-posts-page .post-filter-types {
  border-bottom: 1px solid #d6d6d6;
}
#campaign-posts-page .ant-select-selection-selected-value,
#campaign-posts-page .ant-select-selection__placeholder {
  font-size: 0.75rem !important;
  font-weight: 600;
}
.example-campaign .video-player {
  width: 446px;
  height: 250px;
  margin-top: 86.5px;
  margin-bottom: 42px;
}
.example-campaign .account-approval-status {
  font-family: SFProDisplay;
  font-weight: bold;
  color: #000000;
  font-size: 16px;
  line-height: 16px;
  margin-bottom: 8px;
}
.example-campaign .approval-status::after {
  content: '';
  margin-left: 3px;
  border-radius: 100%;
  width: 8px;
  height: 8px;
  display: inline-block;
  margin-bottom: 2px;
}
.example-campaign .approval-status.pending,
.example-campaign .approval-status.rejected {
  color: #f6ab00;
}
.example-campaign .approval-status.pending::after,
.example-campaign .approval-status.rejected::after {
  background-color: #f6ab00;
}
.example-campaign .approval-status.approved {
  color: #19d921;
}
.example-campaign .approval-status.approved::after {
  background-color: #19d921;
}
.example-campaign .media {
  margin-top: 133.5px;
}
.example-campaign img {
  width: 411px;
  height: 197px;
  margin-bottom: 37px;
}
.example-campaign h1 {
  font-size: 26px;
  color: #000000;
  padding: 0;
  margin-bottom: 8px;
}
.example-campaign p {
  font-family: SFProDisplay;
  font-weight: normal;
  font-style: normal;
  font-size: 16px;
  color: #000000;
  line-height: 24px;
}
.bulk-actions .trend-btn {
  height: 35px;
}
.bulk-actions .ant-btn.yotpo-btn {
  background: #FFB917;
  border: 0;
  color: #000000;
  font-size: 11px;
  font-family: 'TruenoBold', Arial, sans-serif;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  border-radius: 2px;
  font-family: 'FirmeBlackItalic', Arial, sans-serif !important;
  font-size: 10px !important;
  height: 35px;
  margin-right: 10px;
  background: #1776ec;
  min-width: 153px;
  text-align: left;
  color: #FFFFFF;
}
.bulk-actions .ant-btn.yotpo-btn:hover,
.bulk-actions .ant-btn.yotpo-btn:focus-within {
  background: #ffe8bc;
}
.bulk-actions .ant-btn.yotpo-btn.secondary {
  height: 35px;
  padding-top: 2px;
  background: #f8faff;
  border: 1px solid #dbdfe8;
  box-shadow: none;
  font-size: 9px;
  color: #5B6572;
  letter-spacing: 1px;
}
.bulk-actions .ant-btn.yotpo-btn.secondary:hover,
.bulk-actions .ant-btn.yotpo-btn.secondary:focus-within {
  background: #f0f2f9 !important;
}
.bulk-actions .ant-btn.yotpo-btn.gray {
  background: #3A3A3A;
  color: #FFFFFF !important;
}
@media (min-width: 700px) {
  .bulk-actions .ant-btn.yotpo-btn {
    height: 35px;
  }
}
.bulk-actions .ant-btn.yotpo-btn img {
  position: absolute;
  right: 11px;
  top: 6px;
  object-fit: contain;
  vertical-align: top;
  height: 23px;
  width: 23px;
}
.bulk-actions .ant-btn.yotpo-btn:hover {
  color: #FFFFFF;
  background: #135fbd;
}
#recent-campaign-page h1 {
  margin-top: 38px;
  margin-bottom: 35px;
  font-size: 28px;
}
.campaign-header {
  width: 100%;
  padding: 0 16px;
}
.campaign-header .ant-btn.upgrade {
  font-family: SFProDisplay;
  font-weight: bold;
  text-transform: none;
  font-size: 12px;
  height: 35px;
  line-height: 35px;
  color: #000000;
  background: linear-gradient(265.16deg, #fcd697 0%, #ffebcb 100%);
  border-radius: 23px;
  padding-left: 4px;
  padding-right: 19px;
}
.campaign-header .ant-btn.upgrade span {
  vertical-align: top;
  margin-right: 15px;
  margin-top: 3px;
  display: inline-block;
  background-color: #FFFFFF;
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.15);
  border-radius: 24px;
  width: 72px;
  height: 29px;
  line-height: 28px;
}
.campaign-header .ant-btn.upgrade img.label {
  height: 12.66px;
  width: 27.14px;
  margin-right: 3.1px;
}
.campaign-header .ant-btn.upgrade img.icon {
  width: 16.78px;
  height: 10.24px;
  margin-bottom: -1px;
}
#campaign-page .ant-menu-item:hover,
#campaign-page .ant-menu-item:focus-within {
  border-bottom: 0.25rem solid #FFB917;
}
#campaign-page .campaign-subtabs {
  background: #f8faff;
  border-bottom: 1px solid #e8e8e8;
}
#campaign-page .campaign-subtabs .ant-col {
  width: 100%;
  max-width: 742px;
}
#campaign-page .campaign-subtabs .campaign-subtab {
  font-family: SFProDisplay;
  font-weight: normal;
  font-style: normal;
  background: #f8faff;
  padding-top: 18px;
  height: 88px;
  text-align: center;
}
#campaign-page .campaign-subtabs .campaign-subtab a {
  align-items: center;
  display: flex;
  justify-content: center;
}
#campaign-page .campaign-subtabs .campaign-subtab a:hover .campaign-subtab-icon,
#campaign-page .campaign-subtabs .campaign-subtab a:focus-within .campaign-subtab-icon {
  background-color: #000000;
}
#campaign-page .campaign-subtabs .campaign-subtab.ant-menu-item-selected .campaign-subtab-details {
  color: #000000;
}
#campaign-page .campaign-subtabs .campaign-subtab.ant-menu-item-selected .campaign-subtab-icon {
  background-color: #000000;
}
#campaign-page .campaign-subtabs .campaign-subtab .campaign-subtab-icon {
  background-color: #FFFFFF;
  background-repeat: no-repeat;
  background-position: center;
  transition: all 150ms linear;
}
#campaign-page .campaign-subtabs .campaign-subtab.shipment .campaign-subtab-icon {
  background-image: url(/static/media/ShipmentGrey.1d475795.svg);
}
#campaign-page .campaign-subtabs .campaign-subtab.shipment a:hover .campaign-subtab-icon,
#campaign-page .campaign-subtabs .campaign-subtab.shipment a:focus-within .campaign-subtab-icon {
  background-image: url(/static/media/ShipmentWhite.21461a99.svg);
  background-color: #000000;
}
#campaign-page .campaign-subtabs .campaign-subtab.shipment.ant-menu-item-selected .campaign-subtab-icon {
  background-image: url(/static/media/ShipmentWhite.21461a99.svg);
  background-color: #000000;
}
#campaign-page .campaign-subtabs .campaign-subtab.creators .campaign-subtab-icon,
#campaign-page .campaign-subtabs .campaign-subtab.social .campaign-subtab-icon {
  background-image: url(/static/media/CreatorsGrey.48360eb1.svg);
}
#campaign-page .campaign-subtabs .campaign-subtab.creators a:hover .campaign-subtab-icon,
#campaign-page .campaign-subtabs .campaign-subtab.social a:hover .campaign-subtab-icon,
#campaign-page .campaign-subtabs .campaign-subtab.creators a:focus-within .campaign-subtab-icon,
#campaign-page .campaign-subtabs .campaign-subtab.social a:focus-within .campaign-subtab-icon {
  background-image: url(/static/media/CreatorsWhite.81536ed1.svg);
  background-color: #000000;
}
#campaign-page .campaign-subtabs .campaign-subtab.creators.ant-menu-item-selected .campaign-subtab-icon,
#campaign-page .campaign-subtabs .campaign-subtab.social.ant-menu-item-selected .campaign-subtab-icon {
  background-image: url(/static/media/CreatorsWhite.81536ed1.svg);
}
#campaign-page .campaign-subtabs .campaign-subtab.pending_content .campaign-subtab-icon {
  background-image: url(/static/media/PendingContentGrey.42a790df.svg);
}
#campaign-page .campaign-subtabs .campaign-subtab.pending_content a:hover .campaign-subtab-icon,
#campaign-page .campaign-subtabs .campaign-subtab.pending_content a:focus-within .campaign-subtab-icon {
  background-image: url(/static/media/PendingContenWhite.12d6e78b.svg);
}
#campaign-page .campaign-subtabs .campaign-subtab.pending_content.ant-menu-item-selected .campaign-subtab-icon {
  background-image: url(/static/media/PendingContenWhite.12d6e78b.svg);
}
#campaign-page .campaign-subtabs .campaign-subtab.completed .campaign-subtab-icon {
  background-position-x: 11px;
  background-position-y: 9px;
  background-image: url(/static/media/CompletedGrey.b2f75959.svg);
}
#campaign-page .campaign-subtabs .campaign-subtab.completed a:hover .campaign-subtab-icon,
#campaign-page .campaign-subtabs .campaign-subtab.completed a:focus-within .campaign-subtab-icon {
  background-image: url(/static/media/CompletedWhite.134a17d3.svg);
}
#campaign-page .campaign-subtabs .campaign-subtab.completed.ant-menu-item-selected .campaign-subtab-icon {
  background-image: url(/static/media/CompletedWhite.134a17d3.svg);
}
#campaign-page .campaign-subtabs .campaign-subtab .campaign-subtab-icon {
  border: 1px solid #5b6572;
}
#campaign-page .campaign-subtabs .campaign-subtab.ant-menu-item-selected {
  font-weight: 600;
}
#campaign-page .campaign-subtabs .campaign-subtab.shipment.ant-menu-item-selected .campaign-subtab-icon {
  color: #000000;
}
#campaign-page .campaign-subtabs .campaign-subtab.shipment.ant-menu-item-selected .campaign-subtab-icon .anticon {
  font-size: 51px;
}
#campaign-page .campaign-subtabs .campaign-subtab.shipment.ant-menu-item-selected .campaign-subtab-icon .anticon path {
  stroke: #FFFFFF;
  fill: #000000;
}
#campaign-page .campaign-subtabs .campaign-subtab.social.ant-menu-item-selected .campaign-subtab-icon {
  color: #000000;
}
#campaign-page .campaign-subtabs .campaign-subtab.social.ant-menu-item-selected .campaign-subtab-icon .anticon {
  font-size: 51px;
}
#campaign-page .campaign-subtabs .campaign-subtab.social.ant-menu-item-selected .campaign-subtab-icon .anticon path {
  fill: #FFFFFF;
  stroke: #000000;
}
#campaign-page .campaign-subtabs .campaign-subtab.social.ant-menu-item-selected .campaign-subtab-icon .anticon path.circle {
  fill: #000000;
}
#campaign-page .campaign-subtabs .campaign-subtab.pending_content.ant-menu-item-selected .campaign-subtab-icon {
  color: #000000;
}
#campaign-page .campaign-subtabs .campaign-subtab.pending_content.ant-menu-item-selected .campaign-subtab-icon .anticon {
  font-size: 51px;
}
#campaign-page .campaign-subtabs .campaign-subtab.pending_content.ant-menu-item-selected .campaign-subtab-icon .anticon path.circle {
  fill: #000000;
}
#campaign-page .campaign-subtabs .campaign-subtab.pending_content.ant-menu-item-selected .campaign-subtab-icon .anticon path.line {
  fill: #FFFFFF;
}
#campaign-page .campaign-subtabs .campaign-subtab.creators.ant-menu-item-selected .campaign-subtab-icon {
  color: #000000;
}
#campaign-page .campaign-subtabs .campaign-subtab.creators.ant-menu-item-selected .campaign-subtab-icon .anticon {
  font-size: 51px;
}
#campaign-page .campaign-subtabs .campaign-subtab.creators.ant-menu-item-selected .campaign-subtab-icon .anticon path.circle {
  fill: #000000;
}
#campaign-page .campaign-subtabs .campaign-subtab.creators.ant-menu-item-selected .campaign-subtab-icon .anticon path.line {
  fill: #FFFFFF;
  stroke: #FFFFFF;
}
#campaign-page .campaign-subtabs .campaign-subtab-icon {
  background: #FFFFFF;
  color: #FFFFFF;
  width: 51px;
  height: 51px;
  border-radius: 51px;
  margin-right: 15px;
  display: inline-block;
  position: relative;
}
#campaign-page .campaign-subtabs .campaign-subtab-icon .anticon {
  font-size: 51px;
}
#campaign-page .campaign-subtabs .campaign-subtab-details {
  color: #5b6572;
  display: inline-block;
  text-align: left;
}
#campaign-page .campaign-subtabs .campaign-subtab-details-count {
  font-family: SFProDisplay;
  font-weight: 800;
  font-size: 16px;
  line-height: 16px;
  margin-bottom: 6px;
}
#campaign-page .campaign-subtabs .campaign-subtab-details-label {
  font-size: 9px;
  line-height: 11px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
}
#campaign-page .campaign-subtabs .ant-badge {
  position: absolute;
  top: 0;
  right: 5px;
}
#campaign-page .campaign-subtabs .ant-badge .ant-badge-count {
  border: none;
  color: #000000;
  box-shadow: none;
}
#campaign-page #shipments-content .ant-btn {
  font-family: SFProDisplay;
  font-weight: bold;
  height: 40px;
  width: auto;
  font-size: 12px;
  line-height: 14px;
  padding: 0 16px;
  text-align: center;
  white-space: wrap;
}
#campaign-page #shipments-content .ant-btn.secondary {
  background: #FFFFFF;
}
@media (min-width: 700px) {
  #campaign-page #shipments-content .shipment-list {
    padding: 0;
  }
}
#campaign-page #shipments-content .shipment-list-header {
  margin-top: 54px;
  padding-bottom: 1rem;
}
#campaign-page #shipments-content .shipment-list-header > .ant-row-flex {
  max-width: 742px;
  margin: auto;
  padding: 0 16px;
}
#campaign-page #shipments-content .shipment-list.needsShipping .shipment-list-title {
  color: #d8612e;
}
#campaign-page #shipments-content .shipment-list.shipped .shipment-list-title {
  color: #000000;
}
#campaign-page #shipments-content .shipment-list-title {
  font-family: SFProDisplay;
  font-weight: 800;
  font-size: 16px;
  line-height: 16px;
  margin-bottom: 9px;
}
#campaign-page #shipments-content .shipment-list-description {
  font-family: SFProDisplay;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  width: 100%;
  color: #000000;
  margin: 0;
  padding-right: 16px;
}
#campaign-page #shipments-content .shipment-list-download {
  font-family: SFProDisplay;
  font-weight: bold;
  height: auto;
  max-width: 100%;
  background: #f8faff;
  border: 1px solid #dbdfe8;
  box-sizing: border-box;
  border-radius: 2px;
  font-size: 10px;
  line-height: 12px;
  text-transform: none;
  text-align: center;
  padding: 12px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
}
@media (min-width: 700px) {
  #campaign-page #shipments-content .shipment-list-download {
    height: 40px;
    text-align: left;
    grid-gap: 14px;
    gap: 14px;
    flex-wrap: nowrap;
  }
}
#campaign-page #shipments-content .shipment-list-download .anticon {
  display: inline-block;
  line-height: 16px;
  font-size: 16px;
}
#campaign-page #shipments-content .shipment-list-download span {
  vertical-align: top;
  display: inline-block;
  font-size: 10px;
  line-height: 12px;
  margin: 0;
}
#campaign-page #shipments-content .trend-table .cell-action {
  padding-left: 0;
  border-left: none;
  max-width: 100%;
  grid-gap: 0.5rem;
  gap: 0.5rem;
}
#campaign-page #shipments-content .trend-table .cell-action .ant-btn {
  border-radius: 3px;
  display: inline-block;
  letter-spacing: normal;
  white-space: nowrap;
}
#campaign-page #shipments-content .trend-table .cell-action .rehire-button {
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  vertical-align: top;
  white-space: nowrap;
}
#campaign-page #shipments-content .trend-table .cell-action .rehire-button.awaiting {
  font-size: 11px;
  line-height: 13px;
  border: 1px solid #dbdfe8;
}
#campaign-page #shipments-content .trend-table .cell-action .rehire-button.awaiting span {
  text-align: left;
}
#campaign-page .trend-table .ant-table-thead:before,
#campaign-page .trend-table .ant-table-thead:after {
  top: 27px;
}
#campaign-page .trend-table .ant-table-thead > tr > th {
  font-family: SFProDisplay;
  font-weight: 400;
  font-weight: 600;
  font-size: 9px;
  line-height: 11px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  height: 26px;
  padding: 8px !important;
}
#campaign-page .trend-table .ant-table-thead > tr > th.favorite-creator {
  width: 0;
}
#campaign-page .trend-table .ant-table-tbody > tr > td {
  font-family: SFProDisplay;
  font-weight: 400;
}
#campaign-page .trend-table .influencer-avatar {
  font-family: SFProDisplay;
  font-weight: normal;
  font-style: normal;
  display: flex;
}
#campaign-page .trend-table .cell-action .trend-btn {
  text-transform: none;
}
#campaign-page .favorite-button-container {
  padding-right: 8px;
}
#campaign-page .favorite-button-container .favorite-button {
  background: transparent;
  border: none;
  width: -webkit-min-content;
  width: min-content;
  box-shadow: none;
}
#campaign-page .filter-favorites-button {
  border-radius: 26px;
  background: #ffffff;
  border: 1px solid #a9b0b9;
  border-radius: 22px;
  color: #5b6572;
  font-style: normal;
  height: 30px;
}
#campaign-page .filter-favorites-button.active {
  background: #fff4f4;
  border: 1px solid #ff7474;
  color: #ff7474;
}
#campaign-page .filter-favorites-button span {
  display: flex;
  align-items: center;
}
#campaign-page .filter-favorites-button span #button-text {
  font-family: SFProDisplay;
  font-weight: normal;
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
}
#campaign-page .filter-favorites-button svg {
  width: 12px;
  height: 11.33px;
}
#campaign-page .router-container {
  padding-bottom: 80px;
  background: #ffffff;
}
#campaign-page .router-container.showPullListingBanner {
  padding-bottom: 280px;
}
@media (min-width: 700px) {
  #campaign-page .router-container.showPullListingBanner {
    padding-bottom: 180px;
  }
}
#campaign-page .campaign-footer {
  display: flex;
  flex-wrap: nowrap;
  grid-gap: 1rem;
  gap: 1rem;
  justify-content: space-between;
  margin: 0 auto;
  max-width: 35rem;
}
@media (min-width: 48rem) {
  #campaign-page .campaign-footer {
    max-width: 43.75rem;
  }
}
th.favorite-creator {
  width: 0;
}
.non-shippable-modal {
  border-radius: 10px;
}
.non-shippable-modal .ant-modal-content {
  background: #ffe6de;
  padding: 50px 20px;
  font-family: 'SFProDisplay';
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  /* Konqueror HTML */
  /* Old versions of Firefox */
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
.non-shippable-modal .ant-modal-content .ant-modal-body {
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  flex-direction: column;
}
.non-shippable-modal .ant-modal-content .ant-modal-body h2 {
  font-family: 'SFProDisplay';
  margin-top: 20px;
  text-align: center;
  padding: 0 50px;
  font-size: 24px;
}
.non-shippable-modal .ant-modal-content .ant-modal-body p {
  font-family: 'SFProDisplay';
  text-align: center;
  padding: 0 20px;
  font-size: 16px;
  color: #000000;
  font-weight: 600;
}
.non-shippable-modal .ant-modal-content .ant-modal-footer {
  border-top: none;
  margin-bottom: -20px;
  display: flex;
  justify-content: center;
}
.non-shippable-modal .ant-modal-content .ant-modal-footer button {
  font-family: 'SFProDisplay';
  height: 50px;
  border-radius: 5px;
  font-style: normal;
  line-height: 18px;
  text-align: center;
  border: none;
  border-bottom: 2px solid rgba(0, 0, 0, 0.1);
  border-right: 2px solid rgba(0, 0, 0, 0.1);
  font-weight: 600;
  white-space: normal;
}
.non-shippable-modal .ant-modal-content .ant-modal-footer button:hover {
  opacity: 0.8;
}
.non-shippable-modal .ant-modal-content .ant-modal-footer .non-shippable-confirm-button {
  background: #FFFFFF;
  color: #000000;
}
.non-shippable-modal .ant-modal-content .ant-modal-footer .non-shippable-cancel-button {
  background: #000000;
  color: #FFFFFF;
}
#message-container {
  border-bottom: 1px solid #e8e8e8;
}
.company-page {
  margin: 0 auto;
  max-width: 36.25rem;
  padding: 0 1rem 8rem 1rem;
}
.company-page form {
  align-items: center;
  display: flex;
  flex-direction: column;
  grid-gap: 2rem;
  gap: 2rem;
  max-width: 100%;
}
.company-page .input-wrapper {
  display: flex;
  flex-direction: column;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  width: 100%;
}
.company-page .input-wrapper .error {
  color: #e15674;
}
.company-page label {
  color: #FFFFFF;
  font-size: 1rem;
  margin: 0;
}
.company-page input {
  background-color: transparent;
  border: none;
  border-bottom: 0.0625rem solid #93949f;
  color: #FFFFFF;
  font-size: 1rem;
}
.company-page input:focus {
  outline: none;
}
.company-page input::-webkit-input-placeholder {
  color: #93949f;
}
.company-page input::placeholder {
  color: #93949f;
}
.company-page textarea {
  background-color: transparent;
  border: 0.0625rem solid #93949f;
  border-radius: 0.25rem;
  color: #FFFFFF;
  font-size: 1rem;
}
.company-page textarea::-webkit-input-placeholder {
  color: #93949f;
}
.company-page textarea::placeholder {
  color: #93949f;
}
.company-page .company-page-footer {
  align-items: center;
  background-color: #000000;
  border-top: 0.0625rem solid #272727;
  bottom: 0;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  left: 0;
  padding: 2rem;
  position: fixed;
  right: 0;
  z-index: 1;
}
.company-page .save-changes-btn {
  background-color: #FFB917;
  border-radius: 0.25rem;
  color: #000000;
  font-family: 'SFProDisplay', sans-serif;
  font-size: 1rem;
  font-weight: 600;
  padding: 0.5rem 1rem;
}
.company-page .save-changes-btn:hover:enabled {
  background-color: #ffc84a;
}
.image-uploader {
  align-items: center;
  background-color: #151515;
  border-radius: 0.25rem;
  display: flex;
  height: 20.375rem;
  justify-content: center;
  overflow: hidden;
  position: relative;
}
.image-uploader .uploaded-image {
  bottom: 0;
  height: 100%;
  left: 0;
  margin: auto;
  object-fit: contain;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
}
.image-uploader .change-photo-label {
  align-items: center;
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  bottom: 1rem;
  display: flex;
  justify-content: center;
  position: absolute;
  right: 0;
  width: -webkit-max-content;
  width: max-content;
}
.image-uploader .change-photo-label:hover,
.image-uploader .change-photo-label:focus {
  color: #000000;
}
.image-uploader .change-photo-label:focus-visible {
  outline: revert;
}
.image-uploader .icon {
  background-color: #2f313c;
  border-radius: 0.25rem;
  font-size: 1.375rem;
  padding: 1rem;
}
.image-uploader .uploader-label {
  align-items: center;
  color: #FFFFFF;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  font-size: 1rem;
  height: 100%;
  justify-content: center;
  width: 100%;
}
.ant-avatar > img {
  object-fit: cover;
}
.post-avatar {
  position: relative;
  display: inline-block;
}
.post-avatar .play-button {
  position: absolute;
  z-index: 1;
  font-size: 30px;
  left: 10px;
  top: 10px;
}
.post-avatar .empty-state {
  background: #FFFFFF;
  border: 1px dashed #172337;
  box-sizing: border-box;
}
.post-avatar .story-type {
  color: #000;
  position: absolute;
  padding-top: 2px;
  height: 15px;
  bottom: -4px;
  width: 59px;
  left: -4px;
  text-transform: uppercase;
  font-family: FirmeBlackItalic;
  font-size: 8px;
  font-weight: 900;
  font-style: italic;
  font-stretch: normal;
  line-height: 1.38;
  letter-spacing: 0.4px;
  text-align: center;
  color: #000000;
}
.post-avatar .story-type.type-image {
  background: #ffd982;
}
.post-avatar .story-type.type-video {
  background: #4df1ff;
}
.post-avatar .story-type.type-story {
  background: #66bdff;
}
#all-post-page h1 {
  margin-top: 38px;
  margin-bottom: 35px;
  font-size: 28px;
}
#all-post-page .post-card {
  width: 360px;
  height: auto;
  margin-bottom: 28px;
  background: transparent;
}
#all-post-page .post-card.trend-post-card,
#all-post-page .post-card .trend-post-card-content {
  background: transparent;
}
#all-post-page .post-card.trend-post-card .ant-card-cover,
#all-post-page .post-card .trend-post-card-content .ant-card-cover {
  border-radius: 5px 5px 0px 0px;
  background: transparent;
}
#all-post-page .post-card.trend-post-card .ant-card-cover img,
#all-post-page .post-card .trend-post-card-content .ant-card-cover img {
  border-radius: 5px 5px 0px 0px;
  object-fit: cover;
  height: 307px;
}
#all-post-page .post-card.trend-post-card .ant-card-cover video,
#all-post-page .post-card .trend-post-card-content .ant-card-cover video {
  height: 307px;
  object-fit: cover;
}
#all-post-page .post-card.trend-post-card:hover,
#all-post-page .post-card .trend-post-card-content:hover {
  border-radius: 5px 5px 0px 0px;
  box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.26);
}
#all-post-page .post-card.trend-post-card .ant-card-meta-detail,
#all-post-page .post-card .trend-post-card-content .ant-card-meta-detail {
  width: calc(100% - 60px);
}
#all-post-page .post-card .ant-card-body {
  border: 1px solid #e8e8e8;
  height: auto;
}
#all-post-page .post-card .ant-card-body .ant-avatar {
  width: 50px !important;
  height: 50px !important;
}
#all-post-page .post-card .ant-card-body h2 {
  font-size: 16px !important;
}
#all-post-page .post-card .post-stats {
  margin-top: 19px !important;
}
#all-post-page .post-filters {
  margin-bottom: 49px;
}
#all-post-page .post-filters .ant-checkbox + span {
  vertical-align: top;
  padding-left: 9px;
  line-height: normal;
}
#all-post-page .post-filters .ant-checkbox-wrapper {
  margin-left: 25px !important;
}
#all-post-page .post-filters .trend-select .ant-select-selection__placeholder {
  margin-top: -11px;
}
#all-post-page .post-filters .trend-select .ant-select-selection-selected-value {
  margin-top: -1px;
}
.message-col {
  cursor: pointer;
}
.list-message {
  font-family: SFProDisplay;
  font-weight: normal;
  font-style: normal;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 6;
  display: -webkit-box;
  font-size: 0.6875rem;
  font-weight: 600;
  line-height: 1.3;
  margin-bottom: 0;
  overflow: hidden;
  text-align: left;
  max-width: 13.75rem;
  height: 100%;
}
@media (min-width: 700px) {
  .list-message {
    -webkit-line-clamp: 2;
  }
}
.brand-notifications {
  margin: 0 auto 5.5rem auto;
  max-width: 33rem;
}
@media (min-width: 48rem) {
  .brand-notifications {
    margin-bottom: 7.5rem;
  }
}
.brand-notifications .heading {
  color: #FFFFFF;
  font-family: 'SFProDisplay', sans-serif;
  font-size: 1rem;
  margin: 0 1rem;
}
.brand-notifications .add-email-wrapper {
  background-color: #272727;
  border-radius: 0.375rem;
  display: flex;
  font-family: 'SFProDisplay', sans-serif;
  grid-gap: 0.375rem;
  gap: 0.375rem;
  margin: 0 1rem;
  padding: 0.625rem;
}
.brand-notifications .add-email-wrapper input {
  background-color: transparent;
  border: none;
  color: #FFFFFF;
  flex-grow: 1;
  font-size: 1rem;
}
.brand-notifications .add-email-wrapper input:focus {
  outline: none;
}
.brand-notifications .add-email-wrapper input::-webkit-input-placeholder {
  color: #93949f;
}
.brand-notifications .add-email-wrapper input::placeholder {
  color: #93949f;
}
.brand-notifications .add-email-wrapper button {
  background-color: #FFB917;
  border-radius: 0.375rem;
  color: #000000;
  font-size: 0.75rem;
  font-weight: 700;
  padding: 0.375rem 0.625rem;
}
.brand-notifications .add-email-wrapper button:hover {
  background-color: #ffc84a;
}
.brand-notifications .add-email-wrapper button:active {
  background-color: #FFB917;
}
.brand-notifications .email-list {
  border-bottom: 0.125rem solid #2f313c;
  display: flex;
  flex-wrap: wrap;
  grid-gap: 1rem;
  gap: 1rem;
  justify-content: space-between;
  padding: 2.5rem 1rem;
}
.brand-notifications .primary-email {
  background-color: #272727;
  border-radius: 0.1875rem;
  color: #93949f;
  font-family: 'SFProDisplay', sans-serif;
  font-weight: 700;
  height: -webkit-max-content;
  height: max-content;
  margin: 0;
  padding: 0.625rem;
  text-transform: lowercase;
  width: -webkit-max-content;
  width: max-content;
}
.brand-notifications .secondary-email {
  align-items: center;
  background-color: #FFFFFF;
  border-radius: 0.1875rem;
  color: #000000;
  display: flex;
  font-family: 'SFProDisplay', sans-serif;
  font-weight: 700;
  grid-gap: 1.75rem;
  gap: 1.75rem;
  height: -webkit-max-content;
  height: max-content;
  padding: 0.625rem;
  transition: background-color 0.2s;
  text-transform: lowercase;
  width: -webkit-max-content;
  width: max-content;
}
.brand-notifications .secondary-email p {
  margin: 0;
}
.brand-notifications .secondary-email button {
  padding: 0.5rem;
}
.brand-notifications .secondary-email button:hover {
  background-color: #e6e6e6;
}
.brand-notifications .secondary-email svg {
  -webkit-transform: scale(1.25);
          transform: scale(1.25);
}
.brand-notifications .checkbox-list {
  display: flex;
  flex-direction: column;
  grid-gap: 0.75rem;
  gap: 0.75rem;
  margin: 0;
  padding: 0;
  width: -webkit-max-content;
  width: max-content;
}
.brand-notifications .checkbox-list label {
  align-items: center;
  color: #FFFFFF;
  display: flex;
  font-family: 'SFProDisplay', sans-serif;
  grid-gap: 0.5rem;
  gap: 0.5rem;
}
.brand-notifications footer {
  align-items: center;
  background-color: #000000;
  border-top: 0.0625rem solid #2f313c;
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  padding: 1rem;
  position: fixed;
  width: 100%;
}
@media (min-width: 48rem) {
  .brand-notifications footer {
    padding-bottom: 2rem;
    padding-top: 2rem;
  }
}
.brand-notifications .save-changes-btn {
  background-color: #FFB917;
  border-radius: 0.25rem;
  color: #000000;
  font-family: 'SFProDisplay', sans-serif;
  font-size: 1.25rem;
  font-weight: 600;
  padding: 0.75rem 2rem;
}
.brand-notifications .save-changes-btn:hover:enabled {
  background-color: #ffc84a;
}
.update-email {
  align-items: center;
  display: flex;
  flex-direction: column;
  grid-gap: 1rem;
  gap: 1rem;
  padding: 5rem 1rem 3rem 1rem;
}
@media (min-width: 48rem) {
  .update-email {
    padding-top: 2rem;
  }
}
.update-email h1 {
  color: #FFFFFF;
  font-family: 'SFProDisplay', sans-serif;
  font-size: 1.75rem;
  margin: 0;
}
.update-email h2 {
  color: #FFFFFF;
  font-family: 'SFProDisplay', sans-serif;
  margin: 0;
  padding-bottom: 2rem;
}
.update-email form {
  align-items: center;
  display: flex;
  flex-direction: column;
  grid-gap: 2rem;
  gap: 2rem;
  max-width: 100%;
}
.update-email .input-wrapper {
  display: flex;
  flex-direction: column;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  max-width: 100%;
}
.update-email .input-wrapper span {
  color: #e15674;
}
.update-email label {
  color: #FFFFFF;
  font-size: 1rem;
  margin: 0;
}
.update-email input {
  background-color: transparent;
  border: none;
  border-bottom: 0.0625rem solid #93949f;
  color: #FFFFFF;
  font-size: 1rem;
}
.update-email input:focus {
  outline: none;
}
.update-email input::-webkit-input-placeholder {
  color: #93949f;
}
.update-email input::placeholder {
  color: #93949f;
}
.update-email .save-changes-btn {
  background-color: #FFB917;
  border-radius: 0.25rem;
  color: #000000;
  font-family: 'SFProDisplay', sans-serif;
  font-size: 1rem;
  font-weight: 600;
  padding: 0.5rem 1rem;
}
.update-email .save-changes-btn:hover:enabled {
  background-color: #ffc84a;
}
.update-password {
  align-items: center;
  display: flex;
  flex-direction: column;
  grid-gap: 1rem;
  gap: 1rem;
  padding: 5rem 1rem 3rem 1rem;
}
@media (min-width: 48rem) {
  .update-password {
    padding-top: 2rem;
  }
}
.update-password h1 {
  color: #FFFFFF;
  font-family: 'SFProDisplay', sans-serif;
  font-size: 1.75rem;
  margin: 0;
}
.update-password h2 {
  color: #FFFFFF;
  font-family: 'SFProDisplay', sans-serif;
  margin: 0;
}
.update-password .subtitle {
  color: #FFFFFF;
  margin: 0;
  max-width: 25rem;
  padding-bottom: 2rem;
  text-align: center;
}
.update-password .subtitle a:hover {
  color: #ffc84a;
}
.update-password form {
  align-items: center;
  display: flex;
  flex-direction: column;
  grid-gap: 2rem;
  gap: 2rem;
  max-width: 100%;
}
.update-password .input-wrapper {
  display: flex;
  flex-direction: column;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  max-width: 100%;
}
.update-password .input-wrapper span {
  color: #e15674;
}
.update-password label {
  color: #FFFFFF;
  font-size: 1rem;
  margin: 0;
}
.update-password input {
  background-color: transparent;
  border: none;
  border-bottom: 0.0625rem solid #93949f;
  color: #FFFFFF;
  font-size: 1rem;
}
.update-password input:focus {
  outline: none;
}
.update-password input::-webkit-input-placeholder {
  color: #93949f;
}
.update-password input::placeholder {
  color: #93949f;
}
.update-password .save-changes-btn {
  background-color: #FFB917;
  border-radius: 0.25rem;
  color: #000000;
  font-family: 'SFProDisplay', sans-serif;
  font-size: 1rem;
  font-weight: 600;
  padding: 0.5rem 1rem;
}
.update-password .save-changes-btn:hover:enabled {
  background-color: #ffc84a;
}
#message-page header {
  background-color: #fff;
  border-bottom: 1px solid #d6d6d6;
  height: 100px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1;
  padding: 0 1rem;
}
@media (min-width: 700px) {
  #message-page header {
    width: calc(100% - 210px);
  }
}
#message-page footer {
  background-color: #F1F5FD;
  position: fixed;
  bottom: 0;
  width: 100%;
  border-top: 1px solid #e8e8e8;
  margin: 0;
  padding: 13px 24px 13px 24px;
  z-index: 1;
}
@media (min-width: 700px) {
  #message-page footer {
    width: calc(100% - 210px);
  }
}
#message-page .message-attachment {
  width: 89px;
  height: 49px;
  margin-right: 5px;
  border-radius: 4px;
  position: relative;
}
#message-page .message-attachment-close {
  position: absolute;
  top: -5px;
  right: -4px;
  width: 20px;
  height: 20px;
  padding: 0px;
  background-color: #0a5bfa;
  color: #fff;
  border: none;
}
#message-page .message-attachment-close:hover {
  opacity: 0.7;
}
#message-page .message-attachment-file {
  width: 89px;
  height: 49px;
}
#message-page .message-attachment-file video,
#message-page .message-attachment-file img {
  border-radius: 3px;
  object-fit: contain;
}
#message-page .back {
  font-family: SFProDisplay;
  font-weight: normal;
  font-style: normal;
  color: #000;
  font-weight: bold;
  vertical-align: top;
  display: inline-block;
  margin-top: 9px;
  border: none;
  border-right: 1px solid #d6d6d6;
  margin-right: 21px;
  font-size: 12px;
}
#message-page .influencer-name {
  font-family: SFProDisplay;
  font-weight: normal;
  color: #000;
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 33px;
}
#message-page .influencer-profile {
  line-height: 12px;
}
#message-page .influencer-profile button {
  font-family: SFProDisplay;
  font-weight: normal;
  color: '#ffb917';
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 12px;
  height: 12px;
  padding: 0px;
}
#message-page .rehire-button {
  font-family: SFProDisplay;
  font-weight: normal;
  font-style: normal;
  position: absolute;
  right: 0px;
  font-weight: bold;
  font-size: 11px;
  line-height: 13px;
  text-align: left;
}
#message-page .input-actions {
  position: absolute;
  right: 0;
}
#message-page .input-actions .upload-btn {
  margin-right: 12px;
  display: inline-block;
  vertical-align: top;
  border-left: 1px solid #b2c1d6;
  padding-left: 13px;
}
#message-page .input-actions .trend-btn {
  text-transform: initial;
  vertical-align: top;
}
.text-control {
  word-wrap: break-word;
  word-break: break-word;
  white-space: pre-wrap;
  /* CSS3 */
  white-space: -moz-pre-wrap;
  /* Firefox */
  white-space: -pre-wrap;
  /* Opera <7 */
  white-space: -o-pre-wrap;
  /* Opera 7 */
}
#creator-content-approval-content .submitted-contents {
  padding: 31px 10px 39px;
  background-color: #f1f5fd;
  overflow-x: scroll;
  overflow-y: hidden;
}
#creator-content-approval-content .submitted-contents .empty {
  font-family: SFProDisplay;
  font-style: italic;
  font-weight: 800;
  font-size: 24px;
  line-height: 16px;
  /* identical to box height, or 62% */
  text-align: center;
  text-transform: uppercase;
  margin-top: 35px;
  margin-bottom: 26px;
  color: #dbe3ee;
}
#creator-content-approval-content .submitted-contents .post-card {
  display: inline-block;
  margin-right: 20px;
}
#creator-content-approval-content .creator-content-card {
  float: left;
  margin-right: 20px;
}
#creator-content-approval-content .creator-content-card .video-loader .anticon {
  font-size: 38px;
}
#creator-content-approval-content .trend-table .ant-table-thead th {
  text-align: left !important;
}
.campaign-summary {
  color: #000000;
  max-width: 47rem;
  margin: 0 auto;
}
@media (min-width: 48rem) {
  .campaign-summary {
    margin-top: 1.875rem;
  }
}
.campaign-summary .post-guidelines {
  padding: 1.5rem 1rem;
}
.campaign-summary #image-container {
  width: 100%;
  height: auto;
  overflow: hidden;
  padding-bottom: 1.5rem;
}
.campaign-summary #image-container img {
  max-width: 100%;
  min-width: 100%;
  min-height: 17rem;
  object-fit: cover;
}
.campaign-summary .campaign-summary-wrapper {
  max-width: 100%;
}
@media (min-width: 48rem) {
  .campaign-summary .campaign-summary-wrapper {
    display: flex;
    grid-gap: 2rem;
    gap: 2rem;
  }
  .campaign-summary .campaign-summary-wrapper .left-column {
    width: 66.66667%;
  }
  .campaign-summary .campaign-summary-wrapper .right-column {
    width: 33.33333%;
  }
}
.campaign-summary .video-text {
  font-family: SFProDisplay;
  font-weight: 800;
  font-size: 1rem;
  line-height: 1.375rem;
  margin: 0;
  padding: 1.5rem 1rem;
}
.campaign-summary .header-text {
  font-family: SFProDisplay;
  font-weight: bold;
  font-size: 14px;
  line-height: 12px;
  margin: 0;
}
.campaign-summary .sub-header-text {
  font-family: SFProDisplay;
  font-weight: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 12px;
  margin-bottom: 10px;
  margin-top: 22px;
}
.campaign-summary .sub-header-text.adjust-margin {
  margin-top: 17px;
}
.campaign-summary .text-description {
  font-family: SFProDisplay;
  font-weight: normal;
  font-style: normal;
  font-size: 0.875rem;
  line-height: 1.5rem;
}
.campaign-summary .side-header-text {
  font-family: SFProDisplay;
  font-weight: bold;
  font-size: 13px;
  line-height: 12px;
  margin-bottom: 20px;
}
.campaign-summary .side-sub-header-text {
  font-family: 'SFProDisplay', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  color: #5B6572;
  font-size: 0.5625rem;
  font-weight: 600;
  line-height: 0.6875rem;
  letter-spacing: 0.0625rem;
  text-transform: uppercase;
}
.campaign-summary .side-text-description {
  font-family: SFProDisplay;
  font-weight: bold;
  color: #000000;
  display: block;
  font-size: 0.75rem;
  line-height: 1rem;
  margin-bottom: 1.5rem;
}
.campaign-summary .side-text-description--capitalize {
  text-transform: capitalize;
}
.campaign-summary .side-text-description--link:hover {
  color: #666666;
}
.campaign-summary .side-text-description--link:focus-visible {
  outline: revert;
}
.campaign-summary .side-text-description--platform {
  align-items: center;
  display: flex;
  text-transform: capitalize;
}
.campaign-summary .side-text-description--platform svg path {
  fill: #000000;
}
.campaign-summary #campaignRecentPosts {
  width: 100%;
  padding: 1.5rem 1rem;
}
@media (min-width: 48rem) {
  .campaign-summary #campaignRecentPosts {
    padding-top: 0;
  }
}
.campaign-summary #campaignRecentPosts .ant-col:nth-child(n + 3) {
  margin-top: 13px;
}
.campaign-summary #campaignRecentPosts .latest-content {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
  width: 100%;
}
.campaign-summary #campaignRecentPosts .latest-content a {
  align-items: center;
  color: #000000;
  display: flex;
  font-size: 0.625rem;
  grid-gap: 0.25rem;
  gap: 0.25rem;
}
.campaign-summary #campaignRecentPosts .latest-content a:hover {
  color: #666666;
}
.campaign-summary #campaignRecentPosts .latest-content a:hover .see-all-icon path {
  fill: #666666;
}
.campaign-summary #campaignRecentPosts .latest-content a:focus-visible {
  outline: revert;
}
.campaign-summary .see-all-icon {
  width: 0.75rem;
}
.campaign-summary .see-all-icon path {
  transition: fill 0.3s;
}
.campaign-summary .recent-content {
  display: grid;
  grid-gap: 1rem;
  gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(5rem, 1fr));
}
#campaignMediaCompleted .download-container {
  display: flex;
  justify-content: flex-end;
}
#campaignMediaCompleted .download-container #downloadAllBtn {
  font-family: SFProDisplay !important;
  font-weight: bold !important;
  border-radius: 5px;
  height: 30px;
  font-size: 10px !important;
  letter-spacing: 0px;
  line-height: 12px !important;
  text-transform: capitalize !important;
  padding: 0;
  width: 96px;
}
#campaignMediaCompleted .download-container #downloadAllBtn:focus svg path,
#campaignMediaCompleted .download-container #downloadAllBtn:hover svg path {
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  stroke: #ffcc40;
}
#campaignMediaCompleted .content-gallery {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  margin: auto;
  max-width: 742px;
  padding: 0 8px;
}
@media (min-width: 700px) {
  #campaignMediaCompleted .content-gallery {
    grid-template-columns: repeat(3, 1fr);
  }
}
#campaignMediaCompleted .content-gallery--filters {
  display: flex;
  justify-content: space-between;
  padding: 24px 16px;
  margin: 0 auto;
  max-width: 742px;
  width: 100%;
}
.campaign-posts-page .post-type-filters {
  border-bottom: 1px solid #d6d6d6;
}
.content-showcase-page-header {
  font-family: SFProDisplay;
  font-weight: normal;
  font-style: normal;
  align-items: center;
  background: #fffbef;
  display: flex;
  flex-wrap: wrap-reverse;
  grid-gap: 1rem;
  gap: 1rem;
  justify-content: center;
  padding: 2rem 1rem;
  width: 100%;
}
.content-showcase-page-header .header-content {
  flex: 0 1 25rem;
}
.content-showcase-page-header .header-title {
  font-weight: 700;
  font-size: 1.5625rem;
  line-height: 2rem;
  margin-bottom: 0.75rem;
}
.content-showcase-page-header .header-text {
  color: #000000;
  font-size: 0.875rem;
  line-height: 1.375rem;
  margin-bottom: 0;
}
.content-showcase-page-header .header-inline-link {
  color: #000000;
  text-decoration: underline;
  transition: color 0.2s;
}
.content-showcase-page-header .header-inline-link:hover,
.content-showcase-page-header .header-inline-link:focus {
  color: #666666;
  text-decoration: underline;
}
.content-showcase-page-header .header-inline-link:focus-visible {
  outline: revert !important;
}
.content-showcase-page-header .video-section {
  align-items: center;
  display: flex;
  flex: 0 1 auto;
  flex-direction: column;
  grid-gap: 1rem;
  gap: 1rem;
}
.content-showcase-page-header .trend-video-arrow {
  display: none;
}
.content-showcase-page-header .info-video {
  border: none;
  border-radius: 0.3125rem;
  max-height: 12.5rem;
  max-width: 20rem;
  width: 100%;
}
.content-showcase-page-header .onboarding-link {
  font-family: SFProDisplay;
  font-weight: 800;
  background-color: #FFB917;
  border-radius: 0.125rem;
  color: #000000;
  margin-top: 1rem;
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
  padding: 1rem 0.875rem;
  width: 100%;
}
.content-showcase-page-header .onboarding-link:hover,
.content-showcase-page-header .onboarding-link:focus {
  background-color: #ffc84a;
}
.content-showcase-page-header .onboarding-link:active {
  background-color: #e39f00;
}
@media (min-width: 48rem) {
  .content-showcase-page-header {
    flex-wrap: wrap;
  }
  .content-showcase-page-header .video-section {
    flex-direction: row;
    grid-gap: 0;
    gap: 0;
  }
  .content-showcase-page-header .trend-logo {
    display: none;
  }
  .content-showcase-page-header .trend-video-arrow {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 5.875rem;
    justify-content: center;
    width: 5.75rem;
  }
  .content-showcase-page-header .trend-video-arrow p {
    font-family: 'Syne', sans-serif;
    font-weight: 700;
    font-size: 0.625rem;
    line-height: 0.75rem;
    margin-bottom: -0.5625rem;
    width: 4.375rem;
  }
  .content-showcase-page-header .info-video {
    max-width: 12.5rem;
  }
  .content-showcase-page-header .onboarding-link {
    width: -webkit-max-content;
    width: max-content;
  }
}
.content-showcase-nav {
  align-items: center;
  border-bottom: 0.0625rem solid #D6D6D6;
  display: flex;
  justify-content: center;
  overflow: hidden;
}
.content-showcase-nav ul {
  list-style: none;
  display: flex;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  margin: 0;
  overflow-x: auto;
  padding: 1rem 0.25rem;
}
.content-showcase-nav .nav-button {
  border-radius: 1rem;
  color: #000000;
  font-family: 'Helvetica Neue';
  font-size: 0.75rem;
  line-height: 0.875rem;
  padding: 0.5rem 1rem;
  transition: background-color 0.3s, color 0.2s;
}
.content-showcase-nav .nav-button.selected {
  background: #000000;
  color: #FFFFFF;
}
.content-showcase-nav .nav-button:hover,
.content-showcase-nav .nav-button:focus {
  background: #000000;
  color: #FFFFFF;
}
.main-content-wrapper {
  align-items: center;
  background: #FFFFFF;
  display: flex;
  flex-direction: column;
  grid-gap: 2rem;
  gap: 2rem;
  margin: 0 auto;
  padding: 1rem;
  width: 100%;
}
.main-content-wrapper .content-choice-details {
  font-family: SFProDisplay;
  font-weight: normal;
  font-style: normal;
  color: #000000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 1rem;
}
@media (min-width: 48rem) {
  .main-content-wrapper {
    max-width: 47.75rem;
    padding: 2rem;
  }
}
.content-showcase-section {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 1rem;
  gap: 1rem;
  justify-content: center;
}
.content-showcase-section .content-card {
  display: flex;
  flex: 1 1 30%;
  justify-content: center;
}
.content-showcase-section .video-thumbnail {
  border-radius: 0.75rem;
  position: relative;
}
.content-showcase-section .video-loader {
  display: none;
}
.content-showcase-section .content-card-inner {
  align-items: flex-start;
  border-radius: 0.75rem;
  flex-direction: column;
  grid-gap: 1rem;
  gap: 1rem;
  overflow: hidden;
}
.content-showcase-section .content-card-inner:hover .content-img,
.content-showcase-section .content-card-inner:focus .content-img {
  -webkit-transform: scale(1.07);
          transform: scale(1.07);
}
.content-showcase-section .content-img {
  border-radius: 0.75rem;
  cursor: pointer;
  height: 10.625rem;
  object-fit: cover;
  overflow: hidden;
  transition: -webkit-transform 0.2s;
  transition: transform 0.2s;
  transition: transform 0.2s, -webkit-transform 0.2s;
  width: 13.125rem;
}
.content-showcase-section .creator-figure {
  display: flex;
  align-items: center;
  grid-gap: 0.375rem;
  gap: 0.375rem;
}
.content-showcase-section .creator-figure .creator-avatar {
  border-radius: 50%;
  height: 2rem;
  object-fit: cover;
  width: 2rem;
}
.content-showcase-section .creator-figure .creator-name {
  font-family: SFProDisplay;
  font-weight: normal;
  font-style: normal;
  color: #000000;
  font-weight: 700;
  font-size: 0.75rem;
  margin-bottom: 0;
}
@media (min-width: 48rem) {
  .content-showcase-section {
    grid-gap: 2rem;
    gap: 2rem;
  }
  .content-showcase-section .video-thumbnail {
    display: none;
  }
  .content-showcase-section .video-loader {
    display: block;
    height: 23.25rem;
    width: 13.125rem;
  }
  .content-showcase-section .video-loader .video-player-actions {
    height: 3.75rem;
    left: 50%;
    position: absolute;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    width: 3.75rem;
  }
  .content-showcase-section .video-loader .video-player-actions .anticon {
    font-size: 3.4375rem;
  }
  .content-showcase-section .video-loader video {
    border-radius: 0.75rem;
  }
}
.faq-section {
  font-family: SFProDisplay;
  font-weight: normal;
  font-style: normal;
}
.faq-section .faq-title {
  margin-bottom: 0.875rem;
}
.faq-section ul {
  align-items: stretch;
  display: flex;
  justify-content: center;
  flex-direction: column;
  grid-gap: 0.75rem;
  gap: 0.75rem;
  list-style-type: none;
  margin: 0;
  padding: 0;
  width: 100%;
  flex-grow: 1;
}
.faq-section .faq-card {
  background: #fffbef;
  border-radius: 0.75rem;
  color: #000000;
  font-size: 1rem;
  line-height: 1.25rem;
  width: 100%;
}
.faq-section .faq-card ul {
  text-decoration: none;
}
.faq-section .faq-card .faq-toggle-btn {
  align-items: center;
  display: flex;
  grid-gap: 2rem;
  gap: 2rem;
  justify-content: space-between;
  padding: 1.5rem 2rem;
  text-align: left;
  width: 100%;
}
.faq-section .faq-card .faq-toggle-btn span {
  font-weight: 600;
}
.faq-section .faq-card .faq-toggle-btn .plus-icon {
  -webkit-animation: rotateIconClose 0.2s;
          animation: rotateIconClose 0.2s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  flex-shrink: 0;
}
.faq-section .faq-card .faq-toggle-btn .plus-icon.open {
  -webkit-animation: rotateIconOpen 0.2s;
          animation: rotateIconOpen 0.2s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}
.faq-section .faq-card .answer-drawer {
  display: none;
}
.faq-section .faq-card .answer-drawer.open {
  display: block;
  padding: 0 2rem;
}
@-webkit-keyframes rotateIconOpen {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
  }
}
@keyframes rotateIconOpen {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
  }
}
@-webkit-keyframes rotateIconClose {
  from {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
  }
  to {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
}
@keyframes rotateIconClose {
  from {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
  }
  to {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
}
.content-modal {
  background-color: #000000;
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 90;
}
.content-modal .content-modal-body {
  align-items: center;
  background: #FFFFFF;
  border-radius: 0.75rem;
  display: flex;
  flex-direction: column;
  grid-gap: 1rem;
  gap: 1rem;
  overflow-y: auto;
  padding: 3rem 1rem 1rem 1rem;
  height: 100%;
}
.content-modal h3 {
  font-size: 1.5rem;
  margin: 0;
}
.content-modal .close-btn {
  border-radius: 0.375rem;
  padding: 0.5rem;
  position: absolute;
  right: 1rem;
  top: 1rem;
}
.content-modal .close-btn:hover {
  background-color: #E8E8E8;
}
.content-modal .close-btn:active {
  background-color: transparent;
}
.content-modal .close-btn svg * {
  stroke: #000000;
}
.content-modal .asset-container {
  display: flex;
  justify-content: center;
  max-height: 40rem;
  max-width: 40rem;
}
.content-modal .asset-container img,
.content-modal .asset-container video {
  border-radius: 0.75rem;
  max-height: 100%;
  max-width: 100%;
}
.content-modal .creator-details {
  font-family: SFProDisplay;
  font-weight: normal;
  font-style: normal;
  color: #000000;
  display: flex;
  flex-wrap: wrap;
  grid-gap: 1rem;
  gap: 1rem;
  justify-content: space-between;
  margin: 0 auto;
  max-width: 100%;
  padding-top: 1rem;
  width: -webkit-max-content;
  width: max-content;
}
.content-modal .creator-details button {
  background-color: #FFB917;
  border-radius: 0.25rem;
  font-weight: 700;
  padding: 0.5rem 1rem;
}
.content-modal .creator-details button:hover {
  background-color: #ffc84a;
}
.content-modal .creator-details button:active {
  background-color: #FFB917;
}
.content-modal .creator-container {
  align-items: center;
  display: flex;
  grid-gap: 0.25rem;
  gap: 0.25rem;
}
.content-modal .creator-container img {
  object-fit: cover;
  width: 3.125rem;
  height: 3.125rem;
  border-radius: 50%;
}
.content-modal .creator-container h4 {
  font-weight: 700;
  font-size: 16px;
  margin: 0;
}
.content-modal .creator-container p {
  font-size: 0.75rem;
}
.soona-showcase-page {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}
.soona-showcase-page iframe {
  border: none;
  height: 100%;
  width: 100%;
}
#api-queues-page .ant-table .ant-table-thead th {
  text-align: left;
}
#api-queues-page .ant-table .ant-table-row td {
  height: 50px;
}
#api-queues-page .details {
  padding: 15px 10px 16px;
}
#api-queues-page .details .ant-descriptions-item-content {
  padding-left: 15px !important;
}
#api-queues-page .partnership-header {
  margin: 10px 0 10px;
}
#api-queues-page .filters {
  padding: 10px 0px;
}
#admin-business-approval button.download-csv {
  width: 122px;
  padding-top: 6px;
  padding-bottom: 9px;
  color: #5b6572;
  text-align: left;
  border-radius: 2px;
  border: solid 1px #dbdfe8;
  background-color: #f8faff;
}
#admin-business-approval button.download-csv .anticon {
  font-size: 16px;
}
#admin-business-approval button.download-csv span {
  display: inline-block;
  text-align: left;
  text-transform: uppercase;
  font-family: 'FirmeBlackItalic';
  font-size: 9px;
  font-style: italic;
  line-height: 1.11;
  letter-spacing: 1px;
}
#admin-layout {
  overflow-x: hidden;
}
#admin-layout .search-filters {
  position: relative;
  padding-top: 15px;
  padding-bottom: 16px;
}
#admin-layout .search-filters .trend-select {
  width: 100%;
}
#admin-layout .search-filters .trend-select .ant-select-selection__clear {
  right: 25px;
}
#admin-layout .search-filters .search .ant-select-selection {
  background: transparent;
  position: relative;
}
#admin-layout .search-filters .search .ant-select-selection .ant-select-selection__clear {
  top: 13px;
}
#admin-layout .search-filters .search .anticon {
  font-size: 18px;
  color: #777e9b;
}
#admin-layout .search-filters .search .ant-select-selection__rendered {
  font-size: 17px;
}
#admin-layout .search-filters .search input {
  background: transparent;
  border: none;
  font-family: 'TruenoLight';
  color: #777e9b;
  font-size: 17px;
  padding-left: 14;
  width: calc(100% - 20px);
}
#admin-layout .filters-combo > label {
  padding-left: 1rem;
}
#admin-pending-influencers-approval .search {
  padding: 20px;
  display: flex;
  justify-content: center;
}
#admin-pending-influencers-approval .search .ant-select-selection {
  background: transparent;
  position: relative;
}
#admin-pending-influencers-approval .search .ant-select-selection .ant-select-selection__clear {
  top: 13px;
}
#admin-pending-influencers-approval .search .anticon {
  font-size: 18px;
  color: #777e9b;
}
#admin-pending-influencers-approval .search .ant-select-selection__rendered {
  font-size: 17px;
}
#admin-pending-influencers-approval .search input {
  background: transparent;
  border: none;
  border-bottom: 2px solid #777e9b;
  border-radius: 0;
  font-family: 'TruenoLight';
  color: #777e9b;
  font-size: 17px;
  padding-left: 14;
}
#admin-pending-influencers-approval .search input:hover {
  border-bottom: 2px solid #ffb917;
}
#admin-pending-influencers-approval .bulk-actions {
  display: flex;
  justify-content: left;
}
#admin-pending-influencers-approval .bulk-actions .ant-btn {
  margin-right: 10px;
}
#admin-influencer-page {
  background: #fff;
}
#admin-influencer-page .influencers-stats {
  margin-top: 28px;
  margin-bottom: 24px;
}
#admin-influencer-page .influencers-stats .stats {
  color: #000;
  background: #fff;
  border-radius: 2px;
  border: solid 1px #a9b0b9;
  background-color: #ffffff;
  padding: 8px 15px;
  font-size: 12px;
  line-height: 1.48;
  height: 100%;
}
#admin-influencer-page .influencers-stats .stats .stat-title {
  font-family: 'TruenoBold', Arial, sans-serif;
  text-transform: uppercase;
}
#admin-influencer-page .influencers-stats .stats .stat-count {
  font-family: 'TruenoBold', Arial, sans-serif;
}
#admin-influencer-page .influencers-stats .stats .stat-label {
  font-size: 11px;
}
#admin-influencer-page .search-filters {
  background: #fff;
}
#admin-influencer-page .search-filters .search .ant-select-selection {
  background: transparent;
  position: relative;
}
#admin-influencer-page .search-filters .search .ant-select-selection .ant-select-selection__clear {
  top: 13px;
}
#admin-influencer-page .search-filters .search .anticon {
  font-size: 18px;
  color: #777e9b;
}
#admin-influencer-page .search-filters .search .ant-select-selection__rendered {
  font-size: 17px;
}
#admin-influencer-page .search-filters .search input {
  background: transparent;
  border: none;
  font-family: 'TruenoLight';
  color: #777e9b;
  font-size: 17px;
  padding-left: 14;
  width: calc(100% - 20px);
}
#admin-influencer-page .search-filters:before,
#admin-influencer-page .search-filters:after {
  content: '';
  position: absolute;
  width: 100%;
  height: 63px;
  top: 0px;
  background: #fff;
  z-index: 1;
  overflow: hidden;
}
#admin-influencer-page .search-filters:before {
  left: -100%;
}
#admin-influencer-page .search-filters:after {
  right: -100%;
}
#admin-influencer-page .search-filters button.download-csv {
  width: 122px;
  padding-top: 6px;
  padding-bottom: 9px;
  color: #5b6572;
  text-align: left;
  border-radius: 2px;
  border: solid 1px #dbdfe8;
  background-color: #f8faff;
}
#admin-influencer-page .search-filters button.download-csv .anticon {
  font-size: 16px;
}
#admin-influencer-page .search-filters button.download-csv span {
  display: inline-block;
  text-align: left;
  text-transform: uppercase;
  font-family: 'FirmeBlackItalic';
  font-size: 9px;
  font-style: italic;
  line-height: 1.11;
  letter-spacing: 1px;
}
#admin-influencer-page .ant-table td {
  text-align: right;
}
#admin-influencer-page .ant-table td.username {
  padding: 22px 0 25px;
}
#admin-influencer-page .ant-table td.last-login-date {
  width: 100px;
  padding-right: 25px;
  color: #5b6572;
}
#admin-influencer-page .ant-table td.joined {
  width: calc(34px + 43px);
  color: #000000;
  padding-right: calc(43px / 2);
}
#admin-influencer-page .ant-table td.rating {
  width: calc(34px + (43px + 34px) / 2);
  padding-right: calc((43px + 34px) / 4);
}
#admin-influencer-page .ant-table td.followers {
  width: calc(56px + (34px + 33px) / 2);
  padding-right: calc((34px + 33px) / 4);
}
#admin-influencer-page .ant-table td.apps {
  width: calc(27px + (33px + 30px) / 2);
  padding-right: calc((33px + 30px) / 4);
}
#admin-influencer-page .ant-table td.last-applied-date {
  width: calc(70px + (30px + 29px) / 2);
  padding-right: calc((30px + 29px) / 4);
}
#admin-influencer-page .ant-table td.active {
  width: calc(36px + (29px + 34px) / 2);
  padding-right: calc((29px + 34px) / 4);
}
#admin-influencer-page .ant-table td.complete {
  width: calc(49px + (34px + 38px) / 2);
  padding-right: calc((34px + 38px) / 4);
}
#admin-influencer-page .ant-table td.level {
  text-align: center;
}
#admin-influencer-page .ant-table td.level img {
  width: 34px;
  height: 47px;
}
#admin-influencer-page .ant-table td .active-partnerships,
#admin-influencer-page .ant-table td .completed-partnerships {
  height: 27px;
  width: 27px;
  border-radius: 100%;
  display: inline-block;
  text-align: center;
  line-height: 2;
  font-family: 'TruenoRegular';
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #000000;
}
#admin-influencer-page .ant-table td .active-partnerships {
  border: solid 1px #a9b0b9;
  cursor: pointer;
  background-color: #ffffff;
}
#admin-influencer-page .ant-table td .completed-partnerships {
  border: solid 1px #a9b0b9;
  cursor: pointer;
  background-color: #ffffff;
}
.admin-influencer-active-campaigns {
  width: 197px;
  font-family: 'TruenoRegular';
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: right;
  background: #000;
  padding: 10px 18px 17px 14px;
  border-radius: 2px;
  color: #fff;
}
.admin-influencer-active-campaigns p {
  margin-bottom: 10px;
  cursor: pointer;
}
.admin-influencer-active-campaigns p:hover {
  color: #ffb917;
}
.ant-popover-placement-bottomRight > .ant-popover-content > .ant-popover-arrow,
.ant-popover-placement-topRight > .ant-popover-content > .ant-popover-arrow {
  color: #000 !important;
  border-top-color: #000 !important;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: #000 !important;
  box-shadow: -2px -2px 5px rgba(0, 0, 0, 0.06);
  border-right-color: #000 !important;
  border-bottom-color: #000 !important;
}
#expired-credits-table .ant-table-thead th {
  text-align: center !important;
}
#expired-credits-table .ant-table-thead th:first-child {
  text-align: left !important;
}
#expired-credits-table .ant-table-tbody td {
  text-align: right;
}
#expired-credits-table .ant-table-tbody td:first-child {
  text-align: left;
}
#payouts-page .ant-table .ant-table-thead th {
  text-align: left;
}
#payouts-page .ant-table .ant-table-row td {
  height: 50px;
}
#payouts-page .partnership-details {
  padding: 15px 10px 16px;
}
#payouts-page .partnership-details .ant-descriptions-item-content {
  padding-left: 15px !important;
}
#payouts-page .partnership-header {
  margin: 10px 0 10px;
}
.success-purchase {
  color: #fff;
}
.success-purchase .logo {
  margin-top: 38.37px;
}
.success-purchase .header {
  font-family: SFProDisplay;
  font-weight: 800;
  font-size: 40px;
  line-height: 46px;
  margin-top: 225px;
  text-align: center;
}
.success-purchase .description {
  font-family: SFProDisplay;
  font-weight: normal;
  font-size: 20px;
  line-height: 30px;
  margin-top: 14px;
  text-align: center;
}
.success-purchase .btn-section {
  margin-top: 43px;
}
.success-purchase .btn-section .start-new-campaign-btn.ant-btn,
.success-purchase .btn-section .accept-creators-btn.ant-btn {
  color: #000;
  border-radius: 25px;
  height: 50px;
  font-size: 14px;
  line-height: 18px;
  text-shadow: none;
  font-style: italic;
  padding: 10px 0;
  width: 358px;
  font-family: SFProDisplay;
  font-weight: normal;
  font-style: normal;
}
.success-purchase .btn-section .start-new-campaign-btn.ant-btn span,
.success-purchase .btn-section .accept-creators-btn.ant-btn span {
  font-family: SFProDisplay;
  font-weight: normal;
  font-style: normal;
  font-weight: 800;
  display: inline;
}
.success-purchase .btn-section .accept-creators-btn {
  margin-top: 19px;
  margin-bottom: 50px;
}
.confetti-particles {
  position: fixed;
  top: 0;
}
.confetti-particles .particle {
  position: absolute;
  transition: all 10s ease-out;
}
.m-0 {
  margin: 0 !important;
}
.mt-0 {
  margin-top: 0 !important;
}
.mb-0 {
  margin-bottom: 0 !important;
}
.ml-0 {
  margin-left: 0 !important;
}
.mr-0 {
  margin-right: 0 !important;
}
.mt-1 {
  margin-top: 5px !important;
}
.mb-1 {
  margin-bottom: 5px !important;
}
.ml-1 {
  margin-left: 5px !important;
}
.mr-1 {
  margin-right: 5px !important;
}
.mt-2 {
  margin-top: 10px !important;
}
.mb-2 {
  margin-bottom: 10px !important;
}
.ml-2 {
  margin-left: 10px !important;
}
.mr-2 {
  margin-right: 10px !important;
}
.mt-3 {
  margin-top: 15px !important;
}
.mb-3 {
  margin-bottom: 15px !important;
}
.ml-3 {
  margin-left: 15px !important;
}
.mr-3 {
  margin-right: 15px !important;
}
.mt-4 {
  margin-top: 20px !important;
}
.mb-4 {
  margin-bottom: 20px !important;
}
.ml-4 {
  margin-left: 20px !important;
}
.mr-4 {
  margin-right: 20px !important;
}
.mt-5 {
  margin-top: 25px !important;
}
.mb-5 {
  margin-bottom: 25px !important;
}
.ml-5 {
  margin-left: 25px !important;
}
.mr-5 {
  margin-right: 25px !important;
}
.mt-6 {
  margin-top: 30px !important;
}
.mb-6 {
  margin-bottom: 30px !important;
}
.ml-6 {
  margin-left: 30px !important;
}
.mr-6 {
  margin-right: 30px !important;
}
.mt-7 {
  margin-top: 35px !important;
}
.mb-7 {
  margin-bottom: 35px !important;
}
.ml-7 {
  margin-left: 35px !important;
}
.mr-7 {
  margin-right: 35px !important;
}
.mt-8 {
  margin-top: 40px !important;
}
.mb-8 {
  margin-bottom: 40px !important;
}
.ml-8 {
  margin-left: 40px !important;
}
.mr-8 {
  margin-right: 40px !important;
}
.mt-9 {
  margin-top: 45px !important;
}
.mb-9 {
  margin-bottom: 45px !important;
}
.ml-9 {
  margin-left: 45px !important;
}
.mr-9 {
  margin-right: 45px !important;
}
.pt-0 {
  padding-top: 0 !important;
}
.pb-0 {
  padding-bottom: 0 !important;
}
.pl-0 {
  padding-left: 0 !important;
}
.pr-0 {
  padding-right: 0 !important;
}
.pt-1 {
  padding-top: 5px !important;
}
.pb-1 {
  padding-bottom: 5px !important;
}
.pl-1 {
  padding-left: 5px !important;
}
.pr-1 {
  padding-right: 5px !important;
}
.pt-2 {
  padding-top: 10px !important;
}
.pb-2 {
  padding-bottom: 10px !important;
}
.pl-2 {
  padding-left: 10px !important;
}
.pr-2 {
  padding-right: 10px !important;
}
.pt-3 {
  padding-top: 15px !important;
}
.pb-3 {
  padding-bottom: 15px !important;
}
.pl-3 {
  padding-left: 15px !important;
}
.pr-3 {
  padding-right: 15px !important;
}
.pt-4 {
  padding-top: 20px !important;
}
.pb-4 {
  padding-bottom: 20px !important;
}
.pl-4 {
  padding-left: 20px !important;
}
.pr-4 {
  padding-right: 20px !important;
}
.pt-5 {
  padding-top: 25px !important;
}
.pb-5 {
  padding-bottom: 25px !important;
}
.pl-5 {
  padding-left: 25px !important;
}
.pr-5 {
  padding-right: 25px !important;
}
.pt-6 {
  padding-top: 30px !important;
}
.pb-6 {
  padding-bottom: 30px !important;
}
.pl-6 {
  padding-left: 30px !important;
}
.pr-6 {
  padding-right: 30px !important;
}
.pt-7 {
  padding-top: 35px !important;
}
.pb-7 {
  padding-bottom: 35px !important;
}
.pl-7 {
  padding-left: 35px !important;
}
.pr-7 {
  padding-right: 35px !important;
}
.pt-8 {
  padding-top: 40px !important;
}
.pb-8 {
  padding-bottom: 40px !important;
}
.pl-8 {
  padding-left: 40px !important;
}
.pr-8 {
  padding-right: 40px !important;
}
.content-padding {
  padding-top: 40px;
  padding-bottom: 40px;
}
.brand-image .ant-avatar-image,
.brand-image .ant-avatar {
  border-radius: 0;
  background-color: #000;
  width: 100%;
  height: 200px;
}
.min-layout-content {
  width: 100%;
  padding-left: 39px;
  padding-right: 39px;
}
.d-block {
  display: block;
}
.p-relative {
  position: relative;
}
.p-absolute {
  position: absolute;
}
.f-left {
  float: left;
}
.f-right {
  float: right;
}
.font-face {
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-weight: normal;
  font-style: normal;
  font-family: 'TruenoLight';
  src: url(/static/media/TruenoLt.5258bb66.otf) format('opentype');
}
@font-face {
  font-weight: normal;
  font-style: normal;
  font-family: 'TruenoLightItalic';
  src: url(/static/media/TruenoLtIt.49c656db.otf) format('opentype');
}
@font-face {
  font-weight: normal;
  font-style: normal;
  font-family: 'TruenoRegular';
  src: url(/static/media/TruenoRg.7aba95e5.otf) format('opentype');
}
@font-face {
  font-weight: normal;
  font-style: normal;
  font-family: 'TruenoRegularItalic';
  src: url(/static/media/TruenoRgIt.d1e41148.otf) format('opentype');
}
@font-face {
  font-weight: normal;
  font-style: normal;
  font-family: 'TruenoBold';
  src: url(/static/media/TruenoBd.75e1a3c3.otf) format('opentype');
}
@font-face {
  font-weight: normal;
  font-style: normal;
  font-family: 'TruenoBoldItalic';
  src: url(/static/media/TruenoBdIt.76f5ed5b.otf) format('opentype');
}
@font-face {
  font-weight: normal;
  font-style: normal;
  font-family: 'TruenoSemiBold';
  src: url(/static/media/TruenoSBd.97519f36.otf) format('opentype');
}
@font-face {
  font-weight: normal;
  font-style: normal;
  font-family: 'TruenoExtraBold';
  src: url(/static/media/TruenoExBd.88d5fca3.otf) format('opentype');
}
@font-face {
  font-family: 'FirmeBlackItalic';
  src: url(/static/media/firme-blackitalic-webfont.26fb76f7.woff2) format('woff2'), url(/static/media/firme-blackitalic-webfont.3c6a948d.woff) format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'FirmeMedium';
  src: url(/static/media/firme-medium-webfont.43798026.woff2) format('woff2'), url(/static/media/firme-medium-webfont.ed27d0ab.woff) format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: SFProDisplay;
  src: url(/static/media/FontsFree-Net-SFProDisplay-Black.bc43a493.ttf) format('opentype');
  font-weight: 900;
}
@font-face {
  font-family: SFProDisplay;
  src: url(/static/media/FontsFree-Net-SFProDisplay-Bold.42d79eba.ttf) format('opentype');
  font-weight: bold;
}
@font-face {
  font-family: SFProDisplay;
  src: url(/static/media/FontsFree-Net-SFProDisplay-Heavy-1.28117b03.ttf) format('opentype');
  font-weight: 800;
}
@font-face {
  font-family: SFProDisplay;
  src: url(/static/media/FontsFree-Net-SFProDisplay-HeavyItalic-1.d4bace2f.ttf) format('opentype');
  font-weight: 800;
  font-style: italic;
}
@font-face {
  font-family: SFProDisplay;
  src: url(/static/media/FontsFree-Net-SFProDisplay-Light-1.2cd73177.ttf) format('opentype');
  font-weight: normal;
}
@font-face {
  font-family: SFProDisplay;
  src: url(/static/media/FontsFree-Net-SFProDisplay-MediumItalic.405e7fd8.ttf) format('opentype');
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: SFProDisplay;
  src: url(/static/media/FontsFree-Net-SFProDisplay-BoldItalic.14d487c1.ttf) format('opentype');
  font-weight: bold;
  font-style: italic;
}
@font-face {
  font-family: SFProDisplay;
  src: url(/static/media/FontsFree-Net-SFProDisplay-LightItalic.04f67004.ttf) format('opentype');
  font-weight: normal;
  font-style: italic;
}
.type-light {
  font-family: 'TruenoLight', Arial, sans-serif;
}
.type-ilight {
  font-family: 'TruenoLightItalic', Arial, sans-serif;
}
.type-regular {
  font-family: 'TruenoRegular', Arial, sans-serif;
}
.type-regular-sfpro-display {
  font-family: 'SFProDisplay', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
.type-bold {
  font-family: 'TruenoBold', Arial, sans-serif;
}
.type-bold-sfpro-display {
  font-family: 'SFProDisplay', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: bold;
}
.type-sbold {
  font-family: 'TruenoSemiBold', Arial, sans-serif;
}
.type-ebold {
  font-family: 'TruenoExtraBold', Arial, sans-serif;
}
.type-firme-iblack {
  font-family: 'FirmeBlackItalic', Arial, sans-serif !important;
}
.type-firme-medium {
  font-family: 'FirmeMedium', Arial, sans-serif !important;
}
.type-sfpro-regular {
  font-family: SFProDisplay;
  font-weight: normal;
  font-style: normal;
}
.type-sfpro-medium {
  font-family: SFProDisplay;
  font-weight: normal;
}
.type-sfpro-iregular {
  font-family: SFProDisplay;
  font-weight: normal;
  font-style: italic;
}
.type-sfpro-bold {
  font-family: SFProDisplay;
  font-weight: bold;
}
.type-sfpro-light {
  font-family: SFProDisplay;
  font-weight: 400;
}
.type-sfpro-heavy {
  font-family: SFProDisplay;
  font-weight: 800;
}
.type-sfpro-iheavy {
  font-family: SFProDisplay;
  font-weight: 800;
  font-style: italic;
}
.type-sfpro-thin {
  font-family: SFProDisplay;
  font-weight: 100;
}
.type-sfpro-ibold {
  font-family: SFProDisplay;
  font-weight: bold;
  font-style: italic;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: SFProDisplay !important;
  font-weight: 800 !important;
}
body {
  font-family: 'TruenoRegular', Arial, sans-serif !important;
  font-weight: normal;
  font-style: normal;
}
.text-primary {
  color: #ffb917;
}
.size-8px {
  font-size: 8px !important;
}
.size-9px {
  font-size: 9px !important;
}
.size-10px {
  font-size: 10px !important;
}
.size-11px {
  font-size: 11px !important;
}
.size-12px {
  font-size: 12px !important;
}
.size-13px {
  font-size: 13px !important;
}
.size-14px {
  font-size: 14px !important;
}
.size-15px {
  font-size: 15px !important;
}
.size-16px {
  font-size: 16px !important;
}
.size-17px {
  font-size: 17px !important;
}
.size-18px {
  font-size: 18px !important;
}
.size-20px {
  font-size: 20px !important;
}
.size-40px {
  font-size: 40px !important;
}
.size-21px {
  font-size: 21px !important;
}
.size-22px {
  font-size: 22px !important;
}
.size-24px {
  font-size: 24px !important;
}
.size-25px {
  font-size: 25px !important;
}
.size-28px {
  font-size: 28px !important;
}
.size-31px {
  font-size: 31px !important;
}
.size-32px {
  font-size: 32px !important;
}
.size-34px {
  font-size: 34px !important;
}
.font-weight-400 {
  font-weight: 400;
}
.font-weight-700 {
  font-weight: 700;
}
.font-weight-800 {
  font-weight: 800;
}
.color-yellow-font {
  color: #ffcb6f;
}
.most-popular {
  background: -webkit-linear-gradient(right, #f5cf79, #f7f7f7);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.spacing-05px {
  letter-spacing: 0.5px;
}
.spacing-1px {
  letter-spacing: 1px;
}
.leading-18px {
  line-height: 18px;
}
.leading-24px {
  line-height: 24px;
}
.leading-1 {
  line-height: 1;
}
.leading-23 {
  line-height: 2.33;
}
.uppercase {
  text-transform: uppercase;
}
.clickable {
  cursor: pointer;
}
.text-left {
  text-align: left !important;
}
.text-right {
  text-align: right !important;
}
.text-center {
  text-align: center !important;
}
.text-wrap {
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
}
.label-value {
  font-family: 'truenoextrabold';
  color: #000000;
  font-size: 12px;
}
.text-black {
  color: #000000;
}
.text-black-bis {
  color: #151515;
}
.text-black-ter {
  color: #3A3A3A;
}
.text-gray-darkest {
  color: #747474;
}
.text-gray-darker {
  color: #969696;
}
.text-gray-dark {
  color: #999999;
}
.text-gray {
  color: #A6A6A6;
}
.text-gray-light {
  color: #D8D8D8;
}
.text-gray-lighter {
  color: #D6D6D6;
}
.text-gray-lightest {
  color: #E8E8E8;
}
.text-white {
  color: #FFFFFF;
}
.text-orange {
  color: #FFB917;
}
.text-pigeon-dark {
  color: #5B6572;
}
.text-pigeon-light {
  color: #A9B0B9;
}
.text-pigeon {
  color: #DBDFE8;
}
.bg-black {
  background-color: #000000;
}
.bg-black-bis {
  background-color: #151515;
}
.bg-black-ter {
  background-color: #3A3A3A;
}
.bg-gray-darkest {
  background-color: #747474 !important;
}
.bg-gray-darker {
  background-color: #969696;
}
.bg-gray-dark {
  background-color: #999999;
}
.bg-gray {
  background-color: #A6A6A6;
}
.bg-gray-light {
  background-color: #D8D8D8;
}
.bg-gray-lighter {
  background-color: #D6D6D6;
}
.bg-gray-lightest {
  background-color: #E8E8E8;
}
.bg-white {
  background-color: #FFFFFF;
}
.bg-orange {
  background-color: #FFB917;
}
.bg-black {
  background-color: #000000;
}
.bg-white {
  background-color: #FFFFFF !important;
}
body {
  background-color: #000000 !important;
}
.ant-layout {
  background: #fff !important;
}
.header-offset {
  height: calc(100vh - 4rem);
  margin-top: 4rem;
  position: relative;
}
.header-offset--with-banner {
  height: calc(100vh - 6.5625rem);
  margin-top: 6.5625rem;
}
@media (min-width: 48rem) {
  .header-offset {
    height: 100%;
    margin-top: 0;
  }
  .header-offset--with-banner {
    height: calc(100vh - 2.5625rem);
    margin-top: 2.5625rem;
  }
}
/*
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/
@media (min-width: 1281px) {
  .min-layout-content {
    width: 1080px;
  }
  .small {
    width: 580px;
  }
}
/*
  ##Device = Laptops, Desktops
  ##Screen = B/w 1025px to 1280px
*/
@media (min-width: 1025px) and (max-width: 1280px) {
  .min-layout-content {
    width: 1080px;
  }
  .small {
    width: 580px;
  }
}
/*
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/
@media (min-width: 768px) and (max-width: 1024px) {
  .min-layout-content {
    padding-left: 20px;
    padding-right: 20px;
  }
  .content-padding {
    padding: 15px;
  }
  .small {
    width: 580px;
  }
}
@media (max-width: 992px) {
  .logo {
    display: none;
  }
  .no-padding-mobile {
    padding: 0 !important;
  }
  .campaign-menu,
  .trend-menu-inline {
    text-align: center;
  }
  .ant-menu-item .anticon,
  .ant-menu-submenu-title .anticon {
    margin-right: 0 !important;
  }
  .ant-menu-inline.secondary .ant-menu-item svg,
  .ant-menu-inline.settings svg {
    float: none;
    margin-left: 0;
    margin-right: 0;
  }
  .side-small-title {
    font-size: 6px !important;
  }
  .plan-title {
    font-size: 8px !important;
  }
  .package-title {
    margin: 0 !important;
    font-size: 12px !important;
  }
}
.u-button-reset {
  align-items: center;
  background: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  padding: 0;
  transition: background-color 0.3s;
  width: -webkit-max-content;
  width: max-content;
}
.u-button-reset:focus-within {
  outline: revert !important;
}
.u-button-reset:disabled {
  background-color: #93949f;
  cursor: not-allowed;
}
.u-loading-indicator {
  -webkit-animation: spin 1s ease-in-out infinite;
          animation: spin 1s ease-in-out infinite;
}
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
.u-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
.brand-login-btn {
  border: 0.0625rem solid #D6D6D6;
  border-radius: 0.25rem;
  padding: 0.25rem 0.5rem;
}
.brand-login-btn:hover {
  background: #E8E8E8;
}
.content-preview-btn {
  border-radius: 0.5rem;
  height: 100%;
  overflow: hidden;
  width: 100%;
}
#project-tab {
  font-family: SFProDisplay;
  font-weight: normal;
  font-style: normal;
  padding: 0 0 0 15px;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: -0.004em;
}
#icon-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
#icon-container #icon-text {
  font-family: SFProDisplay;
  font-weight: normal;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  white-space: nowrap;
  color: #fff;
}
.sonar-wrapper {
  position: relative;
  z-index: 0;
  overflow: hidden;
}
.sonar-emitter {
  position: relative;
  margin: 13px 14px;
  width: 29.33px;
  height: 29.33px;
  border-radius: 9999px;
}
.sonar-wave {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 9999px;
  border: 1px #ffb917 solid;
  opacity: 0;
  z-index: -1;
  pointer-events: none;
}
.sonar-wave1 {
  -webkit-animation: sonarWave 2s linear infinite;
          animation: sonarWave 2s linear infinite;
}
.sonar-wave2 {
  -webkit-animation: sonarWave 2s 0.5s linear infinite;
          animation: sonarWave 2s 0.5s linear infinite;
}
.sonar-wave3 {
  -webkit-animation: sonarWave 2s 1s linear infinite;
          animation: sonarWave 2s 1s linear infinite;
}
@-webkit-keyframes sonarWave {
  from {
    -webkit-transform: scale(0.2);
            transform: scale(0.2);
    opacity: 1;
  }
  to {
    -webkit-transform: scale(2);
            transform: scale(2);
    opacity: 0;
  }
}
@keyframes sonarWave {
  from {
    -webkit-transform: scale(0.2);
            transform: scale(0.2);
    opacity: 1;
  }
  to {
    -webkit-transform: scale(2);
            transform: scale(2);
    opacity: 0;
  }
}
@media (max-width: 47.9375rem) {
  .hidden-xmobile {
    display: none !important;
  }
}
/*
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/
@media (min-width: 320px) and (max-width: 480px) {
  .min-layout-content {
    padding-left: 20px;
    padding-right: 20px;
  }
  .content-padding {
    padding: 5px;
  }
  .small {
    width: 100%;
  }
}
.ant-layout-sider .brand-image {
  position: relative;
  height: 182px;
  background: #333;
}
.ant-layout-sider .brand-image .ant-avatar-image {
  height: 100%;
}
.ant-layout-sider .brand-image .ant-avatar-circle {
  background: #333;
  height: 100%;
}
.ant-layout-sider .brand-image .subscription-tag {
  margin-left: 61px;
  min-width: 87px;
  padding-top: 4.7px;
  padding-bottom: 5.5px;
  border-radius: 100px;
  background: #000;
  position: absolute;
  bottom: 15px;
  text-align: center;
}
.ant-layout-sider .brand-image .subscription-tag .name {
  display: inline-block;
  width: 48px;
  margin-bottom: 4px;
  vertical-align: bottom;
}
.ant-layout-sider .brand-image .subscription-tag .icon {
  display: inline-block;
  margin-bottom: 4px;
  width: 14px;
  vertical-align: bottom;
}
.side-menu #archive-link-container {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 10px;
}
.side-menu .menu-title {
  font-family: SFProDisplay;
  font-weight: 800;
  font-size: 12px;
  line-height: 18px;
  color: #fff;
}
.side-menu .new-project {
  border-radius: 50%;
  margin-right: 0.3125rem;
}
.side-menu .new-project rect {
  transition: fill 0.3s;
}
.side-menu .new-project:hover rect,
.side-menu .new-project:focus rect {
  fill: #ffc84a;
}
.side-menu .new-project:focus-within rect {
  fill: #ffc84a;
}
.side-menu .new-project:active rect {
  fill: #e39f00;
}
.side-menu .toggle-projects-btn path {
  transition: fill 0.3s;
}
.side-menu .toggle-projects-btn:hover path,
.side-menu .toggle-projects-btn:focus path {
  fill: #bfc3c7;
}
.side-menu .toggle-projects-btn:focus-within path {
  fill: #bfc3c7;
}
.side-menu .toggle-projects-btn:active path {
  fill: #ffffff;
}
.side-menu .icons .new-project-icon {
  font-size: 20px;
}
.side-menu .icons .cheveron-icon:hover {
  cursor: pointer;
}
.side-menu .icons .cheveron-icon.open {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}
.new-pill {
  padding: 0.125rem 0.5rem;
  border-radius: 1.75rem;
  background-color: #f2b5fa;
  color: #000000;
  font-size: 0.5rem;
  font-weight: 700;
  text-transform: uppercase;
}
.sidebar-link {
  align-items: center;
  display: flex;
  grid-gap: 0.75rem;
  gap: 0.75rem;
  justify-content: space-between;
  padding: 0.5rem 1rem;
  transition: background-color 0.2s;
  width: 100%;
}
.sidebar-link:hover,
.sidebar-link:focus {
  background-color: #2f313c;
}
.sidebar-link:focus-visible {
  background-color: #2f313c;
  outline: revert !important;
  outline-offset: -0.25rem;
}
.sidebar-link--active {
  background-color: #272727;
  border-right: 0.1875rem solid #FFB917;
}
.sidebar-icon-wrapper {
  align-items: center;
  background-color: #000000;
  border: 0.0625rem solid #3A3A3A;
  color: #FFFFFF;
  display: flex;
  height: 2.25rem;
  justify-content: center;
  font-size: 1.25rem;
  width: 2.25rem;
}
.sidebar-icon {
  height: 2.25rem;
  width: 2.25rem;
}
.sidebar-icon rect {
  fill: #000000;
}
.sidebar-icon--account {
  height: 1.5625rem;
  width: 1.5625rem;
}
.sidebar-icon--account > g > g {
  stroke: #FFFFFF;
  fill: #FFFFFF;
}
.sidebar-link-copy {
  font-family: SFProDisplay;
  font-weight: normal;
  font-style: normal;
  color: #FFFFFF;
  flex-grow: 1;
  font-size: 0.75rem;
  font-weight: 600;
}
#admin-layout .ant-layout-content {
  background: #f6f7fb;
}
#admin-layout .page-header {
  width: 100%;
  padding-top: 47px;
  background: #fff;
  border-bottom: 1px solid #e8e8e8;
}
#admin-layout .page-header h1 {
  font-size: 26px;
  font-family: 'TruenoExtraBold';
}
#admin-layout .page-header .ant-menu.ant-menu-horizontal {
  border-bottom: 0px;
}
.firebase-emulator-warning {
  display: none;
}
.standardized-image {
  height: 100%;
  max-height: 90rem;
  max-width: 90rem;
  object-fit: cover;
  width: 100%;
}
.ant-layout-sider-dark .ant-menu-item.ant-menu-item-selected svg .stroke {
  fill: #fff;
}
.ant-layout-sider-dark .ant-menu-item.ant-menu-item-selected svg g[fill-rule='evenodd'] {
  stroke: #fff;
}
.ant-layout-sider-dark .ant-menu-item.ant-menu-item-active svg .stroke {
  fill: #fff;
}
.ant-layout-sider-dark .ant-menu-item.ant-menu-item-active svg g[fill-rule='evenodd'] {
  stroke: #fff;
}

.image-kit-container {
  position: relative;
}
.image-kit-container img {
  position: relative;
}
.image-kit-container img:after {
  content: " ";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
}
.video-player-action-container {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.content-style-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  max-width: 100%;
  width: 100%;
  max-height: 86px;
}
.content-style-container img,
.content-style-container video,
.content-style-container .video-loader {
  max-height: 60px;
  height: 60px;
  max-width: 60px;
  width: 60px;
  background: #000;
  border-radius: 4px;
  object-fit: cover;
}
.content-style-container img:after {
  border-radius: 4px;
}
.content-style-container .video-loader {
  margin-right: 8px;
}
.content-style-container .video-loader .anticon {
  font-size: 60px;
}
.content-style-container svg {
  max-height: 32px;
  max-width: 32px;
}
.content-style-container .video-player-actions {
  z-index: 0;
}

.admin-campaign-email-modal .ant-modal-content {
  width: 584px;
  border-radius: 0px;
  position: relative;
}
.admin-campaign-email-modal .ant-modal-content .ant-modal-body {
  padding: 0;
}
.admin-campaign-email-modal .ant-modal-content .modal-header {
  height: 120px;
  padding: 20px;
  color: #fff;
  background-color: #000;
  position: relative;
}
.admin-campaign-email-modal .ant-modal-content .modal-header .close {
  position: absolute;
  right: 5px;
  top: 20px;
}
.admin-campaign-email-modal .ant-modal-content .modal-header .close .anticon {
  font-size: 22px;
}
.admin-campaign-email-modal .ant-modal-content .modal-header h1 {
  color: #fff;
  font-size: 14px;
  font-family: 'TruenoBold';
  height: 34px;
  margin-bottom: 12px;
}
.admin-campaign-email-modal .ant-modal-content .modal-header .campaign-details {
  font-size: 11px;
}
.admin-campaign-email-modal .ant-modal-content .modal-header .action .ant-btn {
  font-size: 10px;
  font-family: 'FirmeBlackItalic';
  text-transform: uppercase;
  width: 100%;
  color: #000;
}
.admin-campaign-email-modal .ant-modal-content .modal-body .influencer-table {
  overflow-y: auto;
  height: 445px;
  width: 100%;
}
.admin-campaign-email-modal .ant-modal-content .modal-body .ant-table-thead th {
  height: 10px;
  padding: 4px 19px 6px;
  font-size: 8px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  font-family: 'TruenoSemiBold';
  letter-spacing: 0.5px;
  line-height: normal;
  height: 20px;
  color: #5b6572;
  text-transform: uppercase;
  border-color: #ecedf7;
}
.admin-campaign-email-modal .ant-modal-content .modal-body .ant-table-thead th.influencer-details {
  width: 215px;
}
.admin-campaign-email-modal .ant-modal-content .modal-body .ant-table-thead th.product-delivered {
  width: calc(98px + 58px);
  text-align: right;
}
.admin-campaign-email-modal .ant-modal-content .modal-body .ant-table-thead th.due-date {
  width: calc(46px + 89px);
  text-align: right;
}
.admin-campaign-email-modal .ant-modal-content .modal-body .ant-table-row td {
  color: #000;
  padding: 10px 19px 7px;
  font-size: 10px;
  font-family: 'TruenoSemiBold';
}
.admin-campaign-email-modal .ant-modal-content .modal-body .ant-table-row td.influencer-details .wrapper,
.admin-campaign-email-modal .ant-modal-content .modal-body .ant-table-row td.influencer-details .ant-avatar {
  float: left;
}
.admin-campaign-email-modal .ant-modal-content .modal-body .ant-table-row td.influencer-details .influencer-username {
  line-height: 1.8;
}
.admin-campaign-email-modal .ant-modal-content .modal-body .ant-table-row td.influencer-details .post-state.overdue {
  color: #ca1212;
  text-transform: uppercase;
  line-height: 1;
}
.admin-campaign-email-modal .ant-modal-content .modal-body .ant-table-row td.influencer-details .post-state.in-range {
  color: #17c424;
}
.admin-campaign-email-modal .ant-modal-content .modal-body .ant-table-row td.influencer-details .post-state.completed {
  color: #17c424;
}
.admin-campaign-email-modal .ant-modal-content .modal-body .ant-table-row td.product-delivered {
  text-align: right;
}
.admin-campaign-email-modal .ant-modal-content .modal-body .ant-table-row td.due-date {
  text-align: right;
}
.admin-campaign-email-modal .ant-modal-content .modal-body .ant-table-row td.actions .ant-btn {
  width: 64px;
  border-radius: 15px;
  font-size: 9px;
  font-family: 'TruenoRegular';
  padding: 0px;
  text-align: center;
}
.admin-campaign-email-modal .ant-modal-content .modal-body .ant-table-row td.actions .ant-btn.ant-btn-default {
  color: #bcbfdd;
}
.admin-campaign-email-modal .ant-modal-content .modal-body .ant-table-row td.actions .ant-btn.ant-btn-primary {
  color: #000;
}
.admin-campaign-email-modal .ant-modal-content .modal-body .ant-table-row .influencer-data .ant-avatar {
  margin-right: 10px;
}
.admin-campaign-email-modal .ant-modal-content .modal-body .influencer-list .title {
  height: 60px;
  border-bottom: 1px solid #ecedf7;
  margin-top: 19px;
}
.admin-campaign-email-modal .ant-modal-content .modal-body .influencer-list .title .name {
  font-family: 'TruenoBold';
  margin-top: 6px;
  padding-left: 20px;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000;
}
.admin-campaign-email-modal .ant-modal-content .modal-body .influencer-list .title .overdue-count {
  margin-top: 8px;
  padding-right: 20px;
  font-family: 'TruenoRegular';
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #000000;
}
.admin-campaign-email-modal .ant-modal-content .modal-body .influencer-list .search {
  position: relative;
}
.admin-campaign-email-modal .ant-modal-content .modal-body .influencer-list .search .anticon {
  position: absolute;
  left: 0;
  z-index: 2;
  font-size: 18px;
  color: #777e9b;
  top: 19px;
  left: 19px;
}
.admin-campaign-email-modal .ant-modal-content .modal-body .influencer-list .search input {
  height: 55px;
  font-size: 16px;
  padding-left: 46px;
  border-color: #ecedf7;
  border-radius: 0px;
  border-left: 0;
  border-top: 0;
  border-right: 0;
}
.admin-campaign-email-modal .ant-modal-content .modal-footer {
  height: 87px;
}
.admin-campaign-email-modal .ant-modal-content .modal-footer .ant-btn {
  height: 87px;
  border-radius: 0;
}
.admin-compose-email {
  width: 584px;
  border-radius: 0px;
  position: relative;
}
.admin-compose-email .ant-modal-body {
  padding: 0;
}
.admin-compose-email .modal-header {
  background: #000;
  height: 73px;
  padding: 30px 58px 26px;
  color: #fff;
  font-size: 14px;
  font-family: 'TruenoSemiBold';
  line-height: normal;
  position: relative;
}
.admin-compose-email .modal-header .back {
  position: absolute;
  left: 5px;
  top: 22px;
}
.admin-compose-email .modal-header .back .anticon {
  font-size: 18px;
}
.admin-compose-email .modal-header .close {
  position: absolute;
  right: 5px;
  top: 20px;
}
.admin-compose-email .modal-header .close .anticon {
  font-size: 22px;
}
.admin-compose-email .modal-body {
  padding: 16px 35px 0px;
  color: #000;
}
.admin-compose-email .modal-body h1 {
  font-family: 'TruenoExtraBold';
  line-height: 1.33;
  font-size: 18px;
  margin-bottom: 13px;
}
.admin-compose-email .modal-body p {
  font-family: 'TruenoRegular';
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
}
.admin-compose-email .modal-body textarea {
  padding: 16px 17px;
  height: 327px;
  font-family: 'TruenoRegular';
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #5b6572;
}
.admin-compose-email .modal-footer {
  padding: 19px 35px 25px;
}
.admin-compose-email .modal-footer .ant-btn {
  font-family: 'TruenoBold';
  color: #000;
  height: 45px;
  width: 178px;
}

.creator-content-card {
  border-radius: 1rem;
  border: 1px solid #000000;
  width: 210px;
  height: 304px;
  background-color: #fff;
  padding: 8px 12px;
  text-align: center;
}
.creator-content-card .video-image-container {
  height: 14.5rem;
  position: relative;
}
.creator-content-card .video-image-container .vide-image {
  z-index: -1;
  position: absolute;
  width: 100%;
  height: 100%;
}
.creator-content-card .video-image-container .info-container {
  width: 45px;
  height: 18px;
  background-color: #ebd0ff;
  position: absolute;
  top: 8px;
  left: 8px;
  border-radius: 28px;
  text-align: center;
  border: 1px solid #000000;
}
.creator-content-card .video-image-container .info-container .info-title {
  font-family: 'SFProDisplay';
  font-size: 9px;
  font-weight: 700;
  font-style: normal;
  color: #000000;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.creator-content-card .video-image-container .btn-group {
  position: absolute;
  bottom: 10px;
  right: 10px;
  border-radius: 6px;
  width: 3.875rem;
  height: 1.875rem;
  background-color: #bde5cc;
  color: #000000;
  font-family: 'SFProDisplay';
  font-size: 0.625rem;
  font-weight: 600;
  line-height: 0.813rem;
  letter-spacing: 0px;
  text-align: center;
  z-index: 1;
  padding: 0;
  border: 1px solid #000000;
}
.creator-content-card .video-image-container .icon-style {
  margin-left: 0.2rem;
}
.creator-content-card .video-loader img {
  width: 100%;
  height: 232px;
  background-color: #eee;
  border-radius: 0.563rem;
  object-fit: cover;
}
.creator-content-card .video-loader video {
  width: 100%;
  height: 232px;
  background-color: #000;
  border-radius: 0.563rem;
  object-fit: fit;
}
.creator-content-card .card-details {
  display: flex;
  align-items: center;
  color: #000000;
  padding-top: 12px;
}
.creator-content-card .card-details img {
  margin-right: 8px;
}
.creator-content-card .card-details .card-username {
  margin-left: 0.4rem;
  color: #000000;
  float: left;
  font-size: 0.75rem;
  line-height: 1.125rem;
  font-family: 'SFProDisplay';
  font-style: normal;
  font-weight: 600;
  display: flex;
  flex-direction: column;
  text-align: left;
}
.creator-content-card .card-details .card-username .approvals-countdown {
  font-weight: normal;
}
.creator-content-card .card-details .card-stats {
  font-size: 0.75rem;
  line-height: 1.125rem;
  margin-left: 0.2rem;
  float: left;
  font-family: 'SFProDisplay';
  font-style: normal;
  font-weight: 400;
}

.creator-profile-drawer {
  display: none;
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  max-height: 100%;
  z-index: 51;
  width: calc(100vw - 210px);
  background: #f4f4f4;
  align-items: center;
  justify-content: center;
}
.creator-profile-drawer.show {
  display: flex;
}
.creator-profile-drawer > div {
  position: absolute;
}
.creator-profile-drawer iframe {
  height: 100%;
  border: none;
  width: calc(100vw - 210px);
}

.text-black {
  color: #000000;
}
.text-black-bis {
  color: #151515;
}
.text-black-ter {
  color: #3A3A3A;
}
.text-gray-darkest {
  color: #747474;
}
.text-gray-darker {
  color: #969696;
}
.text-gray-dark {
  color: #999999;
}
.text-gray {
  color: #A6A6A6;
}
.text-gray-light {
  color: #D8D8D8;
}
.text-gray-lighter {
  color: #D6D6D6;
}
.text-gray-lightest {
  color: #E8E8E8;
}
.text-white {
  color: #FFFFFF;
}
.text-orange {
  color: #FFB917;
}
.text-pigeon-dark {
  color: #5B6572;
}
.text-pigeon-light {
  color: #A9B0B9;
}
.text-pigeon {
  color: #DBDFE8;
}
.bg-black {
  background-color: #000000;
}
.bg-black-bis {
  background-color: #151515;
}
.bg-black-ter {
  background-color: #3A3A3A;
}
.bg-gray-darkest {
  background-color: #747474 !important;
}
.bg-gray-darker {
  background-color: #969696;
}
.bg-gray-dark {
  background-color: #999999;
}
.bg-gray {
  background-color: #A6A6A6;
}
.bg-gray-light {
  background-color: #D8D8D8;
}
.bg-gray-lighter {
  background-color: #D6D6D6;
}
.bg-gray-lightest {
  background-color: #E8E8E8;
}
.bg-white {
  background-color: #FFFFFF;
}
.bg-orange {
  background-color: #FFB917;
}
.approval-table {
  display: flex;
  flex-direction: column;
  min-width: 100%;
  font-family: 'SFProDisplay';
}
.header-width-container {
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 742px;
  height: 26px;
  text-transform: uppercase;
}
.approval-table-header {
  background: #f1f5fd;
  min-width: 100%;
  padding: 0 16px;
}
.approval-table-header p {
  font-family: 'SFProDisplay';
  font-style: normal;
  font-weight: 600;
  font-size: 9px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: #5b6572;
  margin-bottom: 0;
}
.creator-name-column,
.required-content-column,
.action-column {
  display: flex;
  align-items: center;
  height: -webkit-max-content;
  height: max-content;
}
.creator-name-column.hidden-xs,
.required-content-column.hidden-xs,
.action-column.hidden-xs {
  display: none;
}
@media (min-width: 700px) {
  .creator-name-column.hidden-xs,
  .required-content-column.hidden-xs,
  .action-column.hidden-xs {
    display: flex;
  }
}
.creator-name-column {
  min-width: 40%;
  max-width: 40%;
  cursor: pointer;
}
.creator-name-column .influencer-avatar-image {
  margin-right: 0 !important;
}
.required-content-column {
  min-width: 42%;
  max-width: 42%;
}
.required-content-column .required-content-style {
  display: flex;
  flex-direction: column;
  max-width: 100%;
}
.required-content-column .required-content-style p {
  font-family: 'SFProDisplay';
  margin-bottom: 8px;
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
}
.required-content-column .required-content-style .content-preview-row {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  grid-gap: 5px;
  gap: 5px;
}
.required-content-column .required-content-style .required-content-preview {
  background: #FFFFFF;
  border: 0.0625rem solid #000000;
  border-radius: 0.5rem;
  height: 2.5rem;
  overflow: hidden;
  width: 2.5rem;
}
.required-content-column .required-content-style .required-content-preview img,
.required-content-column .required-content-style .required-content-preview video {
  object-fit: contain;
}
.required-content-column .required-content-style .required-content-preview svg {
  display: none;
}
.required-content-column .required-content-style .additional-previews {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.required-content-column .required-content-style .additional-previews p {
  margin: 0;
  font-weight: 600;
  font-size: 10px;
  line-height: 13px;
  color: #000000;
}
.content-table-action-column .action-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 62px;
  background: #000000;
  border: 1px solid #000000;
  border-radius: 6px;
  position: relative;
  cursor: pointer;
}
.content-table-action-column .action-card p {
  font-family: 'SFProDisplay';
  margin: 0;
}
.content-table-action-column .action-card .info-container {
  background-color: #ebd0ff;
  position: absolute;
  top: -16px;
  right: -5px;
  border-radius: 28px;
  text-align: center;
  border: 1px solid #000000;
  padding: 0.125rem 0.25rem;
}
@media (min-width: 700px) {
  .content-table-action-column .action-card .info-container {
    width: 45px;
    height: 18px;
    top: -9px;
    right: 12px;
    padding: 0;
  }
}
.content-table-action-column .action-card .info-container .info-title {
  font-family: 'SFProDisplay';
  font-size: 9px;
  font-weight: 700;
  font-style: normal;
  color: #000000;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.content-table-action-column .action-card .review-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #bde5cc;
  width: 100%;
  height: 40px;
  border-radius: 6px;
  padding: 0 2px;
}
.content-table-action-column .action-card .review-content p {
  font-family: 'SFProDisplay';
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  color: #000000;
}
.content-table-action-column .action-card .review-content img {
  height: 12px;
}
@media (min-width: 700px) {
  .content-table-action-column .action-card .review-content {
    padding: 8px 10px;
  }
}
.content-table-action-column .action-card .content-approved-count {
  padding: 8px 10px;
}
.content-table-action-column .action-card .content-approved-count p {
  font-family: 'SFProDisplay';
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  color: #ffffff;
}
.approval-table-body {
  display: flex;
  flex-direction: column;
  min-width: 100%;
  background: #ffffff;
}
.approval-table-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  min-width: 100%;
}
.row-width-container {
  margin-left: auto;
  margin-right: auto;
  padding: 0 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 742px;
  height: 104px;
}

.text-black {
  color: #000000;
}
.text-black-bis {
  color: #151515;
}
.text-black-ter {
  color: #3A3A3A;
}
.text-gray-darkest {
  color: #747474;
}
.text-gray-darker {
  color: #969696;
}
.text-gray-dark {
  color: #999999;
}
.text-gray {
  color: #A6A6A6;
}
.text-gray-light {
  color: #D8D8D8;
}
.text-gray-lighter {
  color: #D6D6D6;
}
.text-gray-lightest {
  color: #E8E8E8;
}
.text-white {
  color: #FFFFFF;
}
.text-orange {
  color: #FFB917;
}
.text-pigeon-dark {
  color: #5B6572;
}
.text-pigeon-light {
  color: #A9B0B9;
}
.text-pigeon {
  color: #DBDFE8;
}
.bg-black {
  background-color: #000000;
}
.bg-black-bis {
  background-color: #151515;
}
.bg-black-ter {
  background-color: #3A3A3A;
}
.bg-gray-darkest {
  background-color: #747474 !important;
}
.bg-gray-darker {
  background-color: #969696;
}
.bg-gray-dark {
  background-color: #999999;
}
.bg-gray {
  background-color: #A6A6A6;
}
.bg-gray-light {
  background-color: #D8D8D8;
}
.bg-gray-lighter {
  background-color: #D6D6D6;
}
.bg-gray-lightest {
  background-color: #E8E8E8;
}
.bg-white {
  background-color: #FFFFFF;
}
.bg-orange {
  background-color: #FFB917;
}
.font-face {
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-weight: normal;
  font-style: normal;
  font-family: 'TruenoLight';
  src: url(/static/media/TruenoLt.5258bb66.otf) format('opentype');
}
@font-face {
  font-weight: normal;
  font-style: normal;
  font-family: 'TruenoLightItalic';
  src: url(/static/media/TruenoLtIt.49c656db.otf) format('opentype');
}
@font-face {
  font-weight: normal;
  font-style: normal;
  font-family: 'TruenoRegular';
  src: url(/static/media/TruenoRg.7aba95e5.otf) format('opentype');
}
@font-face {
  font-weight: normal;
  font-style: normal;
  font-family: 'TruenoRegularItalic';
  src: url(/static/media/TruenoRgIt.d1e41148.otf) format('opentype');
}
@font-face {
  font-weight: normal;
  font-style: normal;
  font-family: 'TruenoBold';
  src: url(/static/media/TruenoBd.75e1a3c3.otf) format('opentype');
}
@font-face {
  font-weight: normal;
  font-style: normal;
  font-family: 'TruenoBoldItalic';
  src: url(/static/media/TruenoBdIt.76f5ed5b.otf) format('opentype');
}
@font-face {
  font-weight: normal;
  font-style: normal;
  font-family: 'TruenoSemiBold';
  src: url(/static/media/TruenoSBd.97519f36.otf) format('opentype');
}
@font-face {
  font-weight: normal;
  font-style: normal;
  font-family: 'TruenoExtraBold';
  src: url(/static/media/TruenoExBd.88d5fca3.otf) format('opentype');
}
@font-face {
  font-family: 'FirmeBlackItalic';
  src: url(/static/media/firme-blackitalic-webfont.26fb76f7.woff2) format('woff2'), url(/static/media/firme-blackitalic-webfont.3c6a948d.woff) format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'FirmeMedium';
  src: url(/static/media/firme-medium-webfont.43798026.woff2) format('woff2'), url(/static/media/firme-medium-webfont.ed27d0ab.woff) format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: SFProDisplay;
  src: url(/static/media/FontsFree-Net-SFProDisplay-Black.bc43a493.ttf) format('opentype');
  font-weight: 900;
}
@font-face {
  font-family: SFProDisplay;
  src: url(/static/media/FontsFree-Net-SFProDisplay-Bold.42d79eba.ttf) format('opentype');
  font-weight: bold;
}
@font-face {
  font-family: SFProDisplay;
  src: url(/static/media/FontsFree-Net-SFProDisplay-Heavy-1.28117b03.ttf) format('opentype');
  font-weight: 800;
}
@font-face {
  font-family: SFProDisplay;
  src: url(/static/media/FontsFree-Net-SFProDisplay-HeavyItalic-1.d4bace2f.ttf) format('opentype');
  font-weight: 800;
  font-style: italic;
}
@font-face {
  font-family: SFProDisplay;
  src: url(/static/media/FontsFree-Net-SFProDisplay-Light-1.2cd73177.ttf) format('opentype');
  font-weight: normal;
}
@font-face {
  font-family: SFProDisplay;
  src: url(/static/media/FontsFree-Net-SFProDisplay-MediumItalic.405e7fd8.ttf) format('opentype');
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: SFProDisplay;
  src: url(/static/media/FontsFree-Net-SFProDisplay-BoldItalic.14d487c1.ttf) format('opentype');
  font-weight: bold;
  font-style: italic;
}
@font-face {
  font-family: SFProDisplay;
  src: url(/static/media/FontsFree-Net-SFProDisplay-LightItalic.04f67004.ttf) format('opentype');
  font-weight: normal;
  font-style: italic;
}
.type-light {
  font-family: 'TruenoLight', Arial, sans-serif;
}
.type-ilight {
  font-family: 'TruenoLightItalic', Arial, sans-serif;
}
.type-regular {
  font-family: 'TruenoRegular', Arial, sans-serif;
}
.type-regular-sfpro-display {
  font-family: 'SFProDisplay', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
.type-bold {
  font-family: 'TruenoBold', Arial, sans-serif;
}
.type-bold-sfpro-display {
  font-family: 'SFProDisplay', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: bold;
}
.type-sbold {
  font-family: 'TruenoSemiBold', Arial, sans-serif;
}
.type-ebold {
  font-family: 'TruenoExtraBold', Arial, sans-serif;
}
.type-firme-iblack {
  font-family: 'FirmeBlackItalic', Arial, sans-serif !important;
}
.type-firme-medium {
  font-family: 'FirmeMedium', Arial, sans-serif !important;
}
.type-sfpro-regular {
  font-family: SFProDisplay;
  font-weight: normal;
  font-style: normal;
}
.type-sfpro-medium {
  font-family: SFProDisplay;
  font-weight: normal;
}
.type-sfpro-iregular {
  font-family: SFProDisplay;
  font-weight: normal;
  font-style: italic;
}
.type-sfpro-bold {
  font-family: SFProDisplay;
  font-weight: bold;
}
.type-sfpro-light {
  font-family: SFProDisplay;
  font-weight: 400;
}
.type-sfpro-heavy {
  font-family: SFProDisplay;
  font-weight: 800;
}
.type-sfpro-iheavy {
  font-family: SFProDisplay;
  font-weight: 800;
  font-style: italic;
}
.type-sfpro-thin {
  font-family: SFProDisplay;
  font-weight: 100;
}
.type-sfpro-ibold {
  font-family: SFProDisplay;
  font-weight: bold;
  font-style: italic;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: SFProDisplay !important;
  font-weight: 800 !important;
}
body {
  font-family: 'TruenoRegular', Arial, sans-serif !important;
  font-weight: normal;
  font-style: normal;
}
.text-primary {
  color: #ffb917;
}
.size-8px {
  font-size: 8px !important;
}
.size-9px {
  font-size: 9px !important;
}
.size-10px {
  font-size: 10px !important;
}
.size-11px {
  font-size: 11px !important;
}
.size-12px {
  font-size: 12px !important;
}
.size-13px {
  font-size: 13px !important;
}
.size-14px {
  font-size: 14px !important;
}
.size-15px {
  font-size: 15px !important;
}
.size-16px {
  font-size: 16px !important;
}
.size-17px {
  font-size: 17px !important;
}
.size-18px {
  font-size: 18px !important;
}
.size-20px {
  font-size: 20px !important;
}
.size-40px {
  font-size: 40px !important;
}
.size-21px {
  font-size: 21px !important;
}
.size-22px {
  font-size: 22px !important;
}
.size-24px {
  font-size: 24px !important;
}
.size-25px {
  font-size: 25px !important;
}
.size-28px {
  font-size: 28px !important;
}
.size-31px {
  font-size: 31px !important;
}
.size-32px {
  font-size: 32px !important;
}
.size-34px {
  font-size: 34px !important;
}
.font-weight-400 {
  font-weight: 400;
}
.font-weight-700 {
  font-weight: 700;
}
.font-weight-800 {
  font-weight: 800;
}
.color-yellow-font {
  color: #ffcb6f;
}
.most-popular {
  background: -webkit-linear-gradient(right, #f5cf79, #f7f7f7);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.spacing-05px {
  letter-spacing: 0.5px;
}
.spacing-1px {
  letter-spacing: 1px;
}
.leading-18px {
  line-height: 18px;
}
.leading-24px {
  line-height: 24px;
}
.leading-1 {
  line-height: 1;
}
.leading-23 {
  line-height: 2.33;
}
.uppercase {
  text-transform: uppercase;
}
.clickable {
  cursor: pointer;
}
.text-left {
  text-align: left !important;
}
.text-right {
  text-align: right !important;
}
.text-center {
  text-align: center !important;
}
.text-wrap {
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
}
.label-value {
  font-family: 'truenoextrabold';
  color: #000000;
  font-size: 12px;
}
.pt-0 {
  padding-top: 0 !important;
}
.pb-0 {
  padding-bottom: 0 !important;
}
.pl-0 {
  padding-left: 0 !important;
}
.pr-0 {
  padding-right: 0 !important;
}
.pt-1 {
  padding-top: 5px !important;
}
.pb-1 {
  padding-bottom: 5px !important;
}
.pl-1 {
  padding-left: 5px !important;
}
.pr-1 {
  padding-right: 5px !important;
}
.pt-2 {
  padding-top: 10px !important;
}
.pb-2 {
  padding-bottom: 10px !important;
}
.pl-2 {
  padding-left: 10px !important;
}
.pr-2 {
  padding-right: 10px !important;
}
.pt-3 {
  padding-top: 15px !important;
}
.pb-3 {
  padding-bottom: 15px !important;
}
.pl-3 {
  padding-left: 15px !important;
}
.pr-3 {
  padding-right: 15px !important;
}
.pt-4 {
  padding-top: 20px !important;
}
.pb-4 {
  padding-bottom: 20px !important;
}
.pl-4 {
  padding-left: 20px !important;
}
.pr-4 {
  padding-right: 20px !important;
}
.pt-5 {
  padding-top: 25px !important;
}
.pb-5 {
  padding-bottom: 25px !important;
}
.pl-5 {
  padding-left: 25px !important;
}
.pr-5 {
  padding-right: 25px !important;
}
.pt-6 {
  padding-top: 30px !important;
}
.pb-6 {
  padding-bottom: 30px !important;
}
.pl-6 {
  padding-left: 30px !important;
}
.pr-6 {
  padding-right: 30px !important;
}
.pt-7 {
  padding-top: 35px !important;
}
.pb-7 {
  padding-bottom: 35px !important;
}
.pl-7 {
  padding-left: 35px !important;
}
.pr-7 {
  padding-right: 35px !important;
}
.pt-8 {
  padding-top: 40px !important;
}
.pb-8 {
  padding-bottom: 40px !important;
}
.pl-8 {
  padding-left: 40px !important;
}
.pr-8 {
  padding-right: 40px !important;
}
.content-padding {
  padding-top: 40px;
  padding-bottom: 40px;
}
.brand-image .ant-avatar-image,
.brand-image .ant-avatar {
  border-radius: 0;
  background-color: #000;
  width: 100%;
  height: 200px;
}
.min-layout-content {
  width: 100%;
  padding-left: 39px;
  padding-right: 39px;
}
.d-block {
  display: block;
}
.p-relative {
  position: relative;
}
.p-absolute {
  position: absolute;
}
.f-left {
  float: left;
}
.f-right {
  float: right;
}
.m-0 {
  margin: 0 !important;
}
.mt-0 {
  margin-top: 0 !important;
}
.mb-0 {
  margin-bottom: 0 !important;
}
.ml-0 {
  margin-left: 0 !important;
}
.mr-0 {
  margin-right: 0 !important;
}
.mt-1 {
  margin-top: 5px !important;
}
.mb-1 {
  margin-bottom: 5px !important;
}
.ml-1 {
  margin-left: 5px !important;
}
.mr-1 {
  margin-right: 5px !important;
}
.mt-2 {
  margin-top: 10px !important;
}
.mb-2 {
  margin-bottom: 10px !important;
}
.ml-2 {
  margin-left: 10px !important;
}
.mr-2 {
  margin-right: 10px !important;
}
.mt-3 {
  margin-top: 15px !important;
}
.mb-3 {
  margin-bottom: 15px !important;
}
.ml-3 {
  margin-left: 15px !important;
}
.mr-3 {
  margin-right: 15px !important;
}
.mt-4 {
  margin-top: 20px !important;
}
.mb-4 {
  margin-bottom: 20px !important;
}
.ml-4 {
  margin-left: 20px !important;
}
.mr-4 {
  margin-right: 20px !important;
}
.mt-5 {
  margin-top: 25px !important;
}
.mb-5 {
  margin-bottom: 25px !important;
}
.ml-5 {
  margin-left: 25px !important;
}
.mr-5 {
  margin-right: 25px !important;
}
.mt-6 {
  margin-top: 30px !important;
}
.mb-6 {
  margin-bottom: 30px !important;
}
.ml-6 {
  margin-left: 30px !important;
}
.mr-6 {
  margin-right: 30px !important;
}
.mt-7 {
  margin-top: 35px !important;
}
.mb-7 {
  margin-bottom: 35px !important;
}
.ml-7 {
  margin-left: 35px !important;
}
.mr-7 {
  margin-right: 35px !important;
}
.mt-8 {
  margin-top: 40px !important;
}
.mb-8 {
  margin-bottom: 40px !important;
}
.ml-8 {
  margin-left: 40px !important;
}
.mr-8 {
  margin-right: 40px !important;
}
.mt-9 {
  margin-top: 45px !important;
}
.mb-9 {
  margin-bottom: 45px !important;
}
.ml-9 {
  margin-left: 45px !important;
}
.mr-9 {
  margin-right: 45px !important;
}
.header-offset {
  height: calc(100vh - 4rem);
  margin-top: 4rem;
  position: relative;
}
.header-offset--with-banner {
  height: calc(100vh - 6.5625rem);
  margin-top: 6.5625rem;
}
@media (min-width: 48rem) {
  .header-offset {
    height: 100%;
    margin-top: 0;
  }
  .header-offset--with-banner {
    height: calc(100vh - 2.5625rem);
    margin-top: 2.5625rem;
  }
}
/*
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/
@media (min-width: 1281px) {
  .min-layout-content {
    width: 1080px;
  }
  .small {
    width: 580px;
  }
}
/*
  ##Device = Laptops, Desktops
  ##Screen = B/w 1025px to 1280px
*/
@media (min-width: 1025px) and (max-width: 1280px) {
  .min-layout-content {
    width: 1080px;
  }
  .small {
    width: 580px;
  }
}
/*
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/
@media (min-width: 768px) and (max-width: 1024px) {
  .min-layout-content {
    padding-left: 20px;
    padding-right: 20px;
  }
  .content-padding {
    padding: 15px;
  }
  .small {
    width: 580px;
  }
}
@media (max-width: 992px) {
  .logo {
    display: none;
  }
  .no-padding-mobile {
    padding: 0 !important;
  }
  .campaign-menu,
  .trend-menu-inline {
    text-align: center;
  }
  .ant-menu-item .anticon,
  .ant-menu-submenu-title .anticon {
    margin-right: 0 !important;
  }
  .ant-menu-inline.secondary .ant-menu-item svg,
  .ant-menu-inline.settings svg {
    float: none;
    margin-left: 0;
    margin-right: 0;
  }
  .side-small-title {
    font-size: 6px !important;
  }
  .plan-title {
    font-size: 8px !important;
  }
  .package-title {
    margin: 0 !important;
    font-size: 12px !important;
  }
}
.u-button-reset {
  align-items: center;
  background: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  padding: 0;
  transition: background-color 0.3s;
  width: -webkit-max-content;
  width: max-content;
}
.u-button-reset:focus-within {
  outline: revert !important;
}
.u-button-reset:disabled {
  background-color: #93949f;
  cursor: not-allowed;
}
.u-loading-indicator {
  -webkit-animation: spin 1s ease-in-out infinite;
          animation: spin 1s ease-in-out infinite;
}
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
.u-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
.brand-login-btn {
  border: 0.0625rem solid #D6D6D6;
  border-radius: 0.25rem;
  padding: 0.25rem 0.5rem;
}
.brand-login-btn:hover {
  background: #E8E8E8;
}
.content-preview-btn {
  border-radius: 0.5rem;
  height: 100%;
  overflow: hidden;
  width: 100%;
}
#project-tab {
  font-family: SFProDisplay;
  font-weight: normal;
  font-style: normal;
  padding: 0 0 0 15px;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: -0.004em;
}
#icon-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
#icon-container #icon-text {
  font-family: SFProDisplay;
  font-weight: normal;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  white-space: nowrap;
  color: #fff;
}
.sonar-wrapper {
  position: relative;
  z-index: 0;
  overflow: hidden;
}
.sonar-emitter {
  position: relative;
  margin: 13px 14px;
  width: 29.33px;
  height: 29.33px;
  border-radius: 9999px;
}
.sonar-wave {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 9999px;
  border: 1px #ffb917 solid;
  opacity: 0;
  z-index: -1;
  pointer-events: none;
}
.sonar-wave1 {
  -webkit-animation: sonarWave 2s linear infinite;
          animation: sonarWave 2s linear infinite;
}
.sonar-wave2 {
  -webkit-animation: sonarWave 2s 0.5s linear infinite;
          animation: sonarWave 2s 0.5s linear infinite;
}
.sonar-wave3 {
  -webkit-animation: sonarWave 2s 1s linear infinite;
          animation: sonarWave 2s 1s linear infinite;
}
@-webkit-keyframes sonarWave {
  from {
    -webkit-transform: scale(0.2);
            transform: scale(0.2);
    opacity: 1;
  }
  to {
    -webkit-transform: scale(2);
            transform: scale(2);
    opacity: 0;
  }
}
@keyframes sonarWave {
  from {
    -webkit-transform: scale(0.2);
            transform: scale(0.2);
    opacity: 1;
  }
  to {
    -webkit-transform: scale(2);
            transform: scale(2);
    opacity: 0;
  }
}
@media (max-width: 47.9375rem) {
  .hidden-xmobile {
    display: none !important;
  }
}
/*
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/
@media (min-width: 320px) and (max-width: 480px) {
  .min-layout-content {
    padding-left: 20px;
    padding-right: 20px;
  }
  .content-padding {
    padding: 5px;
  }
  .small {
    width: 100%;
  }
}
.ant-layout-sider .brand-image {
  position: relative;
  height: 182px;
  background: #333;
}
.ant-layout-sider .brand-image .ant-avatar-image {
  height: 100%;
}
.ant-layout-sider .brand-image .ant-avatar-circle {
  background: #333;
  height: 100%;
}
.ant-layout-sider .brand-image .subscription-tag {
  margin-left: 61px;
  min-width: 87px;
  padding-top: 4.7px;
  padding-bottom: 5.5px;
  border-radius: 100px;
  background: #000;
  position: absolute;
  bottom: 15px;
  text-align: center;
}
.ant-layout-sider .brand-image .subscription-tag .name {
  display: inline-block;
  width: 48px;
  margin-bottom: 4px;
  vertical-align: bottom;
}
.ant-layout-sider .brand-image .subscription-tag .icon {
  display: inline-block;
  margin-bottom: 4px;
  width: 14px;
  vertical-align: bottom;
}
.side-menu #archive-link-container {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 10px;
}
.side-menu .menu-title {
  font-family: SFProDisplay;
  font-weight: 800;
  font-size: 12px;
  line-height: 18px;
  color: #fff;
}
.side-menu .new-project {
  border-radius: 50%;
  margin-right: 0.3125rem;
}
.side-menu .new-project rect {
  transition: fill 0.3s;
}
.side-menu .new-project:hover rect,
.side-menu .new-project:focus rect {
  fill: #ffc84a;
}
.side-menu .new-project:focus-within rect {
  fill: #ffc84a;
}
.side-menu .new-project:active rect {
  fill: #e39f00;
}
.side-menu .toggle-projects-btn path {
  transition: fill 0.3s;
}
.side-menu .toggle-projects-btn:hover path,
.side-menu .toggle-projects-btn:focus path {
  fill: #bfc3c7;
}
.side-menu .toggle-projects-btn:focus-within path {
  fill: #bfc3c7;
}
.side-menu .toggle-projects-btn:active path {
  fill: #ffffff;
}
.side-menu .icons .new-project-icon {
  font-size: 20px;
}
.side-menu .icons .cheveron-icon:hover {
  cursor: pointer;
}
.side-menu .icons .cheveron-icon.open {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}
.new-pill {
  padding: 0.125rem 0.5rem;
  border-radius: 1.75rem;
  background-color: #f2b5fa;
  color: #000000;
  font-size: 0.5rem;
  font-weight: 700;
  text-transform: uppercase;
}
.sidebar-link {
  align-items: center;
  display: flex;
  grid-gap: 0.75rem;
  gap: 0.75rem;
  justify-content: space-between;
  padding: 0.5rem 1rem;
  transition: background-color 0.2s;
  width: 100%;
}
.sidebar-link:hover,
.sidebar-link:focus {
  background-color: #2f313c;
}
.sidebar-link:focus-visible {
  background-color: #2f313c;
  outline: revert !important;
  outline-offset: -0.25rem;
}
.sidebar-link--active {
  background-color: #272727;
  border-right: 0.1875rem solid #FFB917;
}
.sidebar-icon-wrapper {
  align-items: center;
  background-color: #000000;
  border: 0.0625rem solid #3A3A3A;
  color: #FFFFFF;
  display: flex;
  height: 2.25rem;
  justify-content: center;
  font-size: 1.25rem;
  width: 2.25rem;
}
.sidebar-icon {
  height: 2.25rem;
  width: 2.25rem;
}
.sidebar-icon rect {
  fill: #000000;
}
.sidebar-icon--account {
  height: 1.5625rem;
  width: 1.5625rem;
}
.sidebar-icon--account > g > g {
  stroke: #FFFFFF;
  fill: #FFFFFF;
}
.sidebar-link-copy {
  font-family: SFProDisplay;
  font-weight: normal;
  font-style: normal;
  color: #FFFFFF;
  flex-grow: 1;
  font-size: 0.75rem;
  font-weight: 600;
}
#admin-layout .ant-layout-content {
  background: #f6f7fb;
}
#admin-layout .page-header {
  width: 100%;
  padding-top: 47px;
  background: #fff;
  border-bottom: 1px solid #e8e8e8;
}
#admin-layout .page-header h1 {
  font-size: 26px;
  font-family: 'TruenoExtraBold';
}
#admin-layout .page-header .ant-menu.ant-menu-horizontal {
  border-bottom: 0px;
}
.firebase-emulator-warning {
  display: none;
}
.standardized-image {
  height: 100%;
  max-height: 90rem;
  max-width: 90rem;
  object-fit: cover;
  width: 100%;
}
.banner-card {
  display: none;
}
.banner-card.brief-page {
  bottom: 80px;
}
@media (min-width: 48rem) {
  .banner-card {
    align-items: center;
    background: #bde5cc;
    border: 0.0625rem solid #000000;
    border-radius: 0.625rem;
    bottom: 1.5rem;
    display: flex;
    grid-gap: 1rem;
    gap: 1rem;
    justify-content: space-between;
    left: calc(50% + 15rem / 2);
    max-width: 60rem;
    padding: 1.5rem;
    position: fixed;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    width: calc(100% - 17rem);
  }
  .banner-card .reactivate-listing-btn {
    font-family: SFProDisplay;
    font-weight: 800;
    background-color: #000000;
    border-radius: 0.1875rem;
    color: #FFFFFF;
    font-size: 1rem;
    line-height: 1.5rem;
    padding: 0.5rem 1rem;
  }
  .banner-card .reactivate-listing-btn:hover,
  .banner-card .reactivate-listing-btn:focus {
    background-color: #404040;
  }
  .banner-card .reactivate-listing-btn:active {
    background-color: #000000;
  }
}
.text-column {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-family: 'SFProDisplay';
}
.text-column h5 {
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  margin: 0;
  margin-bottom: 8px;
}
.text-column p {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  margin: 0;
}
.action-column {
  display: flex;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

#admin-brands-active-campaigns {
  padding-bottom: 25px;
}
#admin-brands-active-campaigns .search-filters {
  margin-top: 15px;
  margin-bottom: 28px;
}
#admin-brands-active-campaigns .search-filters .trend-select {
  width: 100%;
}
#admin-brands-active-campaigns .search-filters .trend-select .ant-select-selection__clear {
  right: 25px;
}
#admin-brands-active-campaigns .campaign-card {
  cursor: pointer;
  caret-color: transparent;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.15);
  background-color: #ffffff;
  color: #000;
  font-size: 14px;
  font-family: 'TruenoBold';
  margin-bottom: 20px;
}
#admin-brands-active-campaigns .campaign-card-name {
  margin-bottom: 18px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
#admin-brands-active-campaigns .campaign-card-credits {
  font-size: 14px;
  font-family: 'TruenoBold';
  margin-bottom: 19px;
}
#admin-brands-active-campaigns .campaign-card-upper {
  padding: 21px 18px 19px;
  border-bottom: 1px solid #ecedf7;
}
#admin-brands-active-campaigns .campaign-card-lower {
  padding: 19px 18px 16px;
  font-size: 11px;
}
#admin-brands-active-campaigns .campaign-card-type {
  cursor: pointer;
  font-size: 20px;
  position: absolute;
  right: 20px;
  top: 18px;
}
#admin-brands-active-campaigns .campaign-card .brand-details {
  font-size: 11px;
  font-family: 'TruenoRegular';
}
#admin-brands-active-campaigns .campaign-card .brand-details-name {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
#admin-brands-active-campaigns .campaign-card .brand-details-health .health-icon {
  margin-right: 6px;
  width: 8px;
  height: 8px;
  display: inline-block;
  border-radius: 100%;
}
#admin-brands-active-campaigns .campaign-card .brand-details-health .health-icon.poor {
  background: #d92e2e;
}
#admin-brands-active-campaigns .campaign-card .brand-details-health .health-icon.range,
#admin-brands-active-campaigns .campaign-card .brand-details-health .health-icon.in.range {
  background: #ffb917;
}
#admin-brands-active-campaigns .campaign-card .brand-details-health .health-icon.great {
  background: #15f590;
}
#admin-brands-active-campaigns .campaign-card .brand-details-health .health-label {
  text-transform: capitalize;
}
#admin-brands-active-campaigns .campaign-card .campaign-type {
  font-family: 'TruenoRegular';
}
#admin-brands-active-campaigns .campaign-card .campaign-startdate {
  font-family: 'TruenoRegular';
  margin-bottom: 16px;
}
#admin-brands-active-campaigns .campaign-card .campaign-stats .stat {
  font-size: 12px;
  margin-bottom: 16px;
}
#admin-brands-active-campaigns .campaign-card .campaign-stats .stat:last-child {
  margin-bottom: 0px;
}
#admin-brands-active-campaigns .campaign-card .campaign-stats .stat-health {
  font-size: 8px;
  font-family: 'TruenoRegularItalic';
  text-transform: uppercase;
}
#admin-brands-active-campaigns .campaign-card .campaign-stats .stat-health.great {
  color: #15f590;
}
#admin-brands-active-campaigns .campaign-card .campaign-stats .stat-health.great .stat-icon {
  background: #15f590;
}
#admin-brands-active-campaigns .campaign-card .campaign-stats .stat-health.range,
#admin-brands-active-campaigns .campaign-card .campaign-stats .stat-health.in.range {
  color: #ffb917;
}
#admin-brands-active-campaigns .campaign-card .campaign-stats .stat-health.range .stat-icon,
#admin-brands-active-campaigns .campaign-card .campaign-stats .stat-health.in.range .stat-icon {
  background: #ffb917;
}
#admin-brands-active-campaigns .campaign-card .campaign-stats .stat-health.poor {
  color: #ff0000;
}
#admin-brands-active-campaigns .campaign-card .campaign-stats .stat-health.poor .stat-icon {
  background: #ff0000;
}
#admin-brands-active-campaigns .campaign-card .campaign-stats .stat-icon {
  width: 8px;
  height: 8px;
  margin-right: 3px;
  display: inline-block;
  border-radius: 100%;
}

.add-credits-modal .ant-modal-body {
  min-height: 200px;
  padding: 48px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.add-credits-modal h2 {
  margin: 48px auto;
}
.add-credits-modal .button-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.add-credits-modal .button-row button {
  font-family: 'SFProDisplay';
  font-weight: 600;
  color: #000;
  border-radius: 5px;
  height: 55px;
  width: 184px;
}
.add-credits-modal .button-row .cancel-btn {
  margin-right: 12px;
}

#admin-layout .ant-layout-header {
  background: #000;
  height: 62px;
  position: relative;
}
#admin-layout .logo {
  position: absolute;
  top: 0;
  right: 43px;
}
#admin-layout .back a {
  color: #fff;
}
#admin-layout .back a .anticon {
  font-size: 18px;
  margin-right: 11px;
}

