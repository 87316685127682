.creator-content-card {
  border-radius: 1rem;
  border: 1px solid #000000;
  width: 210px;
  height: 304px;
  background-color: #fff;
  padding: 8px 12px;
  text-align: center;

  .video-image-container {
    height: 14.5rem;
    position: relative;

    .vide-image {
      z-index: -1;
      position: absolute;
      width: 100%;
      height: 100%;
    }

    .info-container {
      width: 45px;
      height: 18px;
      background-color: #ebd0ff;
      position: absolute;
      top: 8px;
      left: 8px;
      border-radius: 28px;
      text-align: center;
      border: 1px solid #000000;

      .info-title {
        font-family: 'SFProDisplay';
        font-size: 9px;
        font-weight: 700;
        font-style: normal;
        color: #000000;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
      }
    }

    .btn-group {
      position: absolute;
      bottom: 10px;
      right: 10px;
      border-radius: 6px;
      width: 3.875rem;
      height: 1.875rem;
      background-color: #bde5cc;
      color: #000000;
      font-family: 'SFProDisplay';
      font-size: 0.625rem;
      font-weight: 600;
      line-height: 0.813rem;
      letter-spacing: 0px;
      text-align: center;
      z-index: 1;
      padding: 0;
      border: 1px solid #000000;
    }

    .icon-style {
      margin-left: 0.2rem;
    }
  }

  .video-loader {
    img {
      width: 100%;
      height: 232px;
      background-color: #eee;
      border-radius: 0.563rem;
      object-fit: cover;
    }
    video {
      width: 100%;
      height: 232px;
      background-color: #000;
      border-radius: 0.563rem;
      object-fit: fit;
    }
  }

  .card-details {
    display: flex;
    align-items: center;
    color: #000000;
    padding-top: 12px;

    img {
      margin-right: 8px;
    }

    .card-username {
      margin-left: 0.4rem;
      color: #000000;
      float: left;
      font-size: 0.75rem;
      line-height: 1.125rem;
      font-family: 'SFProDisplay';
      font-style: normal;
      font-weight: 600;
      display: flex;
      flex-direction: column;
      text-align: left;

      .approvals-countdown {
        font-weight: normal;
      }
    }

    .card-stats {
      font-size: 0.75rem;
      line-height: 1.125rem;
      margin-left: 0.2rem;
      float: left;
      font-family: 'SFProDisplay';
      font-style: normal;
      font-weight: 400;
    }
  }
}

@primary-color: #ffb917;@info-color: #ffb916;@input-addon-bg: transparent;@slider-track-background-color: @primary-color;@slider-track-background-color-hover: @primary-color;