.ant-btn {
  &.trend-btn {
    background: @color-orange;
    border: 0;
    color: @color-black;
    font-size: 11px;
    .type-bold();
    letter-spacing: 0.5px;
    text-transform: uppercase;
    border-radius: 2px;

    &:hover,
    &:focus-within {
      background: #ffe8bc;
    }

    &.secondary {
      height: 35px;
      padding-top: 2px;
      background: #f8faff;
      border: 1px solid #dbdfe8;
      box-shadow: none;
      font-size: 9px;
      color: @color-pigeon-dark;
      letter-spacing: 1px;

      &:hover,
      &:focus-within {
        background: #f0f2f9 !important;
      }
    }

    &.gray {
      background: @color-black-ter;
      color: @color-white !important;
    }
  }

  &.huge-btn {
    height: 57px !important;
    .size-14px();
    color: @color-black !important;
    text-transform: none !important;
    letter-spacing: 0;
  }

  &.medium-btn {
    height: 45px !important;
    .size-14px();
    color: @color-black !important;
    text-transform: none !important;
    letter-spacing: 0;
  }

  &.reject {
    background: #ff5a5a !important;
  }

  &.product-btn {
    height: 57px;
    padding: 16px 68px;
    font-size: 16px;
    .type-bold();
    text-transform: none;
    letter-spacing: 0;

    &.ant-btn-loading {
      padding: 16px 68px !important;
    }
  }
}

.action-link {
  .back {
    color: @color-orange;
    flex-direction: column;
    font-family: 'TruenoSemiBold', Arial, sans-serif;
    left: 2.1875rem;
    position: absolute;
    top: 1.9375rem;
  }

  .sign-out {
    flex-direction: column;
    font-family: 'TruenoSemiBold', Arial, sans-serif;
    position: absolute;
    right: 2.1875rem;
    top: 1.9375rem;
  }
}

.action-btn {
  .type-bold-sfpro-display();
  padding: 0.5rem 1rem !important;
  background-color: @color-black !important;
  border: 0.0625rem solid #3c3c3c !important;
  font-size: 0.625rem;
  color: @color-gray-lightest;
  border-radius: 0.125rem;
  text-transform: uppercase;

  &:focus-visible {
    outline: revert !important;
  }
}

.sub-menu-btn {
  float: right;
  color: #b0b0b0;
  border-radius: 2px;
  border: 1px solid #3c3c3c;
  background: #151515;
  .spacing-05px();
  .type-sfpro-regular();
  .uppercase();

  &.archive {
    display: flex;
    font-size: 9px;
    line-height: 11px;
    border-radius: 30px;
    justify-content: center;
    font-weight: 700;
    letter-spacing: 0.1em;
    padding: 0.375rem 1rem;

    &:focus-visible {
      outline: revert !important;
    }
  }
}

.post-filter-action {
  .text-right();
  position: relative;
  z-index: 2;

  .ant-btn-group {
    height: 25px;
    padding: 3px 0;
    background: #f8f9fb;
    border-radius: 2px;
  }
}

.remove-dropdown {
  span {
    .size-9px();
    .spacing-05px();
    .type-sbold();
    .text-pigeon-dark();
  }

  .ant-dropdown-menu-item {
    &:hover {
      background-color: #f8faff !important;
    }
  }
}

.yotpo-btn.ant-btn:not(.ant-btn-link) {
  background-color: #1776ec;
  color: #fff;
  border-radius: 2px;
  border: 0;
  .type-firme-iblack();
  font-size: 10px;
  height: 33px;
  padding-right: 15px;
  padding-left: 15px;

  &.ant-btn-loading {
    padding-right: 42px;
  }
  &:hover,
  &:focus {
    color: #fff;
    background-color: #528cd4;
  }
}
