.video-loader {
  position: relative;
  width: 100%;

  .ant-spin {
    top: calc(50% - 12.5px);
    left: calc(50% - 10px);
    position: absolute;
  }
}
