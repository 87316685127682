.trend-table-actions {
  .ant-checkbox-checked {
    .ant-checkbox-inner {
      background-color: @color-orange;
      border-color: @color-orange;

      &:after {
        display: none;
      }
    }
  }

  .ant-checkbox-indeterminate {
    .ant-checkbox-inner {
      width: 25px;
      height: 25px;
      border-radius: 25px;
      background: #f4f4f4;
      border: 1px solid #dadada;

      &:before {
        display: block;
        margin-top: 4px;
        margin-left: 4px;
        content: "";
        background: @color-orange;
        width: 15px;
        height: 15px;
        border-radius: 25px;
      }
    }
  }

  .ant-checkbox-inner {
    width: 25px;
    height: 25px;
    border-radius: 25px;
    background: #f4f4f4;
    border: 1px solid #dadada;
  }
}
