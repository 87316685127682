// Variables
// https://fontlibrary.org/en/font/trueno
// --------------------------------------------------------

.font-face {
  font-weight: normal;
  font-style: normal;
}

@font-src: '../../webfonts/Trueno';

@font-face {
  .font-face();
  font-family: 'TruenoLight';
  src: url('@{font-src}/TruenoLt.otf') format('opentype');
}

@font-face {
  .font-face();
  font-family: 'TruenoLightItalic';
  src: url('@{font-src}/TruenoLtIt.otf') format('opentype');
}

@font-face {
  .font-face();
  font-family: 'TruenoRegular';
  src: url('@{font-src}/TruenoRg.otf') format('opentype');
}

@font-face {
  .font-face();
  font-family: 'TruenoRegularItalic';
  src: url('@{font-src}/TruenoRgIt.otf') format('opentype');
}

@font-face {
  .font-face();
  font-family: 'TruenoBold';
  src: url('@{font-src}/TruenoBd.otf') format('opentype');
}

@font-face {
  .font-face();
  font-family: 'TruenoBoldItalic';
  src: url('@{font-src}/TruenoBdIt.otf') format('opentype');
}

@font-face {
  .font-face();
  font-family: 'TruenoSemiBold';
  src: url('@{font-src}/TruenoSBd.otf') format('opentype');
}

@font-face {
  .font-face();
  font-family: 'TruenoExtraBold';
  src: url('@{font-src}/TruenoExBd.otf') format('opentype');
}

@font-face {
  font-family: 'FirmeBlackItalic';
  src: url('../../webfonts/firme-blackitalic-webfont.woff2') format('woff2'),
    url('../../webfonts/firme-blackitalic-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'FirmeMedium';
  src: url('../../webfonts/firme-medium-webfont.woff2') format('woff2'),
    url('../../webfonts/firme-medium-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: SFProDisplay;
  src: url('../../webfonts/SF-Pro/FontsFree-Net-SFProDisplay-Black.ttf') format('opentype');
  font-weight: 900;
}
@font-face {
  font-family: SFProDisplay;
  src: url('../../webfonts/SF-Pro/FontsFree-Net-SFProDisplay-Bold.ttf') format('opentype');
  font-weight: bold;
}
@font-face {
  font-family: SFProDisplay;
  src: url('../../webfonts/SF-Pro/FontsFree-Net-SFProDisplay-Heavy-1.ttf') format('opentype');
  font-weight: 800;
}
@font-face {
  font-family: SFProDisplay;
  src: url('../../webfonts/SF-Pro/FontsFree-Net-SFProDisplay-HeavyItalic-1.ttf') format('opentype');
  font-weight: 800;
  font-style: italic;
}
@font-face {
  font-family: SFProDisplay;
  src: url('../../webfonts/SF-Pro/FontsFree-Net-SFProDisplay-Light-1.ttf') format('opentype');
  font-weight: normal;
}
@font-face {
  font-family: SFProDisplay;
  src: url('../../webfonts/SF-Pro/FontsFree-Net-SFProDisplay-MediumItalic.ttf') format('opentype');
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: SFProDisplay;
  src: url('../../webfonts/SF-Pro/FontsFree-Net-SFProDisplay-BoldItalic.ttf') format('opentype');
  font-weight: bold;
  font-style: italic;
}
@font-face {
  font-family: SFProDisplay;
  src: url('../../webfonts/SF-Pro/FontsFree-Net-SFProDisplay-LightItalic.ttf') format('opentype');
  font-weight: normal;
  font-style: italic;
}

// Mixins
// --------------------------------------------------------

@sans-serif: Arial, sans-serif;
@system-sans: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

.type-light {
  font-family: 'TruenoLight', @sans-serif;
}
.type-ilight {
  font-family: 'TruenoLightItalic', @sans-serif;
}
.type-regular {
  font-family: 'TruenoRegular', @sans-serif;
}
.type-regular-sfpro-display {
  font-family: 'SFProDisplay', @system-sans;
}
.type-bold {
  font-family: 'TruenoBold', @sans-serif;
}
.type-bold-sfpro-display {
  font-family: 'SFProDisplay', @system-sans;
  font-weight: bold;
}
.type-sbold {
  font-family: 'TruenoSemiBold', @sans-serif;
}
.type-ebold {
  font-family: 'TruenoExtraBold', @sans-serif;
}

.type-firme-iblack {
  font-family: 'FirmeBlackItalic', @sans-serif !important;
}
.type-firme-medium {
  font-family: 'FirmeMedium', @sans-serif !important;
}
.type-sfpro-regular {
  font-family: SFProDisplay;
  font-weight: normal;
  font-style: normal;
}
.type-sfpro-medium {
  font-family: SFProDisplay;
  font-weight: normal;
}
.type-sfpro-iregular {
  font-family: SFProDisplay;
  font-weight: normal;
  font-style: italic;
}
.type-sfpro-bold {
  font-family: SFProDisplay;
  font-weight: bold;
}
.type-sfpro-light {
  font-family: SFProDisplay;
  font-weight: 400;
}
.type-sfpro-heavy {
  font-family: SFProDisplay;
  font-weight: 800;
}
.type-sfpro-iheavy {
  font-family: SFProDisplay;
  font-weight: 800;
  font-style: italic;
}
.type-sfpro-thin {
  font-family: SFProDisplay;
  font-weight: 100;
}
.type-sfpro-ibold {
  font-family: SFProDisplay;
  font-weight: bold;
  font-style: italic;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  .type-sfpro-heavy() !important;
}

body {
  .type-regular() !important;
  .font-face();
}

.text-primary {
  color: @primary-color;
}

// Font Sizes
// --------------------------------------------------------

.size-8px {
  font-size: 8px !important;
}
.size-9px {
  font-size: 9px !important;
}
.size-10px {
  font-size: 10px !important;
}
.size-11px {
  font-size: 11px !important;
}
.size-12px {
  font-size: 12px !important;
}
.size-13px {
  font-size: 13px !important;
}
.size-14px {
  font-size: 14px !important;
}
.size-15px {
  font-size: 15px !important;
}
.size-16px {
  font-size: 16px !important;
}
.size-17px {
  font-size: 17px !important;
}
.size-18px {
  font-size: 18px !important;
}
.size-20px {
  font-size: 20px !important;
}
.size-40px {
  font-size: 40px !important;
}
.size-21px {
  font-size: 21px !important;
}
.size-22px {
  font-size: 22px !important;
}
.size-24px {
  font-size: 24px !important;
}
.size-25px {
  font-size: 25px !important;
}
.size-28px {
  font-size: 28px !important;
}
.size-31px {
  font-size: 31px !important;
}
.size-32px {
  font-size: 32px !important;
}
.size-34px {
  font-size: 34px !important;
}
.font-weight-400 {
  font-weight: 400;
}
.font-weight-700 {
  font-weight: 700;
}
.font-weight-800 {
  font-weight: 800;
}
.color-yellow-font {
  color: #ffcb6f;
}

.most-popular {
  background: -webkit-linear-gradient(right, rgba(245, 207, 121, 1), rgba(247, 247, 247, 1));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

// Letter Spacing
// --------------------------------------------------------

.spacing-05px {
  letter-spacing: 0.5px;
}
.spacing-1px {
  letter-spacing: 1px;
}

// Line Height
// --------------------------------------------------------

.leading-18px {
  line-height: 18px;
}
.leading-24px {
  line-height: 24px;
}
.leading-1 {
  line-height: 1;
}
.leading-23 {
  line-height: 2.33;
}

.uppercase {
  text-transform: uppercase;
}

// Temporary

.clickable {
  cursor: pointer;
}

.text-left {
  text-align: left !important;
}
.text-right {
  text-align: right !important;
}
.text-center {
  text-align: center !important;
}

.text-wrap {
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
}

.label-value {
  font-family: 'truenoextrabold';
  color: @color-black;
  font-size: 12px;
}
