.content-style-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  // overflow-x: scroll;
  max-width: 100%;
  width: 100%;
  max-height: 86px;

  img,
  video,
  .video-loader {
    max-height: 60px;
    height: 60px;
    max-width: 60px;
    width: 60px;
    background: #000;
    border-radius: 4px;
    object-fit: cover;
  }

  img:after {
    border-radius: 4px;
  }

  .video-loader {
    margin-right: 8px;
    .anticon {
      font-size: 60px;
    }
  }

  svg {
    max-height: 32px;
    max-width: 32px;
  }

  .video-player-actions {
    z-index: 0;
  }
}

@primary-color: #ffb917;@info-color: #ffb916;@input-addon-bg: transparent;@slider-track-background-color: @primary-color;@slider-track-background-color-hover: @primary-color;