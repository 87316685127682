.trend-badge {
  align-items: center;
  background-color: @primary-color;
  border-radius: 50%;
  display: flex;
  font-size: 0.75rem;
  height: 1.25rem;
  justify-content: center;
  margin: 0 auto;
  width: 1.25rem;
}
