.company-page {
  margin: 0 auto;
  max-width: 36.25rem;
  padding: 0 1rem 8rem 1rem;

  form {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    max-width: 100%;
  }

  .input-wrapper {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    width: 100%;

    .error {
      color: @roses-50;
    }
  }

  label {
    color: @color-white;
    font-size: 1rem;
    margin: 0;
  }

  input {
    background-color: transparent;
    border: none;
    border-bottom: 0.0625rem solid @gray-50;
    color: @color-white;
    font-size: 1rem;

    &:focus {
      outline: none;
    }

    &::placeholder {
      color: @gray-50;
    }
  }

  textarea {
    background-color: transparent;
    border: 0.0625rem solid @gray-50;
    border-radius: 0.25rem;
    color: @color-white;
    font-size: 1rem;

    &::placeholder {
      color: @gray-50;
    }
  }

  .company-page-footer {
    align-items: center;
    background-color: @color-black;
    border-top: 0.0625rem solid @color-off-black;
    bottom: 0;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    left: 0;
    padding: 2rem;
    position: fixed;
    right: 0;
    z-index: 1;
  }

  .save-changes-btn {
    background-color: @color-orange;
    border-radius: 0.25rem;
    color: @color-black;
    font-family: 'SFProDisplay', sans-serif;
    font-size: 1rem;
    font-weight: 600;
    padding: 0.5rem 1rem;

    &:hover:enabled {
      background-color: lighten(@color-orange, 10%);
    }
  }
}
