.soona-showcase-page {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;

  iframe {
    border: none;
    height: 100%;
    width: 100%;
  }
}
