#admin-business-approval {
  button.download-csv {
    width: 122px;
    padding-top: 6px;
    padding-bottom: 9px;
    color: #5b6572;
    text-align: left;
    border-radius: 2px;
    border: solid 1px #dbdfe8;
    background-color: #f8faff;
    .anticon {
      font-size: 16px;
    }

    span {
      display: inline-block;
      text-align: left;
      text-transform: uppercase;
      font-family: 'FirmeBlackItalic';
      font-size: 9px;
      font-style: italic;
      line-height: 1.11;
      letter-spacing: 1px;
    }
  }
}
