.style-guide {
  border-top: 0.1875rem solid @color-black;
  color: @color-black;
  padding: 1.5rem 1rem;

  .link-list {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;

    .style-guide-link {
      .type-sfpro-bold();
      background-color: @color-white-ter;
      border: 0.0625rem solid @color-pigeon;
      border-radius: 0.125rem;
      color: @color-black;
      font-size: 0.6875rem;
      padding: 0.25rem 0.625rem;

      &:hover {
        background-color: darken(@color-white-ter, 5%);
      }

      &:active {
        background-color: @color-white-ter;
      }
    }
  }

  .asset-list {
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(auto-fit, minmax(7rem, 1fr));
  }

  .style-guide-asset {
    position: relative;

    img {
      height: 7rem;
      object-fit: cover;
      width: 100%;
    }

    video {
      background-color: @color-black;
      height: 7rem;
      width: 100%;
    }

    svg {
      height: 1.875rem;
      width: 1.875rem;
    }

    .video-mask {
      background-color: transparent;
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 1;
    }
  }
}
