@side-menu-width: 210px;
@drawer-width: calc(100vw - @side-menu-width);

.creator-profile-drawer {
  display: none;
  &.show {
    display: flex;
  }
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  max-height: 100%;
  z-index: 51;
  width: @drawer-width;

  background: #f4f4f4;
  align-items: center;
  justify-content: center;
  > div {
    position: absolute;
  }
  iframe {
    height: 100%;
    border: none;
    width: @drawer-width;
  }
}

@primary-color: #ffb917;@info-color: #ffb916;@input-addon-bg: transparent;@slider-track-background-color: @primary-color;@slider-track-background-color-hover: @primary-color;