#campaignStyleCategory {
  border-bottom: 0.1875rem solid @color-black;
  border-top: 0.1875rem solid @color-black;

  .style-category {
    padding: 1.5rem 1rem;
    width: 100%;

    .title {
      .type-sfpro-heavy();
      font-size: 1rem;
      line-height: 1.125rem;
      margin: 0;
    }

    .description {
      .type-regular-sfpro-display();
      font-weight: 600;
      font-size: 0.75rem;
      line-height: 0.75rem;
      margin: 0;
      padding-top: 0.5rem;
    }
  }

  .video-details-wrapper {
    display: flex;
    gap: 2rem;
    padding-top: 1rem;
  }

  .video-details {
    align-items: center;
    display: flex;
    gap: 0.75rem;
    width: min-content;

    p {
      .type-regular-sfpro-display();
      font-size: 0.75rem;
      font-weight: 600;
      line-height: 0.875rem;
      margin: 0;
      text-transform: capitalize;
      width: min-content;
    }

    svg {
      flex-shrink: 0;
    }

    svg path,
    svg g circle,
    svg circle {
      fill: @color-black;
    }

    .layout-shape {
      background: @color-white;
      border: 0.1875rem solid @color-black;
      flex-shrink: 0;

      &--square {
        height: 1.4375rem;
        width: 1.4375rem;
      }

      &--horizontal {
        height: 1.4375rem;
        width: 2.4375rem;
      }

      &--vertical {
        height: 2.4375rem;
        width: 1.4375rem;
      }
    }
  }
}
