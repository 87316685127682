.ant-row.page-filters {
  margin-bottom: 49px;

  .ant-select {
    margin-right: 25px;

    .ant-select-selection-selected-value {
      font-size: 10px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      letter-spacing: normal;
      color: #5b6572;
    }
  }

  .ant-checkbox {
    .ant-checkbox-inner {
      width: 20px;
      height: 20px;

      &:after {
        left: 6.571429px;
        top: 3.142857px;
      }
    }

    + span {
      margin: 0 9px;
    }
  }
}
