.add-credits-modal {
  .ant-modal-body {
    min-height: 200px;
    padding: 48px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  h2 {
    margin: 48px auto;
  }

  .button-row {
    display: flex;
    align-items: center;
    justify-content: space-between;

    button {
      font-family: 'SFProDisplay';
      font-weight: 600;
      color: #000;
      border-radius: 5px;
      height: 55px;
      width: 184px;
    }
    .cancel-btn {
      margin-right: 12px;
    }
    .submit-btn {
    }
  }
}

@primary-color: #ffb917;@info-color: #ffb916;@input-addon-bg: transparent;@slider-track-background-color: @primary-color;@slider-track-background-color-hover: @primary-color;