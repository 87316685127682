.ant-input.trend-input {
  &-bordered {
    border: 1px solid #3c3c3c;
    background: #151515;
    border-radius: 2px;
    padding: 16px 15px 17px 15px;
    font-size: 15px;
    color: @primary-color;
    height: 51px;

    &::placeholder {
      color: #989898;
    }
  }
}

.notifications {
  &-checkbox {
    align-items: center;
    background-color: #545454;
    border: 1px solid #989898;
    border-radius: 1px;
    cursor: pointer;
    display: flex;
    height: 1.25rem;
    justify-content: center;
    transition: all 150ms ease;
    width: 1.25rem;

    svg {
      margin-top: 1px;
      transform: scale(1.75);

      path {
        stroke: @color-black;
      }
    }

    &:hover {
      cursor: pointer;
      border-color: @color-white;
    }

    &--active {
      background-color: @color-orange;
      border-color: @color-orange;
    }
  }
}
