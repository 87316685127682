.image-kit-container {
  position: relative;
  img {
    position: relative;
  }
  img:after {
    content: " ";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000;
  }
}

.video-player-action-container {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

@primary-color: #ffb917;@info-color: #ffb916;@input-addon-bg: transparent;@slider-track-background-color: @primary-color;@slider-track-background-color-hover: @primary-color;