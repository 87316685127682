.post-approval-card {
  .post-btn-group {
    &.revise {
      .ant-btn {
        background-color: #46c5ff;
        font-size: 20px;
        border: none;
      }
    }
  }

  .video-loader {
    width: 195px;
    height: 160px;
    margin-left: 12px;
    margin-bottom: 7px;
    img,
    video {
      width: 100%;
      height: 100%;
      border-radius: 10px;
      object-fit: cover;
    }
  }
}
