#campaignRecentPosts {
  border-bottom: 1px solid #d6d6d6;
  padding-bottom: 28px;
  width: 222px;

  .side-header-text {
    margin-bottom: 0 !important;
  }

  .see-all {
    .type-sfpro-regular();
    font-weight: 500;
    font-size: 11px;
    line-height: 12px;
    text-align: right;
  }

  .post-image,
  .empty-box {
    border-radius: 4px;
    height: 107.36px;
    width: 107.84px;

    &.with-border {
      border: 1px dashed #888;
    }
  }

  .row-container {
    display: flex;
  }

  .creatorName {
    .type-sfpro-medium();
    font-size: 10px;
    font-weight: 600;
    line-height: 14px;
    letter-spacing: -0.008em;
    margin-top: 3px;
  }
}
