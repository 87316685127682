.empty-campaign {
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 2rem 1rem 0 1rem;

  img {
    max-width: 100%;
    max-height: 17.5rem;
  }

  h2 {
    .type-sfpro-bold();
    color: @color-black;
    display: block;
    font-size: 1.5rem;
    margin: 0 auto;
    padding: 2rem 0 1rem;
  }

  p {
    .type-sfpro-regular();
    color: @color-black;
    font-size: 1rem;
    margin: 0 auto;
    max-width: 31rem;
    text-align: center;
  }
}
