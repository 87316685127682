#creatorsCampaign {
  max-width: 480px;
  width: 100%;
  margin-right: auto;
  margin-left: auto;

  .page-title {
    .type-sfpro-heavy();
    font-size: 24px;
    line-height: 30px;
    color: white;
  }

  .trend-radio-buttons-v2 {
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    @media screen and (max-width: 600px) {
      justify-content: center;
    }
  }

  .page-description {
    .type-sfpro-regular();
    color: white;
    font-size: 16px;
    line-height: 24px;
    margin-top: 3px;
    margin-bottom: 43px;
  }

  .ant-radio-button-wrapper {
    min-width: 142px;
    max-width: 142px;
    min-height: 120px;
    max-height: 120px;
    border-radius: 5px;
    margin-right: 8px;
    margin-bottom: 8px;

    .gender-label {
      .type-sfpro-bold();
      font-size: 14px !important;
      font-style: normal;
      line-height: 16px;
      margin-top: 13px;
    }

    span:last-child {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100%;
    }
    &:last-child {
      margin-right: 0;
      margin-bottom: 0px;
      @media screen and (max-width: 600px) {
        margin-right: 8px;
      }
    }
  }
}
