@import './margin.less';

.header-offset {
  height: calc(100vh - 4rem);
  margin-top: 4rem;
  position: relative;

  &--with-banner {
    height: calc(100vh - 6.5625rem);
    margin-top: 6.5625rem;
  }
}

@media (min-width: 48rem) {
  .header-offset {
    height: 100%;
    margin-top: 0;

    &--with-banner {
      height: calc(100vh - 2.5625rem);
      margin-top: 2.5625rem;
    }
  }
}

/*
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/

@media (min-width: 1281px) {
  .min-layout-content {
    width: 1080px;
  }

  .small {
    width: 580px;
  }
}

/*
  ##Device = Laptops, Desktops
  ##Screen = B/w 1025px to 1280px
*/
@media (min-width: 1025px) and (max-width: 1280px) {
  //CSS
  .min-layout-content {
    width: 1080px;
  }

  .small {
    width: 580px;
  }
}

/*
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/
@media (min-width: 768px) and (max-width: 1024px) {
  .min-layout-content {
    padding-left: 20px;
    padding-right: 20px;
  }

  .content-padding {
    padding: 15px;
  }

  .small {
    width: 580px;
  }
}

@media (max-width: 992px) {
  .logo {
    display: none;
  }

  .no-padding-mobile {
    padding: 0 !important;
  }

  .campaign-menu,
  .trend-menu-inline {
    text-align: center;
  }

  .ant-menu-item .anticon,
  .ant-menu-submenu-title .anticon {
    margin-right: 0 !important;
  }

  .ant-menu-inline.secondary .ant-menu-item svg,
  .ant-menu-inline.settings svg {
    float: none;
    margin-left: 0;
    margin-right: 0;
  }

  .side-small-title {
    font-size: 6px !important;
  }

  .plan-title {
    font-size: 8px !important;
  }

  .package-title {
    .m-0();
    font-size: 12px !important;
  }
}

.u-button-reset {
  align-items: center;
  background: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  padding: 0;
  transition: background-color 0.3s;
  width: max-content;

  &:focus-within {
    outline: revert !important;
  }

  &:disabled {
    background-color: @gray-50;
    cursor: not-allowed;
  }
}

.u-loading-indicator {
  animation: spin 1s ease-in-out infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}

.u-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.brand-login-btn {
  border: 0.0625rem solid @color-gray-lighter;
  border-radius: 0.25rem;
  padding: 0.25rem 0.5rem;

  &:hover {
    background: @color-gray-lightest;
  }
}

.content-preview-btn {
  border-radius: 0.5rem;
  height: 100%;
  overflow: hidden;
  width: 100%;
}

#project-tab {
  .type-sfpro-regular();
  padding: 0 0 0 15px;
  font-size: 12px;
  line-height: 18px;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: -0.004em;
}

#icon-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  #icon-text {
    .type-sfpro-regular();
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    white-space: nowrap;
    color: #fff;
  }
}

.sonar-wrapper {
  position: relative;
  z-index: 0;
  overflow: hidden;
}

.sonar-emitter {
  position: relative;
  margin: 13px 14px;
  width: 29.33px;
  height: 29.33px;
  border-radius: 9999px;
}

.sonar-wave {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 9999px;
  border: 1px #ffb917 solid;
  opacity: 0;
  z-index: -1;
  pointer-events: none;
}

.sonar-wave1 {
  animation: sonarWave 2s linear infinite;
}

.sonar-wave2 {
  animation: sonarWave 2s 0.5s linear infinite;
}

.sonar-wave3 {
  animation: sonarWave 2s 1s linear infinite;
}

@keyframes sonarWave {
  from {
    transform: scale(0.2);
    opacity: 1;
  }
  to {
    transform: scale(2);
    opacity: 0;
  }
}

@media (max-width: 47.9375rem) {
  .hidden-xmobile {
    display: none !important;
  }
}

/*
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/
@media (min-width: 320px) and (max-width: 480px) {
  .min-layout-content {
    padding-left: 20px;
    padding-right: 20px;
  }

  .content-padding {
    padding: 5px;
  }

  .small {
    width: 100%;
  }
}

.ant-layout-sider {
  .brand-image {
    position: relative;
    height: 182px;
    background: #333;
    .ant-avatar-image {
      height: 100%;
    }
    .ant-avatar-circle {
      background: #333;
      height: 100%;
    }

    .subscription-tag {
      margin-left: 61px;
      min-width: 87px;
      padding-top: 4.7px;
      padding-bottom: 5.5px;
      border-radius: 100px;
      background: #000;
      position: absolute;
      bottom: 15px;
      text-align: center;

      .name {
        display: inline-block;
        width: 48px;
        margin-bottom: 4px;
        vertical-align: bottom;
      }

      .icon {
        display: inline-block;
        margin-bottom: 4px;
        width: 14px;
        vertical-align: bottom;
      }
    }
  }
}

.side-menu {
  #archive-link-container {
    width: 100%;
    display: flex;
    justify-content: center;
    padding-top: 10px;
  }

  .menu-title {
    .type-sfpro-heavy();
    font-size: 12px;
    line-height: 18px;
    color: #fff;
  }

  .new-project {
    border-radius: 50%;
    margin-right: 0.3125rem;

    rect {
      transition: fill 0.3s;
    }

    &:hover,
    &:focus {
      rect {
        fill: lighten(@color-orange, 10%);
      }
    }

    &:focus-within {
      rect {
        fill: lighten(@color-orange, 10%);
      }
    }

    &:active {
      rect {
        fill: darken(@color-orange, 10%);
      }
    }
  }

  .toggle-projects-btn {
    path {
      transition: fill 0.3s;
    }

    &:hover,
    &:focus {
      path {
        fill: lighten(#6f767e, 30%);
      }
    }

    &:focus-within {
      path {
        fill: lighten(#6f767e, 30%);
      }
    }

    &:active {
      path {
        fill: lighten(#6f767e, 90%);
      }
    }
  }

  .icons {
    .new-project-icon {
      font-size: 20px;
    }
    .cheveron-icon {
      &:hover {
        cursor: pointer;
      }
      &.open {
        -webkit-transform: rotate(180deg);
        -moz-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        -o-transform: rotate(180deg);
        transform: rotate(180deg);
      }
    }
  }
}

.new-pill {
  padding: 0.125rem 0.5rem;
  border-radius: 1.75rem;
  background-color: @bubbletape-pink-30;
  color: @color-black;
  font-size: 0.5rem;
  font-weight: 700;
  text-transform: uppercase;
}

.sidebar-link {
  align-items: center;
  display: flex;
  gap: 0.75rem;
  justify-content: space-between;
  padding: 0.5rem 1rem;
  transition: background-color 0.2s;
  width: 100%;

  &:hover,
  &:focus {
    background-color: @gray-90;
  }

  &:focus-visible {
    background-color: @gray-90;
    outline: revert !important;
    outline-offset: -0.25rem;
  }

  &--active {
    background-color: @color-off-black;
    border-right: 0.1875rem solid @color-orange;
  }
}

.sidebar-icon-wrapper {
  align-items: center;
  background-color: @color-black;
  border: 0.0625rem solid @color-black-ter;
  color: @color-white;
  display: flex;
  height: 2.25rem;
  justify-content: center;
  font-size: 1.25rem;
  width: 2.25rem;
}

.sidebar-icon {
  height: 2.25rem;
  width: 2.25rem;

  rect {
    fill: @color-black;
  }

  &--account {
    height: 1.5625rem;
    width: 1.5625rem;

    & > g > g {
      stroke: @color-white;
      fill: @color-white;
    }
  }
}

.sidebar-link-copy {
  .type-sfpro-regular();
  color: @color-white;
  flex-grow: 1;
  font-size: 0.75rem;
  font-weight: 600;
}

#admin-layout {
  .ant-layout-content {
    background: #f6f7fb;
  }
  .page-header {
    width: 100%;
    padding-top: 47px;
    background: #fff;
    border-bottom: 1px solid #e8e8e8;

    h1 {
      font-size: 26px;
      font-family: 'TruenoExtraBold';
    }

    .ant-menu.ant-menu-horizontal {
      border-bottom: 0px;
    }
  }
}

.firebase-emulator-warning {
  display: none;
}

.standardized-image {
  height: 100%;
  max-height: 90rem;
  max-width: 90rem;
  object-fit: cover;
  width: 100%;
}
