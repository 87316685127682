// Override avatar to fix scaled images

.ant-avatar > img {
  object-fit: cover;
}

.post-avatar {
  position: relative;
  display: inline-block;

  .play-button {
    position: absolute;
    z-index: 1;
    font-size: 30px;
    left: 10px;
    top: 10px;
  }

  .empty-state {
    background: @color-white;
    border: 1px dashed #172337;
    box-sizing: border-box;
  }

  .story-type {
    color: #000;
    position: absolute;
    padding-top: 2px;
    height: 15px;
    bottom: -4px;
    width: 59px;
    left: -4px;
    text-align: center;
    text-transform: uppercase;
    font-family: FirmeBlackItalic;
    font-size: 8px;
    font-weight: 900;
    font-style: italic;
    font-stretch: normal;
    line-height: 1.38;
    letter-spacing: 0.4px;
    text-align: center;
    color: #000000;

    &.type-image {
      background: #ffd982;
    }

    &.type-video {
      background: #4df1ff;
    }

    &.type-story {
      background: #66bdff;
    }
  }
}

#all-post-page {
  h1 {
    margin-top: 38px;
    margin-bottom: 35px;
    font-size: 28px;
  }

  .post-card {
    width: 360px;
    height: auto;
    margin-bottom: 28px;
    background: transparent;

    &.trend-post-card,
    .trend-post-card-content {
      background: transparent;
      .ant-card-cover {
        border-radius: 5px 5px 0px 0px;
        background: transparent;
        img {
          border-radius: 5px 5px 0px 0px;
          object-fit: cover;
          height: 307px;
        }

        video {
          height: 307px;
          object-fit: cover;
        }
      }
      &:hover {
        border-radius: 5px 5px 0px 0px;
        box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.26);
      }

      .ant-card-meta-detail {
        width: calc(100% - 60px);
      }
    }

    .ant-card-body {
      border: 1px solid #e8e8e8;
      height: auto;

      .ant-avatar {
        width: 50px !important;
        height: 50px !important;
      }

      h2 {
        font-size: 16px !important;
      }
    }

    .post-stats {
      margin-top: 19px !important;
    }
  }

  .post-filters {
    margin-bottom: 49px;
    .ant-checkbox + span {
      vertical-align: top;
      padding-left: 9px;
      line-height: normal;
    }

    .ant-checkbox-wrapper {
      margin-left: 25px !important;
    }

    .trend-select {
      .ant-select-selection__placeholder {
        margin-top: -11px;
      }
      .ant-select-selection-selected-value {
        margin-top: -1px;
      }
    }
  }
}
