.ant-layout-sider,
.ant-menu-dark,
.ant-menu-dark .ant-menu-sub {
  background: @color-black !important;
  .sub-menu-item {
    span {
      vertical-align: middle;
    }

    .icon-posts {
      background-color: #151515;

      svg {
        margin: 6px 15px 15px 6px !important;
      }
    }
  }
}

.ant-layout {
  height: 100vh;
}

.ant-layout.with-top-banner {
  height: calc(100vh - 41px);
}
