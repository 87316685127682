.ant-layout-sider-dark {
  .ant-menu-item {
    &.ant-menu-item-selected {
      svg {
        .stroke {
          fill: #fff;
        }

        g[fill-rule='evenodd'] {
          stroke: #fff;
        }
      }
    }
    &.ant-menu-item-active {
      svg {
        .stroke {
          fill: #fff;
        }

        g[fill-rule='evenodd'] {
          stroke: #fff;
        }
      }
    }
  }
}
