.trend-select-dark-v2 {
  width: 448px;
}

.trend-selected-tags-dropdown-v2 {
  background: #272727 !important;
  border: 0;

  &.ant-select-dropdown--single {
    .ant-select-dropdown-menu {
      .ant-select-dropdown-menu-item-selected,
      .ant-select-dropdown-menu-item-active {
        color: black !important;
      }

      .ant-select-dropdown-menu-item:not(.ant-select-dropdown-menu-item-active, .ant-select-dropdown-menu-item-selected) {
        color: #989898 !important;
      }
    }
  }
}
