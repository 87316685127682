.campaign-summary {
  color: @color-black;
  max-width: 47rem;
  margin: 0 auto;

  @media (min-width: 48rem) {
    margin-top: 1.875rem;
  }

  .post-guidelines {
    padding: 1.5rem 1rem;
  }

  #image-container {
    width: 100%;
    height: auto;
    overflow: hidden;
    padding-bottom: 1.5rem;

    img {
      max-width: 100%;
      min-width: 100%;
      min-height: 17rem;
      object-fit: cover;
    }
  }

  .campaign-summary-wrapper {
    max-width: 100%;

    @media (min-width: 48rem) {
      display: flex;
      gap: 2rem;

      .left-column {
        width: 66.66667%;
      }

      .right-column {
        width: 33.33333%;
      }
    }
  }

  .video-text {
    .type-sfpro-heavy();
    font-size: 1rem;
    line-height: 1.375rem;
    margin: 0;
    padding: 1.5rem 1rem;
  }

  .header-text {
    .type-sfpro-bold();
    font-size: 14px;
    line-height: 12px;
    margin: 0;
  }

  .sub-header-text {
    .type-sfpro-medium();
    font-weight: 600;
    font-size: 12px;
    line-height: 12px;
    margin-bottom: 10px;
    margin-top: 22px;

    &.adjust-margin {
      margin-top: 17px;
    }
  }

  .text-description {
    .type-sfpro-regular();
    font-size: 0.875rem;
    line-height: 1.5rem;
  }

  .side-header-text {
    .type-sfpro-bold();
    font-size: 13px;
    line-height: 12px;
    margin-bottom: 20px;
  }

  .side-sub-header-text {
    .type-regular-sfpro-display();
    color: @color-pigeon-dark;
    font-size: 0.5625rem;
    font-weight: 600;
    line-height: 0.6875rem;
    letter-spacing: 0.0625rem;
    text-transform: uppercase;
  }

  .side-text-description {
    .type-sfpro-bold();
    color: @color-black;
    display: block;
    font-size: 0.75rem;
    line-height: 1rem;
    margin-bottom: 1.5rem;

    &--capitalize {
      text-transform: capitalize;
    }

    &--link {
      &:hover {
        color: lighten(@color-black, 40%);
      }

      &:focus-visible {
        outline: revert;
      }
    }

    &--platform {
      align-items: center;
      display: flex;
      text-transform: capitalize;

      svg path {
        fill: @color-black;
      }
    }
  }

  #campaignRecentPosts {
    width: 100%;
    padding: 1.5rem 1rem;

    @media (min-width: 48rem) {
      padding-top: 0;
    }

    .ant-col {
      &:nth-child(n + 3) {
        margin-top: 13px;
      }
    }

    .latest-content {
      align-items: center;
      display: flex;
      justify-content: space-between;
      margin-bottom: 0.5rem;
      width: 100%;

      a {
        align-items: center;
        color: @color-black;
        display: flex;
        font-size: 0.625rem;
        gap: 0.25rem;

        &:hover {
          color: lighten(@color-black, 40%);

          .see-all-icon path {
            fill: lighten(@color-black, 40%);
          }
        }

        &:focus-visible {
          outline: revert;
        }
      }
    }
  }

  .see-all-icon {
    width: 0.75rem;

    path {
      transition: fill 0.3s;
    }
  }

  .recent-content {
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(auto-fit, minmax(5rem, 1fr));
  }
}
