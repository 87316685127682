.ant-notification {
  &.trend-notifcation {
    &.notif-on {
      width: 261px;
      height: 85px;
      background: #FFFFFF;
      box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
      border-radius: 0px 2px 2px 0px;
      padding: 14px;
      float: right;

      .ant-notification-notice-content {
        .ant-notification-notice-with-icon {
          .ant-notification-notice-icon {
            margin-left: 0;
          }

          .ant-notification-notice-message {
            .type-sfpro-heavy();
            color: #000;
            font-size: 14px;
            line-height: 14px;
            margin-left: 72px;
          }

          .ant-notification-notice-description {
            .type-sfpro-regular();
            color: #000;
            font-size: 12px;
            line-height: 16px;
            margin-left: 72px;
          }
        }
      }

      .ant-notification-notice-close {
        display: none;
      }
    }
  }
}