.content-showcase-page-header {
  .type-sfpro-regular();
  align-items: center;
  background: @color-primary-brown;
  display: flex;
  flex-wrap: wrap-reverse;
  gap: 1rem;
  justify-content: center;
  padding: 2rem 1rem;
  width: 100%;

  .header-content {
    flex: 0 1 25rem;
  }

  .header-title {
    font-weight: 700;
    font-size: 1.5625rem;
    line-height: 2rem;
    margin-bottom: 0.75rem;
  }

  .header-text {
    color: @color-black;
    font-size: 0.875rem;
    line-height: 1.375rem;
    margin-bottom: 0;
  }

  .header-inline-link {
    color: @color-black;
    text-decoration: underline;
    transition: color 0.2s;

    &:hover,
    &:focus {
      color: lighten(@color-black, 40%);
      text-decoration: underline;
    }

    &:focus-visible {
      outline: revert !important;
    }
  }

  .video-section {
    align-items: center;
    display: flex;
    flex: 0 1 auto;
    flex-direction: column;
    gap: 1rem;
  }

  .trend-video-arrow {
    display: none;
  }

  .info-video {
    border: none;
    border-radius: 0.3125rem;
    max-height: 12.5rem;
    max-width: 20rem;
    width: 100%;
  }

  .onboarding-link {
    .type-sfpro-heavy();
    background-color: @color-orange;
    border-radius: 0.125rem;
    color: @color-black;
    margin-top: 1rem;
    min-width: fit-content;
    padding: 1rem 0.875rem;
    width: 100%;

    &:hover,
    &:focus {
      background-color: lighten(@color-orange, 10%);
    }

    &:active {
      background-color: darken(@color-orange, 10%);
    }
  }

  @media (min-width: 48rem) {
    flex-wrap: wrap;

    .video-section {
      flex-direction: row;
      gap: 0;
    }

    .trend-logo {
      display: none;
    }

    .trend-video-arrow {
      align-items: center;
      display: flex;
      flex-direction: column;
      height: 5.875rem;
      justify-content: center;
      width: 5.75rem;

      p {
        font-family: 'Syne', sans-serif;
        font-weight: 700;
        font-size: 0.625rem;
        line-height: 0.75rem;
        margin-bottom: -0.5625rem;
        width: 4.375rem;
      }
    }

    .info-video {
      max-width: 12.5rem;
    }

    .onboarding-link {
      width: max-content;
    }
  }
}

.content-showcase-nav {
  align-items: center;
  border-bottom: 0.0625rem solid @color-gray-lighter;
  display: flex;
  justify-content: center;
  overflow: hidden;

  ul {
    list-style: none;
    display: flex;
    gap: 0.5rem;
    margin: 0;
    overflow-x: auto;
    padding: 1rem 0.25rem;
  }

  .nav-button {
    border-radius: 1rem;
    color: @color-black;
    font-family: 'Helvetica Neue';
    font-size: 0.75rem;
    line-height: 0.875rem;
    padding: 0.5rem 1rem;
    transition: background-color 0.3s, color 0.2s;

    &.selected {
      background: @color-black;
      color: @color-white;
    }

    &:hover,
    &:focus {
      background: @color-black;
      color: @color-white;
    }
  }
}

.main-content-wrapper {
  align-items: center;
  background: @color-white;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin: 0 auto;
  padding: 1rem;
  width: 100%;

  .content-choice-details {
    .type-sfpro-regular();
    color: @color-black;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 1rem;
  }

  @media (min-width: 48rem) {
    max-width: 47.75rem;
    padding: 2rem;
  }
}

.content-showcase-section {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: center;

  .content-card {
    display: flex;
    flex: 1 1 30%;
    justify-content: center;
  }

  .video-thumbnail {
    border-radius: 0.75rem;
    position: relative;
  }

  .video-loader {
    display: none;
  }

  .content-card-inner {
    align-items: flex-start;
    border-radius: 0.75rem;
    flex-direction: column;
    gap: 1rem;
    overflow: hidden;

    &:hover,
    &:focus {
      .content-img {
        transform: scale(1.07);
      }
    }
  }

  .content-img {
    border-radius: 0.75rem;
    cursor: pointer;
    height: 10.625rem;
    object-fit: cover;
    overflow: hidden;
    transition: transform 0.2s;
    width: 13.125rem;
  }

  .creator-figure {
    display: flex;
    align-items: center;
    gap: 0.375rem;

    .creator-avatar {
      border-radius: 50%;
      height: 2rem;
      object-fit: cover;
      width: 2rem;
    }

    .creator-name {
      .type-sfpro-regular();
      color: @color-black;
      font-weight: 700;
      font-size: 0.75rem;
      margin-bottom: 0;
    }
  }

  @media (min-width: 48rem) {
    gap: 2rem;

    .video-thumbnail {
      display: none;
    }

    .video-loader {
      display: block;
      height: 23.25rem;
      width: 13.125rem;

      .video-player-actions {
        height: 3.75rem;
        left: 50%;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 3.75rem;

        .anticon {
          font-size: 3.4375rem;
        }
      }

      video {
        border-radius: 0.75rem;
      }
    }
  }
}

.faq-section {
  .type-sfpro-regular();

  .faq-title {
    margin-bottom: 0.875rem;
  }

  ul {
    align-items: stretch;
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 0.75rem;
    list-style-type: none;
    margin: 0;
    padding: 0;
    width: 100%;
    flex-grow: 1;
  }

  .faq-card {
    background: @color-primary-brown;
    border-radius: 0.75rem;
    color: @color-black;
    font-size: 1rem;
    line-height: 1.25rem;
    width: 100%;

    ul {
      text-decoration: none;
    }

    .faq-toggle-btn {
      align-items: center;
      display: flex;
      gap: 2rem;
      justify-content: space-between;
      padding: 1.5rem 2rem;
      text-align: left;
      width: 100%;

      span {
        font-weight: 600;
      }

      .plus-icon {
        animation: rotateIconClose 0.2s;
        animation-fill-mode: forwards;
        flex-shrink: 0;

        &.open {
          animation: rotateIconOpen 0.2s;
          animation-fill-mode: forwards;
        }
      }
    }
    .answer-drawer {
      display: none;

      &.open {
        display: block;
        padding: 0 2rem;
      }
    }
  }
}

@keyframes rotateIconOpen {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(45deg);
  }
}

@keyframes rotateIconClose {
  from {
    transform: rotate(45deg);
  }
  to {
    transform: rotate(0deg);
  }
}

.content-modal {
  background-color: @color-black;
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 90; // to compete with the sidebar

  .content-modal-body {
    align-items: center;
    background: @color-white;
    border-radius: 0.75rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    overflow-y: auto;
    padding: 3rem 1rem 1rem 1rem;
    height: 100%;
  }

  h3 {
    font-size: 1.5rem;
    margin: 0;
  }

  .close-btn {
    border-radius: 0.375rem;
    padding: 0.5rem;
    position: absolute;
    right: 1rem;
    top: 1rem;

    &:hover {
      background-color: @color-gray-lightest;
    }

    &:active {
      background-color: transparent;
    }

    svg * {
      stroke: @color-black;
    }
  }

  .asset-container {
    display: flex;
    justify-content: center;
    max-height: 40rem;
    max-width: 40rem;

    img,
    video {
      border-radius: 0.75rem;
      max-height: 100%;
      max-width: 100%;
    }
  }

  .creator-details {
    .type-sfpro-regular();
    color: @color-black;
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    justify-content: space-between;
    margin: 0 auto;
    max-width: 100%;
    padding-top: 1rem;
    width: max-content;

    button {
      background-color: @color-orange;
      border-radius: 0.25rem;
      font-weight: 700;
      padding: 0.5rem 1rem;

      &:hover {
        background-color: lighten(@color-orange, 10%);
      }

      &:active {
        background-color: @color-orange;
      }
    }
  }

  .creator-container {
    align-items: center;
    display: flex;
    gap: 0.25rem;

    img {
      object-fit: cover;
      width: 3.125rem;
      height: 3.125rem;
      border-radius: 50%;
    }

    h4 {
      font-weight: 700;
      font-size: 16px;
      margin: 0;
    }

    p {
      font-size: 0.75rem;
    }
  }
}
