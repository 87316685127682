#admin-brands-active-campaigns {
  padding-bottom: 25px;

  .search-filters {
    margin-top: 15px;
    margin-bottom: 28px;

    .trend-select {
      width: 100%;
      .ant-select-selection__clear {
        right: 25px;
      }
    }
  }

  .campaign-card {
    cursor: pointer;
    caret-color: transparent;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.15);
    background-color: #ffffff;
    color: #000;
    font-size: 14px;
    font-family: 'TruenoBold';
    margin-bottom: 20px;

    &-name {
      margin-bottom: 18px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
    &-credits {
      font-size: 14px;
      font-family: 'TruenoBold';
      margin-bottom: 19px;
    }
    &-upper {
      padding: 21px 18px 19px;
      border-bottom: 1px solid #ecedf7;
    }

    &-lower {
      padding: 19px 18px 16px;
      font-size: 11px;
    }
    &-type {
      cursor: pointer;
      font-size: 20px;
      position: absolute;
      right: 20px;
      top: 18px;
    }

    .brand-details {
      font-size: 11px;
      font-family: 'TruenoRegular';

      &-name {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
      &-health {
        .health-icon {
          margin-right: 6px;
          width: 8px;
          height: 8px;
          display: inline-block;
          border-radius: 100%;

          &.poor {
            background: #d92e2e;
          }
          &.range,
          &.in.range {
            background: #ffb917;
          }
          &.great {
            background: #15f590;
          }
        }

        .health-label {
          text-transform: capitalize;
        }
      }
    }

    .campaign-type {
      font-family: 'TruenoRegular';
    }

    .campaign-startdate {
      font-family: 'TruenoRegular';
      margin-bottom: 16px;
    }

    .campaign-stats {
      .stat {
        font-size: 12px;
        margin-bottom: 16px;

        &:last-child {
          margin-bottom: 0px;
        }
      }
      .stat-health {
        font-size: 8px;
        font-family: 'TruenoRegularItalic';
        text-transform: uppercase;

        &.great {
          color: #15f590;
          .stat-icon {
            background: #15f590;
          }
        }

        &.range,
        &.in.range {
          color: #ffb917;
          .stat-icon {
            background: #ffb917;
          }
        }

        &.poor {
          color: #ff0000;
          .stat-icon {
            background: #ff0000;
          }
        }
      }
      .stat-icon {
        width: 8px;
        height: 8px;
        margin-right: 3px;
        display: inline-block;
        border-radius: 100%;
      }
    }
  }
}

@primary-color: #ffb917;@info-color: #ffb916;@input-addon-bg: transparent;@slider-track-background-color: @primary-color;@slider-track-background-color-hover: @primary-color;