#message-page {
  header {
    background-color: #fff;
    border-bottom: 1px solid #d6d6d6;
    height: 100px;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1;
    padding: 0 1rem;

    @media (min-width: 700px) {
      width: calc(100% - 210px);
    }
  }

  footer {
    background-color: #F1F5FD;
    position: fixed;
    bottom: 0;
    width: 100%;
    border-top: 1px solid #e8e8e8;
    margin: 0;
    padding: 13px 24px 13px 24px;
    z-index: 1;

    @media (min-width: 700px) {
      width: calc(100% - 210px);
    }
  }

  .message-attachment {
    width: 89px;
    height: 49px;
    margin-right: 5px;
    border-radius: 4px;
    position: relative;

    &-close {
      position: absolute;
      top: -5px;
      right: -4px;
      width: 20px;
      height: 20px;
      padding: 0px;
      background-color: #0a5bfa;
      color: #fff;
      border: none;

      &:hover {
        opacity: 0.7;
      }
    }

    &-file {
      width: 89px;
      height: 49px;

      video,
      img {
        border-radius: 3px;
        object-fit: contain;
      }
    }
  }

  .back {
    .type-sfpro-regular();
    color: #000;
    font-weight: bold;
    vertical-align: top;
    display: inline-block;
    margin-top: 9px;
    border: none;
    border-right: 1px solid #d6d6d6;
    margin-right: 21px;
    font-size: 12px;
  }

  .influencer-name {
    .type-sfpro-regular();
    color: #000;
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 33px;
  }

  .influencer-profile {
    line-height: 12px;
    button {
      .type-sfpro-regular();
      color: '#ffb917';
      font-style: normal;
      font-weight: bold;
      font-size: 12px;
      line-height: 12px;
      height: 12px;
      padding: 0px;
    }
  }

  .rehire-button {
    .type-sfpro-regular();
    position: absolute;
    right: 0px;
    font-weight: bold;
    font-size: 11px;
    line-height: 13px;
    text-align: left;
  }

  .input-actions {
    position: absolute;
    right: 0;

    .upload-btn {
      margin-right: 12px;
      display: inline-block;
      vertical-align: top;
      border-left: 1px solid #b2c1d6;
      padding-left: 13px;
    }

    .trend-btn {
      text-transform: initial;
      vertical-align: top;
    }
  }
}

.text-control {
  word-wrap: break-word;
  word-break: break-word;
  white-space: pre-wrap;      /* CSS3 */
  white-space: -moz-pre-wrap; /* Firefox */
  white-space: -pre-wrap;     /* Opera <7 */
  white-space: -o-pre-wrap;   /* Opera 7 */
}
