#chartContainer {
  height: 100;
  width: 100%;
  margin: 0 auto;
  position: block;
}

#chart-cursor {
  position: absolute;
  height: 100;
  width: 100%;
  margin: 0 auto;
  top: 0;
  left: 0;
}

#postGraph {
  position: relative;
  width: 100% !important;
  overflow: hidden;
}

#chartjs-tooltip {
  background-color: #fff;
  box-shadow: -4px 4px 10px -2px #ddd;
  padding: 8px 15px 10px;

  .tooltip-container {
    width: 140px;

    .tooltip-title {
      text-transform: uppercase;
      font-size: 8pt;
      color: #a7afbe;
    }

    .tooltip-item {
      font-family: 'TruenoBold';
      font-size: 9pt;

      &-icon {
        width: 10px;
        height: 10px;
        border-radius: 10px;
        display: inline-block;
      }

      &-label {
        display: inline-block;
        padding-left: 5px;
      }

      &-value {
        float: right;
      }
    }
  }

}
