.ant-menu-item-group-title {
  .type-sfpro-heavy();
  color: white !important;
  font-style: normal !important;
  font-weight: 800 !important;
  font-size: 12px !important;
  line-height: 18px !important;
}

.ant-menu.trend-menu-horizontal {
  border: 0;
  max-width: 742px;
  margin: auto;
  display: flex;
  align-items: stretch;
  justify-content: center;

  @media (min-width: 700px) {
    padding: 0;
  }

  .trend-badge {
    margin-top: -1.5rem;
  }

  .ant-menu-item {
    flex: 1;
    width: auto;
    align-content: center;
    text-align: center;
    line-height: 1rem;
    white-space: wrap;

    @media (min-width: 700px) {
      white-space: nowrap;
    }

    a {
      padding: 12px 0;
      font-size: 14px;
      .type-sfpro-regular();
    }

    &:hover {
      a {
        color: @color-black !important;
      }
    }
  }

  & > .ant-menu-item-selected {
    border-color: transparent;

    & > a {
      .type-sfpro-bold();
      color: @color-black !important;

      &:after {
        content: '';
        clear: both;
        display: block;
        position: absolute;
        left: 0;
        right: 0;
        bottom: -4px;
        margin: auto;
        width: 31px;
        height: 4px;
        background-color: @color-orange;
      }
    }

    &:hover {
      border-color: transparent;
    }
  }
}

.account-menu {
  border: 0 !important;
  background: transparent !important;

  & > .ant-menu-item {
    & > a {
      padding-bottom: 14px;
      font-size: 15px;
      color: #747474 !important;
      font-family: 'SFProDisplay', @system-sans;

      &:focus-visible {
        outline: revert !important;
      }
    }

    &-selected {
      & > a {
        .type-bold-sfpro-display();
        color: @color-white !important;
      }
    }
  }
}

.trend-menu-inline {
  &.ant-menu-inline-collapsed > .ant-menu-item {
    padding: 0px 16px !important;
  }

  a.campaign-link.first-campaign {
    display: inline;
    .add-campaign {
      animation: radar-animation 1s ease-out infinite;
      border-radius: 100%;
      z-index: 10;
    }
  }
}
@keyframes radar-animation {
  0% {
    box-shadow: 0 0 0 0 rgba(249, 249, 249, 1);
  }
  100% {
    box-shadow: 0 0 0 7px rgba(249, 193, 202, 0);
  }
}
// Sider

.ant-menu-inline {
  &.settings {
    .ant-menu-item {
      position: fixed;
      bottom: 0px;
      width: 20px !important;

      a {
        position: fixed;
        bottom: 0;
        left: 0;
        height: 65px;
        background: #151515 !important;
        border-top: 1px solid #3c3c3c;
        padding: 12px;
        .type-sfpro-regular();

        svg {
          vertical-align: middle;
          margin-bottom: 3px;
        }
      }
    }
  }
  &.settings.with-notification {
    .ant-menu-item {
      a {
        margin-bottom: 68px;
      }
    }
  }

  &.secondary {
    .pb-6();
    min-height: 80px;

    .ant-menu-item {
      height: 50px !important;
      margin-bottom: 0 !important;
      margin-top: 2px;

      a {
        padding: 5px 0;
      }

      svg {
        float: left;
        margin: 8px 15px 15px 8px;
      }
    }
  }

  &.trend-menu-inline {
    .ant-menu-submenu-title {
      margin: 0 !important;
      height: 100% !important;
      padding-top: 10px;
      padding-bottom: 10px;
      line-height: 1 !important;
      cursor: default;
      pointer-events: none;

      &:hover {
        background: transparent !important;
      }
    }

    .ant-menu-submenu-title,
    .ant-menu-item {
      padding-left: 15px !important;
      margin-bottom: 4px !important;

      &:hover {
        background-color: #161616;
      }

      a {
        .size-11px();
        .text-gray-dark();
        .type-sfpro-regular();
        span:not(.box-border, .new-pill) {
          .type-sfpro-regular();
          font-weight: 600;
          .size-11px();
        }
      }
    }

    .ant-menu-sub {
      .ant-menu-item {
        height: 50px;
        margin: 0;
        padding: 5px 0;
        margin-bottom: 4px;
      }
    }
  }
}

.ant-menu-sub {
  .trend-badge {
    display: inline-flex;
    margin-right: 0.5rem;
  }
}

.ant-layout-sider-children {
  .ant-menu-item-selected {
    background-color: #2b2b2b !important;

    a {
      color: #fff !important;
    }

    &:after {
      content: '';
      position: absolute;
      right: 0px !important;
      width: 3px;
      height: 100%;
      background-color: @color-orange;
    }
  }
}
.add-credit-message {
  width: 100%;
  position: fixed;
  bottom: 0;
  height: 68px;
  line-height: 68px;
  .type-sfpro-heavy();
  color: #fff;
  background: @color-black;
  overflow: hidden;
  white-space: nowrap;
  box-sizing: border-box;
  margin: 0 auto;
  cursor: pointer;
  z-index: 50;

  &.expired {
    p {
      padding-right: 50px;
      color: #ffb917;
      font-weight: 800;
    }
  }
  p {
    font-size: 22px;
    font-weight: bold;
    font-style: italic;
    letter-spacing: -0.008em;
    display: inline-block;
    /* show the marquee just outside the paragraph */
    animation: marquee 8s linear infinite;
  }

  .countdown {
    margin-left: 24px;
    margin-right: 24px;
    color: #27aa5c;
  }
  .countdown-dot {
    font-size: 12px;
    line-height: 22px;
    font-style: normal;
  }
}

/* Make it move */

@keyframes marquee {
  0% {
    transform: translate(0, 0);
  }
  100% {
    display: none;
    transform: translate(-25%, 0);
  }
}

.with-notification {
  margin-bottom: 68px;
}

.menu-section {
  .type-sfpro-regular();
  .campaign-menu {
    .ant-menu-item-group {
      .ant-menu-item-group-title {
        display: none;
      }
    }
  }
}

.campaign-menu {
  .ant-menu-sub {
    li {
      padding-left: 30px !important;
    }
  }

  .ant-menu-item {
    height: 50px !important;
    line-height: 49px !important;
    padding-right: 0 !important;
  }

  .campaign-link {
    align-items: center;
    display: flex;
    gap: 1rem;
    height: 100%;
    white-space: normal !important;

    &:focus-visible {
      outline: revert !important;
      outline-offset: -0.3125rem;
    }

    .ant-row-flex {
      height: 50px;
      max-height: 50px;
    }
    .text-wrap();
    .ant-avatar {
      width: 36px;
      float: left;
    }
  }

  .campaign-avatar {
    flex-shrink: 0;
  }

  .campaign-item {
    .type-sfpro-regular();
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    display: -webkit-box;
    font-size: 0.6875rem;
    font-weight: 600;
    line-clamp: 3;
    line-height: 1.3;
    margin-bottom: 0;
    overflow: hidden;
    padding-right: 1rem;
    text-align: left;
  }
}

.trend-campaign-avatar {
  position: relative;
  overflow: hidden;
  background: #1f1f1f !important;

  img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

// Header
.platform-header {
  align-items: center;
  background-color: @color-white;
  display: flex;
  padding: 1rem 1.25rem;
  position: fixed;
  top: 0;
  width: 100%;

  @media (min-width: 48rem) {
    display: none;
  }
}

.nav-toggle {
  align-items: center;
  background: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  height: 1.25rem;
  justify-content: center;
  padding: 0;
  position: relative;
  width: 1.5rem;

  &::before {
    background-color: @color-black;
    border-radius: 0.1875rem;
    content: '';
    height: 0.1875rem;
    left: 0;
    position: absolute;
    top: 0;
    transition: transform 0.2s;
    width: 100%;
  }

  &::after {
    background-color: @color-black;
    border-radius: 0.1875rem;
    bottom: 0;
    content: '';
    height: 0.1875rem;
    position: absolute;
    right: 0;
    transition: transform 0.2s;
    width: 100%;
  }

  span {
    background-color: @color-black;
    border-radius: 0.1875rem;
    height: 0.1875rem;
    opacity: 1;
    transition: opacity 0.5s;
    width: 100%;
  }
}

.nav-toggle[aria-expanded='true'] {
  &::before {
    top: 41%;
    transform: rotate(45deg);
  }

  &::after {
    bottom: 41%;
    transform: rotate(-45deg);
  }

  span {
    opacity: 0;
    visibility: hidden;
    transition: 0s;
  }
}

.header-logo {
  display: block;
  height: 1.4375rem;
  margin: 0 auto;
  width: 5.0625rem;
}

.sidenav-logo {
  display: none;

  @media (min-width: 48rem) {
    display: block;
    height: 3.75rem;
    margin: 0 auto;
    width: 7.5rem;
  }
}

// Promo Banner
.promo-banner {
  background-color: @color-orange;
  position: sticky !important;
  text-align: center;
  top: 4rem;

  a {
    color: @color-black;
    display: block;
    padding: 0.612rem 0;
    transition: background-color 0.3s;

    &:hover,
    &:focus {
      background-color: lighten(@color-orange, 10%);
      color: @color-black;
    }

    &:focus-visible {
      outline: revert !important;
      outline-offset: -0.125rem;
    }

    &:active {
      background-color: darken(@color-orange, 10%);
    }
  }

  @media (min-width: 48rem) {
    top: 0;
  }
}

// Sidebar
.sidenav-wrapper {
  flex: 0 0 15rem !important;
  height: calc(100dvh - 4rem);
  max-width: none !important;
  min-height: calc(100dvh - 4rem);
  min-width: auto !important;
  opacity: 0;
  overflow: hidden;
  position: fixed !important;
  top: 4rem;
  visibility: hidden;
  width: 0 !important;
  z-index: 90;

  &--visible {
    opacity: 1;
    visibility: visible;
    width: 100% !important;
  }

  @media (min-width: 48rem) {
    flex: 0 0 15rem !important;
    height: 100%;
    min-height: 100%;
    opacity: 1;
    position: relative !important;
    top: 0;
    visibility: visible;
    width: auto !important;
  }
}

.ant-layout-sider-children {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

.sidenav-header {
  background: linear-gradient(66deg, #2c2c2c 0.99%, @color-black 96.59%);
  padding: 1.25rem;

  @media (min-width: 48rem) {
    padding: 1.25rem 1rem;
  }
}

.sidenav-main-wrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 1rem;
}

.sidenav-campaigns {
  flex-grow: 1;
}

.sidenav-footer {
  background-color: @color-black;
  border-top: 0.0625rem solid @gray-90;
  list-style: none;
  padding: 0;
}

.side-small-title {
  .text-gray-darker();
  .spacing-05px();
  .size-8px();
  .type-sbold();
}

.credit-title-block {
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: space-between;

  @media (min-width: 48rem) {
    flex-direction: column;
  }
}

.credit-value {
  .type-sfpro-heavy();
  color: @color-white;
  font-size: 1.625rem;
  line-height: 1.9375rem;
  display: block;
  text-align: center;
}

.credit-title {
  .type-sfpro-heavy();
  color: @color-white;
  font-size: 0.75rem;
  letter-spacing: 0.075rem;
  text-transform: uppercase;
}

.credit-desc {
  .type-sfpro-regular();
  color: @gray-40;
  font-size: 0.75rem;
  letter-spacing: 0em;
  line-height: 1.25rem;
  margin-bottom: 0;
}

.credit-add.ant-btn {
  .type-sfpro-heavy();
  color: @color-black;
  border-radius: 0.3125rem;
  font-size: 0.875rem;
  height: auto;
  line-height: 1.125rem;
  padding: 0.5rem 1rem;
  text-shadow: none;
  width: max-content;

  &:hover,
  &:focus,
  &:active {
    color: @color-black;
  }

  &:focus-visible {
    color: @color-black;
    outline: revert !important;
  }
}

.package-title {
  .type-ebold();
  .size-20px();
  padding-top: 2px;
  margin-bottom: 7px;
  color: #3c3c3c;
}

.trend-menu-item-dark {
  background-color: #151515 !important;
  overflow: scroll;
  .ant-dropdown-menu-item {
    background-color: #151515 !important;
    color: #5a626e !important;
    font-size: 10px;
    &:hover {
      background-color: @color-black !important;
    }
  }
}
.trend-selected-tags-dropdown {
  background-color: #151515 !important;
}

.trend-select-states-selector {
  .trend-selected-tags .ant-select-selection__rendered ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: inline;
    li.ant-select-selection__choice {
      display: inline-block;
      background: #ffb917 !important;
      border-radius: 2px;
      border: 0 !important;
      height: 34px;
      padding: 7px 12px;
      margin-bottom: 8px;
      margin-right: 10px;
      font-size: 11px;
      color: @color-black;
    }
  }

  .trend-selected-tags .ant-select-selection__rendered {
    background-image: none;
    width: auto;
    height: auto !important;
    margin-right: 33px;
    margin-top: 15px;
    margin-bottom: 5px;
    margin-left: 15px;
  }

  .trend-dropdown-option {
    &:hover {
      background-color: @color-black !important;
    }
  }

  .trend-states-custom-caret {
    position: absolute;
    color: #ffffff;
    left: 100%;
    margin-left: -25px;
    top: 50% !important;
    z-index: 1 !important;
    cursor: pointer;
  }
}

.trend-selected-tags {
  .ant-select-selection--multiple {
    width: 100% important;
    display: 'inline-block';
    cursor: pointer;
    &::before {
      font-family: FontAwesome;
      content: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='14' height='14' fill='white' stroke='none'><path d='M0,0 L5,6 L10,0 L0,0 Z'></path></svg>");
      padding-left: 0.5em;
      vertical-align: middle;
      position: absolute;
      right: 9px;
      top: 48%;
      color: #ffffff;
    }
  }
}

.trend-states-custom-caret {
  position: absolute;
  color: #ffffff;
  left: 100%;
  margin-left: -25px;
  top: 64px;
  z-index: 1 !important;
  cursor: pointer;
}

.trend-selection_choice {
  display: inline-block;
  background: #ffb917 !important;
  border-radius: 2px;
  border: 0 !important;
  padding: 7px 24px 7px 12px;
  font-size: 11px;
  color: @color-black;
  margin-right: 8px;
  min-width: 34px;
  margin-right: 10px;
  margin-bottom: 10px !important;
}

.trend-dropdown-option {
  &:hover {
    background-color: @color-black !important;
  }
}
.side-menu {
  padding-top: 1rem;

  .anticon.add-campaign {
    font-size: 36px;
    color: #fff;
    vertical-align: middle;
  }
}
