.creator-content-recent-card {
  span {
    display: block;
    max-width: 100%;
  }

  img {
    height: 6rem;
    object-fit: cover;
    width: 100%;
  }

  .username {
    .type-sfpro-regular();
    font-style: normal;
    font-weight: bold;
    font-size: 10px;
    line-height: 14px;
  }
}
