.campaign-stats {
  border-bottom: 0.1875rem solid @color-black;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: space-between;
  padding: 0 1rem 1.5rem 1rem;

  .stats-heading {
    .type-sfpro-bold();
    font-size: 1rem;
    line-height: 1.875rem;
    margin: 0;
  }

  p {
    .type-regular-sfpro-display();
    color: @color-pigeon-dark;
    font-size: 0.625rem;
    font-weight: 600;
    letter-spacing: 0.03125rem;
    line-height: 0.75rem;
    margin: 0;
    text-transform: uppercase;
  }
}
