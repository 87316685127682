.tag-select-v2 {
  .ant-tag {
    padding: 0 10px;
  }

  .trend-tag {
    .type-sfpro-bold();
    background-color: @color-white !important;
    font-size: 11px;
  }

  svg {
    height: 10px;
    margin-bottom: -2px;
    margin-left: 10px;
    width: 10px;
  }
}
