.message-col {
  cursor: pointer;
}

.list-message {
  .type-sfpro-regular();
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 6;
  display: -webkit-box;
  font-size: 0.6875rem;
  font-weight: 600;
  line-height: 1.3;
  margin-bottom: 0;
  overflow: hidden;
  text-align: left;
  max-width: 13.75rem;
  height: 100%;

  @media (min-width: 700px) {
    -webkit-line-clamp: 2;
  }
}
