#creator-content-approval-content {
  .submitted-contents {
    padding: 31px 10px 39px;
    background-color: #f1f5fd;
    overflow-x: scroll;
    overflow-y: hidden;

    .empty {
      .type-sfpro-heavy();
      text-align: center;
      font-style: italic;
      font-weight: 800;
      font-size: 24px;
      line-height: 16px;
      /* identical to box height, or 62% */

      text-align: center;
      text-transform: uppercase;
      margin-top: 35px;
      margin-bottom: 26px;

      color: #dbe3ee;
    }

    .post-card {
      display: inline-block;
      margin-right: 20px;
    }
  }

  .creator-content-card {
    float: left;
    margin-right: 20px;

    .video-loader {
      .anticon {
        font-size: 38px;
      }
    }
  }

  .trend-table .ant-table-thead th {
    text-align: left !important;
  }
}
