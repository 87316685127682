.influencer-avatar {
  color: #000;

  &:hover,
  &:active {
    color: #000;
  }
  span {
    display: block;
  }
  &-image {
    float: left;
    position: relative;
    margin-right: 10px;
  }
  .influencer-vip {
    position: absolute;
    bottom: 1px;
    left: 35px;
  }

  .influencer-info {
    .type-sfpro-light();
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 0.625rem;

    &-username {
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 18px;
      margin: 0;
    }

    &-state {
      font-weight: 400;
      font-size: 11px;
      line-height: 18px;
      margin: 0;
    }
  }
}

.ant-btn.influencer-instagram-button {
  .type-sfpro-regular();
  font-style: normal;
  font-weight: bold;
  font-size: 14px;

  border: none;
  border-radius: 3px;
  display: inline-block;
  height: 48px;
  line-height: 47px;
  background: #f1f5fd;
  color: #000;

  &:hover {
    color: #000;
    background: #dce5f7;
  }

  img {
    vertical-align: middle;
    height: 28px;
    width: 28px;
    margin-right: 9px;
  }
}
