.rehire-modal {
  .type-sfpro-regular();
  .ant-modal-body {
    @media (min-width: 700px) {
      padding: 0px;
    }
  }

  .ant-modal-content {
    border-radius: 10px;
  }

  .close {
    background: #ffffff;
    border: 1px solid #000000;
    box-sizing: border-box;
    border-radius: 4px;
    width: 45px;
    height: 45px;
    position: absolute;
    right: 25px;
    top: 25px;
    padding: 0px;

    i {
      font-size: 15px;
    }

    img {
      width: 14px;
      height: 15px;
    }
  }

  .user-avatar {
    text-align: center;
    position: relative;
    margin-top: 23px;
    margin-bottom: 13px;

    .ambassador-label {
      position: absolute;
      top: 73px;
      left: -11px;
      width: 112px;
      height: 26px;
      background: #ffffff;
      border: 1px solid #000000;
      box-sizing: border-box;

      img {
        width: 93.35px;
        height: 7.13px;
      }
    }
  }

  .credit-cost-rehire {
    margin-top: 31px;
    margin-bottom: 47px;
    border: 1px solid #d8d8d8;
    border-radius: 9px;
    width: 448px;
    height: 104px;
  }

  h1 {
    .type-sfpro-bold();
    font-style: normal;
    font-weight: 800;
    font-size: 22px;
    line-height: 26px;
    margin-bottom: 40px;

    text-align: center;
  }

  .ant-form {
    .ant-form-item-label {
      font-style: normal;
      font-weight: 800;
      font-size: 16px;
      line-height: 20px;
      padding-bottom: 9px;
      color: #000;

      label {
        .type-sfpro-bold();
      }

      p {
        font-weight: normal;
        font-size: 14px;
        line-height: 22px;
      }
    }
    .ant-input {
      font-weight: bold;
      font-style: normal;
      line-height: 16px;
      background: #f1f5fd;
      border-radius: 8px;
      border: none;
      padding: 18px 16px;
      color: #000;

      &::placeholder {
        color: #5b6572;
      }
    }
    input.ant-input {
      height: 55px;
    }

    textarea.ant-input {
      height: 142px;
    }
    .ant-form-item {
      margin-bottom: 40px;
      padding-bottom: 0px;
    }
    .shipping {
      .ant-form-item-control {
        height: 55px;
      }
    }

    .ant-radio-group {
      &.ant-radio-group-outline {
        width: 100%;
        .ant-radio-button-wrapper {
          height: 51px;
          line-height: 51px;
          text-align: center;
          display: inline-block;
          width: 50%;
          border: none;
          background: #f1f5fd;
          font-style: normal;
          font-weight: bold;
          font-size: 14px;
          box-shadow: none;

          &:hover {
            background-color: #dce5f7;
            color: #000;
          }

          &-checked {
            background: #ffb917;
            color: #000;
            &:hover {
              background: #df9e09;
            }
          }
          &:before {
            background: transparent;
          }
        }
      }
    }
    .ant-select-selection {
      padding-top: 19px;
      padding-left: 16px;
      padding-right: 16px;
      background: #f1f5fd;
      border-radius: 8px;
      border: none;
      height: 75px;

      .ant-select-search__field__wrap {
        vertical-align: top;
        line-height: 16px;
      }
      .ant-select-search__field__mirror {
        vertical-align: top;
        line-height: 16px;
      }
      .ant-select-search__field {
        font-size: 14px;
        line-height: 16px;
        padding: 0;
        margin-top: -10px;
      }
      .ant-select-selection__rendered {
        margin: 0px;
      }
      .ant-select-selection__placeholder {
        font-weight: bold;
        margin-top: -16px;
        color: #5b6572;
        height: auto;
      }
      .ant-select-selection-item {
        font-size: 14px;
        .rehire-selector-name {
          font-style: normal;
          font-weight: bold;
          line-height: 16px;
        }
        .rehire-selector-details {
          line-height: 20px;
        }
      }

      .ant-select-arrow {
        right: 16px;
        i {
          svg {
            width: 0;
            height: 0;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            border-top: 6px solid #000;
            display: inline-block;
            path {
              display: none;
            }
          }
        }
      }
    }
  }

  .footer {
    text-align: center;
    .submit-btn {
      width: 183px;
      height: 55px;
      background: #ffb917;
      border-radius: 5px;
      color: #000;
      border: none;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 20px;

      &:hover {
        background: #df9e09;
      }
    }

    p {
      .type-sfpro-regular();
      width: 318px;
      margin-top: 21px;
      margin-bottom: 41px;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 18px;
      color: #000;
    }
  }

  &.awaiting {
    .ant-modal-body {
      padding-top: 40px;
    }
    img.trend-ambassador {
      width: 175px;
      height: 23.89px;
      margin-bottom: 29.31px;
    }
    img.banner {
      height: 150px;
      width: 162px;
      margin-bottom: 31px;
    }
    h1 {
      font-style: normal;
      font-weight: 800;
      font-size: 22px;
      line-height: 26px;
      margin-bottom: 8px;
    }

    p {
      width: 603px;
      text-align: center;
      margin-bottom: 29px;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      color: #000;

      a {
        color: #000;
        text-decoration: underline;
      }
    }
    .footer {
      .ant-btn {
        margin-bottom: 47px;
      }
    }
  }

  &.accepted {
    color: #000;
    .ant-modal-content {
      min-height: 640px;
    }
    .ant-modal-body {
      padding-top: 40px;
    }
    img.trend-ambassador {
      width: 175px;
      height: 23.89px;
      margin-bottom: 29.31px;
    }
    h1 {
      font-style: normal;
      font-weight: 800;
      font-size: 36px;
      line-height: 42px;
      margin-bottom: 5px;
    }
    .ant-avatar {
      margin-bottom: 15px;
    }
    .username {
      text-align: center;
      font-style: normal;
      font-weight: 800;
      font-size: 14px;
      line-height: 23px;
      margin-bottom: 4px;
    }
    p {
      .type-sfpro-regular();
      width: 453px;
      text-align: center;
      margin-bottom: 47px;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
    }
    .footer {
      .ant-btn {
        margin-bottom: 48px;
        width: 288px;
      }
    }

    .rehire-list {
      &-item {
        padding: 18px 0px 18px 34px;
        border-bottom: 1px solid #e8e8e8;

        &:first-child {
          border-top: 1px solid #e8e8e8;
        }
        .ant-avatar {
          vertical-align: middle;
          margin-bottom: 0;
          margin-right: 15px;
        }
        .rehire-details {
          display: inline-block;
          margin-top: 1;
          vertical-align: middle;

          &-username {
            .type-sfpro-regular();
            font-style: normal;
            font-weight: bold;
            font-size: 14px;
            line-height: 23px;
          }

          &-campaign {
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 23px;
          }
        }
        .rehire-list-item-actions {
          text-align: right;
          padding-right: 41px;

          .ant-btn {
            .type-sfpro-regular();
            height: 41px;
            width: 110px;
            font-style: normal;
            font-weight: bold;
            font-size: 11px;
            line-height: 41px;
            background-color: #f1f5fd;
            border: none;

            &:hover,
            &:active,
            &:focus {
              color: #000;
              background-color: #dce5f7;
            }
          }

          .ant-btn.ant-btn-primary {
            background-color: #ffb917;
            margin-left: 11px;
            text-shadow: none;
            color: #000;
            &:hover {
              background-color: #df9e09;
            }
          }
        }
      }
    }
  }
}

.rehire-selector-name {
  color: #000;
  font-style: normal !important;
  font-weight: bold !important;
  line-height: 16px !important ;
}
.rehire-selector-details {
  font-weight: normal !important;
}

.rehire-label {
  display: block;
  text-align: center;
  margin-top: 8px;
  margin-left: 18px;
  color: #5b6572 !important;
  font-style: normal;
  font-weight: 600;
  font-size: 9px;
  line-height: 10px;
  justify-content: center;
}

.rehire-button.ant-btn {
  .type-sfpro-bold();
  background: #f1f5fd;
  color: #000;
  border-radius: 3px;
  height: 44px;
  border: none;

  font-weight: bold;
  font-size: 14px;
  line-height: 20px;

  &:hover,
  &:focus-within {
    background-color: #dce5f7;
    color: #000;
  }

  &:active {
    color: #000;
    background: #f1f5fd;
  }

  &:not(.awaiting) {
    .image-wrapper {
      background-color: #00b267;
      border-radius: 100%;
      height: 28px;
      width: 28px;
      padding-top: 7px;
      padding-left: 6px;
      text-align: left;
    }
  }

  &.awaiting {
    background-color: #fff;
    border: 1px solid #dbdfe8;
    color: #000;
    cursor: default;
    &:hover:focus {
      background-color: #fff;
      color: #000;
    }
  }

  .image-wrapper {
    margin-right: 9px;

    i {
      font-size: 16px;
    }

    img {
      vertical-align: middle;
      width: 28px;
      height: 28px;
    }
  }

  span {
    vertical-align: middle;
  }

  &.waiting {
    border: 1px solid #dbdfe8;
    background: #fff;
  }
}

.influencer-avatar {
  position: relative;
  display: inline-block;

  .ambassador-tag {
    text-align: center;
    position: absolute;
    left: -6px;
    top: 46px;
    background: #ffffff;
    border: 1px solid #000000;
    box-sizing: border-box;
    width: 62px;
    height: 14.67px;

    svg {
      position: absolute;
      top: 4px;
      left: 5px;
      width: 51.67px;
    }
  }
}

.ambassador-tag {
  text-align: center;
  background: #ffffff;
  border: 1px solid #000000;
  box-sizing: border-box;
  width: 62px;
  height: 14.67px;

  svg {
    position: absolute;
    top: 4px;
    left: 5px;
    width: 51.67px;
  }
}

.ant-select-dropdown.trend-select {
  background: transparent;
  border: none;
  box-shadow: none;
  > div {
    margin-top: 20px;
    background: #f1f5fd;
    border-radius: 8px;

    .ant-select-dropdown-menu {
      padding: 12.5px 0;
      margin: 0px;

      &-item {
        .type-sfpro-regular();

        .rehire-selector-name {
          font-size: 14px;
          line-height: 16px;
        }
        .rehire-selector-details {
          font-size: 14px;
        }

        padding: 12.5px 25px;
      }
      &-item-selected,
      &-item-active {
        background-color: transparent !important;
        color: #0276ff !important;

        .rehire-selector-name {
          color: #0276ff !important;
        }
      }
    }
  }

  &::after {
    display: none;
  }
}
