.text-black {
  color: #000000;
}
.text-black-bis {
  color: #151515;
}
.text-black-ter {
  color: #3A3A3A;
}
.text-gray-darkest {
  color: #747474;
}
.text-gray-darker {
  color: #969696;
}
.text-gray-dark {
  color: #999999;
}
.text-gray {
  color: #A6A6A6;
}
.text-gray-light {
  color: #D8D8D8;
}
.text-gray-lighter {
  color: #D6D6D6;
}
.text-gray-lightest {
  color: #E8E8E8;
}
.text-white {
  color: #FFFFFF;
}
.text-orange {
  color: #FFB917;
}
.text-pigeon-dark {
  color: #5B6572;
}
.text-pigeon-light {
  color: #A9B0B9;
}
.text-pigeon {
  color: #DBDFE8;
}
.bg-black {
  background-color: #000000;
}
.bg-black-bis {
  background-color: #151515;
}
.bg-black-ter {
  background-color: #3A3A3A;
}
.bg-gray-darkest {
  background-color: #747474 !important;
}
.bg-gray-darker {
  background-color: #969696;
}
.bg-gray-dark {
  background-color: #999999;
}
.bg-gray {
  background-color: #A6A6A6;
}
.bg-gray-light {
  background-color: #D8D8D8;
}
.bg-gray-lighter {
  background-color: #D6D6D6;
}
.bg-gray-lightest {
  background-color: #E8E8E8;
}
.bg-white {
  background-color: #FFFFFF;
}
.bg-orange {
  background-color: #FFB917;
}
.font-face {
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-weight: normal;
  font-style: normal;
  font-family: 'TruenoLight';
  src: url('../../../../webfonts/Trueno/TruenoLt.otf') format('opentype');
}
@font-face {
  font-weight: normal;
  font-style: normal;
  font-family: 'TruenoLightItalic';
  src: url('../../../../webfonts/Trueno/TruenoLtIt.otf') format('opentype');
}
@font-face {
  font-weight: normal;
  font-style: normal;
  font-family: 'TruenoRegular';
  src: url('../../../../webfonts/Trueno/TruenoRg.otf') format('opentype');
}
@font-face {
  font-weight: normal;
  font-style: normal;
  font-family: 'TruenoRegularItalic';
  src: url('../../../../webfonts/Trueno/TruenoRgIt.otf') format('opentype');
}
@font-face {
  font-weight: normal;
  font-style: normal;
  font-family: 'TruenoBold';
  src: url('../../../../webfonts/Trueno/TruenoBd.otf') format('opentype');
}
@font-face {
  font-weight: normal;
  font-style: normal;
  font-family: 'TruenoBoldItalic';
  src: url('../../../../webfonts/Trueno/TruenoBdIt.otf') format('opentype');
}
@font-face {
  font-weight: normal;
  font-style: normal;
  font-family: 'TruenoSemiBold';
  src: url('../../../../webfonts/Trueno/TruenoSBd.otf') format('opentype');
}
@font-face {
  font-weight: normal;
  font-style: normal;
  font-family: 'TruenoExtraBold';
  src: url('../../../../webfonts/Trueno/TruenoExBd.otf') format('opentype');
}
@font-face {
  font-family: 'FirmeBlackItalic';
  src: url('../../../../webfonts/firme-blackitalic-webfont.woff2') format('woff2'), url('../../../../webfonts/firme-blackitalic-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'FirmeMedium';
  src: url('../../../../webfonts/firme-medium-webfont.woff2') format('woff2'), url('../../../../webfonts/firme-medium-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: SFProDisplay;
  src: url('../../../../webfonts/SF-Pro/FontsFree-Net-SFProDisplay-Black.ttf') format('opentype');
  font-weight: 900;
}
@font-face {
  font-family: SFProDisplay;
  src: url('../../../../webfonts/SF-Pro/FontsFree-Net-SFProDisplay-Bold.ttf') format('opentype');
  font-weight: bold;
}
@font-face {
  font-family: SFProDisplay;
  src: url('../../../../webfonts/SF-Pro/FontsFree-Net-SFProDisplay-Heavy-1.ttf') format('opentype');
  font-weight: 800;
}
@font-face {
  font-family: SFProDisplay;
  src: url('../../../../webfonts/SF-Pro/FontsFree-Net-SFProDisplay-HeavyItalic-1.ttf') format('opentype');
  font-weight: 800;
  font-style: italic;
}
@font-face {
  font-family: SFProDisplay;
  src: url('../../../../webfonts/SF-Pro/FontsFree-Net-SFProDisplay-Light-1.ttf') format('opentype');
  font-weight: normal;
}
@font-face {
  font-family: SFProDisplay;
  src: url('../../../../webfonts/SF-Pro/FontsFree-Net-SFProDisplay-MediumItalic.ttf') format('opentype');
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: SFProDisplay;
  src: url('../../../../webfonts/SF-Pro/FontsFree-Net-SFProDisplay-BoldItalic.ttf') format('opentype');
  font-weight: bold;
  font-style: italic;
}
@font-face {
  font-family: SFProDisplay;
  src: url('../../../../webfonts/SF-Pro/FontsFree-Net-SFProDisplay-LightItalic.ttf') format('opentype');
  font-weight: normal;
  font-style: italic;
}
.type-light {
  font-family: 'TruenoLight', Arial, sans-serif;
}
.type-ilight {
  font-family: 'TruenoLightItalic', Arial, sans-serif;
}
.type-regular {
  font-family: 'TruenoRegular', Arial, sans-serif;
}
.type-regular-sfpro-display {
  font-family: 'SFProDisplay', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
.type-bold {
  font-family: 'TruenoBold', Arial, sans-serif;
}
.type-bold-sfpro-display {
  font-family: 'SFProDisplay', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: bold;
}
.type-sbold {
  font-family: 'TruenoSemiBold', Arial, sans-serif;
}
.type-ebold {
  font-family: 'TruenoExtraBold', Arial, sans-serif;
}
.type-firme-iblack {
  font-family: 'FirmeBlackItalic', Arial, sans-serif !important;
}
.type-firme-medium {
  font-family: 'FirmeMedium', Arial, sans-serif !important;
}
.type-sfpro-regular {
  font-family: SFProDisplay;
  font-weight: normal;
  font-style: normal;
}
.type-sfpro-medium {
  font-family: SFProDisplay;
  font-weight: normal;
}
.type-sfpro-iregular {
  font-family: SFProDisplay;
  font-weight: normal;
  font-style: italic;
}
.type-sfpro-bold {
  font-family: SFProDisplay;
  font-weight: bold;
}
.type-sfpro-light {
  font-family: SFProDisplay;
  font-weight: 400;
}
.type-sfpro-heavy {
  font-family: SFProDisplay;
  font-weight: 800;
}
.type-sfpro-iheavy {
  font-family: SFProDisplay;
  font-weight: 800;
  font-style: italic;
}
.type-sfpro-thin {
  font-family: SFProDisplay;
  font-weight: 100;
}
.type-sfpro-ibold {
  font-family: SFProDisplay;
  font-weight: bold;
  font-style: italic;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: SFProDisplay !important;
  font-weight: 800 !important;
}
body {
  font-family: 'TruenoRegular', Arial, sans-serif !important;
  font-weight: normal;
  font-style: normal;
}
.text-primary {
  color: #ffb917;
}
.size-8px {
  font-size: 8px !important;
}
.size-9px {
  font-size: 9px !important;
}
.size-10px {
  font-size: 10px !important;
}
.size-11px {
  font-size: 11px !important;
}
.size-12px {
  font-size: 12px !important;
}
.size-13px {
  font-size: 13px !important;
}
.size-14px {
  font-size: 14px !important;
}
.size-15px {
  font-size: 15px !important;
}
.size-16px {
  font-size: 16px !important;
}
.size-17px {
  font-size: 17px !important;
}
.size-18px {
  font-size: 18px !important;
}
.size-20px {
  font-size: 20px !important;
}
.size-40px {
  font-size: 40px !important;
}
.size-21px {
  font-size: 21px !important;
}
.size-22px {
  font-size: 22px !important;
}
.size-24px {
  font-size: 24px !important;
}
.size-25px {
  font-size: 25px !important;
}
.size-28px {
  font-size: 28px !important;
}
.size-31px {
  font-size: 31px !important;
}
.size-32px {
  font-size: 32px !important;
}
.size-34px {
  font-size: 34px !important;
}
.font-weight-400 {
  font-weight: 400;
}
.font-weight-700 {
  font-weight: 700;
}
.font-weight-800 {
  font-weight: 800;
}
.color-yellow-font {
  color: #ffcb6f;
}
.most-popular {
  background: -webkit-linear-gradient(right, #f5cf79, #f7f7f7);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.spacing-05px {
  letter-spacing: 0.5px;
}
.spacing-1px {
  letter-spacing: 1px;
}
.leading-18px {
  line-height: 18px;
}
.leading-24px {
  line-height: 24px;
}
.leading-1 {
  line-height: 1;
}
.leading-23 {
  line-height: 2.33;
}
.uppercase {
  text-transform: uppercase;
}
.clickable {
  cursor: pointer;
}
.text-left {
  text-align: left !important;
}
.text-right {
  text-align: right !important;
}
.text-center {
  text-align: center !important;
}
.text-wrap {
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
}
.label-value {
  font-family: 'truenoextrabold';
  color: #000000;
  font-size: 12px;
}
.pt-0 {
  padding-top: 0 !important;
}
.pb-0 {
  padding-bottom: 0 !important;
}
.pl-0 {
  padding-left: 0 !important;
}
.pr-0 {
  padding-right: 0 !important;
}
.pt-1 {
  padding-top: 5px !important;
}
.pb-1 {
  padding-bottom: 5px !important;
}
.pl-1 {
  padding-left: 5px !important;
}
.pr-1 {
  padding-right: 5px !important;
}
.pt-2 {
  padding-top: 10px !important;
}
.pb-2 {
  padding-bottom: 10px !important;
}
.pl-2 {
  padding-left: 10px !important;
}
.pr-2 {
  padding-right: 10px !important;
}
.pt-3 {
  padding-top: 15px !important;
}
.pb-3 {
  padding-bottom: 15px !important;
}
.pl-3 {
  padding-left: 15px !important;
}
.pr-3 {
  padding-right: 15px !important;
}
.pt-4 {
  padding-top: 20px !important;
}
.pb-4 {
  padding-bottom: 20px !important;
}
.pl-4 {
  padding-left: 20px !important;
}
.pr-4 {
  padding-right: 20px !important;
}
.pt-5 {
  padding-top: 25px !important;
}
.pb-5 {
  padding-bottom: 25px !important;
}
.pl-5 {
  padding-left: 25px !important;
}
.pr-5 {
  padding-right: 25px !important;
}
.pt-6 {
  padding-top: 30px !important;
}
.pb-6 {
  padding-bottom: 30px !important;
}
.pl-6 {
  padding-left: 30px !important;
}
.pr-6 {
  padding-right: 30px !important;
}
.pt-7 {
  padding-top: 35px !important;
}
.pb-7 {
  padding-bottom: 35px !important;
}
.pl-7 {
  padding-left: 35px !important;
}
.pr-7 {
  padding-right: 35px !important;
}
.pt-8 {
  padding-top: 40px !important;
}
.pb-8 {
  padding-bottom: 40px !important;
}
.pl-8 {
  padding-left: 40px !important;
}
.pr-8 {
  padding-right: 40px !important;
}
.content-padding {
  padding-top: 40px;
  padding-bottom: 40px;
}
.brand-image .ant-avatar-image,
.brand-image .ant-avatar {
  border-radius: 0;
  background-color: #000;
  width: 100%;
  height: 200px;
}
.min-layout-content {
  width: 100%;
  padding-left: 39px;
  padding-right: 39px;
}
.d-block {
  display: block;
}
.p-relative {
  position: relative;
}
.p-absolute {
  position: absolute;
}
.f-left {
  float: left;
}
.f-right {
  float: right;
}
.m-0 {
  margin: 0 !important;
}
.mt-0 {
  margin-top: 0 !important;
}
.mb-0 {
  margin-bottom: 0 !important;
}
.ml-0 {
  margin-left: 0 !important;
}
.mr-0 {
  margin-right: 0 !important;
}
.mt-1 {
  margin-top: 5px !important;
}
.mb-1 {
  margin-bottom: 5px !important;
}
.ml-1 {
  margin-left: 5px !important;
}
.mr-1 {
  margin-right: 5px !important;
}
.mt-2 {
  margin-top: 10px !important;
}
.mb-2 {
  margin-bottom: 10px !important;
}
.ml-2 {
  margin-left: 10px !important;
}
.mr-2 {
  margin-right: 10px !important;
}
.mt-3 {
  margin-top: 15px !important;
}
.mb-3 {
  margin-bottom: 15px !important;
}
.ml-3 {
  margin-left: 15px !important;
}
.mr-3 {
  margin-right: 15px !important;
}
.mt-4 {
  margin-top: 20px !important;
}
.mb-4 {
  margin-bottom: 20px !important;
}
.ml-4 {
  margin-left: 20px !important;
}
.mr-4 {
  margin-right: 20px !important;
}
.mt-5 {
  margin-top: 25px !important;
}
.mb-5 {
  margin-bottom: 25px !important;
}
.ml-5 {
  margin-left: 25px !important;
}
.mr-5 {
  margin-right: 25px !important;
}
.mt-6 {
  margin-top: 30px !important;
}
.mb-6 {
  margin-bottom: 30px !important;
}
.ml-6 {
  margin-left: 30px !important;
}
.mr-6 {
  margin-right: 30px !important;
}
.mt-7 {
  margin-top: 35px !important;
}
.mb-7 {
  margin-bottom: 35px !important;
}
.ml-7 {
  margin-left: 35px !important;
}
.mr-7 {
  margin-right: 35px !important;
}
.mt-8 {
  margin-top: 40px !important;
}
.mb-8 {
  margin-bottom: 40px !important;
}
.ml-8 {
  margin-left: 40px !important;
}
.mr-8 {
  margin-right: 40px !important;
}
.mt-9 {
  margin-top: 45px !important;
}
.mb-9 {
  margin-bottom: 45px !important;
}
.ml-9 {
  margin-left: 45px !important;
}
.mr-9 {
  margin-right: 45px !important;
}
.header-offset {
  height: calc(100vh - 4rem);
  margin-top: 4rem;
  position: relative;
}
.header-offset--with-banner {
  height: calc(100vh - 6.5625rem);
  margin-top: 6.5625rem;
}
@media (min-width: 48rem) {
  .header-offset {
    height: 100%;
    margin-top: 0;
  }
  .header-offset--with-banner {
    height: calc(100vh - 2.5625rem);
    margin-top: 2.5625rem;
  }
}
/*
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/
@media (min-width: 1281px) {
  .min-layout-content {
    width: 1080px;
  }
  .small {
    width: 580px;
  }
}
/*
  ##Device = Laptops, Desktops
  ##Screen = B/w 1025px to 1280px
*/
@media (min-width: 1025px) and (max-width: 1280px) {
  .min-layout-content {
    width: 1080px;
  }
  .small {
    width: 580px;
  }
}
/*
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/
@media (min-width: 768px) and (max-width: 1024px) {
  .min-layout-content {
    padding-left: 20px;
    padding-right: 20px;
  }
  .content-padding {
    padding: 15px;
  }
  .small {
    width: 580px;
  }
}
@media (max-width: 992px) {
  .logo {
    display: none;
  }
  .no-padding-mobile {
    padding: 0 !important;
  }
  .campaign-menu,
  .trend-menu-inline {
    text-align: center;
  }
  .ant-menu-item .anticon,
  .ant-menu-submenu-title .anticon {
    margin-right: 0 !important;
  }
  .ant-menu-inline.secondary .ant-menu-item svg,
  .ant-menu-inline.settings svg {
    float: none;
    margin-left: 0;
    margin-right: 0;
  }
  .side-small-title {
    font-size: 6px !important;
  }
  .plan-title {
    font-size: 8px !important;
  }
  .package-title {
    margin: 0 !important;
    font-size: 12px !important;
  }
}
.u-button-reset {
  align-items: center;
  background: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  padding: 0;
  transition: background-color 0.3s;
  width: max-content;
}
.u-button-reset:focus-within {
  outline: revert !important;
}
.u-button-reset:disabled {
  background-color: #93949f;
  cursor: not-allowed;
}
.u-loading-indicator {
  animation: spin 1s ease-in-out infinite;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.u-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
.brand-login-btn {
  border: 0.0625rem solid #D6D6D6;
  border-radius: 0.25rem;
  padding: 0.25rem 0.5rem;
}
.brand-login-btn:hover {
  background: #E8E8E8;
}
.content-preview-btn {
  border-radius: 0.5rem;
  height: 100%;
  overflow: hidden;
  width: 100%;
}
#project-tab {
  font-family: SFProDisplay;
  font-weight: normal;
  font-style: normal;
  padding: 0 0 0 15px;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: -0.004em;
}
#icon-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
#icon-container #icon-text {
  font-family: SFProDisplay;
  font-weight: normal;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  white-space: nowrap;
  color: #fff;
}
.sonar-wrapper {
  position: relative;
  z-index: 0;
  overflow: hidden;
}
.sonar-emitter {
  position: relative;
  margin: 13px 14px;
  width: 29.33px;
  height: 29.33px;
  border-radius: 9999px;
}
.sonar-wave {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 9999px;
  border: 1px #ffb917 solid;
  opacity: 0;
  z-index: -1;
  pointer-events: none;
}
.sonar-wave1 {
  animation: sonarWave 2s linear infinite;
}
.sonar-wave2 {
  animation: sonarWave 2s 0.5s linear infinite;
}
.sonar-wave3 {
  animation: sonarWave 2s 1s linear infinite;
}
@keyframes sonarWave {
  from {
    transform: scale(0.2);
    opacity: 1;
  }
  to {
    transform: scale(2);
    opacity: 0;
  }
}
@media (max-width: 47.9375rem) {
  .hidden-xmobile {
    display: none !important;
  }
}
/*
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/
@media (min-width: 320px) and (max-width: 480px) {
  .min-layout-content {
    padding-left: 20px;
    padding-right: 20px;
  }
  .content-padding {
    padding: 5px;
  }
  .small {
    width: 100%;
  }
}
.ant-layout-sider .brand-image {
  position: relative;
  height: 182px;
  background: #333;
}
.ant-layout-sider .brand-image .ant-avatar-image {
  height: 100%;
}
.ant-layout-sider .brand-image .ant-avatar-circle {
  background: #333;
  height: 100%;
}
.ant-layout-sider .brand-image .subscription-tag {
  margin-left: 61px;
  min-width: 87px;
  padding-top: 4.7px;
  padding-bottom: 5.5px;
  border-radius: 100px;
  background: #000;
  position: absolute;
  bottom: 15px;
  text-align: center;
}
.ant-layout-sider .brand-image .subscription-tag .name {
  display: inline-block;
  width: 48px;
  margin-bottom: 4px;
  vertical-align: bottom;
}
.ant-layout-sider .brand-image .subscription-tag .icon {
  display: inline-block;
  margin-bottom: 4px;
  width: 14px;
  vertical-align: bottom;
}
.side-menu #archive-link-container {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 10px;
}
.side-menu .menu-title {
  font-family: SFProDisplay;
  font-weight: 800;
  font-size: 12px;
  line-height: 18px;
  color: #fff;
}
.side-menu .new-project {
  border-radius: 50%;
  margin-right: 0.3125rem;
}
.side-menu .new-project rect {
  transition: fill 0.3s;
}
.side-menu .new-project:hover rect,
.side-menu .new-project:focus rect {
  fill: #ffc84a;
}
.side-menu .new-project:focus-within rect {
  fill: #ffc84a;
}
.side-menu .new-project:active rect {
  fill: #e39f00;
}
.side-menu .toggle-projects-btn path {
  transition: fill 0.3s;
}
.side-menu .toggle-projects-btn:hover path,
.side-menu .toggle-projects-btn:focus path {
  fill: #bfc3c7;
}
.side-menu .toggle-projects-btn:focus-within path {
  fill: #bfc3c7;
}
.side-menu .toggle-projects-btn:active path {
  fill: #ffffff;
}
.side-menu .icons .new-project-icon {
  font-size: 20px;
}
.side-menu .icons .cheveron-icon:hover {
  cursor: pointer;
}
.side-menu .icons .cheveron-icon.open {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}
.new-pill {
  padding: 0.125rem 0.5rem;
  border-radius: 1.75rem;
  background-color: #f2b5fa;
  color: #000000;
  font-size: 0.5rem;
  font-weight: 700;
  text-transform: uppercase;
}
.sidebar-link {
  align-items: center;
  display: flex;
  gap: 0.75rem;
  justify-content: space-between;
  padding: 0.5rem 1rem;
  transition: background-color 0.2s;
  width: 100%;
}
.sidebar-link:hover,
.sidebar-link:focus {
  background-color: #2f313c;
}
.sidebar-link:focus-visible {
  background-color: #2f313c;
  outline: revert !important;
  outline-offset: -0.25rem;
}
.sidebar-link--active {
  background-color: #272727;
  border-right: 0.1875rem solid #FFB917;
}
.sidebar-icon-wrapper {
  align-items: center;
  background-color: #000000;
  border: 0.0625rem solid #3A3A3A;
  color: #FFFFFF;
  display: flex;
  height: 2.25rem;
  justify-content: center;
  font-size: 1.25rem;
  width: 2.25rem;
}
.sidebar-icon {
  height: 2.25rem;
  width: 2.25rem;
}
.sidebar-icon rect {
  fill: #000000;
}
.sidebar-icon--account {
  height: 1.5625rem;
  width: 1.5625rem;
}
.sidebar-icon--account > g > g {
  stroke: #FFFFFF;
  fill: #FFFFFF;
}
.sidebar-link-copy {
  font-family: SFProDisplay;
  font-weight: normal;
  font-style: normal;
  color: #FFFFFF;
  flex-grow: 1;
  font-size: 0.75rem;
  font-weight: 600;
}
#admin-layout .ant-layout-content {
  background: #f6f7fb;
}
#admin-layout .page-header {
  width: 100%;
  padding-top: 47px;
  background: #fff;
  border-bottom: 1px solid #e8e8e8;
}
#admin-layout .page-header h1 {
  font-size: 26px;
  font-family: 'TruenoExtraBold';
}
#admin-layout .page-header .ant-menu.ant-menu-horizontal {
  border-bottom: 0px;
}
.firebase-emulator-warning {
  display: none;
}
.standardized-image {
  height: 100%;
  max-height: 90rem;
  max-width: 90rem;
  object-fit: cover;
  width: 100%;
}
.banner-card {
  display: none;
}
.banner-card.brief-page {
  bottom: 80px;
}
@media (min-width: 48rem) {
  .banner-card {
    align-items: center;
    background: #bde5cc;
    border: 0.0625rem solid #000000;
    border-radius: 0.625rem;
    bottom: 1.5rem;
    display: flex;
    gap: 1rem;
    justify-content: space-between;
    left: calc(50% + 15rem / 2);
    max-width: 60rem;
    padding: 1.5rem;
    position: fixed;
    transform: translateX(-50%);
    width: calc(100% - 17rem);
  }
  .banner-card .reactivate-listing-btn {
    font-family: SFProDisplay;
    font-weight: 800;
    background-color: #000000;
    border-radius: 0.1875rem;
    color: #FFFFFF;
    font-size: 1rem;
    line-height: 1.5rem;
    padding: 0.5rem 1rem;
  }
  .banner-card .reactivate-listing-btn:hover,
  .banner-card .reactivate-listing-btn:focus {
    background-color: #404040;
  }
  .banner-card .reactivate-listing-btn:active {
    background-color: #000000;
  }
}
.text-column {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-family: 'SFProDisplay';
}
.text-column h5 {
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  margin: 0;
  margin-bottom: 8px;
}
.text-column p {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  margin: 0;
}
.action-column {
  display: flex;
  width: fit-content;
}
