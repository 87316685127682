.creator-content-avatar {
  position: relative;

  &:nth-child(n + 6) {
    margin-top: 10px;
  }

  &.pending,
  &.rejected,
  &.submitted {
    .ant-avatar {
      background-color: #fff;
      border: 1px dashed #000;
    }
  }

  .ant-avatar {
    .ant-avatar-string {
      left: 0 !important;
      transform: unset !important;
      width: 100%;
      height: 100%;
    }
  }

  .creator-content-type {
    .type-sfpro-regular();
    width: 59px;
    height: 15px;
    position: absolute;
    left: -4px;
    bottom: -4px;
    font-weight: 600;
    font-style: italic;
    font-size: 7px;
    line-height: 15px;
    /* identical to box height, or 157% */

    text-align: center;
    letter-spacing: 0.4px;
    text-transform: uppercase;

    &.type-video {
      background-color: #4df1ff;
    }
    &.type-image {
      background-color: #ffd982;
    }
  }
}
