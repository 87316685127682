#videosNeeded {
  color: @color-white;
  max-width: 480px;
  width: 100%;
  margin-right: auto;
  margin-left: auto;

  .page-title {
    .type-sfpro-heavy();
    font-size: 24px;
    line-height: 30px;
  }

  .page-description {
    .type-sfpro-regular();
    color: @color-white;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.5px;
    margin-top: 11px;
    margin-bottom: 62px;
  }

  .level-bar {
    display: flex;
    margin-top: 24px;
    margin-bottom: 19px;

    .level-width-min {
      width: 224px;
    }

    .level-width-max {
      width: 109.5px;
    }

    div {
      height: 10px;
      background: #545454;
      transform: rotate(-180deg);
      margin-right: 5px;

      &:last-child {
        margin-right: 0 !important;
      }
    }

    .level-up {
      background: @color-orange;
      transform: rotate(-180deg);
    }
  }

  .platform {
    display: flex !important;
    flex-wrap: wrap;
    justify-content: space-around;

    .ant-radio-button-wrapper {
      align-items: center;
      border-radius: 5px;
      display: flex;
      height: 120px;
      justify-content: center;
      width: 142px;

      margin-right: 8px;
      margin-bottom: 16px;

      span:last-child {
        .type-sfpro-bold();
        font-size: 14px;
        line-height: 16px;
        letter-spacing: -0.09px;
        text-align: center;

        div {
          margin-top: 15.94px;
        }
      }
    }
  }

  .platform-layout {
    .common-shape {
      border: 4px solid white;
      box-sizing: border-box;
      margin-bottom: 14px;
    }

    .square-shape {
      height: 40px;
      margin-top: 32px;
      width: 40px;
    }

    .vertical-shape {
      height: 53px;
      margin-top: 19px;
      width: 32px;
    }

    .horizontal-shape {
      height: 32px;
      margin-top: 40px;
      width: 55px;
    }

    .ant-radio-button-wrapper-checked {
      .square-shape,
      .vertical-shape,
      .horizontal-shape {
        border: 4px solid black;
      }
    }
    .ant-radio-button-wrapper-disabled {
      background-color: rgba(80, 47, 47, 0.5);
      color: #502f2f !important;

      .square-shape,
      .vertical-shape,
      .horizontal-shape {
        border: 4px solid #502f2f;
      }
    }

    .ant-radio-button-wrapper {
      border-radius: 5px;
      display: flex;
      height: 120px;
      justify-content: center;
      padding: 0;
      width: 142px;

      .icon-holder {
        width: 100%;
        text-align: right;
        position: absolute;
        top: 7px;
        right: 7px;
        color: rgb(255, 185, 23);
      }

      span:last-child {
        .type-sfpro-bold();
        align-items: center;
        display: flex;
        flex-direction: column;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: -0.09px;
        text-align: center;
        width: 100%;
      }
      &:not(.ant-radio-button-wrapper-disabled):hover {
        .square-shape,
        .vertical-shape,
        .horizontal-shape {
          border: 4px solid #000;
        }
      }
    }
  }

  .video-length {
    .ant-radio-button-wrapper:hover,
    .ant-radio-button-wrapper-checked {
      circle,
      svg g circle {
        fill: black;
      }
    }

    .ant-radio-button-wrapper {
      align-items: center;
      border-radius: 5px;
      display: flex;
      height: 120px;
      justify-content: center;
      width: 142px;

      span:last-child {
        .type-sfpro-bold();
        font-size: 14px;
        line-height: 16px;
        letter-spacing: -0.09px;
        text-align: center;
      }
    }
  }

  .style-category-options {
    display: flex;
    justify-content: space-between;
    width: 100%;
    @media screen and (max-width: 600px) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }

  .creator-instruction {
    .ant-radio-button-wrapper-checked {
      .more-examples {
        color: black !important;
      }

      .card-checkbox-circle {
        background: @color-black !important;
      }

      .select-label {
        color: @color-black !important;
      }
    }

    .ant-radio-button-wrapper:hover:not(.ant-radio-button-wrapper-checked) {
      .card-checkbox-circle {
        align-items: center;
        background: #fff0cd;
        border: 1px solid #fff0cd;
      }

      .select-label {
        color: #fff0cd;
      }

      .more-examples {
        color: black !important;
      }
    }

    .ant-radio-button-wrapper {
      border-radius: 5px;
      height: 363px;
      padding: 0;
      width: 142px;
      margin-right: 12px;
      caret-color: transparent;
      user-select: none;
      -webkit-user-select: none;

      @media screen and (max-width: 600px) {
        width: 100%;
        max-width: 220px;
        margin-right: 0px;
        margin-bottom: 16px;
        height: 400px;
      }

      .card {
        align-items: center;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        padding: 16px;
        position: static !important;
      }

      .card-image {
        position: relative;
        text-align: center;

        @media screen and (max-width: 600px) {
          width: 100%;
          height: auto;
        }
        img.thumbnail {
          height: 124px;
          border-radius: 4px;
          width: 124px;
          @media screen and (max-width: 600px) {
            width: 100%;
            height: auto;
          }
        }

        img.play-button {
          position: absolute;
          height: 38px;
          width: 38px;
          top: calc(50% - 19px); // 50% - (height / 2)
          left: calc(50% - 19px);
        }
      }
      .card-title {
        .type-sfpro-bold();
        font-size: 14px;
        line-height: 18px;
        margin-top: 20px;
        margin-bottom: 1px;
        text-align: center;
      }

      .more-examples {
        .type-sfpro-regular();
        color: white;
        font-size: 10px;
        line-height: 20px;
        text-align: center;
        text-decoration-line: underline;
        margin-bottom: 38px;
      }

      .selector-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: absolute;
        bottom: 1.5rem;
      }

      .card-checkbox-circle {
        align-items: center;
        background: #545454;
        border: 1px solid #545454;
        box-sizing: border-box;
        border-radius: 41px;
        display: flex;
        justify-content: center;
        height: 53px;
        width: 53px;
      }

      .select-label {
        .type-sfpro-bold();
        color: #595959;
        font-size: 12px;
        line-height: 12px;
        margin-top: 8px;
      }

      .box-incrementor {
        background: black;
        border: 1px solid #424242;
        border-radius: 5px;
        height: 104px;
        margin-bottom: 24px;
        margin-top: 20px;
        width: 88px;
        position: absolute;
        bottom: 0;

        .counter {
          .type-sfpro-heavy();
          color: @color-white;
          font-size: 36px;
          line-height: 30px;
          margin-bottom: 14px;
          margin-top: 17px;
          text-align: center;
        }

        .operator {
          display: flex;
          justify-content: center;

          .counter-btn {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 30px;
            border-radius: 37px;
            width: 30px;
          }

          .plus {
            background-color: @color-orange;
          }
        }
      }

      &.option2 {
        margin-left: 10px;
        margin-right: 10px;
      }
    }
  }

  .style-category .ant-form-item-control .ant-form-explain {
    top: -28px !important;
  }
}
