.admin-campaign-email-modal {
  .ant-modal-content {
    width: 584px;
    border-radius: 0px;
    position: relative;

    .ant-modal-body {
      padding: 0;
    }
    .modal-header {
      height: 120px;
      padding: 20px;
      color: #fff;
      background-color: #000;
      position: relative;

      .close {
        position: absolute;
        right: 5px;
        top: 20px;

        .anticon {
          font-size: 22px;
        }
      }
      h1 {
        color: #fff;
        font-size: 14px;
        font-family: 'TruenoBold';
        height: 34px;
        margin-bottom: 12px;
      }
      .campaign-details {
        font-size: 11px;
      }
      .action {
        .ant-btn {
          font-size: 10px;
          font-family: 'FirmeBlackItalic';
          text-transform: uppercase;
          width: 100%;
          color: #000;
        }
      }
    }
    .modal-body {
      .influencer-table {
        overflow-y: auto;
        height: 445px;
        width: 100%;
      }
      .ant-table-thead th {
        height: 10px;
        padding: 4px 19px 6px;
        font-size: 8px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        font-family: 'TruenoSemiBold';
        letter-spacing: 0.5px;
        line-height: normal;
        height: 20px;
        color: #5b6572;
        text-transform: uppercase;
        border-color: #ecedf7;

        &.influencer-details {
          width: 215px;
        }
        &.product-delivered {
          width: calc(98px + 58px);
          text-align: right;
        }
        &.due-date {
          width: calc(46px + 89px);
          text-align: right;
        }
      }

      .ant-table-row {
        td {
          color: #000;
          padding: 10px 19px 7px;
          font-size: 10px;
          font-family: 'TruenoSemiBold';
          &.influencer-details {
            .wrapper,
            .ant-avatar {
              float: left;
            }
            .influencer-username {
              line-height: 1.8;
            }
            .post-state {
              &.overdue {
                color: #ca1212;
                text-transform: uppercase;
                line-height: 1;
              }

              &.in-range {
                color: #17c424;
              }

              &.completed {
                color: #17c424;
              }
            }
          }
          &.product-delivered {
            text-align: right;
          }
          &.due-date {
            text-align: right;
          }
          &.actions {
            .ant-btn {
              width: 64px;
              border-radius: 15px;
              font-size: 9px;
              font-family: 'TruenoRegular';
              padding: 0px;
              text-align: center;

              &.ant-btn-default {
                color: #bcbfdd;
              }

              &.ant-btn-primary {
                color: #000;
              }
            }
          }
        }
        .influencer-data {
          .ant-avatar {
            margin-right: 10px;
          }
        }
      }

      .influencer-list {
        .title {
          height: 60px;
          border-bottom: 1px solid #ecedf7;
          margin-top: 19px;

          .name {
            font-family: 'TruenoBold';
            margin-top: 6px;
            padding-left: 20px;
            font-size: 20px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #000;
          }
          .overdue-count {
            margin-top: 8px;
            padding-right: 20px;
            text-align: right;
            font-family: 'TruenoRegular';
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: right;
            color: #000000;
          }
        }

        .search {
          position: relative;
          .anticon {
            position: absolute;
            left: 0;
            z-index: 2;
            font-size: 18px;
            color: #777e9b;
            top: 19px;
            left: 19px;
          }

          input {
            height: 55px;
            font-size: 16px;
            padding-left: 46px;
            border-color: #ecedf7;
            border-radius: 0px;
            border-left: 0;
            border-top: 0;
            border-right: 0;
          }
        }
      }
    }
    .modal-footer {
      height: 87px;

      .ant-btn {
        height: 87px;
        border-radius: 0;
      }
    }
  }
}

.admin-compose-email {
  width: 584px;
  border-radius: 0px;
  position: relative;

  .ant-modal-body {
    padding: 0;
  }

  .modal-header {
    background: #000;
    height: 73px;
    padding: 30px 58px 26px;
    color: #fff;
    font-size: 14px;
    font-family: 'TruenoSemiBold';
    line-height: normal;
    position: relative;

    .back {
      position: absolute;
      left: 5px;
      top: 22px;
      .anticon {
        font-size: 18px;
      }
    }
    .close {
      position: absolute;
      right: 5px;
      top: 20px;

      .anticon {
        font-size: 22px;
      }
    }
  }
  .modal-body {
    padding: 16px 35px 0px;
    color: #000;
    h1 {
      font-family: 'TruenoExtraBold';
      line-height: 1.33;
      font-size: 18px;
      margin-bottom: 13px;
    }
    p {
      font-family: 'TruenoRegular';
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.71;
      letter-spacing: normal;
    }
    textarea {
      padding: 16px 17px;
      height: 327px;
      font-family: 'TruenoRegular';
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      color: #5b6572;
    }
  }
  .modal-footer {
    padding: 19px 35px 25px;
    .ant-btn {
      font-family: 'TruenoBold';
      color: #000;
      height: 45px;
      width: 178px;
    }
  }
}

@primary-color: #ffb917;@info-color: #ffb916;@input-addon-bg: transparent;@slider-track-background-color: @primary-color;@slider-track-background-color-hover: @primary-color;