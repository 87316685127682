.brand-notifications {
  margin: 0 auto 5.5rem auto;
  max-width: 33rem;

  @media (min-width: 48rem) {
    margin-bottom: 7.5rem;
  }

  .heading {
    color: @color-white;
    font-family: 'SFProDisplay', sans-serif;
    font-size: 1rem;
    margin: 0 1rem;
  }

  .add-email-wrapper {
    background-color: @color-off-black;
    border-radius: 0.375rem;
    display: flex;
    font-family: 'SFProDisplay', sans-serif;
    gap: 0.375rem;
    margin: 0 1rem;
    padding: 0.625rem;

    input {
      background-color: transparent;
      border: none;
      color: @color-white;
      flex-grow: 1;
      font-size: 1rem;

      &:focus {
        outline: none;
      }

      &::placeholder {
        color: @gray-50;
      }
    }

    button {
      background-color: @color-orange;
      border-radius: 0.375rem;
      color: @color-black;
      font-size: 0.75rem;
      font-weight: 700;
      padding: 0.375rem 0.625rem;

      &:hover {
        background-color: lighten(@color-orange, 10%);
      }

      &:active {
        background-color: @color-orange;
      }
    }
  }

  .email-list {
    border-bottom: 0.125rem solid @gray-90;
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    justify-content: space-between;
    padding: 2.5rem 1rem;
  }

  .primary-email {
    background-color: @color-off-black;
    border-radius: 0.1875rem;
    color: @gray-50;
    font-family: 'SFProDisplay', sans-serif;
    font-weight: 700;
    height: max-content;
    margin: 0;
    padding: 0.625rem;
    text-transform: lowercase;
    width: max-content;
  }

  .secondary-email {
    align-items: center;
    background-color: @color-white;
    border-radius: 0.1875rem;
    color: @color-black;
    display: flex;
    font-family: 'SFProDisplay', sans-serif;
    font-weight: 700;
    gap: 1.75rem;
    height: max-content;
    padding: 0.625rem;
    transition: background-color 0.2s;
    text-transform: lowercase;
    width: max-content;

    p {
      margin: 0;
    }

    button {
      padding: 0.5rem;

      &:hover {
        background-color: darken(@color-white, 10%);
      }
    }

    svg {
      transform: scale(1.25);
    }
  }

  .checkbox-list {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    margin: 0;
    padding: 0;
    width: max-content;

    label {
      align-items: center;
      color: @color-white;
      display: flex;
      font-family: 'SFProDisplay', sans-serif;
      gap: 0.5rem;
    }
  }

  footer {
    align-items: center;
    background-color: @color-black;
    border-top: 0.0625rem solid @gray-90;
    bottom: 0;
    display: flex;
    justify-content: center;
    left: 0;
    padding: 1rem;
    position: fixed;
    width: 100%;

    @media (min-width: 48rem) {
      padding-bottom: 2rem;
      padding-top: 2rem;
    }
  }

  .save-changes-btn {
    background-color: @color-orange;
    border-radius: 0.25rem;
    color: @color-black;
    font-family: 'SFProDisplay', sans-serif;
    font-size: 1.25rem;
    font-weight: 600;
    padding: 0.75rem 2rem;

    &:hover:enabled {
      background-color: lighten(@color-orange, 10%);
    }
  }
}
