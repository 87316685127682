.post-approved-card {
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);

  &:hover {
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.3);
  }

  &.ant-card {
    border: none;
    border-radius: 0 0 5px 5px;
  }

  .additional-contents {
    margin-bottom: 14.91px;

    .slick-arrow {
      height: 100%;
      margin-top: 0;
      top: 0;
      z-index: 5;
    }

    .slick-disabled {
      visibility: hidden;
    }

    .slick-prev:hover,
    .slick-prev {
      background: linear-gradient(270deg, #ffffff 22.5%, rgba(255, 255, 255, 0) 100%);
      left: -8px;
      transform: matrix(-1, 0, 0, 1, 0, 0);

      &::before {
        content: url(../../../images/components/Post/ApprovedCard/ArrowRight.svg);
      }
    }

    .slick-next:hover,
    .slick-next {
      background: linear-gradient(270deg, #ffffff 22.5%, rgba(255, 255, 255, 0) 100%);
      right: -8px;

      &::before {
        content: url(../../../images/components/Post/ApprovedCard/ArrowRight.svg);
      }
    }

    .slick-list {
      margin: 0 -3px;
    }
    .slick-slide > div {
      padding: 0 3px;
    }

    .video-image {
      position: relative;

      svg {
        position: absolute;
        top: 17px;
        left: 17px;
      }
    }

    .content {
      align-items: center;
      background-color: #fff;
      width: 62px !important;
      height: 62px !important;
      border: 2px solid #fff;
      border-radius: 3px;
      display: flex !important;
      justify-content: center;

      &.active {
        border-color: black;
      }

      &:hover {
        border-color: black;
      }

      .video-player-action-container {
        height: 55.76px;
        width: 55.76px;
      }
    }
  }

  .ant-card-cover {
    min-height: 220px;
    max-height: 220px;
    position: relative;
    overflow: hidden;

    .media-actions {
      height: 100%;
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      z-index: 1;

      .influencer-section {
        align-items: center;
        display: flex;
        flex-direction: column;
        left: 0;
        position: absolute;
        right: 0;
        top: 10px;
        width: 100%;

        .username {
          .type-sfpro-bold();
          font-size: 11px;
          line-height: 15px;
          color: #ffffff;
          margin-top: 6px;
        }
      }

      .action-button {
        bottom: 11px;
        position: absolute;
        text-align: center;
        width: 100%;
        padding: 0 10px;

        button {
          align-items: center;
          background: rgba(255, 255, 255, 0.3);
          border: 0 !important;
          border-radius: 2px;
          display: flex;
          height: 44px;
          justify-content: center;
          padding: 0;
          width: 100%;

          &:hover {
            background: rgba(255, 255, 255, 0.5);
          }

          .label {
            .type-sfpro-bold();
            color: white;
            font-size: 10px;
            line-height: 12px;
            margin-left: 8px;
            text-align: left;
          }
        }
        .rehire-button {
          img {
            margin-right: 6px;
          }
          span {
            .type-sfpro-bold();
            color: white;
            font-size: 10px;
            line-height: 12px;
            text-align: left;
          }

          &.awaiting {
            .image-wrapper {
              background: #fff;
              border-radius: 100%;
              width: 28px;
              height: 28px;
              img {
                width: 19px;
                height: auto;
                margin-top: 5px;
                margin-left: 4px;
              }
            }
          }
        }

        .shape {
          align-items: center;
          border-radius: 35px;
          display: flex;
          height: 28px;
          justify-content: center;
          width: 28px;

          &.send {
            background: #00b267;
          }

          &.download {
            background: #0056fe;
          }
        }
      }
    }

    .media-actions {
      opacity: 0;
      transition: all 250ms linear;
      .media-actions-overlay {
        background: rgba(0, 0, 0, 0.65);
        height: 100%;
        width: 100%;
        cursor: pointer;
      }
    }
    &:hover .media-actions {
      opacity: 1;
    }

    .post-type-label {
      .type-sfpro-regular();
      display: inline-block;
      width: 59px;
      height: 15px;
      padding-top: 2px;
      text-transform: uppercase;
      font-size: 7px;
      font-weight: bold;
      font-style: italic;
      font-stretch: normal;
      line-height: 1.38;
      letter-spacing: 0.4px;
      text-align: center;
      color: #000000;
      position: absolute;
      z-index: 1;
      left: calc(50% - 59px / 2);

      &.type-image {
        background: #ffd982;
      }

      &.type-video {
        background: #4df1ff;
      }

      &.type-story {
        background: #66bdff;
      }
    }
    .ambassador-tag {
      position: absolute;
      z-index: 1;
      top: 13px;
      right: 10px;
      width: 62px;
    }
  }

  .ant-card-body {
    padding: 12px 12px 15px;
  }
  .ant-card-meta {
    margin-top: 0;
    display: flex;
    align-items: center;
    justify-items: flex-start;
    margin-bottom: 12px;
  }
  .ant-card-meta-title {
    .type-sfpro-regular();
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 13px;
    vertical-align: middle;
  }

  .video-loader {
    height: 100%;
    img,
    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .video-loader {
    .video-player-actions {
      cursor: pointer;
      left: calc(50% - 30px);
      top: calc(50% - 30px);
      position: absolute;
      width: 60px;
      height: 60px;
      .anticon {
        font-size: 60px;
      }
    }
  }

  .stats {
    height: 34px;
    .label {
      .type-sfpro-regular();
      font-style: normal;
      font-weight: 500;
      font-size: 10px;
      line-height: 10px;
      height: 15px;
      color: #000;
    }
    .value {
      .type-sfpro-regular();
      font-style: italic;
      font-weight: 800;
      font-size: 17px;
      line-height: 12px;
      color: #000;
      margin-bottom: 2px;
      height: 17px;
    }

    .story-post-label {
      margin-top: calc(16px - 9px);
      .type-sfpro-regular();
      font-weight: 800;
      font-size: 14px;
      line-height: 15px;
      color: #000000;
    }
  }
}
