.post-filter {
  button {
    height: 100%;
    color: #5b6572;
    padding-bottom: 14px;
    &:focus,
    &:active,
    &:hover {
      color: #000;
    }
  }

  &.post-filter:not(&-selected) {
    button {
      &:focus,
      &:active,
      &:hover {
        color: #000;

        .post-filter-icon {
          background: #000;
          color: #fff;
          transition: all 0.5s;

          svg {
            g,
            path {
              fill: #fff;
              stroke: #fff;
            }
          }
        }

        .post-filter-label {
          color: #000;
        }
      }
    }
  }

  &-selected {
    border-bottom: 3px solid @primary-color;

    button {
      color: #000;
      &:focus,
      &:active,
      &:hover {
        color: #000;
      }
    }

    .post-filter-icon {
      background: #000;
    }

    &.post-filter {
      &-all,
      &-images,
      &-videos,
      &-stories {
        .post-filter-icon {
          color: #fff;
          transition: all 0.5s;

          svg {
            g,
            path {
              fill: #fff;
              stroke: #fff;
            }
          }
        }
      }
    }

    .post-filter-label {
      color: #000;
    }
  }

  &-icon {
    height: 51px;
    width: 51px;
    display: inline-block;
    border: 1px solid #a9b0b9;
    border-radius: 100%;
    margin-right: 15px;
  }

  &-count {
    .type-sfpro-regular();
    text-align: left;
    font-weight: 800;
    font-size: 16px;
    line-height: 16px;
  }

  &-label {
    .type-sfpro-regular();
    letter-spacing: 0.5px;
    color: #5b6572;
    font-weight: 600;
    font-size: 9px;
    line-height: 11px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
  }

  &-all {
    .post-filter-icon {
      font-family: 'TruenoBold';
      font-size: 13px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #000;
      object-fit: contain;
      padding: 16px 13px;
      border-radius: 100%;
    }
  }

  &-images {
    .post-filter-icon {
      font-size: 18px;
      padding-top: 16px;

      .anticon {
        vertical-align: top;
      }
    }
  }

  &-videos {
    .post-filter-icon {
      font-size: 23px;
      padding-top: 16px;
    }

    .anticon {
      vertical-align: top;
      svg {
        height: 18px;
      }
    }
  }

  &-stories {
    .post-filter-icon {
      font-size: 24px;
      padding-top: 16px;
    }

    .anticon {
      vertical-align: top;
      svg {
        height: 18px;
      }
    }
  }
}
