.success-purchase {
  color: #fff;

  .logo {
    margin-top: 38.37px;
  }

  .header {
    .type-sfpro-heavy();
    text-align: center;
    font-size: 40px;
    line-height: 46px;
    margin-top: 225px;
    text-align: center;
  }

  .description {
    .type-sfpro-medium();

    font-size: 20px;
    line-height: 30px;
    margin-top: 14px;
    text-align: center;
  }

  .btn-section {
    margin-top: 43px;

    .start-new-campaign-btn.ant-btn, .accept-creators-btn.ant-btn {
      color: #000;
      border-radius: 25px;
      height: 50px;
      font-size: 14px;
      line-height: 18px;
      text-shadow: none;
      font-style: italic;
      padding: 10px 0;
      width: 358px;
      .type-sfpro-regular();

      span {
        .type-sfpro-regular();
        font-weight: 800;
        display: inline;
      }
    }

    .accept-creators-btn {
      margin-top: 19px;
      margin-bottom: 50px;
    }
  }
}