#brandRegistrationForm {
  color: @color-white;
  max-width: 480px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  padding-top: 48px;

  @media screen and (max-width: 600px) {
    padding: 20px;
    padding-top: 24px;
  }

  div {
    max-width: 100%;
  }

  .creator-note {
    .type-sfpro-bold();
    font-size: 12px;
    line-height: 24px;
  }

  .page-description {
    .type-sfpro-heavy();
    color: @color-white;
    font-size: 24px;
    line-height: 30px;
    margin: 10px 0 46px;
  }

  .ant-checkbox {
    .ant-checkbox-inner {
      height: 35px;
      width: 35px;
      background: #141414;
      border: 1px solid #4e4e4e;
      border-radius: 80px;

      &::after {
        border: 0;
      }
    }

    &.ant-checkbox-checked::after {
      height: 35px;
      width: 35px;
      border-radius: 80px;
      border: 1px solid @color-orange;
    }
  }

  .terms-of-service {
    margin-top: 3px;

    .tos-description {
      .type-sfpro-bold();
      color: @color-white !important;
      font-size: 12px;
      line-height: 18px;
    }

    .ant-form-explain {
      margin-top: 0;
    }

    .tos-container {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
