.update-password {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 5rem 1rem 3rem 1rem;

  @media (min-width: 48rem) {
    padding-top: 2rem;
  }

  h1 {
    color: @color-white;
    font-family: 'SFProDisplay', sans-serif;
    font-size: 1.75rem;
    margin: 0;
  }

  h2 {
    color: @color-white;
    font-family: 'SFProDisplay', sans-serif;
    margin: 0;
  }

  .subtitle {
    color: @color-white;
    margin: 0;
    max-width: 25rem;
    padding-bottom: 2rem;
    text-align: center;

    a {
      &:hover {
        color: lighten(@color-orange, 10%);
      }
    }
  }

  form {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    max-width: 100%;
  }

  .input-wrapper {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    max-width: 100%;

    span {
      color: @roses-50;
    }
  }

  label {
    color: @color-white;
    font-size: 1rem;
    margin: 0;
  }

  input {
    background-color: transparent;
    border: none;
    border-bottom: 0.0625rem solid @gray-50;
    color: @color-white;
    font-size: 1rem;

    &:focus {
      outline: none;
    }

    &::placeholder {
      color: @gray-50;
    }
  }

  .save-changes-btn {
    background-color: @color-orange;
    border-radius: 0.25rem;
    color: @color-black;
    font-family: 'SFProDisplay', sans-serif;
    font-size: 1rem;
    font-weight: 600;
    padding: 0.5rem 1rem;

    &:hover:enabled {
      background-color: lighten(@color-orange, 10%);
    }
  }
}
