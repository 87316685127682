// Color Palette
// --------------------------------------------------------

@color-black : #000000;
@color-black-bis : #151515;
@color-black-ter : #3A3A3A; // use for #3c3c3c?
@color-gray-darkest : #747474;
@color-gray-darker : #969696;
@color-gray-dark : #999999;
@color-gray : #A6A6A6;
@color-gray-light : #D8D8D8;
@color-gray-lighter : #D6D6D6;
@color-gray-lightest : #E8E8E8;
@color-white-ter : #F8FAFF;
@color-white-bis : #FCFCFC;
@color-white : #FFFFFF;

@color-pigeon-dark : #5B6572;
@color-pigeon-light : #A9B0B9;
@color-pigeon : #DBDFE8;

@color-orange : #FFB917;
@color-primary-brown: #fffbef;

@color-apple-blossom : #AF4C4C;

@color-off-black: #272727; // use for #2b2b2b?

@green-30: #bde5cc;

// soona colors
@black-translucent-60: rgba(0, 0, 0, 0.6);

@gray-10: #f5f6f6;
@gray-30: #d6d7dc;
@gray-40: #b8b9c1;
@gray-50: #93949f; // use for #989898?
@gray-90: #2f313c; // use for #161616?

@bubbletape-pink-30: #f2b5fa; // use for #ebd0ff?

@roses-50: #e15674;

// --------------------------------------------------------

// Text color
.text-black {
  color: @color-black;
}
.text-black-bis {
  color: @color-black-bis;
}
.text-black-ter {
  color: @color-black-ter;
}
.text-gray-darkest {
  color: @color-gray-darkest;
}
.text-gray-darker {
  color: @color-gray-darker;
}
.text-gray-dark {
  color: @color-gray-dark;
}
.text-gray {
  color: @color-gray;
}
.text-gray-light {
  color: @color-gray-light;
}
.text-gray-lighter {
  color: @color-gray-lighter;
}
.text-gray-lightest {
  color: @color-gray-lightest;
}
.text-white {
  color: @color-white;
}
.text-orange {
  color: @color-orange;
}

.text-pigeon-dark {
  color: @color-pigeon-dark;
}
.text-pigeon-light {
  color: @color-pigeon-light;
}
.text-pigeon {
  color: @color-pigeon;
}

// Background color
.bg-black {
  background-color: @color-black;
}
.bg-black-bis {
  background-color: @color-black-bis;
}
.bg-black-ter {
  background-color: @color-black-ter;
}
.bg-gray-darkest {
  background-color: @color-gray-darkest !important;
}
.bg-gray-darker {
  background-color: @color-gray-darker;
}
.bg-gray-dark {
  background-color: @color-gray-dark;
}
.bg-gray {
  background-color: @color-gray;
}
.bg-gray-light {
  background-color: @color-gray-light;
}
.bg-gray-lighter {
  background-color: @color-gray-lighter;
}
.bg-gray-lightest {
  background-color: @color-gray-lightest;
}
.bg-white {
  background-color: @color-white;
}
.bg-orange {
  background-color: @color-orange;
}
