.content-approval-modal {
  position: relative;

  .close {
    position: absolute;
    width: 45px;
    height: 45px;
    font-size: 15px;
    border: 1px solid #000;
    border-radius: 4px;
    padding: 0px;
    top: 33px;
    right: 25px;
    z-index: 1;
  }
  .ant-modal-body {
    padding: 0px;
  }

  .ant-modal-content {
    border-radius: 10px;
  }

  .header {
    padding: 23px 33px 0;
  }

  .modal-content {
    border-radius: 10px 10px 0 0;
    background-color: #f0f5fd;
  }
  .footer {
    padding: 23px 20px 17px 36px;
    box-shadow: 0px 2px 19px rgba(0, 0, 0, 0.162024);
    color: #000;
    .type-sfpro-regular();
    h3 {
      font-weight: 800;
      font-size: 18px;
      line-height: 23px;
    }
    p {
      font-weight: normal;
      font-size: 15px;
      line-height: 23px;
    }
    .confirm {
      width: 183px;
      .type-sfpro-regular();
      height: 55px;
      background-color: #ffb917;
      color: #000;
      border: none;
      font-weight: bold;
      font-size: 14px;
      line-height: 13px;

      .timer {
        font-weight: normal;
        font-size: 10px;
        line-height: 13px;
        .ant-statistic {
          display: inline-block;
        }
      }
    }
    .pass {
      font-weight: bold;
      color: #e58989;
      font-size: 11px;
      line-height: 20px;
      text-align: center;
      height: 20px;
      margin-top: 9px;
    }
  }

  .influencer-details {
    float: left;
    .ant-avatar {
      margin-right: 14px;
    }

    &-username,
    .content-stats {
      .type-sfpro-regular();
      color: #000;
      font-size: 16px;
      line-height: 23px;
    }
    &-username {
      margin-top: 7px;
      .type-sfpro-regular();
      font-weight: 800;
    }
  }

  .content-count {
    color: #000;
    text-align: right;
    padding-right: 70px;

    &-number {
      margin-top: 7px;
      .type-sfpro-regular();
      font-weight: 800;
      font-size: 18px;
      line-height: 23px;
    }
    &-label {
      .type-sfpro-regular();
      font-size: 16px;
      line-height: 23px;
    }
  }

  .submitted-contents {
    margin-top: 29px;
    padding-bottom: 26px;
    width: 100%;

    .creator-content-content-card {
      float: left;
      margin-right: 20px;
      background-color: #000;

      img,
      video {
        width: 100%;
        height: 200px;
      }
      .video-loader {
        .anticon {
          font-size: 38px;
        }
      }
    }
  }
}

.trend-modal {
  &.reject-creator-content {
    .modal-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      background: #46c5ff;
      width: 60px;
      height: 60px;
      border-radius: 100%;
      margin-bottom: 11px;
      font-size: 35px;
    }
    .close-modal {
      border: 1px solid #000000;
      box-sizing: border-box;
      border-radius: 4px;
      width: 45px;
      height: 45px;
      position: absolute;
      top: 20px;
      left: 20px;
      text-align: center;
      padding: 0px;

      .anticon {
        line-height: 45px;
        margin-top: 1px;
        font-size: 20px;
        svg {
          path {
            transition: stroke 0.5s;
          }
        }
      }

      &:hover {
        border-color: #ffcc40;
        .anticon {
          svg {
            path {
              transition: stroke 0.5s;
              stroke: #ffcc40;
            }
          }
        }
      }
    }

    .center-horizontally {
      display: flex;
      justify-content: center;
    }

    .reject-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      background: #e58989;
      width: 60px;
      height: 60px;
      border-radius: 100%;
      margin-bottom: 11px;

      .dash {
        height: 7px;
        background: @color-white;
        width: 32px;
        border-radius: 50px;
      }
    }

    .ant-modal-body {
      padding-top: 38px;
      padding-bottom: 39px;
    }

    h2 {
      .type-sfpro-heavy();
      font-style: normal;
      font-weight: 800;
      font-size: 18px;
      line-height: 26px;
      color: #000000;
      margin-bottom: 0px;
    }

    p {
      color: #000;
      .type-sfpro-light();
      font-style: normal;
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 23px;
    }

    .ant-btn.submit {
      .type-sfpro-heavy();
      font-weight: bold;
      font-size: 14px;
      line-height: 16px;
      color: #000;
      height: 45px;
      width: 217px;
    }

    textarea {
      .type-sfpro-light();
      font-style: normal;
      font-size: 14px !important;
      line-height: 20px;
      background: #f1f5fd;
      border-radius: 8px;
      border: none !important;
      width: 100%;
      min-height: 159px;

      &::placeholder {
        color: #5b6572;
      }
    }
  }

  &.creator-approval-modal {
    .ant-modal-body {
      padding: 0;
    }
  }

  .modal-notification {
    background: #124345;
    border-radius: 10px 10px 4px 4px;
    color: white;
    padding: 20px 25px 22px 17px;
    .type-sfpro-regular();
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    #title {
      font-weight: 600;
      font-size: 13px;
      line-height: 12px;
      letter-spacing: 1px;
      margin-bottom: 7px;
    }
    p {
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      color: #FFFFFF;
    }
    button {
      background: #4C8F92;
      border: none;
      border-radius: 4px;
      color: white;
      padding: 16px 0px;
      font-weight: 600;
      font-size: 16px;
      line-height: 18px;
      width: 123px;
      cursor: pointer;
    }
  }
}
