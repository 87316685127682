.bg-black {
  background-color: @color-black;
}

.bg-white {
  background-color: @color-white !important;
}

body {
  background-color: @color-black !important;
}

.ant-layout {
  background: #fff !important;
}
