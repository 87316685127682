.campaign-image {
  position: relative;
  min-height: 260px;
  overflow: hidden;
  background: @color-gray-lighter;
  border-radius: 4px;

  img {
    border-radius: 4px;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  .trend-btn {
    position: absolute;
    bottom: 15px;
    right: 0;
    box-shadow: -2px 1px 4px 0 rgba(0, 0, 0, 0.28);
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
}

.item-campaign {
  height: 359px;
}

.post-requirements {
  .required-posts {
    .post-icons {
      font-size: 20px;
      margin-right: 10px;
      vertical-align: middle;
    }
  }

  .label-value {
    vertical-align: middle;
  }

  &-states {
    &-state {
      margin-top: 13px;
    }

    .circle {
      width: 22px;
      height: 22px;
      border-radius: 100%;
      font-size: 8px;
      display: inline-block;
      border: 1px solid @color-black;
      padding-top: 4px;
      text-align: center;
      margin-right: 10px;
    }
  }
}

.campaign-content {
  .nav-header {
    padding-top: 35px;

    .campaign-header {
      max-width: 742px;
      margin: auto;
    }
    h1 {
      font-size: 26px;
    }
  }
}

#application-page,
#influencers-page {
  .trend-table {
    max-width: 100%;

    .ant-table-thead {
      th {
        text-align: center !important;
        &.influencer-details {
          text-align: left !important;
        }
        &.age-range {
          width: 70px;
        }
        &.followers {
          width: 90px;
        }
        &.like-count {
          width: 90px;
        }
        &.date-applied {
          width: 120px;
        }
        &.comment-count {
          width: 120px;
        }
        &.actions {
          width: 150px;
        }
        &.engagement-rate {
          width: 150px;
        }
      }

      th:first-child {
        text-align: left !important;
      }

      .ant-table-column-has-sorters:not(.ant-table-column-sort) {
        .ant-table-column-sorter {
          .ant-table-column-sorter-inner {
            &:after {
              content: '';
              width: 0;
              height: 0;
              border-style: solid;
              border-width: 0 0 6px 6px;
              border-color: transparent transparent #5b6572 transparent;
            }

            .ant-table-column-sorter-up {
              &.off {
                display: none;
              }
            }
            .ant-table-column-sorter-down {
              &.off {
                display: none;
              }
            }
          }
        }
      }

      .ant-table-column-sort {
        background-color: #fffcf3 !important;

        .ant-table-column-title {
          font-family: TruenoSemiBold;
          color: @color-black;
        }

        .ant-table-column-sorter {
          .ant-table-column-sorter-inner {
            .ant-table-column-sorter-up {
              &.on {
                display: inline-block;
              }
              &.off {
                display: none;
              }
            }
            .ant-table-column-sorter-down {
              &.on {
                color: #000000;
              }
              &.off {
                display: none;
              }
            }
          }
        }
      }
    }

    .ant-table-body {
      overflow-x: auto;
    }

    .ant-table-row {
      td {
        cursor: pointer;
        &.influencer-details {
          width: 291px;
        }
        &.favorite-creator {
          padding-right: 5px !important;
          width: min-content;
        }
        &.followers {
          padding-right: 10px !important;
        }
        &.age-range {
          padding-right: 19px !important;
        }
        &.like-count {
          padding-right: 10px !important;
        }
        &.comment-count {
          padding-right: 10px !important;
        }
        &.engagement-rate {
          padding-right: 10px !important;
        }
        &.date-applied {
          padding-right: 34px !important;
        }
        &.influencer-details {
          .influencer-info,
          .influencer-image {
            cursor: pointer;
          }
        }
        &.creator-credits {
          padding-right: 19px !important;
          width: 182px;
          .credits-circle {
            background: #ffefd2;
            float: right;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 28px;
            height: 28px;
            overflow: hidden;
            font-size: 13;
            font-weight: bold;
          }
        }
      }

      .ant-table-column-sort {
        font-family: TruenoBold;
        background-color: #fffcf3 !important;
      }
    }
  }

  .application-filters {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;

    @media (min-width: 700px) {
      flex-direction: row;
    }

    .favorites-wrapper {
      display: inline;

      @media (min-width: 700px) {
        border-right: 1px solid #a9b0b9;
        padding-right: 10px;
      }
    }
  }

  .empty-application {
    padding-top: 120px;

    .media {
      width: 440px;
      height: 250px;
      margin-bottom: 22px;
    }
    p {
      .type-sfpro-heavy();
      font-size: 26px;
      line-height: 33px;
      text-align: center;
      color: #000000;
    }
  }
}

#influencers-page {
  .trend-table .ant-table-thead th {
    text-align: right !important;
  }

  #post-approval-content .trend-table .ant-table-thead th {
    text-align: left !important;
  }

  #shipments-content .trend-table .ant-table-thead th {
    text-align: left !important;
  }

  #post-approval-content,
  #creator-content-approval-content {
    color: #000000;

    .page-header {
      padding: 32px 16px;
      border-bottom: 1px solid #e8e8e8;

      h2 {
        .type-sfpro-heavy();
        font-style: normal;
        font-weight: 800;
        font-size: 14px;
        line-height: 12px;
        color: #000000;
      }
      p {
        width: 100%;
        .type-sfpro-regular();
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        /* or 143% */

        color: #000000;
      }
    }
    .submitted-posts {
      padding-left: 20px;
      background-color: #f1f5fd;
      padding-top: 31px;
      padding-bottom: 39px;
      overflow-x: scroll;
      overflow-y: hidden;

      .empty {
        .type-sfpro-heavy();
        text-align: center;
        font-style: italic;
        font-weight: 800;
        font-size: 26px;
        line-height: 16px;
        /* identical to box height, or 62% */

        text-align: center;
        text-transform: uppercase;
        margin-top: 35px;
        margin-bottom: 26px;
        color: #dbe3ee;
      }

      .post-card {
        display: inline-block;
        margin-right: 20px;
      }
    }

    .post-card {
      border-radius: 10px;
      width: 219px;
      background-color: @color-white;
      padding-top: 12px;

      .post-card-image,
      .post-card-video {
        width: 195px;
        height: 166px;
        margin-left: 12px;
        margin-bottom: 9px;

        img,
        video {
          width: 195px;
          height: 166px;
          background-color: #eee;
          border-radius: 10px;
        }
      }
    }

    .post-actions {
      padding-left: 12px;
      padding-right: 12px;
    }
    .post-label {
      .type-sfpro-bold();
      font-weight: 800;
      font-size: 18px;
      line-height: 20px;
      color: @color-black;
    }
    .post-link a {
      .type-sfpro-bold();
      text-decoration: underline;
      color: @color-black;
      font-weight: bold;
      font-size: 11px;
      line-height: 10px;
    }
    .post-btn-group {
      .type-sfpro-bold();
      text-align: center;
      font-style: normal;
      font-weight: bold;
      font-size: 9px;
      line-height: 11px;
      .ant-btn {
        padding: 0;
        width: 37px;
        height: 37px;
        border-radius: 100%;
        margin-bottom: 4px;
        &:hover {
          opacity: 0.7;
        }
      }

      &.reject {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .dash {
          height: 4px;
          background-color: @color-black;
          border-radius: 50px;
          width: 19.73px;
        }

        .ant-btn {
          background: #e58989;
          border: none;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
      &.approve {
        .anticon {
          margin-top: 4px;
          display: inline-block;
          font-size: 22px;
        }
        .ant-btn {
          background: #00b267;
          border: none;
        }
      }
    }

    .post-details {
      color: @color-black;
      margin-top: 9px;
      border-top: 1px solid #dbdfe8;
      padding: 12px;

      .post-username {
        .type-sfpro-bold();
        font-weight: bold;
        font-size: 12px;
        line-height: 18px;
      }

      .post-stats {
        .type-sfpro-regular();
        width: 100%;
        font-size: 11px;
        line-height: 17px;

        .post-stat-type {
          float: left;
        }
        .post-stat-timer {
          font-weight: bold;
          float: right;
        }
      }
    }
    .ant-table {
      .post {
        width: 190px;
      }

      tr:first-child > th {
        width: 50%;
      }

      .post-avatar,
      .creator-content-avatar {
        margin-right: 20px;
        .story-type,
        .video-type,
        .image-type {
          .type-sfpro-bold();
          font-size: 7px;
          line-height: 11px;
          text-align: center;
          letter-spacing: 0.4px;
          text-transform: uppercase;
        }

        &:nth-child(n + 4) {
          margin-top: 20px;
        }
      }
    }

    .rehire-button {
      .type-sfpro-regular();
      font-weight: bold;
      font-size: 11px;

      span {
        white-space: initial;
        line-height: 13px;
        text-align: left;
        max-width: 88px;
      }
    }
  }

  @media (min-width: 700px) {
    .shipment-list,
    .trend-table--wrapper {
      margin: 1rem auto 0;
      max-width: 50rem;
      padding: 0;

      thead::before,
      thead::after {
        display: none;
      }

      .trend-table {
        border: 0.0625rem solid @gray-30;
        border-radius: 0.3125rem;

        .table-address {
          p {
            margin: 0;
            width: max-content;
          }
        }

        .col-action.actions {
          padding-right: 1rem !important;
          max-width: max-content;
          width: max-content;
        }

        .cell-action {
          align-items: flex-end;
          flex-wrap: wrap;
        }
      }

      .influencer-avatar-image {
        padding-left: 1rem !important;
      }
    }
  }
}

#influencer-drawer,
#influencer-drawer-admin {
  &.ant-drawer:not(.ant-drawer-open) {
    .ant-drawer-content {
      box-shadow: none;
    }
  }

  .ant-drawer-body {
    padding: 0;
    overflow: hidden;
  }
  .ant-drawer-header-no-title {
    position: relative;
    .ant-drawer-close {
      left: 0;
      color: @color-black;
    }
  }

  .ant-drawer-content {
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.55);
    background-image: @color-white;

    .influencer-info {
      .type-sfpro-regular();
      color: @color-black;

      &-label {
        display: block;
        .type-sfpro-regular();
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        line-height: 16px;
        color: @color-black;
      }

      &-actions {
        padding: 20px;
        button {
          height: 45px;
          border-radius: 3px;
          .type-sfpro-regular();
          font-style: normal;
          font-weight: bold;
          font-size: 14px;
          line-height: 20px;

          &.rehire-button {
            .type-sfpro-bold();
            font-style: normal;
            font-size: 14px;
          }

          &.action-reject {
            color: @color-black;
            border: none;
            background-color: #ff5a5a;

            &:hover {
              background-color: #fa8383;
            }
          }

          &.action-approve {
            color: @color-black;
          }
        }
      }

      &-avatar {
        text-align: center;
        margin-top: 35px;
        position: relative;
      }
      &-vip {
        width: 30px;
        height: 30px;
        font-size: 30px;
        position: absolute;
        display: block;
        bottom: 0;
        right: 117px;
      }
      &-name {
        .type-sfpro-regular();
        text-align: center;
        color: @color-black;
        font-size: 18px;
        font-weight: 600;
        font-style: normal;
        font-stretch: normal;
        line-height: 18px;
        letter-spacing: normal;
        text-align: center;
        padding-top: 13px;
        margin: 0;
      }
      &-address {
        font-size: 12px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 18px;
        letter-spacing: normal;
        text-align: center;
        color: @color-black;
        margin-top: 4px;
      }
    }

    .influencer-socials {
      display: inline-block;
      margin: 3px;
      border: 2px solid #f4f4f4;
      border-radius: 35px;
      &:hover {
        border: 2px solid #ffb917;
      }
      &-button {
        display: table-cell;
        vertical-align: middle;
        text-align: center;
        width: 30px;
        height: 30px;
        padding-top: 2px;
      }
    }

    .influencer-stats {
      border: 1px solid #cbd6ed;
      background-color: #f1f5fd;
      color: @color-black;
      border-right: 0;
      border-left: 0;
      margin-bottom: 33px;

      &-followers {
        padding-top: 13px;
        text-align: center;
        color: @color-black;
        border: 1px solid #cbd6ed;

        .influencer-stats-label {
          margin-bottom: 10px;
        }

        .influencer-stats-label {
          text-align: center;
        }

        .influencer-stats-value {
          .type-sfpro-regular();
          font-style: normal;
          font-weight: bold;
          font-size: 28px;
          line-height: 21px;
          padding-bottom: 21px;
        }
      }

      &-label {
        display: block;
        .type-sfpro-regular();
        color: @color-black;
        font-weight: 600;
        font-size: 10px;
        line-height: 12px;
        letter-spacing: 2px;
        text-transform: uppercase;
      }

      &-other-details {
        padding: 14px 0 21px;
      }

      &-engagement {
        .type-sbold();
        .size-10px();
        text-transform: uppercase;
        padding: 10px 11px;
        text-align: center;
        background: #3b1f5c;
        border-top: 1px solid #46266d;
        letter-spacing: 2px;
      }

      &-likes,
      &-comments {
        padding-left: 19px;

        .influencer-stats-value {
          font-size: 21px;
          .type-sfpro-bold();
          color: @color-black;
          vertical-align: middle;

          &-indicator-icon {
            display: inline-block;
            margin-right: 6px;
          }

          &-count {
            line-height: 21px;
          }

          &-indicator-percent {
            display: inline-block;
            line-height: 11px;
            margin-left: 1px;
            font-size: 9px;
            color: #ffb917;
            .type-sfpro-bold();
            font-weight: 600;
          }

          &.down {
            .influencer-stats-value-indicator-percent {
              color: #f54c15;
            }
          }

          &.up {
            .influencer-stats-value-indicator-percent {
              color: #15f590;
            }
          }
        }
      }

      &-comments {
        padding-left: 23px;
        border-left: solid 1px #46266d;
      }
    }

    .influencer-review-stats {
      margin-left: 21px;
      margin-right: 21px;
      margin-bottom: 30px;
      color: @color-black;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: normal;
      padding-bottom: 2px;

      .review-stat {
        margin-bottom: 26px;
        position: relative;

        &:last-child {
          margin-bottom: 0;
        }
      }

      label.review-stat-label {
        .type-sfpro-regular();
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        line-height: 18px;
        margin-bottom: 5px;
      }

      .review-stat-value {
        .type-sfpro-regular();
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 21px;

        position: absolute;
        right: 0;
        bottom: 7px;
      }

      .review-stat-rate {
        margin-top: 5px;
        position: relative;

        &-bar {
          height: 4px;
          background-color: #ffb917;
        }

        &::before {
          position: absolute;
          display: block;
          content: '';
          width: 100%;
          height: 2px;
          top: 1px;
          background-color: #d0d0d0;
          z-index: -1;
        }
      }

      .ant-progress-bg,
      .ant-progress-inner {
        display: block;
        border-radius: 0 !important;
      }
      .ant-progress {
        display: block;
        & > div {
          display: block;
        }
      }
      .ant-progress-outer {
        display: block;
      }

      .ant-progress-inner {
        display: block;
        background: transparent;

        &:before {
          content: '';
          height: 2px;
          border: solid 1px #46266d;
          width: 100%;
          display: block;
          position: absolute;
          top: 1px;
        }
      }
    }

    .influencer-reviews {
      margin-left: 21px;
      margin-right: 21px;
      margin-bottom: 47px;
      color: @color-black;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: normal;

      .influencer-info-label {
        margin-bottom: 4px;
      }

      .review-comment {
        .type-sfpro-regular();
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 24px;
        margin-bottom: 8px;
      }

      .review-reviewer {
        .type-sfpro-regular();
        font-style: normal;
        font-weight: bold;
        font-size: 10px;
        line-height: 18px;
      }

      .influencer-review {
        border-bottom: 1px solid #46266d;
        padding-bottom: 24px;
        margin-top: 24px;

        &:first-child {
          margin-top: 0;
        }

        &:last-child {
          margin-bottom: 16px;
          border-bottom: none;
          padding-bottom: 0;
        }
      }

      .load-more-reviews {
        button.ant-btn {
          height: 28px;
          background-color: #f1f5fd;
          font-style: normal;
          font-weight: bold;
          font-size: 8px;
          line-height: 10px;
          text-align: center;
          letter-spacing: 3px;
          text-transform: uppercase;
          color: @color-black;
          border: none;

          &:hover {
            opacity: 0.5;
          }
        }
      }
    }

    .influencer-posts {
      margin-left: 21px;
      margin-right: 21px;

      .influencer-info-label {
        margin-bottom: 12px;
      }

      &-images {
        a {
          display: inline-block;
          width: 141px;
          height: 141px;
          padding-right: 4px;
          padding-bottom: 4px;

          &:last-child {
            padding-right: 0;
          }
        }

        img {
          width: 100%;
          height: 100%;
        }
      }
    }
    .influencer-info-bio {
      color: @color-black;
      margin: 32px 20px 22px 20px;

      p {
        .type-sfpro-regular();
        white-space: pre-wrap;
        font-style: normal;
        font-weight: normal;
        font-size: 11px;
        line-height: 20px;

        color: @color-black;
      }
    }

    .influencer-info-work {
      color: @color-black;
      margin: 0 20px;

      .influencer-info-label {
        margin-bottom: 8px;
      }

      .influencer-info-posts {
        .post-card {
          margin-bottom: 20px;

          &-resource {
            position: relative;
            width: 100%;
            height: 287px;

            .post-card-video,
            .post-card-image {
              width: 100%;
              height: 100%;
            }

            .post-card-image a {
              width: 100%;
              height: 100%;
              display: block;
            }

            video,
            img {
              object-fit: contain;
              width: 100%;
              height: 100%;
              object-fit: cover;
            }

            &:after {
              content: '';
              display: block;
              position: absolute;
              bottom: 0;
              width: 100%;
              height: 62px;
              background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), #000000);
            }
          }

          &-stats {
            position: absolute;
            bottom: 0;
            width: 100%;
            padding-left: 16px;
            z-index: 2;
          }

          &-stat {
            float: left;
            width: auto;
            margin-right: 30px;
            margin-bottom: 11px;
            color: @color-white;

            &-value {
              font-family: 'TruenoBold';
              font-size: 16px;
              line-height: 0.75;
              padding-bottom: 6px;
            }

            &-label {
              font-family: 'TruenoSemiBold';
              font-size: 8px;
              letter-spacing: 0.5px;
            }
          }

          &-actions {
            width: 100%;

            button {
              background-color: #f1f5fd;
              height: 48px;
              border: none;
              border-radius: 0;
              color: @color-black;
              font-family: 'TruenoSemiBold';
              font-size: 8px;
              letter-spacing: 3px;
            }
          }
        }
      }
    }
  }

  .post-gallery-actions {
    button {
      top: 118px;
      &.action-next {
        right: 16px;
      }

      &.action-previous {
        left: 16px;
      }
    }
  }

  .ant-tabs-bar {
    border-bottom: solid 1px #46266d;
    margin-bottom: 0;
  }
  .ant-tabs-ink-bar {
    background-color: #ffb917;
    margin-left: 39px;
    width: 31px !important;
  }

  .ant-tabs-nav {
    width: 100%;
    .ant-tabs-tab {
      padding: 23px 0;
      width: calc(100% / 3);
      margin: 0;
      opacity: 0.5;
      font-family: 'TruenoLight';
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 0.86;
      letter-spacing: normal;
      text-align: center;
      color: #ffffff;

      &.ant-tabs-tab-active {
        font-family: 'TruenoBold';
        opacity: 1;
      }
      &:hover {
        opacity: 1;
      }
    }
  }

  .campaign-details {
    text-align: left;
    color: @color-white;
    margin: 18px 19px;
    padding: 19px 20px;
    border-radius: 1;
    background-color: rgba(59, 31, 92, 0.49);

    &-brand-name {
      font-family: 'TruenoBold';
      font-size: 12px;
      line-height: 19px;
      margin-bottom: 3px;
    }

    &-name {
      font-family: 'TruenoBold';
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.36;
      letter-spacing: normal;
      color: #ffffff;
      margin-bottom: 18px;
    }

    .details-status {
      position: relative;
      margin-bottom: 16px;
      &:last-child {
        margin-bottom: 0;
      }
      .status {
        margin-left: 15px;
        font-family: 'TruenoRegular';
        line-height: 0.86;
        &-value {
          margin-bottom: 8px;
        }

        &-label {
          text-transform: uppercase;
          font-family: 'TruenoSemiBold';
          font-size: 8px;
          line-height: normal;
          letter-spacing: 2px;
        }
      }
      .icon {
        top: 5px;
        width: 8px;
        height: 8px;
        position: absolute;
        display: inline-block;
        border-radius: 100%;

        &.success {
          background: #15f590;
        }
        &.danger {
          background-color: #ff0000;
        }
        &.warning {
          background-color: #ffb917;
        }
      }
    }
  }
  .ant-spin-nested-loading {
    height: 100%;
    width: 100%;
    .ant-spin-container {
      height: 100%;
      width: 100%;
    }
  }

  .brand-credit-text {
    .type-sfpro-regular();
    color: black;
    font-weight: bold;
    padding: 10px;
    p {
      margin: 0;
    }
    .credit-amount {
      font-size: 30px;
      line-height: 1;
    }
  }
}

#influencer-drawer-admin {
  .ant-drawer-content .influencer-stats {
    border-top: 0;
  }
  .ant-drawer-content .influencer-info-actions {
    padding: 23px 34px 0 34px;

    button {
      margin-bottom: 6px;
    }
  }
  .ant-drawer-content .influencer-levels {
    padding: 23px 34px 0 34px;
  }
  .influencer-badge {
    position: absolute;
    top: 22px;
    right: 43px;
    width: 34px;
  }
  .admin-credit-text {
    margin: 0;
    color: black;
    text-align: center;
    text-transform: uppercase;
  }

  .ant-tabs-nav {
    .ant-tabs-tab {
      color: @color-black;
      -webkit-user-select: none; /* Safari */
      -moz-user-select: none; /* Firefox */
      -ms-user-select: none; /* IE10+/Edge */
      user-select: none; /* Standard */
    }
  }
}

.admin-nav {
  .ant-menu-item {
    &:hover,
    &:focus {
      background-color: lighten(@color-orange, 40%) !important;
      border-radius: 0.625rem 0.625rem 0 0;
    }
  }

  .ant-menu-item-active {
    background-color: lighten(@color-orange, 40%) !important;
    border-radius: 0.625rem 0.625rem 0 0;
    color: @color-black !important;
  }

  .ant-menu-item-selected {
    background-color: @color-orange;
    border-radius: 0.625rem 0.625rem 0 0;
    color: @color-black !important;

    &:hover,
    &:focus {
      background-color: @color-orange !important;
    }
  }
}

.influencer-cards-flex {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: center;
}

.influencer-card {
  background-color: @color-white;
  border: 0.0625rem solid @gray-40;
  border-radius: 0.625rem;
  min-width: 15.625rem;
  overflow: hidden;
  width: 30%;

  .influencer-img-btn {
    width: 100%;

    img,
    video {
      height: 13.75rem;
      object-fit: cover;
      width: 100%;
    }
  }

  .influencer-card-content {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    padding: 0.75rem;
  }

  .influencer-details {
    align-items: center;
    border-radius: 0.5rem;
    display: flex;
    gap: 1rem;
    transition: background-color 0.3s;

    &:hover,
    &:focus {
      background-color: @gray-10;
    }

    &:focus-visible {
      outline: revert !important;
      outline-offset: -0.125rem;
    }
  }

  .influencer-avatar {
    border-radius: 50%;
    height: 2.25rem;
    width: 2.25rem;
  }

  .influencer-details-right {
    color: @color-black;
    display: flex;
    flex-direction: column;
  }

  .influencer-download-btn {
    background-color: @color-black;
    border-radius: 0.5rem;
    color: @color-white;
    padding: 0.5rem 1rem 0.75rem 1rem;
    width: 100%;
  }
}

#campaign-posts-page {
  .post-filter-types {
    border-bottom: 1px solid #d6d6d6;
  }

  .ant-select-selection-selected-value,
  .ant-select-selection__placeholder {
    font-size: 0.75rem !important;
    font-weight: 600;
  }
}

.example-campaign {
  .video-player {
    width: 446px;
    height: 250px;
    margin-top: 86.5px;
    margin-bottom: 42px;
  }
  .account-approval-status {
    .type-sfpro-bold();
    color: @color-black;
    font-size: 16px;
    line-height: 16px;
    margin-bottom: 8px;
  }
  .approval-status {
    &::after {
      content: '';
      margin-left: 3px;
      border-radius: 100%;
      width: 8px;
      height: 8px;
      display: inline-block;
      margin-bottom: 2px;
    }
    &.pending,
    &.rejected {
      color: #f6ab00;
      &::after {
        background-color: #f6ab00;
      }
    }
    &.approved {
      color: #19d921;
      &::after {
        background-color: #19d921;
      }
    }
  }
}

.example-campaign {
  .media {
    margin-top: 133.5px;
  }
  img {
    width: 411px;
    height: 197px;
    margin-bottom: 37px;
  }
  h1 {
    font-size: 26px;
    color: @color-black;
    padding: 0;
    margin-bottom: 8px;
  }
  p {
    .type-sfpro-regular();
    font-size: 16px;
    color: @color-black;
    line-height: 24px;
  }
}
.bulk-actions {
  .trend-btn {
    height: 35px;
  }
  .ant-btn.yotpo-btn {
    .ant-btn.trend-btn();
    .type-firme-iblack();
    .size-10px();
    height: 35px;
    margin-right: 10px;
    background: #1776ec;
    min-width: 153px;
    text-align: left;
    color: @color-white;

    @media (min-width: 700px) {
      height: 35px;
    }

    img {
      position: absolute;
      right: 11px;
      top: 6px;
      object-fit: contain;
      vertical-align: top;
      height: 23px;
      width: 23px;
    }
    &:hover {
      color: @color-white;
      background: #135fbd;
    }
  }
}

#recent-campaign-page {
  h1 {
    margin-top: 38px;
    margin-bottom: 35px;
    font-size: 28px;
  }
}

.campaign-header {
  width: 100%;
  padding: 0 16px;

  .ant-btn.upgrade {
    .type-sfpro-bold();
    text-transform: none;
    font-size: 12px;
    height: 35px;
    line-height: 35px;
    color: @color-black;
    background: linear-gradient(265.16deg, #fcd697 0%, #ffebcb 100%);
    border-radius: 23px;
    padding-left: 4px;
    padding-right: 19px;

    span {
      vertical-align: top;
      margin-right: 15px;
      margin-top: 3px;
      display: inline-block;
      background-color: @color-white;
      box-shadow: 0 1px 6px rgba(0, 0, 0, 0.15);
      border-radius: 24px;
      width: 72px;
      height: 29px;
      line-height: 28px;
    }
    img {
      &.label {
        height: 12.66px;
        width: 27.14px;
        margin-right: 3.1px;
      }
      &.icon {
        width: 16.78px;
        height: 10.24px;
        margin-bottom: -1px;
      }
    }
  }
}
#campaign-page {
  .ant-menu-item {
    &:hover,
    &:focus-within {
      border-bottom: 0.25rem solid @color-orange;
    }
  }

  .campaign-subtabs {
    background: #f8faff;
    border-bottom: 1px solid #e8e8e8;

    .ant-col {
      width: 100%;
      max-width: 742px;
    }

    .campaign-subtab {
      .type-sfpro-regular();
      background: #f8faff;
      padding-top: 18px;
      height: 88px;
      text-align: center;

      a {
        align-items: center;
        display: flex;
        justify-content: center;

        &:hover,
        &:focus-within {
          .campaign-subtab-icon {
            background-color: @color-black;
          }
        }
      }

      &.ant-menu-item-selected {
        .campaign-subtab-details {
          color: @color-black;
        }
      }

      &.ant-menu-item-selected {
        .campaign-subtab-icon {
          background-color: @color-black;
        }
      }

      .campaign-subtab-icon {
        background-color: @color-white;
        background-repeat: no-repeat;
        background-position: center;
        transition: all 150ms linear;
      }

      &.shipment {
        .campaign-subtab-icon {
          background-image: url('../../images/pages/Campaigns/ShowPage/ShipmentGrey.svg');
        }

        a {
          &:hover,
          &:focus-within {
            .campaign-subtab-icon {
              background-image: url('../../images/pages/Campaigns/ShowPage/ShipmentWhite.svg');
              background-color: @color-black;
            }
          }
        }

        &.ant-menu-item-selected {
          .campaign-subtab-icon {
            background-image: url('../../images/pages/Campaigns/ShowPage/ShipmentWhite.svg');
            background-color: @color-black;
          }
        }
      }

      &.creators,
      &.social {
        .campaign-subtab-icon {
          background-image: url('../../images/pages/Campaigns/ShowPage/CreatorsGrey.svg');
        }

        a {
          &:hover,
          &:focus-within {
            .campaign-subtab-icon {
              background-image: url('../../images/pages/Campaigns/ShowPage/CreatorsWhite.svg');
              background-color: @color-black;
            }
          }
        }

        &.ant-menu-item-selected {
          .campaign-subtab-icon {
            background-image: url('../../images/pages/Campaigns/ShowPage/CreatorsWhite.svg');
          }
        }
      }

      &.pending_content {
        .campaign-subtab-icon {
          background-image: url('../../images/pages/Campaigns/ShowPage/PendingContentGrey.svg');
        }

        a {
          &:hover,
          &:focus-within {
            .campaign-subtab-icon {
              background-image: url('../../images/pages/Campaigns/ShowPage/PendingContenWhite.svg');
            }
          }
        }

        &.ant-menu-item-selected {
          .campaign-subtab-icon {
            background-image: url('../../images/pages/Campaigns/ShowPage/PendingContenWhite.svg');
          }
        }
      }

      &.completed {
        .campaign-subtab-icon {
          background-position-x: 11px;
          background-position-y: 9px;
          background-image: url('../../images/pages/Campaigns/ShowPage/CompletedGrey.svg');
        }

        a {
          &:hover,
          &:focus-within {
            .campaign-subtab-icon {
              background-image: url('../../images/pages/Campaigns/ShowPage/CompletedWhite.svg');
            }
          }
        }

        &.ant-menu-item-selected {
          .campaign-subtab-icon {
            background-image: url('../../images/pages/Campaigns/ShowPage/CompletedWhite.svg');
          }
        }
      }

      .campaign-subtab-icon {
        border: 1px solid #5b6572;
      }

      &.ant-menu-item-selected {
        font-weight: 600;
      }

      &.shipment.ant-menu-item-selected {
        .campaign-subtab-icon {
          color: @color-black;

          .anticon {
            font-size: 51px;
            path {
              stroke: @color-white;
              fill: @color-black;
            }
          }
        }
      }

      &.social.ant-menu-item-selected {
        .campaign-subtab-icon {
          color: @color-black;

          .anticon {
            font-size: 51px;
            path {
              fill: @color-white;
              stroke: @color-black;
            }
            path.circle {
              fill: @color-black;
            }
          }
        }
      }

      &.pending_content.ant-menu-item-selected {
        .campaign-subtab-icon {
          color: @color-black;

          .anticon {
            font-size: 51px;

            path.circle {
              fill: @color-black;
            }
            path.line {
              fill: @color-white;
            }
          }
        }
      }

      &.creators.ant-menu-item-selected {
        .campaign-subtab-icon {
          color: @color-black;

          .anticon {
            font-size: 51px;
            path.circle {
              fill: @color-black;
            }
            path.line {
              fill: @color-white;
              stroke: @color-white;
            }
          }
        }
      }

      &-icon {
        background: @color-white;
        color: @color-white;
        width: 51px;
        height: 51px;
        border-radius: 51px;
        margin-right: 15px;
        display: inline-block;
        position: relative;

        .anticon {
          font-size: 51px;
        }
      }

      &-details {
        color: #5b6572;
        display: inline-block;
        text-align: left;

        &-count {
          .type-sfpro-heavy();
          font-size: 16px;
          line-height: 16px;
          margin-bottom: 6px;
        }

        &-label {
          font-size: 9px;
          line-height: 11px;
          letter-spacing: 0.5px;
          text-transform: uppercase;
        }
      }
    }

    .ant-badge {
      position: absolute;
      top: 0;
      right: 5px;
      .ant-badge-count {
        border: none;
        color: @color-black;
        box-shadow: none;
      }
    }
  }

  #shipments-content {
    .ant-btn {
      .type-sfpro-bold();
      height: 40px;
      width: auto;
      font-size: 12px;
      line-height: 14px;
      padding: 0 16px;
      text-align: center;
      white-space: wrap;

      &.secondary {
        background: @color-white;
      }
    }

    .shipment-list {
      @media (min-width: 700px) {
        padding: 0;
      }

      &-header {
        margin-top: 54px;
        padding-bottom: 1rem;

        > .ant-row-flex {
          max-width: 742px;
          margin: auto;
          padding: 0 16px;
        }
      }
      &.needsShipping {
        .shipment-list-title {
          color: #d8612e;
        }
      }

      &.shipped {
        .shipment-list-title {
          color: @color-black;
        }
      }
      &-title {
        .type-sfpro-heavy();
        font-size: 16px;
        line-height: 16px;
        margin-bottom: 9px;
      }

      &-description {
        .type-sfpro-light();
        font-weight: 400;
        font-size: 13px;
        line-height: 20px;
        width: 100%;
        color: @color-black;
        margin: 0;
        padding-right: 16px;
      }
      &-download {
        .type-sfpro-bold();
        height: auto;
        max-width: 100%;
        background: #f8faff;
        border: 1px solid #dbdfe8;
        box-sizing: border-box;
        border-radius: 2px;
        font-size: 10px;
        line-height: 12px;
        text-transform: none;
        text-align: center;
        padding: 12px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-around;

        @media (min-width: 700px) {
          height: 40px;
          text-align: left;
          gap: 14px;
          flex-wrap: nowrap;
        }

        .anticon {
          display: inline-block;
          line-height: 16px;
          font-size: 16px;
        }
        span {
          vertical-align: top;
          display: inline-block;
          font-size: 10px;
          line-height: 12px;
          margin: 0;
        }
      }
    }
    .trend-table {
      .cell-action {
        padding-left: 0;
        border-left: none;
        max-width: 100%;
        gap: 0.5rem;

        .ant-btn {
          border-radius: 3px;
          display: inline-block;
          letter-spacing: normal;
          white-space: nowrap;
        }
        .rehire-button {
          display: flex;
          align-items: center;
          justify-content: center;
          border: none;
          vertical-align: top;
          white-space: nowrap;

          &.awaiting {
            font-size: 11px;
            line-height: 13px;
            border: 1px solid #dbdfe8;
            span {
              text-align: left;
            }
          }
        }
      }
    }
  }

  .trend-table {
    .ant-table-thead {
      &:before,
      &:after {
        top: 27px;
      }

      & > tr > th {
        .type-sfpro-light();
        font-weight: 600;
        font-size: 9px;
        line-height: 11px;
        letter-spacing: 0.5px;
        text-transform: uppercase;
        height: 26px;
        padding: 8px !important;
        &.favorite-creator {
          width: 0;
        }
      }
    }
    .ant-table-tbody > tr > td {
      .type-sfpro-light();
      font-weight: 400;
    }

    .influencer-avatar {
      .type-sfpro-regular();
      display: flex;
    }

    .cell-action {
      .trend-btn {
        text-transform: none;
      }
    }
  }

  .favorite-button-container {
    padding-right: 8px;
    .favorite-button {
      background: transparent;
      border: none;
      width: min-content;
      box-shadow: none;
    }
  }

  .filter-favorites-button {
    border-radius: 26px;
    background: #ffffff;
    border: 1px solid #a9b0b9;
    border-radius: 22px;
    color: #5b6572;
    font-style: normal;
    height: 30px;
    &.active {
      background: #fff4f4;
      border: 1px solid #ff7474;
      color: #ff7474;
    }
    span {
      display: flex;
      align-items: center;
      #button-text {
        .type-sfpro-regular();
        font-weight: 400;
        font-size: 10px;
        line-height: 12px;
      }
    }
    svg {
      width: 12px;
      height: 11.33px;
    }
  }

  .router-container {
    padding-bottom: 80px;
    background: #ffffff;
    &.showPullListingBanner {
      padding-bottom: 280px;

      @media (min-width: 700px) {
        padding-bottom: 180px;
      }
    }
  }

  .campaign-footer {
    display: flex;
    flex-wrap: nowrap;
    gap: 1rem;
    justify-content: space-between;
    margin: 0 auto;
    max-width: 35rem;

    @media (min-width: 48rem) {
      max-width: 43.75rem;
    }
  }
}

th {
  &.favorite-creator {
    width: 0;
  }
}

.non-shippable-modal {
  border-radius: 10px;

  .ant-modal-content {
    background: #ffe6de;
    padding: 50px 20px;
    font-family: 'SFProDisplay';
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */

    .ant-modal-body {
      display: flex;
      align-items: center;
      align-content: center;
      justify-content: center;
      flex-direction: column;

      h2 {
        font-family: 'SFProDisplay';
        margin-top: 20px;
        text-align: center;
        padding: 0 50px;
        font-size: 24px;
      }

      p {
        font-family: 'SFProDisplay';
        text-align: center;
        padding: 0 20px;
        font-size: 16px;
        color: @color-black;
        font-weight: 600;
      }
    }

    .ant-modal-footer {
      border-top: none;
      margin-bottom: -20px;
      display: flex;
      justify-content: center;

      button {
        font-family: 'SFProDisplay';
        height: 50px;
        border-radius: 5px;
        font-style: normal;
        line-height: 18px;
        text-align: center;
        border: none;
        border-bottom: 2px solid rgba(0, 0, 0, 0.1);
        border-right: 2px solid rgba(0, 0, 0, 0.1);
        font-weight: 600;
        white-space: normal;

        &:hover {
          opacity: 0.8;
        }
      }

      .non-shippable-confirm-button {
        background: @color-white;
        color: @color-black;
      }

      .non-shippable-cancel-button {
        background: @color-black;
        color: @color-white;
      }
    }
  }
}

#message-container {
  border-bottom: 1px solid #e8e8e8;
}
