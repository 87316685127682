#basicsCampaign {
  color: @color-white;
  .title-container {
    display: inline-block;
    text-align: left;
    padding-bottom: 22px;
    .title {
      .type-sfpro-heavy();
      font-size: 24px;
      line-height: 30px;
    }
    .subtitle {
      .type-sfpro-regular();
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
    }
  }
  .video-wrapper {
    border-radius: 5px;
    background: #000000;
    margin-bottom: 51px;
    width: 439px;
    height: 209px;
    iframe {
      width: 100%;
      height: 100%;
    }
    #text-over {
      #title {
        font-weight: 800;
        font-size: 24px;
        line-height: 30px;
      }
      position:absolute; top:130px; left:20px; z-index:2
    }
  }
}
