.videos-preview {
  background: rgba(0, 0, 0, 0.75);

  .ant-modal-content {
    background: transparent;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  .ant-modal-body {
    padding: 0 !important;
  }

  .video-loader {
    display: flex;
    justify-content: center;

    video {
      border-radius: 4px;
      height: 85dvh;
    }
  }
}
