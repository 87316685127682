//Chere here for default themes https://github.com/ant-design/ant-design/blob/master/components/style/themes/default.less

@primary-color : #ffb917;
@info-color : #ffb916;
@input-addon-bg : transparent;

// Slider
@slider-track-background-color: @primary-color;
@slider-track-background-color-hover: @primary-color;

html,
body {
  height: auto;
  width: 100%;
}
