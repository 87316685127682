.trend-steps.steps {
  color: #fff;
  background: #151515;
  border-radius: 28.5px;
  height: 59px;
  width: 100%;
  padding-top: 15px;
  padding-left: 30px;

  .steps-item {
    display: inline-block;

    &:first-child {
      margin-right: 34px;
    }
    &:nth-child(2) {
      margin-right: 16px;
    }
    &-icon {
      .type-sfpro-regular();
      background: #151515;
      border: 1px solid #3c3c3c;
      color: #3c3c3c;
      display: inline-block;
      width: 28px;
      height: 28px;
      font-size: 12px;
      border-radius: 100%;
      text-align: center;
      line-height: 26px;
      margin-right: 8px;
    }

    &-label {
      .type-sfpro-regular();
      color: #747474;
      font-size: 12px;
    }

    &.active {
      .steps-item-icon {
        color: #000;
        background: #ffb917;
        border-color: #ffb917;
      }
      .steps-item-label {
        color: #fff;
        font-weight: bold;
      }
    }
  }
}
