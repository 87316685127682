.media-viewer-modal {
  .ant-modal-content {
    width: '100vh';
    background-color: transparent;
  }
  .ant-modal-body {
    padding: 0;
    width: '100vh';
  }

  .footer {
    text-align: right;
    .close.ant-btn {
      margin-top: 15px;
      height: 20px;
      padding: 0;
      .type-sfpro-bold();
      color: #fff;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 20px;
    }
  }
}
