.campaign-post-requirements {
  border-bottom: 0.0625rem solid @color-gray-lighter;
  padding: 1.5rem 1rem;

  .tag-list {
    .type-sfpro-bold();
    color: @color-black;
    display: flex;
    flex-direction: column;
    font-size: 0.75rem;
    gap: 0.5rem;
    list-style-type: none;
    margin-bottom: 1.5rem;
    padding: 0;

    li {
      display: flex;
      gap: 0.5rem;
    }

    span {
      display: flex;
      flex-direction: column;
    }
  }

  .caption {
    font-weight: 400;
  }

  .post-content {
    .type-sfpro-bold();
    align-items: center;
    color: @color-black;
    display: flex;
    font-size: 0.75rem;
    gap: 0.5rem;
    line-height: 1rem;
    margin-bottom: 1.5rem;
  }
}
