.creator-content-media-card {
  position: relative;
  max-width: 236px;
  max-height: 240px;
  overflow: hidden;

  .video-loader {
    width: 100%;

    .video-player-actions {
      cursor: pointer;
      left: calc(50% - 30px);
      top: calc(50% - 30px);
      position: absolute;
      width: 60px;
      height: 60px;
      z-index: 2;
      .anticon {
        font-size: 60px;
      }
    }

    img {
      height: 15rem;
      max-width: 15rem;
    }

    video {
      width: 100%;
      height: 236px !important;
      object-fit: cover !important;
      background: rgba(0, 0, 0, 0.65);
    }
  }
  .media-actions {
    border-radius: 0.5rem;
    overflow: hidden;
    height: 100%;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;

    .influencer-section {
      align-items: center;
      display: flex;
      flex-direction: column;
      left: 0;
      position: absolute;
      right: 0;
      top: 10px;
      width: 100%;

      .username {
        .type-sfpro-bold();
        font-size: 11px;
        line-height: 15px;
        color: #ffffff;
        margin-top: 6px;
      }
    }

    .action-button {
      bottom: 11px;
      position: absolute;
      text-align: center;
      width: 100%;
      padding: 0 10px;

      button {
        align-items: center;
        background: rgba(255, 255, 255, 0.3);
        border: 0 !important;
        border-radius: 2px;
        display: flex;
        height: 44px;
        justify-content: center;
        padding: 0;
        width: 100%;

        &:hover {
          background: rgba(255, 255, 255, 0.5);
        }

        .label {
          .type-sfpro-bold();
          color: white;
          font-size: 10px;
          line-height: 12px;
          margin-left: 8px;
          text-align: left;
        }
      }
      .rehire-button {
        .image-wrapper {
          margin-right: 6px;
          i {
            font-size: 16px;
          }
        }

        &.awaiting {
          .image-wrapper {
            display: inline-block;
            width: 28px;
            height: 28px;
            background-color: #fff;
            border-radius: 100%;
            padding: 5px 0 0 4px;

            img {
              width: 19px;
              height: auto;
            }
          }
        }
        span {
          .type-sfpro-bold();
          color: white;
          font-size: 10px;
          line-height: 12px;
          text-align: left;
        }
      }

      .shape {
        align-items: center;
        border-radius: 35px;
        display: flex;
        height: 28px;
        justify-content: center;
        width: 28px;

        &.send {
          background: #00b267;
        }

        &.download {
          background: #0056fe;
        }
      }
    }
  }

  .media-actions {
    opacity: 0;
    transition: all 250ms linear;
    .media-actions-overlay {
      background: rgba(0, 0, 0, 0.65);
      height: 100%;
      width: 100%;
      cursor: pointer;
      transition: all 500ms;
    }
  }
  &:hover .media-actions {
    opacity: 1;
  }
}
