#payouts-page {
  .ant-table .ant-table-thead th {
    text-align: left;
  }

  .ant-table .ant-table-row td {
    height: 50px;
  }
  .partnership-details {
    padding: 15px 10px 16px;
    .ant-descriptions-item-content {
      padding-left: 15px !important;
    }
  }

  .partnership-header {
    margin: 10px 0 10px;
  }
}
