.trend-modal.reject-post-modal {
  .close-modal {
    border: 1px solid #000000;
    box-sizing: border-box;
    border-radius: 4px;
    width: 45px;
    height: 45px;
    position: absolute;
    top: 20px;
    left: 20px;
    text-align: center;
    padding: 0px;

    .anticon {
      line-height: 45px;
      margin-top: 1px;
      font-size: 20px;
      svg {
        path {
          transition: stroke 0.5s;
        }
      }
    }

    &:hover {
      border-color: #ffcc40;
      .anticon {
        svg {
          path {
            transition: stroke 0.5s;
            stroke: #ffcc40;
          }
        }
      }
    }
  }

  .center-horizontally {
    display: flex;
    justify-content: center;
  }

  .modal-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #46c5ff;
    width: 60px;
    height: 60px;
    border-radius: 100%;
    margin-bottom: 11px;
    font-size: 35px;
  }
  .reject-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #e58989;
    width: 60px;
    height: 60px;
    border-radius: 100%;
    margin-bottom: 11px;

    .dash {
      height: 7px;
      background: @color-white;
      width: 32px;
      border-radius: 50px;
    }
  }

  .ant-modal-body {
    padding-top: 38px;
    padding-bottom: 39px;
  }

  h2 {
    .type-sfpro-heavy();
    font-style: normal;
    font-weight: 800;
    font-size: 18px;
    line-height: 26px;
    color: #000000;
    margin-bottom: 0px;
  }

  p {
    color: #000;
    .type-sfpro-light();
    font-style: normal;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 23px;
  }

  .ant-btn.submit {
    .type-sfpro-heavy();
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    color: #000;
    height: 45px;
    width: 155px;
  }

  textarea {
    .type-sfpro-light();
    font-style: normal;
    font-size: 14px !important;
    line-height: 20px;
    background: #f1f5fd;
    border-radius: 8px;
    border: none !important;
    width: 100%;
    min-height: 159px;

    &::placeholder {
      color: #5b6572;
    }
  }
}
