.creator-content-content-card {
  width: 200px;
  box-shadow: 0px 1px 5px rgba(143, 158, 187, 0.38);
  border-radius: 10px 10px;

  .video-loader {
    width: 200px;
    height: 200px;
    background-color: #000;
    border-radius: 10px 10px 0 0;
    img,
    video {
      border-radius: 10px 10px 0 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .card-action {
    border-radius: 0 0 10px 10px;
    background: #ffffff;
    height: 80px;
    padding: 23px 11px;

    .ant-btn {
      .type-sfpro-regular();
      width: 100%;
      height: 40px;
      line-height: 40px;
      font-weight: bold;
      font-size: 12px;
      line-height: 12px;
      border-radius: 5px;
      border: none;
      background-color: #f1f5fd;
      color: #8f9ebb;
      span {
        vertical-align: middle;
      }
      .anticon {
        font-size: 19px;
        vertical-align: middle;
        path {
          &.line {
            fill: #f1f5fd;
            stroke: #a8b3c8;
          }

          &.circle {
            fill: #f1f5fd;
            stroke: #a8b3c8;
          }
        }
      }
    }
  }

  &.approved {
    .ant-btn {
      background-color: #00b267;
      color: #fff;

      .anticon {
        path {
          &.line {
            fill: #fff;
            stroke: #fff;
          }

          &.circle {
            stroke: #00b267;
            stroke: #00b267;
          }
        }
      }
    }
  }
}
