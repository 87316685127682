.image-uploader {
  align-items: center;
  background-color: @color-black-bis;
  border-radius: 0.25rem;
  display: flex;
  height: 20.375rem;
  justify-content: center;
  overflow: hidden;
  position: relative;

  .uploaded-image {
    bottom: 0;
    height: 100%;
    left: 0;
    margin: auto;
    object-fit: contain;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
  }

  .change-photo-label {
    align-items: center;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    bottom: 1rem;
    display: flex;
    justify-content: center;
    position: absolute;
    right: 0;
    width: max-content;

    &:hover,
    &:focus {
      color: @color-black;
    }

    &:focus-visible {
      outline: revert;
    }
  }

  .icon {
    background-color: @gray-90;
    border-radius: 0.25rem;
    font-size: 1.375rem;
    padding: 1rem;
  }

  .uploader-label {
    align-items: center;
    color: @color-white;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    font-size: 1rem;
    height: 100%;
    justify-content: center;
    width: 100%;
  }
}
