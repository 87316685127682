.image-preview {
  background: rgba(0, 0, 0, 0.75);

  .ant-modal {
    width: 100% !important;
  }

  .ant-modal-content {
    background: transparent;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  .ant-modal-body {
    padding: 0 !important;

    img,
    video {
      display: block;
      height: 85dvh;
      margin: 0 auto;
    }
  }
}
